import React, { Component } from 'react';
import { AdContainerWhite } from './AdElements';
import "./adStyle.css";

class Ad3 extends Component {
  // componentDidMount() {
  //   (window.adsbygoogle = window.adsbygoogle || []).push({})
  // }

  render() {
    return (
      <div style={{ opacity: "0" }}>
        {/* <ins className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client="ca-pub-2605536906605283"
          data-ad-slot="4761203906"
          data-ad-format="auto"
          data-full-width-responsive="true">
        </ins> */}
      </div>
    )
  }

}

export default Ad3;
