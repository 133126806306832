import React, { useState, useEffect } from "react";
import {
  FacultyContainer,
  FacultyContent,
  FacultyItem,
} from "./ChoiceOfFacultyElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import isEmpty, { isNumber } from "../../../../helpers/validation";
import * as ApiCalls from "../../../../services/ApiCalls";
import background from "./../../../../images/background.jpg";
import Stepper from "../stepper/Stepper";
import WhiteAd from "../../../Ad/WhiteAd";
import Ad from "../../../Ad";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    width: "100%",
    minWidth: 120,
    // marginBottom: '2%'
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  multilineColor: {
    color: "red",
  },
}));

const ChoiceOfFaculty = (props) => {
  const classes = useStyles();
  const [regions, setRegions] = useState([]);
  const [faculties, setFaculties] = useState([]);
  const [zones, setZones] = useState([]);

  let scaled_obtained_marks = props.scaledObtainedMarks;
  if (props.qualification === "1") {
    scaled_obtained_marks = props.ninthScaledObtainedMarks;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    // getRegions();

    getFacultiesByStudyGroupIdAndSubjectId(
      props.studyGroup,
      props.subject,
      scaled_obtained_marks
    );
    props.setOpenBackDrop(false);
  }, []);

  // const getRegions = async () => {
  //   const res = await ApiCalls.getSindhRegions();
  //   if (res?.data?.status === 'success') {
  //     setRegions(res?.data?.data);
  //   }
  // }

  const getFacultiesByStudyGroupIdAndSubjectId = async (
    group_id,
    sub_group_id,
    scaled_obtained_marks
  ) => {
    const res = await ApiCalls.getFacultiesByStudyGroupIdAndSubjectId(
      group_id,
      sub_group_id,
      scaled_obtained_marks
    );
    if (res?.data?.status === "success") {
      setFaculties(res?.data?.data);
    }
  };

  const getZones = async (region_id) => {
    const res = await ApiCalls.getZones(region_id);
    if (res?.data?.status === "success") {
      setZones(res?.data?.data);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "faculty") {
      if (isNumber(value) && !isEmpty(value)) {
        props.setFaculty(value);
      }
    }
    if (name === "region") {
      if (isNumber(value) && !isEmpty(value)) {
        props.setRegion(value);
        if (scaled_obtained_marks < 595) {
          getZones(value);
        }
      }
    }

    if (name === "zone") {
      if (isNumber(value) && !isEmpty(value)) {
        props.setZone(value);
      }
    }
  };

  const handleBack = () => {
    props.setRegion("");
    props.setFaculty("");
    props.setZone("");
    props.setOpenBackDrop(true);
    setTimeout(() => {
      props.setStep(3);
    }, 1000);
  };

  const handleClick = async () => {
    if (isEmpty(props.region)) {
      props.setAlert("Please select Board!", "error");
    } else if (
      parseInt(props.region) === 1 &&
      scaled_obtained_marks < 595 &&
      isEmpty(props.zone)
    ) {
      props.setAlert("Please select Zone!", "error");
    } else if (isEmpty(props.faculty)) {
      props.setAlert("Please select Faculty!", "error");
    } else {
      props.setOpenBackDrop(true);
      setTimeout(() => {
        props.setStep(5);
      }, 1000);
    }
  };

  return (
    <div>
      <FacultyContainer style={{ backgroundImage: `url(${background})` }}>
        <FacultyContent>
          <Stepper step={props.step} />
          <FacultyItem>
            <div style={{ marginBottom: "5%" }}>
              <h1>Choice of Faculty</h1>
            </div>

            <FormControl
              style={{ marginBottom: "10px" }}
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-region">Select Board</InputLabel>
              <Select
                id="outlined-region"
                native
                value={props.region}
                onChange={handleChange}
                label="Select Board"
                name="region"
              >
                <option aria-label="None" value="" />
                <option value="1">KARACHI</option>
                <option value="2">HYDERABAD</option>
                <option value="3">MIRPURKHAS</option>
                <option value="4">SHAHEED BENAZIRABAD</option>
                <option value="5">LARKANA</option>
                <option value="6">SUKKUR</option>
              </Select>
            </FormControl>

            {parseInt(props.region) === 1 && scaled_obtained_marks < 595 && (
              <FormControl
                style={{ marginBottom: "10px" }}
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-zone">Select Zone</InputLabel>
                <Select
                  native
                  id="outlined-zone"
                  value={props?.zone}
                  onChange={handleChange}
                  label="Select Zone"
                  name="zone"
                >
                  <option aria-label="None" value="" />
                  {zones?.length > 0 &&
                    zones?.map((item) => (
                      <option value={item?.id}>{item?.zone}</option>
                    ))}
                </Select>
              </FormControl>
            )}

            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-faculty">
                Select Choice of Faculty
              </InputLabel>
              <Select
                native
                id="outlined-faculty"
                value={props.faculty}
                onChange={handleChange}
                label="Select Choice of Faculty"
                name="faculty"
              >
                <option aria-label="None" value="" />
                {faculties?.length > 0 &&
                  faculties?.map((item) => (
                    <option value={item?.id}>{item?.faculty}</option>
                  ))}
              </Select>
            </FormControl>

            <div style={{ marginTop: "5%" }}>
              <Button
                variant="contained"
                color="default"
                style={{ marginRight: "1rem" }}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Save {"&"} Continue
              </Button>
            </div>
          </FacultyItem>
          <WhiteAd />
        </FacultyContent>
      </FacultyContainer>
      <Ad />
    </div>
  );
};

export default ChoiceOfFaculty;
