import styled from "styled-components";

export const FragmentContainer = styled.div`
  background: #fff;
  height: auto;
  margin-top: 20px;
`;

export const FragmentHeader = styled.div`
  background: #fff;
  padding: 20px 20px;
`;

export const FragmentHeaderH1 = styled.h1`
  color: grey;
`;

export const FragmentContent = styled.div`
  background: #fff;
  padding: 20px 20px;
`;
