import React, { useState, useEffect } from "react";
import {
  PersonalContainer,
  PersonalContent,
  PersonalItem,
  PersonalHeading,
} from "./../NewApplication/components/personal-info-form/PersonalInfoFormElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import isEmpty, {
  isNumber,
  isName,
  isEmail,
  isCNIC,
  isNumberOrDash,
} from "./../../helpers/validation";
import * as ApiCalls from "./../../services/ApiCalls";
import background from "./../../images/background.jpg";
// import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Grid from "@material-ui/core/Grid";

import RegionsData from "./../../data/regions.json";
import DistrictsData from "./../../data/districts.json";
import SubdivisionsData from "./../../data/subdivisions.json";
import ReligionsData from "./../../data/religions.json";
import { getUser } from "./../../functions/functions";
import WhiteAd from "../Ad/WhiteAd";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(0),
    width: "100%",
    minWidth: 120,
    // marginBottom: '1%'
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  multilineColor: {
    color: "red",
  },
}));

const PersonalInfoForm = (props) => {
  const [addressDistricts, setAddressDistricts] = useState([]);
  const [addressTowns, setAddressTowns] = useState([]);
  const [colleges, setColleges] = useState([]);
  const classes = useStyles();

  const user = getUser(localStorage?.getItem("jwtToken"));

  useEffect(async () => {
    window.scrollTo(0, 0);
    await getAddressDistrictsByRegionId(props.collegeRegion);
    await getAddressTownsByDistrictId(props.collegeDistrict);

    // if (props.imageProfileName) await props.setShowProfileImage(`${ApiCalls.profileImageURL}/${props.imageProfileName}`);
    props.setOpenBackDrop(false);
  }, []);

  const getAddressTownsByDistrictId = async (district_id) => {
    const subdivisions = SubdivisionsData?.filter(
      (item) => item.district_id == district_id
    );
    setAddressTowns(subdivisions);
  };

  const getAddressDistrictsByRegionId = async (region_id) => {
    const districts = DistrictsData?.filter(
      (item) => item.region_id == region_id
    );
    setAddressDistricts(districts);
  };

  const getCollegesByDistrictId = async (district_id) => {
    const res = await ApiCalls.getCollegesByDistrictId(district_id);
    if (res?.data?.data?.length > 0) {
      setColleges(res.data.data);
    }
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (name === "fullName") {
      if (isName(value) && value.length < 51) {
        props.setFullName(value);
      }
    }
    if (name === "fatherName") {
      if (isName(value) && value.length < 51) {
        props.setFatherName(value);
      }
    }
    if (name === "email") {
      if (value.length < 101) {
        props.setEmail(value);
      }
    }
    if (name === "mobileNumber") {
      if ((isNumber(value) || value === "") && value.length < 12) {
        props.setMobileNumber(value);
      }
    }
    if (name === "gender") {
      if (value === "1" || value === "2") {
        props.setGender(value);
      }
    }

    if (name === "dateofbirth") {
      props.setDateOfBirth(value);
    }
    if (name === "cnic") {
      if (isNumberOrDash(value) && value.length < 16) {
        props.setCnic(value);
      }
    }

    if (name === "region") {
      await props.setCollegeRegion(value);
      await props.setCollegeDistrict("");
      await props.setCollege("");
      await setColleges([]);
      await setAddressDistricts([]);
      await getAddressDistrictsByRegionId(value);
    }
    if (name === "district") {
      await props.setCollegeDistrict(value);
      await props.setCollege("");
      await setColleges([]);
      await getCollegesByDistrictId(value);
    }
    if (name === "college") {
      props.setCollege(value);
    }
    if (name === "homeAddress") {
      if (value.length < 101) {
        props.setHomeAddress(value);
      }
    }
  };

  const onSelectProfileFile = async (event) => {
    const imagesLength = event.target.files.length;
    if (imagesLength > 1) {
      props.setAlert("Upload only 1 Image!", "error");
    } else {
      const selectedFiles = event.target.files;
      const selectedFilesArray = Array.from(selectedFiles);
      props.setProfileImages(selectedFilesArray);

      const imagesArray = selectedFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });

      props.setSelectedProfileImages(imagesArray);

      // FOR BUG IN CHROME
      event.target.value = "";
    }
  };

  // const handleImage = (e) => {
  //   const img = e.target.files[0];
  //   props.setShowProfileImage(URL.createObjectURL(img));
  //   props.setImageProfile(e.target.files[0]);
  //   props.setImageProfileName(e.target.files[0].name);

  // }

  const handleBack = () => {
    props.setOpenBackDrop(true);
    setTimeout(() => {
      props.setStep(1);
    }, 1000);
  };

  const handleClick = async () => {
    if (isEmpty(props?.image_path) && props?.profileImages.length < 1) {
      props.setAlert("Profile Picture is required!", "error");
    } else if (isEmpty(props.fullName)) {
      props.setAlert("Please fill out Full Name!", "error");
    } else if (!isName(props.fullName)) {
      props.setAlert(
        "Full Name field contains only letters and white spaces!",
        "error"
      );
    } else if (props.fullName.trim().length > 50) {
      props.setAlert(
        "Full Name field cannot contain maximum than 50 characters!",
        "error"
      );
    } else if (isEmpty(props.fatherName)) {
      props.setAlert("Please fill out Father Name!", "error");
    } else if (!isName(props.fatherName)) {
      props.setAlert(
        "Father Name field contains only letters and white spaces!",
        "error"
      );
    } else if (props.fatherName.length > 50) {
      props.setAlert(
        "Father Name field cannot contain maximum than 50 characters!",
        "error"
      );
    } else if (isEmpty(props.email)) {
      props.setAlert("Please fill out Email!", "error");
    } else if (!isEmail(props.email)) {
      props.setAlert("Please fill out valid Email!", "error");
    } else if (props.email.length > 100) {
      props.setAlert(
        "Email field cannot contain maximum than 100 characters!",
        "error"
      );
    } else if (isEmpty(props.mobileNumber)) {
      props.setAlert("Please fill out Mobile Number!", "error");
    } else if (!isNumber(props.mobileNumber)) {
      props.setAlert("Mobile Number field contains only numbers!", "error");
    } else if (props.mobileNumber.length !== 11) {
      props.setAlert("Mobile Number field contains only 11 numbers!", "error");
    } else if (isEmpty(props.gender)) {
      props.setAlert("Please select Gender!", "error");
    } else if (props.gender !== "1" && props.gender !== "2") {
      props.setAlert("Please select valid Gender!", "error");
    } else if (isEmpty(props.dateOfBirth)) {
      props.setAlert("Please fill out Date of Birth!", "error");
    } else if (isEmpty(props.cnic)) {
      props.setAlert("Please fill out CNIC / B-FORM No!", "error");
    } else if (!isCNIC(props.cnic)) {
      props.setAlert("Please fill out valid CNIC / B-FORM No!", "error");
    } else if (props.cnic.length !== 15) {
      props.setAlert(
        "CNIC / B-FORM No field contains only 15 characters!",
        "error"
      );
    } else if (isEmpty(props.collegeRegion)) {
      props.setAlert("Please select College Region!", "error");
    } else if (!isEmpty(addressDistricts) && isEmpty(props.collegeDistrict)) {
      props.setAlert("Please select College District!", "error");
    } else if (isEmpty(props.college)) {
      props.setAlert("Please select your College!", "error");
    } else if (isEmpty(props.homeAddress)) {
      props.setAlert("Please Enter Home Address!", "error");
    } else if (props.email.homeAddress > 100) {
      props.setAlert(
        "Home Address field cannot contain maximum than 100 characters!",
        "error"
      );
    } else {
      props.setOpenBackDrop(true);
      setTimeout(() => {
        props.setStep(3);
      }, 1000);
    }
  };

  return (
    <div>
      <PersonalContainer style={{ backgroundImage: `url(${background})` }}>
        <PersonalContent>
          <PersonalItem>
            <div style={{ marginBottom: "5%" }}>
              <PersonalHeading>Personal Details</PersonalHeading>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="mb-14">
                  <h3>{"Profile picture"}</h3>
                  <section>
                    <label className="my-label">
                      <input
                        className="my-input"
                        type="file"
                        name="images_profile"
                        onChange={onSelectProfileFile}
                        multiple
                        accept="image/png , image/jpeg, image/webp"
                      />
                    </label>
                    <br />

                    <div>
                      {props?.selectedProfileImages?.length > 0 ? (
                        props?.selectedProfileImages?.map((image, index) => {
                          return (
                            <div key={image} className="image">
                              <img
                                style={{ border: "3px solid #fff" }}
                                className="shadow-md"
                                src={image}
                                width="200"
                                alt="upload"
                              />
                            </div>
                          );
                        })
                      ) : (
                        <div className="image">
                          <img
                            style={{ border: "3px solid #fff" }}
                            className="shadow-md"
                            src={
                              "https://www.dgcs.gos.pk/seccap2/public/student-profile-pictures/" +
                              props?.image_path
                            }
                            width="200"
                            alt="profile-picture"
                          />
                        </div>
                      )}
                    </div>
                  </section>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-name"
                    label="Full Name"
                    type="text"
                    variant="outlined"
                    name="fullName"
                    value={props.fullName}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-father"
                    label="Father Name"
                    type="text"
                    variant="outlined"
                    name="fatherName"
                    value={props.fatherName}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-email"
                    placeholder="email@email.com"
                    label="Email Address"
                    type="email"
                    variant="outlined"
                    name="email"
                    value={props.email}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-mobile"
                    placeholder="03001234567"
                    label="Mobile Number"
                    type="text"
                    variant="outlined"
                    name="mobileNumber"
                    value={props.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-gender">
                    Select Gender
                  </InputLabel>
                  <Select
                    native
                    value={props.gender}
                    onChange={handleChange}
                    label="Select Gender"
                    name="gender"
                  >
                    <option aria-label="None" value="" />
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <form className={classes.container} noValidate>
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      name="dateofbirth"
                      id="outlined-date"
                      label="Date of Birth"
                      type="date"
                      variant="outlined"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={props.dateOfBirth}
                      onChange={handleChange}
                    />
                  </form>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-mycnic"
                    placeholder="12345-1234567-1"
                    label="CNIC / B-FORM No"
                    type="text"
                    variant="outlined"
                    name="cnic"
                    value={props.cnic}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                {RegionsData?.length > 0 && (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-region">
                      College Region
                    </InputLabel>
                    <Select
                      native
                      value={props.collegeRegion}
                      onChange={handleChange}
                      id="outlined-region"
                      label="College Region"
                      name="region"
                    >
                      <option aria-label="None" value="" />
                      {RegionsData?.length > 0 &&
                        RegionsData?.map((item) => (
                          <option value={item?.id}>{item?.region}</option>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              {addressDistricts?.length > 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-district">
                      College District
                    </InputLabel>
                    <Select
                      native
                      value={props.collegeDistrict}
                      onChange={handleChange}
                      id="outlined-district"
                      label="College District"
                      name="district"
                    >
                      <option aria-label="None" value="" />
                      {addressDistricts?.length > 0 &&
                        addressDistricts?.map((item) => (
                          <option value={item?.id}>{item?.district}</option>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {colleges?.length > 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-college">College</InputLabel>
                    <Select
                      native
                      value={props.college}
                      onChange={handleChange}
                      id="outlined-college"
                      label="College"
                      name="college"
                    >
                      <option aria-label="None" value="" />
                      {colleges?.length > 0 &&
                        colleges?.map((item) => (
                          <option value={item?.id}>
                            {item?.ddo_code} {item?.college_name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-home-address"
                    label="Home Address"
                    type="text"
                    variant="outlined"
                    name="homeAddress"
                    value={props.homeAddress}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
            </Grid>

            <div style={{ marginTop: "5%" }}>
              <Button
                variant="contained"
                color="default"
                style={{ marginRight: "1rem" }}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Save {"&"} Continue
              </Button>
            </div>
          </PersonalItem>
          <WhiteAd />
        </PersonalContent>
      </PersonalContainer>
    </div>
  );
};

export default PersonalInfoForm;
