import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { FragmentContainer, FragmentContent, FragmentHeader, FragmentHeaderH1 } from './FragmentsElements';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty, { isName, isNumber, isEmail, isCNIC } from "../../../helpers/validation";
import CustomAlert from "../../common/CustomAlert";
import Divider from '@material-ui/core/Divider';
import * as ApiCalls from '../../../services/ApiCalls';


import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import DistrictsData from '../../../data/districts.json';
import { passingYears } from '../../../data/passingyears';
import { getUser } from '../../../functions/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  formControl: {
    width: "100%",
    minWidth: 120,
    marginTop: '0px'
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  multilineColor: {
    color: 'red'
  },
}));

const EditStudentEducationInfo = (props) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');

  const classes = useStyles();

  const [faculties, setFaculties] = useState([]);

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const passingYearsClass8 = [
    props?.studentDetailsInCollege?.passing_year - 4,
    props?.studentDetailsInCollege?.passing_year - 3,
    props?.studentDetailsInCollege?.passing_year - 2
  ];

  const passingYearsClass5 = [
    props?.studentDetailsInCollege?.passing_year - 8,
    props?.studentDetailsInCollege?.passing_year - 7,
    props?.studentDetailsInCollege?.passing_year - 6,
    props?.studentDetailsInCollege?.passing_year - 5
  ]

  const year = new Date().getFullYear();
  const previousyear = year - 1;
  const beforeTwoYear = year - 2;
  const beforeThreeYear = year - 3;
  const scaled_total_marks = 850;

  const user = getUser(localStorage?.jwtToken);

  useEffect(async () => {
    window.scrollTo(0, 0);
    let scaled_obtained_marks;
    if (props?.studentDetailsInCollege?.scaled_obtained_marks) {
      if (props?.studentDetailsInCollege?.scaled_obtained_marks == 0) {
        scaled_obtained_marks = props?.studentDetailsInCollege?.ninth_scaled_obtained_marks;
      } else {
        scaled_obtained_marks = props?.studentDetailsInCollege?.scaled_obtained_marks;
      }
    } else {
      scaled_obtained_marks = props?.studentDetailsInCollege?.ninth_scaled_obtained_marks;
    }
    getFacultiesByStudyGroupIdAndSubjectId(props?.studentDetailsInCollege?.group_id, props?.studentDetailsInCollege?.sub_group_id, scaled_obtained_marks, user?.collegeId, props?.studentDetailsInCollege?.gender);
  }, []);

  const getFacultiesByStudyGroupIdAndSubjectId = async (group_id, sub_group_id, scaled_obtained_marks, college_id, gender) => {
    let gender_id = gender === "MALE" ? 1 : 2;

    const res = await ApiCalls.getFacultiesByStudyGroupIdAndSubjectIdWithCollegeId(group_id, sub_group_id, scaled_obtained_marks, college_id, gender_id);
    if (res?.data?.status === 'success') {
      setFaculties(res?.data?.data);
    }
  }

  const handleBack = () => {
    props.setFragment("studentdetailsincollege");
  }

  const handleClick = async () => {
    // if (isEmpty(props?.studentDetailsInCollege?.matric_roll_no)) {
    //   setAlert('Please fill out Maric Roll No!', 'error');
    // } else if (props?.studentDetailsInCollege?.matric_roll_no.length > 10) {
    //   setAlert("Maric Roll No field cannot contain maximum than 10 numbers!", 'error');
    // } else if (isEmpty(props?.studentDetailsInCollege?.passing_year)) {
    //   setAlert('Please select Matric Passing Year!', 'error');
    // } else if (props?.studentDetailsInCollege?.passing_year.length > 4) {
    //   setAlert("Matric Passing Year field cannot contain maximum than 4 numbers!", 'error');
    // } else if (isEmpty(props?.studentDetailsInCollege?.school_name)) {
    //   setAlert('Please fill out Matric School Name!', 'error');
    // } else if (props?.studentDetailsInCollege?.school_name.length > 100) {
    //   setAlert("Matric School Name field cannot contain maximum than 100 characters!", 'error');
    // } else if (isEmpty(props?.studentDetailsInCollege?.total_marks)) {
    //   setAlert('Please fill out Total Marks!', 'error');
    // } else if (!isNumber(props?.studentDetailsInCollege?.total_marks)) {
    //   setAlert('Total Marks field contains only numbers!', 'error');
    // } else if (props?.studentDetailsInCollege?.total_marks.length > 4) {
    //   setAlert("Total Marks field cannot contain maximum than 4 numbers!", 'error');
    // } else if (isEmpty(props?.studentDetailsInCollege?.obtained_marks)) {
    //   setAlert('Please fill out Obtained Marks!', 'error');
    // } else if (!isNumber(props?.studentDetailsInCollege?.obtained_marks)) {
    //   setAlert('Obtained Marks field contains only numbers!', 'error');
    // } else if (props?.studentDetailsInCollege?.obtained_marks.length > 4) {
    //   setAlert("Obtained Marks field cannot contain maximum than 4 numbers!", 'error');
    // } else if (parseInt(props?.studentDetailsInCollege?.obtained_marks) > parseInt(props?.studentDetailsInCollege?.total_marks)) {
    //   setAlert("Obtained Marks cannot be greater than Total Marks!", 'error');
    // } else if (isEmpty(props?.studentDetailsInCollege?.class8_passing_year)) {
    //   setAlert('Please select Class 8 Passing Year!', 'error');
    // } else if (isEmpty(props?.studentDetailsInCollege?.class8_passing_district_id)) {
    //   setAlert('Please select Class 8 District!', 'error');
    // } else if (isEmpty(props?.studentDetailsInCollege?.class8_school_name)) {
    //   setAlert('Please fill out CLass 8 School Name!', 'error');
    // } else if (props?.studentDetailsInCollege?.class8_school_name.length > 100) {
    //   setAlert("CLass 8 School Name field cannot contain maximum than 100 characters!", 'error');
    // } else if (isEmpty(props?.studentDetailsInCollege?.class5_passing_year)) {
    //   setAlert('Please select Class 5 Passing Year!', 'error');
    // } else if (isEmpty(props?.studentDetailsInCollege?.class5_passing_district_id)) {
    //   setAlert('Please select Class 5 District!', 'error');
    // } else if (isEmpty(props?.studentDetailsInCollege?.class5_school_name)) {
    //   setAlert('Please fill out CLass 5 School Name!', 'error');
    // } else if (props?.studentDetailsInCollege?.class5_school_name.length > 100) {
    //   setAlert("CLass 5 School Name field cannot contain maximum than 100 characters!", 'error');
    // } else 
    if (isEmpty(props?.studentDetailsInCollege?.faculty_id)) {
      setAlert('Please select faculty!', 'error');
    } else {

      let totalMarks;
      let obtainedMarks;
      if (props.studentDetailsInCollege.total_marks) {
        if (props.studentDetailsInCollege.total_marks == 0) {
          totalMarks = props.studentDetailsInCollege.ninth_total_marks;
        } else {
          totalMarks = props.studentDetailsInCollege.total_marks;
        }
      } else {
        totalMarks = props.studentDetailsInCollege.ninth_total_marks;
      }

      if (props.studentDetailsInCollege.obtained_marks) {
        if (props.studentDetailsInCollege.obtained_marks == 0) {
          obtainedMarks = props.studentDetailsInCollege.ninth_obtained_marks;
        } else {
          obtainedMarks = props.studentDetailsInCollege.obtained_marks;
        }
      } else {
        obtainedMarks = props.studentDetailsInCollege.ninth_obtained_marks;
      }

      let scaled_obtained_marks;
      if (props?.studentDetailsInCollege?.scaled_obtained_marks) {
        if (props?.studentDetailsInCollege?.scaled_obtained_marks == 0) {
          scaled_obtained_marks = props?.studentDetailsInCollege?.ninth_scaled_obtained_marks;
        } else {
          scaled_obtained_marks = props?.studentDetailsInCollege?.scaled_obtained_marks;
        }
      } else {
        scaled_obtained_marks = props?.studentDetailsInCollege?.ninth_scaled_obtained_marks;
      }

      const data = {
        matric_roll_no: props.studentDetailsInCollege.matric_roll_no,
        passing_year: props.studentDetailsInCollege.matric_passing_year || props.studentDetailsInCollege.ninth_passing_year,
        school_name: props.studentDetailsInCollege.school_name,
        total_marks: totalMarks,
        obtained_marks: obtainedMarks,
        scaled_obtained_marks,
        class8_passing_year: props.studentDetailsInCollege.class8_passing_year,
        class8_passing_district_id: props.studentDetailsInCollege.class8_passing_district_id,
        class8_school_name: props.studentDetailsInCollege.class8_school_name,
        class5_passing_year: props.studentDetailsInCollege.class5_passing_year,
        class5_passing_district_id: props.studentDetailsInCollege.class5_passing_district_id,
        class5_school_name: props.studentDetailsInCollege.class5_school_name,
        faculty_id: props.studentDetailsInCollege.faculty_id,
        applied_id: props.studentDetailsInCollege.id,
        gender_id: props.studentDetailsInCollege.gender === "MALE" ? 1 : 2,
        college_id: user.collegeId,
      };

      if (1 === 2) {

      } else {
        const response = await axios.post('https://www.dgcs.gos.pk/seccap2/students/updateStudentEducationInfo', data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });
        if (response?.data?.error) {
          setAlert(response?.data?.error?.message, 'error');
        } else if (response?.data?.data) {
          setAlert("Data has been updated successfully!", 'success');
          setTimeout(() => {
            props.setFragment(props.prevFragment);
          }, 1000);

        } else {
          setAlert("Something went wrong, please try again!", 'error');
        }
      }





    }

  }

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (name === 'matricRollNo') {
      if ((isNumber(value) || value === '') && value.length < 11) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            matric_roll_no: value,
          }
        });
      }
    }

    if (name === 'passingYear') {

      let scaled_obtained_marks = props.studentDetailsInCollege.scaled_obtained_marks;

      if (value !== "") {
        scaled_obtained_marks = (props.studentDetailsInCollege.obtained_marks / props.studentDetailsInCollege.total_marks) * scaled_total_marks;

        if (value == previousyear) {
          scaled_obtained_marks = scaled_obtained_marks - 5;
        } else if (value == beforeTwoYear) {
          scaled_obtained_marks = scaled_obtained_marks - 10;
        } else if (value == beforeThreeYear) {
          scaled_obtained_marks = scaled_obtained_marks - 15;
        }

        if (scaled_obtained_marks < 0) {
          scaled_obtained_marks = 0;
        }

      }

      if ((isNumber(value) || value === '') && value.length < 5) {
        getFacultiesByStudyGroupIdAndSubjectId(props?.studentDetailsInCollege?.group_id, props?.studentDetailsInCollege?.sub_group_id, scaled_obtained_marks, user?.collegeId, props?.studentDetailsInCollege?.gender);

        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            passing_year: value,
            scaled_obtained_marks,
            faculty_id: '',
          }
        });
      }

    }

    if (name === 'schoolName') {
      if (value.length < 101) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            school_name: value,
          }
        });
      }
    }

    if (name === 'totalMarks') {
      if ((isNumber(value) || value === '') && value.length < 5) {
        if (parseInt(value) < parseInt(props.studentDetailsInCollege.obtained_marks) || value === '') {
          let totalMarks = value;

          let scaled_obtained_marks = 0;

          if (value !== "") {
            scaled_obtained_marks = (value / totalMarks) * scaled_total_marks;
          }


          if (props.studentDetailsInCollege.passing_year == previousyear) {
            scaled_obtained_marks = scaled_obtained_marks - 5;
          } else if (props.studentDetailsInCollege.passing_year == beforeTwoYear) {
            scaled_obtained_marks = scaled_obtained_marks - 10;
          } else if (props.studentDetailsInCollege.passing_year == beforeThreeYear) {
            scaled_obtained_marks = scaled_obtained_marks - 15;
          }

          if (scaled_obtained_marks < 0) {
            scaled_obtained_marks = 0;
          }

          getFacultiesByStudyGroupIdAndSubjectId(props?.studentDetailsInCollege?.group_id, props?.studentDetailsInCollege?.sub_group_id, scaled_obtained_marks, user?.collegeId, props?.studentDetailsInCollege?.gender);

          props.setStudentDetailsInCollege((prevState) => {
            return {
              ...prevState,
              obtained_marks: value,
              scaled_obtained_marks,
              faculty_id: '',
            }
          });
        } else {
          let totalMarks = value;

          let scaled_obtained_marks = (props.studentDetailsInCollege.obtained_marks / totalMarks) * scaled_total_marks;

          if (props.studentDetailsInCollege.passing_year == previousyear) {
            scaled_obtained_marks = scaled_obtained_marks - 5;
          } else if (props.studentDetailsInCollege.passing_year == beforeTwoYear) {
            scaled_obtained_marks = scaled_obtained_marks - 10;
          } else if (props.studentDetailsInCollege.passing_year == beforeThreeYear) {
            scaled_obtained_marks = scaled_obtained_marks - 15;
          }

          if (scaled_obtained_marks < 0) {
            scaled_obtained_marks = 0;
          }

          getFacultiesByStudyGroupIdAndSubjectId(props?.studentDetailsInCollege?.group_id, props?.studentDetailsInCollege?.sub_group_id, scaled_obtained_marks, user?.collegeId, props?.studentDetailsInCollege?.gender);

          props.setStudentDetailsInCollege((prevState) => {
            return {
              ...prevState,
              total_marks: value,
              scaled_obtained_marks,
              faculty_id: "",
            }
          });
        }

      }
    }

    if (name === 'obtainedMarks') {
      if ((isNumber(value) || value === '') && value.length < 5) {
        if (parseInt(value) > parseInt(props.studentDetailsInCollege.total_marks)) {
          setAlert('Obtained Marks can not be greater than Total Marks', 'error');
        } else {
          let totalMarks = props.studentDetailsInCollege.total_marks;
          let scaled_obtained_marks = (value / totalMarks) * scaled_total_marks;

          if (props.studentDetailsInCollege.passing_year == previousyear) {
            scaled_obtained_marks = scaled_obtained_marks - 5;
          } else if (props.studentDetailsInCollege.passing_year == beforeTwoYear) {
            scaled_obtained_marks = scaled_obtained_marks - 10;
          } else if (props.studentDetailsInCollege.passing_year == beforeThreeYear) {
            scaled_obtained_marks = scaled_obtained_marks - 15;
          }

          getFacultiesByStudyGroupIdAndSubjectId(props?.studentDetailsInCollege?.group_id, props?.studentDetailsInCollege?.sub_group_id, scaled_obtained_marks, user?.collegeId, props?.studentDetailsInCollege?.gender);

          props.setStudentDetailsInCollege((prevState) => {
            return {
              ...prevState,
              obtained_marks: value,
              scaled_obtained_marks,
              faculty_id: '',
            }
          });
        }
      }
    }

    if (name === 'passingYearClass8') {
      props.setStudentDetailsInCollege((prevState) => {
        return {
          ...prevState,
          class8_passing_year: value,
        }
      });
    }

    if (name === 'districtClass8') {
      props.setStudentDetailsInCollege((prevState) => {
        return {
          ...prevState,
          class8_passing_district_id: value,
        }
      });
    }

    if (name === 'schoolNameClass8') {
      if (value.length < 101) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            class8_school_name
              : value,
          }
        });
      }
    }

    if (name === 'passingYearClass5') {
      props.setStudentDetailsInCollege((prevState) => {
        return {
          ...prevState,
          class5_passing_year: value,
        }
      });
    }

    if (name === 'districtClass5') {
      props.setStudentDetailsInCollege((prevState) => {
        return {
          ...prevState,
          class5_passing_district_id: value,
        }
      });
    }

    if (name === 'schoolNameClass5') {
      if (value.length < 101) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            class5_school_name
              : value,
          }
        });
      }
    }

    if (name === 'faculty') {
      if (isNumber(value) && !isEmpty(value)) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            faculty_id: value,
          }
        });
      }
    }

  };

  return (
    <div>
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item xs={6} md={6}>
                <FragmentHeaderH1>Edit Student's Educational Info</FragmentHeaderH1>
              </Grid>
              <Grid item xs={6} md={6}>
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider />
        <Container fixed>
          <FragmentContent>
            <Grid container spacing={2}>

              <Grid container item xs={9} md={9}>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-college" label="Matric Roll No" name="matricRollNo" value={props?.studentDetailsInCollege?.matric_roll_no} onChange={handleChange} type="text" variant="outlined" />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-passing-year">Matric Passing Year</InputLabel>
                        <Select
                          native
                          value={props?.studentDetailsInCollege?.passing_year}
                          onChange={handleChange}
                          label="Matric Passing Year"
                          name="passingYear"
                        >
                          <option aria-label="None" value="" />
                          {passingYears.map(item => (
                            <option value={item}>{item}</option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-school" label="Matric School Name" type="text" variant="outlined" name="schoolName" value={props?.studentDetailsInCollege?.school_name} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-total" label="Matric Total Marks" type="text" variant="outlined" name="totalMarks" value={props?.studentDetailsInCollege?.total_marks} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-obtained" label="Matric Obtained Marks" type="text" variant="outlined" name="obtainedMarks" value={props?.studentDetailsInCollege?.obtained_marks} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    {DistrictsData?.length > 0 && (
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-district-class8">Class 8 Passing District</InputLabel>
                        <Select
                          native
                          value={props?.studentDetailsInCollege?.class8_passing_district_id}
                          onChange={handleChange}
                          label="Class 8 Passing District"
                          name="districtClass8"
                          id="outlined-district-class8"
                        >
                          <option aria-label="None" value="" />
                          {DistrictsData?.length > 0 && DistrictsData?.map(item => (
                            <option value={item?.id}>{item?.district}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-passing-year-class8">Class 8 Passing Year</InputLabel>
                        <Select
                          native
                          value={props?.studentDetailsInCollege?.class8_passing_year}
                          onChange={handleChange}
                          label="Class 8 Passing Year"
                          name="passingYearClass8"
                          id="outlined-passing-year-class8"
                        >
                          <option aria-label="None" value="" />
                          {passingYearsClass8.map(item => (
                            <option value={item}>{item}</option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-school-class8" label="Class 8 School Name" type="text" variant="outlined" name="schoolNameClass8" value={props?.studentDetailsInCollege?.class8_school_name} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    {DistrictsData?.length > 0 && (
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-district-class5">Class 5 Passing District</InputLabel>
                        <Select
                          native
                          value={props?.studentDetailsInCollege?.class5_passing_district_id}
                          onChange={handleChange}
                          label="Class 5 Passing District"
                          name="districtClass5"
                          id="outlined-district-class5"
                        >
                          <option aria-label="None" value="" />
                          {DistrictsData?.length > 0 && DistrictsData?.map(item => (
                            <option value={item?.id}>{item?.district}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-passing-year-class5">Class 5 Passing Year</InputLabel>
                        <Select
                          native
                          value={props?.studentDetailsInCollege?.class5_passing_year}
                          onChange={handleChange}
                          label="Class 5 Passing Year"
                          name="passingYearClass5"
                          id="outlined-passing-year-class5"
                        >
                          <option aria-label="None" value="" />
                          {passingYearsClass5.map(item => (
                            <option value={item}>{item}</option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-school-class5" label="Class 5 School Name" type="text" variant="outlined" name="schoolNameClass5" value={props?.studentDetailsInCollege?.class5_school_name} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                      <InputLabel htmlFor="outlined-faculty">Select Choice of Faculty</InputLabel>
                      <Select
                        native
                        id="outlined-faculty"
                        value={props?.studentDetailsInCollege?.faculty_id}
                        onChange={handleChange}
                        label="Select Choice of Faculty"
                        name="faculty"
                      >
                        <option aria-label="None" value="" />
                        {faculties?.length > 0 && faculties?.map(item => (
                          <option value={item?.faculty_id}>{item?.faculty}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider />
                <div style={{ marginTop: '5%' }}>
                  <Button variant="contained" color="default" style={{ marginRight: '1rem' }} onClick={handleBack} >
                    Back
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleClick}>
                    Save
                  </Button>
                </div>

              </Grid>
            </Grid>
            <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
          </FragmentContent>

          
          
          

        </Container>
      </FragmentContainer>
    </div>
  )
}

export default EditStudentEducationInfo;
