import { useRef } from "react";
import { Link } from "react-router-dom";

export default function Start({ setUsername }) {
  const inputRef = useRef();

  const handleClick = () => {
    inputRef.current.value && setUsername(inputRef.current.value);
  };

  return (
    <div className="quiz-start">
      <Link to="/test" className="quiz-startButton">
        Test
      </Link>
      <Link to="/practice" className="quiz-startButton">
        Practice
      </Link>

      {/* <input
        className="quiz-startInput"
        placeholder="enter your name"
        ref={inputRef}
      />
      <button className="quiz-startButton" onClick={handleClick}>
        Start
      </button> */}
    </div>
  );
}
