import React, { useState, useEffect } from "react";
import {
  FacultyContainer,
  FacultyContent,
  FacultyItem,
} from "./ChoiceOfFacultyElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import isEmpty, { isNumber } from "../../../../helpers/validation";
import * as ApiCalls from "../../../../services/ApiCalls";
import background from "./../../../../images/background.jpg";
import Stepper from "../stepper/Stepper";
import WhiteAd from "../../../Ad/WhiteAd";
import Ad from "../../../Ad";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "98%",
    minWidth: 120,
    marginBottom: "2%",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  multilineColor: {
    color: "red",
  },
}));

const ChoiceOfFaculty = (props) => {
  const classes = useStyles();
  const [colleges, setColleges] = useState([]);
  const [college, setCollege] = useState();
  const [faculties, setFaculties] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // getRegions();
    getFacultiesByStudyGroupIdAndSubjectId(1, 2, props.scaledObtainedMarks);
    props.setOpenBackDrop(false);
  }, []);

  // const getRegions = async () => {
  //   const res = await ApiCalls.getSindhRegions();
  //   if (res?.data?.status === 'success') {
  //     setRegions(res?.data?.data);
  //   }
  // }

  const getFacultiesByStudyGroupIdAndSubjectId = async (
    group_id,
    sub_group_id,
    obtained_marks
  ) => {
    const res = await ApiCalls.getFacultiesByStudyGroupIdAndSubjectId(
      group_id,
      sub_group_id,
      obtained_marks
    );
    if (res?.data?.status === "success") {
      setFaculties(res?.data?.data);
    }
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (name === "faculty") {
      await props.setOpenBackDrop(true);
      await setCollege("");
      if (isNumber(value) && !isEmpty(value)) {
        props.setFaculty(value);

        const data = {
          region_id: props.region,
          faculty_id: value,
          gender_id: props.gender,
        };

        const res = await ApiCalls.getSeccapClaimColleges(data);
        await setColleges(res?.data?.data);
        await props.setOpenBackDrop(false);
      }
    }
    if (name === "region") {
      if (isNumber(value) && !isEmpty(value)) {
        await props.setRegion(value);
      }
    }

    if (name === "college") {
      if (!isEmpty(value)) {
        await setCollege(value);
      }
    }
  };

  const handleClick = async () => {
    let isClaim = false;
    if (isClaim) {
      if (isEmpty(props.region)) {
        props.setAlert("Please select Board!", "error");
      } else if (isEmpty(props.faculty)) {
        props.setAlert("Please select Faculty!", "error");
      } else if (isEmpty(college)) {
        props.setAlert("Please select College!", "error");
      } else {
        await props.setOpenBackDrop(true);

        const data = {
          applied_id: props.appliedId,
          faculty_id: props.faculty,
          college_id: college,
          gender_id: props.gender,
          scaled_obtained_marks: props?.scaledObtainedMarks,
        };

        const res = await ApiCalls.UpdateCollegeInAdmission(data);

        if (res?.data?.status === "success") {
          if (res?.data?.data === true) {
            await props.setStep(7);
          } else {
            props.setOpenBackDrop(false);
            props.setAlert("Something went wrong, Please try again!", "error");
          }
        } else if (res?.data?.error?.message) {
          props.setOpenBackDrop(false);
          props.setAlert(res?.data?.error?.message, "error");
        } else {
          props.setOpenBackDrop(false);
          props.setAlert("Something went wrong, Please try again!", "error");
        }
      }
    }
  };

  return (
    <div>
      <FacultyContainer style={{ backgroundImage: `url(${background})` }}>
        <FacultyContent>
          <div
            style={{
              borderRadius: "5px",
              padding: "0.5rem 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: `"#ff000014"`,
            }}
          >
            <div style={{ textAlign: "center" }}>
              {/* <p
                style={{
                  fontWeight: "bold",
                  color: "red",
                }}
              >
                YOUR CAN CLAIM COLLEGE ONLY 3 TIMES!
              </p> */}
              <p style={{ fontSize: "14px", color: "gray" }}></p>
            </div>
          </div>
          <FacultyItem>
            <div style={{ marginBottom: "5%", marginLeft: "8px" }}>
              <h1>Change College</h1>
            </div>

            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-region">Select Board</InputLabel>
              <Select
                id="outlined-region"
                native
                value={props.region}
                onChange={handleChange}
                label="Select Board"
                name="region"
                disabled
              >
                <option aria-label="None" value="" />
                <option value="1">KARACHI</option>
                <option value="2">HYDERABAD</option>
                <option value="3">MIRPURKHAS</option>
                <option value="4">SHAHEED BENAZIRABAD</option>
                <option value="5">LARKANA</option>
                <option value="6">SUKKUR</option>
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-faculty">
                Select Choice of Faculty
              </InputLabel>
              <Select
                native
                id="outlined-faculty"
                value={props.faculty}
                onChange={handleChange}
                label="Select Choice of Faculty"
                name="faculty"
              >
                <option aria-label="None" value="" />
                {faculties?.length > 0 &&
                  faculties?.map((item) => (
                    <option value={item?.id}>{item?.faculty}</option>
                  ))}
              </Select>
            </FormControl>

            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-college">Select College</InputLabel>
              <Select
                native
                id="outlined-college"
                value={props.college}
                onChange={handleChange}
                label="Select College"
                name="college"
              >
                <option aria-label="None" value="" />
                {colleges?.length > 0 &&
                  colleges?.map((item) => (
                    <option value={item?.college_id}>
                      {item?.college_name}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <div style={{ marginTop: "5%", marginLeft: "8px" }}>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Save
              </Button>
            </div>
          </FacultyItem>
          <WhiteAd />
        </FacultyContent>
      </FacultyContainer>
      <Ad />
    </div>
  );
};

export default ChoiceOfFaculty;
