import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CustomAlert from "../../common/CustomAlert";
import isEmpty from "../../../helpers/validation";
import axios from 'axios';
import { getUser } from '../../../functions/functions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FragmentContainer, FragmentHeader, FragmentHeaderH1, FragmentContent } from './FragmentsElements';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddFacultiesInColleges(props) {

  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [districts, setDistricts] = useState([]);
  const [region, setRegion] = useState();
  const [regions, setRegions] = useState([]);
  const [district, setDistrict] = useState();
  const [subdivisions, setSubdivisions] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [collegeId, setCollegeId] = useState();
  const [subdivision, setSubdivision] = useState();
  const [genders, setGenders] = useState([]);
  const [gender, setGender] = useState();
  const [faculties, setFaculties] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [facultySeat, setFacultySeat] = useState([]);
  const [facultyGender, setFacultyGender] = useState([]);
  const [ddo, setDdo] = useState('');
  const [college, setCollege] = useState('');
  const [seats, setSeats] = useState();
  const user = getUser(localStorage?.jwtToken);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  useEffect(async () => {
    window.scrollTo(0, 0);
    let resRegions;

    if (user?.userRole === 'rd') {
      resRegions = await axios.get(`https://www.dgcs.gos.pk/seccap2/regions/${user?.userId}`);
    } else {
      resRegions = await axios.get("https://www.dgcs.gos.pk/seccap2/regions");
    }

    const genderRes = await axios.get("https://www.dgcs.gos.pk/seccap2/colleges/genders");

    const facultiesRes = await axios.get("https://www.dgcs.gos.pk/seccap2/faculties");
      
      setRegions(resRegions.data.data);
      // setDistricts(res.data.data);
      setGenders(genderRes.data.data);
      setFaculties(facultiesRes.data.data);
  }, []);

  const handleChange = (event) => {
    if (event.target.name === 'region') {
      setDistrict('');
      setSubdivision('');
      setRegion(event.target.value);
      getDistricts(event.target.value);
    } else if (event.target.name === 'district') {
      setSubdivision('');
      setDistrict(event.target.value);
      getSubDivisions(event.target.value);
    } else if (event.target.name === 'subdivision') {
      setCollege('');
      setSubdivision(event.target.value);
      getColleges(event.target.value);
    } else if (event.target.name === 'gender') {
      setFaculty([]);
      setFacultySeat([]);
      setFacultyGender([]);
      setGender(event.target.value);
    } else if (event.target.name === 'ddo') {
      setDdo(event.target.value);
    } else if (event.target.name === 'college') {
      setCollege(event.target.value);
    } else if (event.target.name === 'seats') {
      setSeats(event.target.value);
    }
  };

  const getDistricts = async (region_id) => {
    const res = await axios.post("https://www.dgcs.gos.pk/seccap2/districts/get", {region_id},{
      headers: {
        'Authorization': `${localStorage.getItem('jwtToken')}`
      }});
    
    setDistricts(res.data.data);
  }

  const getSubDivisions = async (district_id) => {
    const res = await axios.post("https://www.dgcs.gos.pk/seccap2/subdivisions/district", {district_id},{
      headers: {
        'Authorization': `${localStorage.getItem('jwtToken')}`
      }});
    
    setSubdivisions(res.data.data);
  }

  const getColleges = async (sub_division_id) => {
    const res = await axios.post("https://www.dgcs.gos.pk/seccap2/colleges/subdivision", {sub_division_id},{
      headers: {
        'Authorization': `${localStorage.getItem('jwtToken')}`
      }});
    
    setColleges(res.data.data);
  }

  const handleChangeMultiple = (event) => {
    if (isEmpty(gender)) {
      setAlert('Please select gender first!', 'error');
    } else if (gender == 3) {
      setFaculty(event.target.value);
      setFacultyGender(() => { 
        return [
          {
            gender_id: 1,
            faculty: event.target.value,
          },
          {
            gender_id: 2,
            faculty: event.target.value,
          },
        ]
        
      })
    } else {
      setFaculty(event.target.value);
      setFacultyGender(() => { 
        return [
          {
            gender_id: gender,
            faculty: event.target.value,
          },
        ]
        
      })
    }
    
  };

  const handleClick = async () => {

    if (isEmpty(region)) {
      setAlert('Please fill out region field!', 'error');
    } else if (isEmpty(district)) {
      setAlert('Please fill out district field!', 'error');
    } else if (isEmpty(subdivision)) {
      setAlert('Please select sub division!', 'error');
    } else if (isEmpty(gender)) {
      setAlert('Please select gender!', 'error');
    } else if (isEmpty(college)) {
      setAlert('Please select college!', 'error');
    } else if (isEmpty(faculty)) {
      setAlert('Please select faculty!', 'error');
    } else if (isEmpty(facultySeat)) {
      setAlert('Please fill out seats field!', 'error');
    } else {

      let finalFacultiesSeats = [];
      let finalFaculties = facultyGender?.map(item => item?.faculty?.map(faculty => facultySeat?.filter(obj => item?.gender_id === obj?.gender_id && faculty === Object.keys(obj)[1])));
      let checkEmpty = finalFaculties.some(item => item.some(item2 => isEmpty(item2)));

      if(checkEmpty) {
        setAlert('Please fill out seats field!', 'error');
      } else {

        finalFaculties = finalFaculties?.map(item => item?.map(item2 => item2?.slice(-1)[0] || []));
        finalFaculties = [].concat(...finalFaculties);

        finalFaculties?.map(item => {
          let facultyObj = faculties?.find(faculty => faculty?.faculty === Object.keys(item)[1])
          let facultySeatGender = {gender_id: item?.gender_id, faculty_id: facultyObj?.id , seats: Object.values(item)[1]};
          if (facultySeatGender !== undefined) {
            finalFacultiesSeats.push(facultySeatGender);
          }
        });

        let checkEmptySeats = finalFacultiesSeats.some(item => isEmpty(item.seats));
        let checkSeatsUnder = finalFacultiesSeats.some(item => item.seats < 1);
        let checkSeatsOver = finalFacultiesSeats.some(item => item.seats > 10000);

        if (checkEmptySeats) {
          setAlert('Please fill out seats field!', 'error');
        } else if (checkSeatsUnder) {
          setAlert('Seats cannot be less than 1!', 'error');
        } else if (checkSeatsOver) {
          setAlert('Seats cannot be more than 10000!', 'error');
        } else {

          const data = {
            region_id: region,
            district_id: district,
            subdivision_id: subdivision,
            gender_id: gender,
            college_id: college,
            faculties: finalFacultiesSeats,
          }

          const res = await axios.post('https://www.dgcs.gos.pk/seccap2/colleges/updateFaculty', data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });

          if(res?.data?.status === 'success') {
            setAlert('Faculty has been updated successfully!', 'success');
            setTimeout(() => {
              props.setFragment('colleges');
            }, 2000);
          } else if (res?.data?.error?.message) {
            setAlert(res?.data?.error?.message, 'error');
          } else {
            setAlert('Something went wrong, Please try again!', 'error');
          }

        }

      }
      
    }
    
  }

  return (
    <FragmentContainer>
      <Container fixed>
        <FragmentHeader>
          <Grid container spacing={5}>
            <Grid item md={12}>
              <FragmentHeaderH1>Add Faculties in Colleges</FragmentHeaderH1>
            </Grid>
          </Grid>
        </FragmentHeader>
      </Container>
      <Divider />
      <Container>
        <FragmentContent>
          <div style={{ width: '100%' }}>
          <div>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Region</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={region}
                  onChange={handleChange}
                  label="Region"
                  name="region"
                >
                  {regions?.map(item => (
                    <MenuItem value={item?.id}>{item?.region}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">District</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={district}
                  onChange={handleChange}
                  label="District"
                  name="district"
                >
                  {districts?.map(item => (
                    <MenuItem value={item?.id}>{item?.district}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Sub Division</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={subdivision}
                  onChange={handleChange}
                  label="Sub Division"
                  name="subdivision"
                >
                  {subdivisions?.length > 0 && subdivisions?.map(item => (
                    <MenuItem value={item?.id}>{item?.subdivision}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Colleges</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={college}
                  onChange={handleChange}
                  label="Colleges"
                  name="college"
                >
                  {colleges?.length > 0 && colleges?.map(item => (
                    <MenuItem value={item?.id}>{item?.college_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{marginBottom: '10px'}}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={gender}
                  onChange={handleChange}
                  label="Gender"
                  name="gender"
                >
                  <MenuItem value="1">BOYS</MenuItem>
                  <MenuItem value="2">GIRLS</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={{marginBottom: '20px'}}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Faculty</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  multiple
                  value={faculty}
                  onChange={handleChangeMultiple}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {faculties?.length > 0 && faculties.map((item) => (
                    <MenuItem key={item?.id} name={item?.faculty} value={item?.faculty} style={getStyles(item?.id, item?.faculty, theme)}>
                      {item?.faculty}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {facultyGender?.length > 0 && facultyGender?.map(item => item?.faculty?.map(faculty =>
              (
                <div style={{marginBottom: '20px'}}>
                <TextField fullWidth id="outlined-seat" label={`No of Seats in ${faculty} for ${item.gender_id == 1 ? "Boys" : "Girls"}`} onChange={(e) => e.target.value < 10000 && setFacultySeat((preValue) => {
                  return [
                    ...preValue,
                    {
                      gender_id: item?.gender_id,
                      [e.target.name]: e.target.value
                    }
                  ]
                })} name={faculty} type="number" variant="outlined" />
              </div>
              )
            ))}

            
            
            <div>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Add
              </Button>
            </div>

            <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
          </div>
        </FragmentContent>
      </Container>
      
    </FragmentContainer>
  );
}
