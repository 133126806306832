import React, { useState, useEffect } from "react";
import {
  SuccessContainer,
  SuccessContent,
  SuccessCenter,
} from "./SuccessElements";
import { Button } from "../../../ButtonElements";
import background from "./../../../../images/background.jpg";

const Success = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.setOpenBackDrop(false);
  }, []);

  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <div>
      <SuccessContainer style={{ backgroundImage: `url(${background})` }}>
        <SuccessContent>
          <SuccessCenter>
            <img width="100" src={"./images/success.png"} alt="success" />
          </SuccessCenter>
          <SuccessCenter style={{ marginTop: "3%" }}>
            <p style={{ color: "#65735b", fontSize: "1.5vw" }}>
              You have changed college successfully.
            </p>
          </SuccessCenter>
          <SuccessCenter style={{ marginTop: "5%" }}></SuccessCenter>
          <SuccessCenter style={{ marginTop: "3%" }}>
            <Button
              to="/o-level-status"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              style={{ width: "50%" }}
            >
              Check Status
            </Button>
          </SuccessCenter>
          <SuccessCenter style={{ marginTop: "3%" }}>
            <Button
              to="/"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              style={{ width: "50%" }}
            >
              Go to Homepage
            </Button>
          </SuccessCenter>
        </SuccessContent>
      </SuccessContainer>
    </div>
  );
};

export default Success;
