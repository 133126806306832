import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "axios";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";

import DirectionsIcon from "@material-ui/icons/Directions";
import SimpleBackdrop from "../../../common/Backdrop";
import { getUser } from "../../../../functions/functions";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 800,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function createData(
  ddo_code,
  college_name,
  gender,
  subdivision,
  district,
  region,
  faculties,
  students_applied,
  students_selected,
  admissions
) {
  return {
    ddo_code,
    college_name,
    gender,
    subdivision,
    district,
    region,
    faculties,
    students_applied,
    students_selected,
    admissions,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.ddo_code}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.college_name}
        </TableCell>
        <TableCell align="right">{row.gender}</TableCell>
        <TableCell align="right">{row.subdivision}</TableCell>
        <TableCell align="right">{row.district}</TableCell>
        <TableCell align="right">{row.region}</TableCell>
      </TableRow>
      <TableRow style={{ background: "#f3f3f3" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Gender</TableCell>
                    <TableCell>Faculty</TableCell>
                    <TableCell align="right">Seats</TableCell>
                    <TableCell align="right">Free Seats</TableCell>
                    <TableCell align="right">Applied Students</TableCell>
                    <TableCell align="right">Selected Students</TableCell>
                    <TableCell align="right">Admissions</TableCell>
                    <TableCell align="right">Closing for Ninth</TableCell>
                    <TableCell align="right">Closing for Matric</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.faculties?.map((faculty) => (
                    <TableRow key={faculty?.id}>
                      <TableCell component="th" scope="row">
                        {faculty?.gender}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {faculty?.faculty}
                      </TableCell>
                      <TableCell align="right">{faculty?.seats}</TableCell>
                      <TableCell align="right">
                        {faculty?.seats - faculty?.students_selected > 0
                          ? faculty?.seats - faculty?.students_selected
                          : 0}
                      </TableCell>
                      <TableCell align="right">
                        {faculty?.students_applied}
                      </TableCell>
                      <TableCell align="right">
                        {faculty?.students_selected}
                      </TableCell>
                      <TableCell align="right">{faculty?.admissions}</TableCell>
                      <TableCell align="right">
                        {faculty?.ninth_merit_current_year}
                      </TableCell>
                      <TableCell align="right">
                        {faculty?.merit_current_year}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function CollegesTable() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const user = localStorage?.jwtToken ? getUser(localStorage?.jwtToken) : null;
  useEffect(() => {
    if (user?.userRole === "rd") {
      getStatsDataInRegion(user?.userId);
    } else {
      getStatsData();
    }
  }, []);

  const handleChange = async (e) => {
    // if (e.target.value === "") {
    //   getStatsData();
    // }
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      const data = {
        value: e.target.value,
      };
      let res;
      if (user?.userRole == "rd") {
        res = await axios.post(
          `https://www.dgcs.gos.pk/seccap2/statistics/StatsCollegesInRegion/${user?.userId}`,
          data
        );
      } else {
        res = await axios.post(
          "https://www.dgcs.gos.pk/seccap2/statistics",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (res?.data?.data) {
        setData(res?.data?.data);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      value: searchValue,
    };

    const res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/statistics",
      data,
      {
        headers: {
          Authorization: `${localStorage.getItem("jwtToken")}`,
        },
      }
    );

    if (res?.data?.data) {
      setData(res?.data?.data);
    }
  };

  const getStatsData = async () => {
    // await setOpenBackDrop(true);
    const res = await axios.get("https://www.dgcs.gos.pk/seccap2/statistics", {
      headers: {
        Authorization: `${localStorage.getItem("jwtToken")}`,
      },
    });
    if (res?.data?.data) {
      setData(res?.data?.data);
    }
    await setOpenBackDrop(false);
  };

  const getStatsDataInRegion = async (userId) => {
    // await setOpenBackDrop(true);
    const res = await axios.get(
      `https://www.dgcs.gos.pk/seccap2/statistics/StatsCollegesInRegion/${userId}`
    );
    if (res?.data?.data) {
      setData(res?.data?.data);
    }
    await setOpenBackDrop(false);
  };

  const rows = data?.map((item) => {
    return createData(
      item?.ddo_code,
      item?.college_name,
      item?.gender,
      item?.subdivision,
      item?.district,
      item?.region,
      item?.faculties?.map((faculty) => faculty)
    );
  });
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          textAlign: "center",
          justifyContent: "center",
          padding: "2rem 0",
        }}
      >
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            onChange={handleChange}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
            onClick={handleSubmit}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
      <SimpleBackdrop openBackDrop={openBackDrop} />
      <div style={{ marginBottom: "5px" }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText={
            <img
              alt=""
              src="https://img.icons8.com/material/24/000000/xls-export.png"
            />
          }
        />
      </div>
      <TableContainer style={{ height: "30rem" }} component={Paper}>
        <Table id="table-to-xls" aria-label="collapsible table">
          <TableHead style={{ background: "#137a4c", color: "#fff" }}>
            <TableRow>
              <TableCell />
              <TableCell style={{ color: "#fff" }}>DDO Code</TableCell>
              <TableCell style={{ color: "#fff" }}>College Name</TableCell>
              <TableCell style={{ color: "#fff" }} align="right">
                Gender
              </TableCell>
              <TableCell style={{ color: "#fff" }} align="right">
                Sub Division
              </TableCell>
              <TableCell style={{ color: "#fff" }} align="right">
                District
              </TableCell>
              <TableCell style={{ color: "#fff" }} align="right">
                Region
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <>
                <Row key={row.college_name} row={row} />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
