import React, { useState } from "react";
import { Button } from "../ButtonElements";
import background from "../../images/background.jpg";
import {
  ApplyOnlineContainer,
  ApplyOnlineContent,
  ApplyOnlineItem,
} from "./ApplyOnlineElements";
import FullScreenDialog from "../common/FullScreenDialog";

const ApplyOnline = () => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <ApplyOnlineContainer style={{ backgroundImage: `url(${background})` }}>
        <ApplyOnlineContent>
          <ApplyOnlineItem>
            <FullScreenDialog />
          </ApplyOnlineItem>
          <ApplyOnlineItem>
            <Button
              to="/new-application"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
              big="true"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              New Application
            </Button>
          </ApplyOnlineItem>

          <ApplyOnlineItem>
            <Button
              to="/edit-application"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
              big="true"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Edit Application
            </Button>
          </ApplyOnlineItem>

          <ApplyOnlineItem>
            <Button
              to="/status"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              big="true"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Check Status
            </Button>
          </ApplyOnlineItem>
        </ApplyOnlineContent>
      </ApplyOnlineContainer>
    </>
  );
};

export default ApplyOnline;
