import React from "react";
import ScrollToTop from "../components/ScrollToTop";
import AboutUs from "../components/AboutUs";

const AboutUsPage = () => {
  return (
    <>
      <ScrollToTop />
      <AboutUs />
    </>
  );
};

export default AboutUsPage;
