let now = new Date();
export const passingYears = [
  now.getFullYear() - 4,
  now.getFullYear() - 3,
  now.getFullYear() - 2,
  now.getFullYear() - 1,
  now.getFullYear(),
];
export const currentPassingYear = [
  now.getFullYear() - 3,
  now.getFullYear() - 2,
  now.getFullYear() - 1,
];
export const olevelPassingYears = [
  now.getFullYear() - 3,
  now.getFullYear() - 2,
  now.getFullYear() - 1,
  now.getFullYear(),
];
