import React from "react";
import Icon1 from "../../images/team1.jpg";
import Icon2 from "../../images/asif-ikram.jpeg";
import Icon3 from "../../images/dg.jpeg";
// import Icon4 from "../../images/team4.jpeg";
import background from "../../images/background.jpg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <ServicesContainer id="services">
        <ServicesH1>Our Visionary Leaders</ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={Icon1} />
            <ServicesH2>Syed Sardar Ali Shah</ServicesH2>
            <ServicesP>Our Honourable Minister of Education</ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon2} />
            <ServicesH2>Asif Ikram</ServicesH2>
            <ServicesP>Secretary College Education Department</ServicesP>
          </ServicesCard>
          <ServicesCard>
            <ServicesIcon src={Icon3} />
            <ServicesH2>Prof. Dr. Navaid Rab Siddique</ServicesH2>
            <ServicesP>Director General Colleges Sindh</ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </div>
  );
};

export default Services;
