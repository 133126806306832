import React, { useState, useEffect } from 'react';
import { PreviewContainer, PreviewContent } from './../StudentCard/PreviewElements';
import { Button } from "../ButtonElements";
import jsPDF from 'jspdf';
import * as ApiCalls from '../../services/ApiCalls';
import background from "./../../images/background.jpg";
import GetAppIcon from '@material-ui/icons/GetApp';
import WhiteAd from '../Ad/WhiteAd';
import Ad2 from '../Ad/Ad2';
import Ad3 from '../Ad/Ad3';
import Ad4 from '../Ad/Ad4';
import Ad from '../Ad';
import VerticalAd from '../Ad/VerticalAd';

import { useParams } from 'react-router-dom';

import "./../StudentCard/styles.css";

const SindhTalentCard = (props) => {

  const params = useParams();
  const applied_id = params?.applied_id;
  const xiOrXiiStudent = params?.xi_or_xii;
  const olevelStudent = params?.olevel;

  const [resData, setResData] = useState([]);

  const [permissionData, setPermissionData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    const gamesIds = props?.selectedGames?.map(item => item.id);
    const data = {
      full_name: props?.fullName,
      father_name: props?.fatherName,
      email: props?.email,
      mobile: props?.mobileNumber,
      gender_id: props?.gender,
      cnic: props?.cnic,
      date_of_birth: props?.dateOfBirth,
      college_id: props?.college,
      home_address: props?.homeAddress,
      college_class: props?.collegeClass,
      olevel_student: props?.olevelStudent,
      applied_id: props?.appliedId,
      sindh_talent_applied_id: props?.sindhTalentAppliedId,
      talents: gamesIds,
    }
    const resAppl = await ApiCalls.sindhTalentApply(data);
    if (resAppl?.data?.status === 'success') {
      await setResData(resAppl?.data?.data);
    }
    // getStudentCardDataByAppliedId(applied_id, xiOrXiiStudent, olevelStudent);

  }, []);

  const generatePDF = () => {
    let doc = new jsPDF("potrait", "pt", "a4", "false");

    doc.html(document.querySelector("#application"), {
      margin: [3, 3, 3, 3],
      autoPaging: 'text',
      callback: function (pdf) {
        pdf.save(`talenthunt-card-${permissionData?.name?.split(" ")[0]}-${permissionData?.name?.split(" ")[1]}.pdf`);
      },

    });
  }

  // function generatePDF() {
  //   let printBtn = document.querySelector("#print-button");
  //   printBtn.style.display = "none";
  //   const input = document.getElementById("application");
  //   html2canvas(input)
  //     .then((canvas) => {
  //       var imgData = canvas.toDataURL('image/png');
  //       var imgWidth = 210;
  //       var pageHeight = 295;
  //       var imgHeight = canvas.height * imgWidth / canvas.width;
  //       var heightLeft = imgHeight;

  //       var doc = new jsPDF('p', 'mm');
  //       var position = 0;

  //       doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         doc.addPage();
  //         doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }
  //       doc.save('permissionletter.pdf');

  //     })
  //   printBtn.style.display = "flex";
  // }

  const getStudentCardDataByAppliedId = async (applied_id, xiOrXiiStudent, olevelStudent) => {
    const res = await ApiCalls.getStudentCardDataByAppliedId(applied_id, xiOrXiiStudent, olevelStudent);
    console.log(res?.data?.data[0]);
    if (res?.data?.status === 'success') {
      setPermissionData(res?.data?.data[0]);
    }
  }

  return (
    <div>
      <PreviewContainer style={{ backgroundImage: `url(${background})` }}>
        <PreviewContent>

          <div>
            <div id='print-button' style={{ display: "flex", flexDirection: "row-reverse" }}>
              <div style={{ width: '50px', marginBottom: '1%' }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: "4px", borderRadius: '4px' }}
                  onClick={generatePDF}
                >
                  <GetAppIcon />
                </Button>
              </div>
            </div>
            <div style={{ padding: '0 2%' }}>
              <h3>Download and Get Print for Principal's Signature and Stamp</h3>
            </div>

            <div className='card-container' style={{ position: 'relative' }} id="application">
              <div style={{ position: 'absolute', top: 0 }}>
                <div style={{ opacity: 0 }}>
                  <Ad2 />
                </div>
              </div>
              <div style={{ position: 'absolute', bottom: 0 }}>
                <div style={{ opacity: 0 }}>
                  <Ad3 />
                </div>
              </div>
              <div className='sindh-talent-card'>
                <div className='sindh-talent-card-header'>
                  <div className='card-header-left'>
                    <div className='card-logo'>
                      <img
                        className='logo-img'
                        src={require("./../../images/logo.png").default}
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className='card-header-right'>
                    <div className='card-heading-1'>
                      <div className='card-heading-1-left'>
                        <h1></h1>
                      </div>
                      <div style={{ color: '#137a4c', textShadow: '2px 2px 4px #000000' }} className='card-heading-1-right'>
                        <h1>Government of Sindh</h1>
                        <h1>College Education Department</h1>
                      </div>
                    </div>
                    <div style={{ color: '#f2f2f2', textShadow: '2px 2px 4px #000000' }} className='card-heading'>
                      <h1>{resData[0]?.college_name}</h1>
                    </div>
                    <div style={{ color: '#ffb300', textShadow: '2px 2px 4px #000000' }} className='card-heading'>
                      <h1>1ST SINDH COLLEGE TALENT HUNT 2023</h1>
                    </div>
                  </div>
                </div>
                <div className='card-body'>
                  <div className='card-body-left'>
                    <div className='card-body-left-up'>
                      <div className='card-body-left-up-left'>
                        <div className='card-profile-img'>
                          <img
                            className='profile-img'
                            src="/images/profile.png"
                            alt="profile"
                          />
                        </div>
                      </div>
                      <div className='card-body-left-up-right'>
                        <div className='card-body-info'>
                          <div style={{ color: '#c2c2c2' }} className='card-body-info-item'>
                            <p className='card-body-info-item-left'>Name:</p>
                            <h2 className='card-body-info-item-right'>{resData[0]?.full_name}</h2>
                          </div>
                          <div style={{ color: '#c2c2c2' }} className='card-body-info-item'>
                            <p className='card-body-info-item-left'>Father Name:</p>
                            <h2 className='card-body-info-item-right'>{resData[0]?.father_name}</h2>
                          </div>
                          <div style={{ color: '#c2c2c2' }} className='card-body-info-item'>
                            <p className='card-body-info-item-left'>Date of Birth:</p>
                            <h2 className='card-body-info-item-right'>{resData[0]?.date_of_birth}</h2>
                          </div>
                          <div style={{ color: '#c2c2c2', marginTop: '4%' }} className='card-body-info-item'>
                            <p className='card-body-info-item-left'>Class:</p>
                            <h2 className='card-body-info-item-right'>{resData[0]?.class === "GAD" ? 'Graduation / Associate Degree' : resData[0]?.class}</h2>
                          </div>
                          <div style={{ color: '#ffb300', textShadow: '2px 2px 4px #000000' }} className=''>
                            <p style={{ fontSize: '12px', paddingBottom: '6px' }} className='card-body-info-item-left'>Selected Talent:</p>
                            {resData?.map((item, index) => (
                              <>
                                <h2 style={{ fontSize: '12px' }} key={index} className='card-body-info-item-right'>{index + 1} {item?.talent}</h2>
                              </>
                            ))}

                          </div>
                          {/* <div className='card-body-info-item'>
                            <p className='card-body-info-item-left'>Section:</p>
                            <h2 className='card-body-info-item-right'></h2>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className='card-body-left-down'>
                      <div className='card-body-info'>
                        <div style={{ color: '#c2c2c2', marginTop: '4%' }} className='card-body-info-item'>
                          <h2 className='card-body-info-item-left'>Contact no:</h2>
                          <p className='card-body-info-item-right'>{resData[0]?.mobile}</p>
                        </div>
                        <div className='card-body-info-item' style={{ color: '#c2c2c2', alignItems: 'center' }}>
                          <h2 className='card-body-info-item-left'>Address:</h2>
                          <p className='card-body-info-item-right' style={{ paddingRight: '10px' }}>{resData[0]?.home_address}</p>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className='card-body-right'>
                    <div className='card-qrcode'>
                      <img
                        className='qrcode-img'
                        src={resData[0]?.qr_code_path}
                        alt="qr-code"
                      />
                      {/* <img
                        className='qrcode-img'
                        src={"https://seccap.dgcs.gos.pk/allimages/studentcardqrcodes/qrcode1fce651c3676767f9be8a95de975ee7b.png"}
                        alt="qr-code"
                      /> */}

                    </div>
                    <div className='card-body-info'>
                      <div style={{ color: '#f2f2f2' }} className='card-body-info-item'>
                        <h2 className='card-body-info-item-left'>S C TH Applied ID:</h2>
                        <h2 className='card-body-info-item-right'>{resData[0]?.sindh_talent_applied_id}</h2>
                      </div>
                      <div style={{ color: '#c2c2c2', marginTop: '3%' }} className='card-body-info-item'>
                        <p className='card-body-info-item-left' style={{ fontSize: '11px' }}>Valid through:</p>
                        <p className='card-body-info-item-right' style={{ fontSize: '11px' }}>31 May 2023</p>
                      </div>
                      <div style={{ color: '#c2c2c2', marginTop: '7%' }} className='card-body-info-item'>
                        <h2 className='card-body-info-item-left' style={{ fontSize: '11px' }}>Principal's Signature:</h2>
                        <h2 className='card-body-info-item-right' style={{ fontSize: '11px' }}>_____________</h2>
                      </div>

                    </div>
                  </div>
                </div>
                {/* <div style={{ color: '#f2f2f2' }} className='card-footer'>
                  <p>This card is NOT TRANSFERABLE.</p>
                  <p>This card is provisional, Permanent card will be available after board Enrollment</p>
                  <p>The College / College Education Department is not responsible for any loss or expenses resulting from the loss, theft, or misuse of this card. if this card is lost, damaged, or stolen. If found, please return the card to nearest Government Colleges.</p>
                </div> */}
              </div>
            </div>

            <WhiteAd />


          </div>

          <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: 0 }}>
              <div style={{ opacity: 0 }}>
                <Ad4 />
              </div>
            </div>
            <div style={{ position: 'absolute', bottom: 0 }}>
              <div style={{ opacity: 0 }}>
              </div>
            </div>
          </div>
        </PreviewContent>

      </PreviewContainer>
      <Ad />
    </div>
  )
}

export default SindhTalentCard;
