import React from "react";
import ScrollToTop from "../components/ScrollToTop";
import Colleges from "../components/Colleges";


const CollegesPage = () => {
  return (
    <>
      <ScrollToTop />
      <Colleges />
      
    </>
  );
};

export default CollegesPage;
