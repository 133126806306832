import React, { useState, useEffect } from 'react'
import { FragmentContainer, FragmentHeader, FragmentHeaderH1 } from '../../../Dashboard/fragments/FragmentsElements'
import Cards from '../../../Dashboard/reports/Cards/Cards';
import axios from 'axios';
import StudentsData from '../students-data/StudentsData';
import { getUser } from '../../../../functions/functions';
import { Link, useHistory } from 'react-router-dom';

const TotalApplications = () => {
  const [cardData, setCardData] = useState([]);

  const getCardData = async () => {
    let res;
    res = await axios.get('https://www.dgcs.gos.pk/seccap2/statistics/seefCard');

    if (res?.data?.data) {
      await setCardData(res?.data?.data);
    }

  }

  let history = useHistory();
  const user = getUser(localStorage?.jwtToken);

  useEffect(async () => {
    window.scrollTo(0, 0);
    // Check for token
    if (!localStorage?.jwtToken) {
      // Redirect to login
      history.push("/seef-signin");
    }

    await getCardData();

  }, []);

  return (
    <div>
      <div style={{ padding: '10%' }}>
        <FragmentContainer>
          <FragmentHeader>
            {/* <FragmentHeaderH1>Dashboard</FragmentHeaderH1> */}
            <p style={{ color: 'red', textAlign: 'right', cursor: 'pointer' }} onClick={() => {
              // Remove token from localStorage
              localStorage.removeItem("jwtToken");
              // Redirect to login
              history.push("/seef-signin");
            }}>Logout</p>
          </FragmentHeader>
          <Cards cardData={cardData} />
        </FragmentContainer>
        <StudentsData />
        
        
        
      </div>
    </div>
  )
}

export default TotalApplications