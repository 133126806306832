import React from "react";
import ScrollToTop from "../components/ScrollToTop";
import ApplyOnline from "../components/ApplyOnline";

const ApplyOnlinePage = () => {
  return (
    <>
      <ScrollToTop />
      <ApplyOnline />
    </>
  );
};

export default ApplyOnlinePage;
