import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import ReactTimeAgo from "react-time-ago";
import { makeStyles } from "@material-ui/core/styles";
import CustomAlert from "../../common/CustomAlert";
import { IoIosArrowBack } from "react-icons/io";
import { AiFillLike } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import WhiteAd from "../../Ad/WhiteAd";
import * as ApiCalls from "../../../services/ApiCalls";
import NavBar from "../NavBar/NavBar";
import SimpleBackdrop from "../../common/Backdrop";
import "./../Discussions.css";
import VerticalAd from "../../Ad/VerticalAd";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { getUser } from "../../../functions/functions";
import { IoEyeOutline } from "react-icons/io5";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 800,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const Profile = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [discussionsData, setDiscussionsData] = useState([]);
  const [discussionsCount, setDiscussionsCount] = useState(0);
  const [userProfileData, setUserProfileData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [nextRows, setNextRows] = useState(10);
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const sub = params.category;
  const user = getUser(localStorage?.discussionsJwtToken);
  let my_id;
  if (user?.appliedId && params?.role === "student") {
    my_id = user?.appliedId;
  } else if (user?.employeeId && params?.role === "staff") {
    my_id = user?.employeeId;
  }

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleLoadMore = (nextRows, role, id) => {
    getDiscussionsData(nextRows + 5, role, id, searchValue);
    setNextRows(nextRows + 5);
  };

  const handleLike = async (post_id) => {
    const res = await ApiCalls.likePost(post_id);
    let newData = [];
    let disData = discussionsData;
    disData.map((item) => {
      if (item?.id === post_id) {
        if (item.my_like === true) {
          item.likes = parseInt(item.likes) - 1;
          item.my_like = false;
        } else if (item.my_like === false) {
          item.likes = parseInt(item.likes) + 1;
          item.my_like = true;
        }
      }
      newData.push(item);
    });
    setDiscussionsData(newData);
  };

  const handleChange = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      getDiscussionsData(nextRows, params?.role, params?.id, e.target.value);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    getDiscussionsData(nextRows, params?.role, params?.id, searchValue);
  };

  const getUserProfileData = async (role, id) => {
    await setOpenBackDrop(true);

    const res = await ApiCalls.getUserProfile(role, id);

    if (res) {
      await setOpenBackDrop(false);
    }
    if (res?.data?.data) {
      setUserProfileData(res?.data?.data);
    }
  };

  const getDiscussionsData = async (nextRows, role, id, searchValue) => {
    let searchFilters = searchValue;
    if (searchFilters?.length === 0) {
      searchFilters = 0;
    }
    await setOpenBackDrop(true);
    const res = await ApiCalls.getUserDiscussions(
      nextRows,
      0,
      role,
      id,
      searchFilters
    );

    if (res) {
      await setOpenBackDrop(false);
    }
    if (res?.data?.data) {
      setDiscussionsData(res?.data?.data);
      setDiscussionsCount(res?.data?.count);
    }
  };

  const visitProfilePage = async (role, id) => {
    const data = {
      role,
      id,
    };

    let res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/discussions/visitProfilePage",
      data,
      {
        headers: {
          Authorization: `${localStorage.getItem("discussionsJwtToken")}`,
        },
      }
    );
  };

  const handleClickFollowUser = async () => {
    let following_applied_id = null;
    let following_employee_id = null;
    if (userProfileData?.designation) {
      following_employee_id = userProfileData?.id;
    }

    if (userProfileData?.faculty) {
      following_applied_id = userProfileData?.id;
    }
    const data = {
      following_applied_id,
      following_employee_id,
    };

    await setOpenBackDrop(true);

    let res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/discussions/follow",
      data,
      {
        headers: {
          Authorization: `${localStorage.getItem("discussionsJwtToken")}`,
        },
      }
    );

    if (res) {
      await setOpenBackDrop(false);
      if (userProfileData?.following) {
        setUserProfileData((preValue) => ({
          ...preValue,
          followers_count: parseInt(preValue.followers_count) - 1,
        }));
        setAlert(
          `You are no longer following ${userProfileData?.name}`,
          "success"
        );
      }

      if (!userProfileData?.following) {
        setUserProfileData((preValue) => ({
          ...preValue,
          followers_count: parseInt(preValue.followers_count) + 1,
        }));
        setAlert(`You are now following ${userProfileData?.name}`, "success");
      }

      setUserProfileData((preValue) => ({
        ...preValue,
        following: !preValue.following,
      }));
    }
  };

  useEffect(() => {
    // Check for token
    if (!localStorage?.discussionsJwtToken) {
      // Redirect to login
      history.push("/login");
    }
    visitProfilePage(params?.role, params?.id);
    getDiscussionsData(nextRows, params?.role, params?.id, searchValue);
    getUserProfileData(params?.role, params?.id);
  }, []);

  return (
    <div>
      <div className="navbar">
        <NavBar />
      </div>

      <div className="main-container">
        <SimpleBackdrop openBackDrop={openBackDrop} />
        <div>
          <Link
            to="/discussions"
            style={{
              display: "flex",
              color: "#6b7280",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
              width: "100px",
              height: "32px",
              border: "1px solid #9ca3af",
              borderRadius: "8px",
            }}
          >
            <IoIosArrowBack style={{ marginRight: "4px" }} /> Back
          </Link>
        </div>

        <div className="profile-container">
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} spacing={3}>
              <div className="discussion-items-center">
                <div>
                  <img
                    className="discussions-profile-img"
                    src={require("./../../../images/profile.png").default}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} spacing={3}>
              <div>
                <p
                  className="discussion-items-center"
                  style={{
                    fontWeight: "bold",
                    marginBottom: "20px",
                    marginTop: "35px",
                  }}
                >
                  {userProfileData?.name}
                </p>
                <p
                  className="discussion-items-center"
                  style={{
                    fontSize: "14px",
                    color: "#4b5563",
                    marginBottom: "5px",
                  }}
                >
                  {userProfileData?.college_name}
                </p>
                <p
                  className="discussion-items-center"
                  style={{
                    fontSize: "14px",
                    color: "#4b5563",
                    marginBottom: "5px",
                  }}
                >
                  {userProfileData?.faculty}
                </p>
                <p
                  className="discussion-items-center"
                  style={{
                    fontSize: "14px",
                    color: "#4b5563",
                    marginBottom: "5px",
                  }}
                >
                  {userProfileData?.designation}
                </p>
                <p
                  className="discussion-items-center"
                  style={{
                    fontSize: "14px",
                    color: "#4b5563",
                    marginBottom: "20px",
                  }}
                >
                  {userProfileData?.subject}
                </p>
                <div>
                  <div className="discussion-items-center">
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "5px",
                        marginRight: "10px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#4b5563",
                          marginRight: "5px",
                        }}
                      >
                        Followers
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {userProfileData?.followers_count}
                      </p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginBottom: "5px",
                        marginRight: "10px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#4b5563",
                          marginRight: "5px",
                        }}
                      >
                        Following
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {userProfileData?.following_count}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={3} spacing={3}>
              <div
                className="discussions-user-score"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "24px",
                      color: "#4b5563",
                    }}
                  >
                    {userProfileData?.score}
                  </p>
                  <p style={{ textAlign: "center", color: "#4b5563" }}>score</p>
                </div>
              </div>
              <div
                className="discussions-user-follow-btn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    background: `${
                      parseInt(my_id) === parseInt(params?.id)
                        ? "#6b7280"
                        : "#000"
                    }`,
                    color: "#fff",
                    padding: "10px 15px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={handleClickFollowUser}
                  disabled={parseInt(my_id) === parseInt(params?.id)}
                >
                  <div>
                    <p>
                      {userProfileData?.following
                        ? "Unfollow User"
                        : "Follow User"}
                    </p>
                  </div>
                </button>
              </div>
            </Grid>
          </Grid>
        </div>

        <div style={{ marginTop: "90px" }}>
          <h2>
            Posts {"("}
            {discussionsCount}
            {")"}
          </h2>

          <div
            style={{
              display: "flex",
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
              padding: "2rem 0",
            }}
          >
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleChange}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
                onClick={handleSubmit}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          <div>
            {discussionsData?.length > 0 ? (
              discussionsData?.map((item, index) => {
                return (index + 1) % 3 === 0 ? (
                  <div key={index}>
                    <div className="forum-card">
                      <div
                        style={{
                          width: "70%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            className="link"
                            to={`/discussions/profile/${item?.role}/${item?.user_id}`}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "2px",
                                border: `2px solid ${
                                  params?.role === "student"
                                    ? "#9333ea"
                                    : "#3b82f6"
                                } `,
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                            >
                              <img
                                style={{
                                  width: "50px",
                                  borderRadius: "50%",
                                }}
                                src={
                                  require("./../../../images/profile.png")
                                    .default
                                }
                              />
                            </div>
                          </Link>
                          <Link
                            key={item?.id}
                            className="link"
                            to={`/discussions/${
                              parseInt(item?.subject_id) === 1
                                ? "english"
                                : parseInt(item?.subject_id) === 12
                                ? "biology"
                                : parseInt(item?.subject_id) === 8
                                ? "mathematics"
                                : parseInt(item?.subject_id) === 7
                                ? "physics"
                                : parseInt(item?.subject_id) === 6
                                ? "chemistry"
                                : parseInt(item?.subject_id) === 13
                                ? "computer-science"
                                : ""
                            }/${item?.id}`}
                          >
                            <div>
                              {item?.seen === true ? (
                                <h3
                                  style={{
                                    marginBottom: "5px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {item?.title}
                                </h3>
                              ) : (
                                <h3 style={{ marginBottom: "5px" }}>
                                  {item?.title}
                                </h3>
                              )}

                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "#5f6368",
                                  marginBottom: "5px",
                                }}
                              >
                                {item?.name} - {item?.college_name} - posted{" "}
                                <ReactTimeAgo date={item?.created_at} />
                              </p>
                              <p style={{ fontSize: "12px", color: "#5f6368" }}>
                                {parseInt(item?.subject_id) === 1
                                  ? "English"
                                  : parseInt(item?.subject_id) === 12
                                  ? "Biology"
                                  : parseInt(item?.subject_id) === 8
                                  ? "Mathematics"
                                  : parseInt(item?.subject_id) === 7
                                  ? "Physics"
                                  : parseInt(item?.subject_id) === 6
                                  ? "Chemistry"
                                  : parseInt(item?.subject_id) === 13
                                  ? "Computer science"
                                  : ""}
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleLike(item?.id)}
                            >
                              {item?.my_like ? (
                                <AiFillLike />
                              ) : (
                                <AiOutlineLike />
                              )}
                            </div>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              {item?.likes} likes
                            </div>
                          </div>

                          <div style={{ marginTop: "10px" }}>
                            <p style={{ fontSize: "12px", color: "#5f6368" }}>
                              {item?.comments} comments
                            </p>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#5f6368",
                                display: "flex",
                                alignContent: "center",
                              }}
                            >
                              <IoEyeOutline
                                style={{ marginRight: "5px", fontSize: "14px" }}
                              />{" "}
                              {item?.views} views
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <VerticalAd />
                  </div>
                ) : (
                  <div key={index}>
                    <div className="forum-card">
                      <div
                        style={{
                          width: "70%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            className="link"
                            to={`/discussions/profile/${item?.role}/${item?.user_id}`}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "2px",
                                border: `2px solid ${
                                  item?.role === "student"
                                    ? "#9333ea"
                                    : "#3b82f6"
                                } `,
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                            >
                              <img
                                style={{
                                  width: "50px",
                                  borderRadius: "50%",
                                }}
                                src={
                                  require("./../../../images/profile.png")
                                    .default
                                }
                              />
                            </div>
                          </Link>
                          <Link
                            key={item?.id}
                            className="link"
                            to={`/discussions/${
                              parseInt(item?.subject_id) === 1
                                ? "english"
                                : parseInt(item?.subject_id) === 12
                                ? "biology"
                                : parseInt(item?.subject_id) === 8
                                ? "mathematics"
                                : parseInt(item?.subject_id) === 7
                                ? "physics"
                                : parseInt(item?.subject_id) === 6
                                ? "chemistry"
                                : parseInt(item?.subject_id) === 13
                                ? "computer-science"
                                : ""
                            }/${item?.id}`}
                          >
                            <div>
                              {item?.seen === true ? (
                                <h3
                                  style={{
                                    marginBottom: "5px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {item?.title}
                                </h3>
                              ) : (
                                <h3 style={{ marginBottom: "5px" }}>
                                  {item?.title}
                                </h3>
                              )}

                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "#5f6368",
                                  marginBottom: "5px",
                                }}
                              >
                                {item?.name} - {item?.college_name} - posted{" "}
                                <ReactTimeAgo date={item?.created_at} />
                              </p>
                              <p style={{ fontSize: "12px", color: "#5f6368" }}>
                                {parseInt(item?.subject_id) === 1
                                  ? "English"
                                  : parseInt(item?.subject_id) === 12
                                  ? "Biology"
                                  : parseInt(item?.subject_id) === 8
                                  ? "Mathematics"
                                  : parseInt(item?.subject_id) === 7
                                  ? "Physics"
                                  : parseInt(item?.subject_id) === 6
                                  ? "Chemistry"
                                  : parseInt(item?.subject_id) === 13
                                  ? "Computer science"
                                  : ""}
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleLike(item?.id)}
                            >
                              {item?.my_like ? (
                                <AiFillLike />
                              ) : (
                                <AiOutlineLike />
                              )}
                            </div>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              {item?.likes} likes
                            </div>
                          </div>

                          <div style={{ marginTop: "10px" }}>
                            <p style={{ fontSize: "12px", color: "#5f6368" }}>
                              {item?.comments} comments
                            </p>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#5f6368",
                                display: "flex",
                                alignContent: "center",
                              }}
                            >
                              <IoEyeOutline
                                style={{ marginRight: "5px", fontSize: "14px" }}
                              />{" "}
                              {item?.views} views
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <p style={{ fontWeight: "bold", color: "#9ca3af" }}>
                  Data not found
                </p>
              </div>
            )}
          </div>

          <div>
            {discussionsData?.length < parseInt(discussionsCount) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    background: "#000",
                    color: "#fff",
                    padding: "10px 15px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    handleLoadMore(nextRows, params?.role, params?.id)
                  }
                >
                  <div>
                    <p>Load more</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <CustomAlert
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
          />
        </div>
      </div>
      <WhiteAd />
    </div>
  );
};

export default Profile;
