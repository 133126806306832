import React, { useState, useEffect } from "react";
import {
  NewApplicationContainer,
  NewApplicationContent,
  NewApplicationItem,
  NewApplicationHeading,
} from "./NewApplicationElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import RegistrationForm from "./components/registration-form/RegistrationForm";
import PersonalInfoForm from "./components/personal-info-form/PersonalInfoForm";
import ChoiceOfFaculty from "./components/choice-of-faculty/ChoiceOfFaculty";
import SelectColleges from "./components/select-colleges/SelectColleges";
import Preview from "./components/preview/Preview";
import Preview2 from "./components/preview/Preview2";
import Success from "./components/success/Success";
import isEmpty, { isNumber } from "../../helpers/validation";
import CustomAlert from "../common/CustomAlert";
import SimpleBackdrop from "../common/Backdrop";
import * as ApiCalls from "../../services/ApiCalls";
import { useLocation } from "react-router";
import background from "./../../images/background.jpg";
import WhiteAd from "../Ad/WhiteAd";
import Ad from "../Ad";

import { isCNIC, isNumberOrDash } from "../../helpers/validation";
import { useHistory } from "react-router-dom";
import { passingYears, currentPassingYear } from "../../data/passingyears";
import { getUser } from "../../functions/functions";

import ProvincesData from "../../data/provinces.json";
import BoardsData from "../../data/boards.json";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  multilineColor: {
    color: "red",
  },
}));

const NewApplication = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const user = getUser(localStorage?.getItem("jwtToken"));

  let step0 = 1;
  let admissionsClosed = 0;

  if (
    location.pathname === "/edit-application" ||
    user?.userRole === "super admin"
  ) {
    step0 = 1;
  }

  const [step, setStep] = useState(step0);
  // Matric Profile
  const [ninthRollNo, setNinthRollNo] = useState("");
  const [matricRollNo, setMatricRollNo] = useState("");
  const [ninthPassingYear, setNinthPassingYear] = useState("");
  const [matricPassingYear, setMatricPassingYear] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [passingYearClass5, setPassingYearClass5] = useState("");
  const [passingYearClass8, setPassingYearClass8] = useState("");
  const [province, setProvince] = useState("");
  const [qualification, setQualification] = useState("");
  const [board, setBoard] = useState("");
  const [studyGroup, setStudyGroup] = useState("");
  const [subject, setSubject] = useState("");
  const [ninthTotalMarks, setNinthTotalMarks] = useState("");
  const [ninthObtainedMarks, setNinthObtainedMarks] = useState("");
  const [ninthScaledObtainedMarks, setNinthScaledObtainedMarks] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [obtainedMarks, setObtainedMarks] = useState("");
  const [scaledObtainedMarks, setScaledObtainedMarks] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoolType, setSchoolType] = useState("");
  const [schoolNameClass5, setSchoolNameClass5] = useState("");
  const [schoolNameClass8, setSchoolNameClass8] = useState("");
  const [districtClass5, setDistrictClass5] = useState("");
  const [districtClass8, setDistrictClass8] = useState("");
  const [imageMarksheet, setImageMarksheet] = useState();
  const [imageMarksheetName, setImageMarksheetName] = useState("");
  const [marksheetImage, setMarksheetImage] = useState("");
  const [showMarksheetImage, setShowMarksheetImage] = useState(
    require("../../images/doc.png").default
  );
  const [showProfileImage, setShowProfileImage] = useState(
    require("../../images/profile.png").default
  );
  // Personal Profile
  const [appliedId, setAppliedId] = useState("");
  const [fullName, setFullName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [cnic, setCnic] = useState("");
  const [religion, setReligion] = useState("");
  const [fatherMobileNumber, setFatherMobileNumber] = useState("");
  const [fatherCnic, setFatherCnic] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [motherCnic, setMotherCnic] = useState("");
  const [domicileDistrict, setDomicileDistrict] = useState("");
  const [fatherDomicileDistrict, setFatherDomicileDistrict] = useState("");
  const [imageProfile, setImageProfile] = useState();
  const [imageProfileName, setImageProfileName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [addressRegion, setAddressRegion] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [addressTown, setAddressTown] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [whoFilledForm, setWhoFilledForm] = useState("");
  const [studentGoal, setStudentGoal] = useState("");
  const [unionCouncil, setUnionCouncil] = useState("");
  const [ward, setWard] = useState("");

  // Apply for college
  const [region, setRegion] = useState("");
  const [faculty, setFaculty] = useState("");
  const [zone, setZone] = useState("");
  const [qrCodePath, setQrcodepath] = useState("");
  const [selectedColleges, setSelectedColleges] = useState([]);

  const [availInDataBase, setAvailInDataBase] = useState(false);
  const [applied, setApplied] = useState(false);

  useEffect(async () => {
    window.scrollTo(0, 0);
    await setOpenBackDrop(false);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "province") {
      setBoard("");
      setProvince(value);
    }

    if (name === "qualification") {
      setPassingYear("");
      setNinthRollNo("");
      setQualification(value);
    }

    if (name === "board") {
      setBoard(value);
    }

    if (name === "cnic") {
      if (user?.userRole === "admin" && value.length < 16) {
        setCnic(value);
      } else {
        if (isNumberOrDash(value) && value.length < 16) {
          setCnic(value);
        }
      }
    }

    if (name === "ninthRollNo") {
      if ((isNumber(value) || value === "") && value.length < 11) {
        setNinthRollNo(value);
      }
    }

    if (name === "matricRollNo") {
      if ((isNumber(value) || value === "") && value.length < 11) {
        setMatricRollNo(value);
      }
    }

    if (name === "passingYear") {
      if ((isNumber(value) || value === "") && value.length < 5) {
        setPassingYear(value);
      }
    }
  };

  const handleClick = async () => {
    if (isEmpty(qualification)) {
      setAlert("Please Select Passing Class!", "error");
    } else if (isEmpty(province)) {
      setAlert("Please Select Province!", "error");
    } else if (isEmpty(board)) {
      setAlert("Please select Board!", "error");
    } else if (isEmpty(cnic)) {
      setAlert("Please fill out CNIC / B-FORM No!", "error");
    } else if (user?.userRole !== "admin" && !isCNIC(cnic)) {
      setAlert("Please fill out valid CNIC / B-FORM No!", "error");
    } else if (cnic?.length !== 15) {
      setAlert("CNIC / B-FORM No field contains only 15 characters!", "error");
    } else if (isEmpty(matricRollNo)) {
      setAlert("Please fill out Maric Roll No!", "error");
    } else if (parseInt(qualification) === 1 && isEmpty(ninthRollNo)) {
      setAlert("Please fill out Ninth Roll No!", "error");
    } else if (matricRollNo?.length > 10) {
      setAlert(
        "Maric Roll No field cannot contain maximum than 10 numbers!",
        "error"
      );
    } else if (isEmpty(passingYear)) {
      setAlert("Please Select Passing Year!", "error");
    } else if (passingYear.length > 4) {
      setAlert(
        "Passing Year field cannot contain maximum than 4 numbers!",
        "error"
      );
    } else {
      setOpenBackDrop(true);

      const data = {
        matric_roll_no: matricRollNo,
        cnic,
      };

      const resApplied = await ApiCalls.isApplied(data);
      if (resApplied?.data?.status === "success") {
        if (resApplied?.data?.data?.length === 0) {
          if (admissionsClosed === 1 && user?.userRole != "super admin") {
            await setOpenBackDrop(false);
            setAlert(
              "ADMISSIONS FOR THE SESSION 2024-25 ARE NOW CLOSED",
              "error"
            );
          } else {
            if (
              parseInt(qualification) === 1 &&
              parseInt(board) === 2 &&
              passingYear === "2023"
            ) {
              const boardResponse = await ApiCalls.isInIxBoard({
                ninth_roll_no: ninthRollNo,
              });
              if (boardResponse?.data?.data?.length === 0) {
                await setStep(2);
              } else {
                await setFullName(boardResponse?.data?.data?.student_name);
                await setFatherName(boardResponse?.data?.data?.father_name);
                await setNinthObtainedMarks(
                  boardResponse?.data?.data?.obtained_marks
                );
                await setNinthTotalMarks(550);
                await setAvailInDataBase(true);
                let sg = 2;
                let sub = "";
                if (
                  boardResponse?.data?.data?.subject === "COMPUTER" ||
                  boardResponse?.data?.data?.subject === "BIOLOGY"
                ) {
                  sg = 1;
                  if (boardResponse?.data?.data?.subject === "COMPUTER") {
                    sub = 1;
                  } else if (boardResponse?.data?.data?.subject === "BIOLOGY") {
                    sub = 2;
                  }
                }
                await setStudyGroup(sg);
                await setSubject(sub);
                await setStep(2);
              }
            } else {
              await setStep(2);
            }
          }
        } else {
          const dataApplied = resApplied?.data?.data;
          await setApplied(true);
          const resAdmissions = await ApiCalls.CheckAdmissionByAppliedId({
            applied_id: dataApplied.id,
          });

          if (
            parseInt(resAdmissions?.data?.data[0]?.status_id) === 1
            // && parseInt(resAdmissions?.data?.data[0]?.class_id) === 1
          ) {
            history.push("/status");
          } else {
            if (
              parseInt(qualification) === 1 &&
              parseInt(board) === 2 &&
              passingYear === "2023"
            ) {
              const boardResponse = await ApiCalls.isInIxBoard({
                ninth_roll_no: ninthRollNo,
              });
              if (boardResponse?.data?.data?.length === 0) {
                await setFullName(dataApplied?.name);
                await setFatherName(dataApplied?.father_name);
                await setNinthObtainedMarks(dataApplied.ninth_obtained_marks);
                await setNinthTotalMarks(dataApplied?.ninth_total_marks);
                await setStudyGroup(dataApplied?.group_id);
                await setSubject(dataApplied?.sub_group_id);
              } else {
                await setFullName(boardResponse?.data?.data?.student_name);
                await setFatherName(boardResponse?.data?.data?.father_name);
                await setNinthObtainedMarks(
                  boardResponse?.data?.data?.obtained_marks
                );
                await setNinthTotalMarks(550);
                await setAvailInDataBase(true);
                let sg = 2;
                let sub = "";
                if (
                  boardResponse?.data?.data?.subject === "COMPUTER" ||
                  boardResponse?.data?.data?.subject === "BIOLOGY"
                ) {
                  sg = 1;
                  if (boardResponse?.data?.data?.subject === "COMPUTER") {
                    sub = 1;
                  } else if (boardResponse?.data?.data?.subject === "BIOLOGY") {
                    sub = 2;
                  }
                }
                await setStudyGroup(sg);
                await setSubject(sub);
              }
            } else {
              await setFullName(dataApplied?.name);
              await setFatherName(dataApplied?.father_name);
              await setNinthObtainedMarks(dataApplied?.ninth_obtained_marks);
              await setNinthTotalMarks(dataApplied?.ninth_total_marks);
              await setStudyGroup(dataApplied?.group_id);
              await setSubject(dataApplied?.sub_group_id);
            }

            await setAppliedId(dataApplied?.id);
            await setQrcodepath(dataApplied?.qr_code_path);
            await setTotalMarks(dataApplied?.total_marks);
            await setObtainedMarks(dataApplied?.obtained_marks);
            await setPassingYearClass8(dataApplied?.class8_passing_year);
            await setDistrictClass8(dataApplied?.class8_passing_district_id);
            await setSchoolNameClass8(dataApplied?.class8_school_name);
            await setPassingYearClass5(dataApplied?.class5_passing_year);
            await setDistrictClass5(dataApplied?.class5_passing_district_id);
            await setSchoolNameClass5(dataApplied?.class5_school_name);
            await setSchoolName(dataApplied?.school_name);
            await setSchoolType(dataApplied?.school_type_id);
            await setEmail(dataApplied?.email);
            await setMobileNumber(dataApplied?.mobile);
            await setCnic(dataApplied?.cnic);
            await setFatherMobileNumber(dataApplied?.father_mobile);
            await setFatherCnic(dataApplied?.father_cnic);
            await setMotherCnic(dataApplied?.mother_cnic);
            await setGender(dataApplied?.gender_id);
            await setNationality(dataApplied?.nationality);
            await setDateOfBirth(dataApplied?.date_of_birth);
            await setPlaceOfBirth(dataApplied?.place_of_birth);
            await setReligion(dataApplied?.religion_id);
            await setDomicileDistrict(dataApplied?.domicile_district_id);
            await setFatherDomicileDistrict(
              dataApplied?.father_domicile_district_id
            );
            await setFatherOccupation(dataApplied?.father_occupation);
            await setAddressRegion(dataApplied?.address_region_id);
            await setRegion(dataApplied?.region_id);
            dataApplied?.address_district_id &&
              (await setAddressDistrict(dataApplied?.address_district_id));
            dataApplied?.address_town_id &&
              (await setAddressTown(dataApplied?.address_town_id));
            await setHomeAddress(dataApplied?.home_address);
            await setStudentGoal(dataApplied?.student_goal);
            await setWhoFilledForm(dataApplied?.who_filled_form);
            await setUnionCouncil(dataApplied?.uc);
            await setWard(dataApplied?.ward);
            await setStep(2);
          }
        }
      } else if (resApplied?.data?.error?.message) {
        await setOpenBackDrop(false);
        setAlert(resApplied?.data?.error?.message, "error");
      } else {
        await setOpenBackDrop(false);
        setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />

      {step === 0 && (
        <div>
          <NewApplicationContainer
            style={{ backgroundImage: `url(${background})` }}
          >
            <NewApplicationContent>
              <NewApplicationItem>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h1
                    style={{
                      color: "#b91c1c",
                      fontSize: "16px",
                      textAlign: "center",
                      lineHeight: 1.5,
                    }}
                  >
                    ADMISSIONS FOR THE SESSION 2024-25 ARE NOW CLOSED
                  </h1>
                </div>
              </NewApplicationItem>
            </NewApplicationContent>
          </NewApplicationContainer>
        </div>
      )}

      {step === 1 && (
        <div>
          <NewApplicationContainer
            style={{ backgroundImage: `url(${background})` }}
          >
            <NewApplicationContent>
              <NewApplicationItem>
                <div style={{ marginBottom: "5%" }}>
                  <NewApplicationHeading>
                    {location.pathname === "/edit-application"
                      ? "Edit Application"
                      : "New Application"}
                  </NewApplicationHeading>
                </div>

                <FormControl
                  style={{ marginBottom: "10px" }}
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-qualification">
                    Select Passing Class
                  </InputLabel>
                  <Select
                    native
                    value={qualification}
                    onChange={handleChange}
                    id="Qualification"
                    label="Select Passing Class"
                    name="qualification"
                  >
                    <option aria-label="None" value="" />
                    <option value={"1"}>Ninth</option>
                    <option value={"2"}>Matric</option>
                  </Select>
                </FormControl>

                <FormControl
                  style={{ marginBottom: "10px" }}
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-passing-year">
                    Select Passing Year
                  </InputLabel>
                  <Select
                    native
                    value={passingYear}
                    onChange={handleChange}
                    label="Select Passing Year"
                    name="passingYear"
                  >
                    <option aria-label="None" value="" />
                    {qualification === "1"
                      ? currentPassingYear?.map((item) => (
                          <option value={item}>{item}</option>
                        ))
                      : passingYears?.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                  </Select>
                </FormControl>

                <FormControl
                  style={{ marginBottom: "10px" }}
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-province">Province</InputLabel>
                  <Select
                    native
                    value={province}
                    onChange={handleChange}
                    id="outlined-province"
                    label="Province"
                    name="province"
                  >
                    <option aria-label="None" value="" />
                    {ProvincesData?.length > 0 &&
                      ProvincesData?.map((item) => (
                        <option value={item?.id}>{item?.province}</option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl
                  style={{ marginBottom: "10px" }}
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-board">Board</InputLabel>
                  <Select
                    native
                    value={board}
                    onChange={handleChange}
                    id="outlined-board"
                    label="Board"
                    name="board"
                  >
                    <option aria-label="None" value="" />
                    {province &&
                      BoardsData?.map(
                        (item) =>
                          item.province_id == province && (
                            <option value={item?.id}>{item?.board}</option>
                          )
                      )}
                  </Select>
                </FormControl>

                <div style={{ marginBottom: "10px" }}>
                  <TextField
                    fullWidth
                    id="outlined-mycnic"
                    placeholder="xxxxx-xxxxxxx-x"
                    label="CNIC / B-FORM No"
                    type="text"
                    variant="outlined"
                    name="cnic"
                    value={cnic}
                    onChange={handleChange}
                  />
                </div>

                {qualification === "1" && (
                  <div style={{ marginBottom: "10px" }}>
                    <TextField
                      fullWidth
                      id="outlined-ninth-roll-no"
                      label="Enter Your Ninth Roll No"
                      name="ninthRollNo"
                      value={ninthRollNo}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                    />
                  </div>
                )}

                <div style={{ marginBottom: "10px" }}>
                  <TextField
                    fullWidth
                    id="outlined-matric-roll-no"
                    label="Enter Your Matric Roll No"
                    name="matricRollNo"
                    value={matricRollNo}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                  />
                </div>

                <div style={{ marginTop: "5%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    Proceed
                  </Button>
                </div>
              </NewApplicationItem>
              <WhiteAd />
            </NewApplicationContent>
          </NewApplicationContainer>
          <Ad />
        </div>
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      )}

      {/* Registration Form */}

      {step === 2 && (
        <RegistrationForm
          qualification={qualification}
          cnic={cnic}
          province={province}
          setProvince={setProvince}
          board={board}
          setBoard={setBoard}
          studyGroup={studyGroup}
          setStudyGroup={setStudyGroup}
          subject={subject}
          setSubject={setSubject}
          ninthTotalMarks={ninthTotalMarks}
          setNinthTotalMarks={setNinthTotalMarks}
          ninthObtainedMarks={ninthObtainedMarks}
          setNinthObtainedMarks={setNinthObtainedMarks}
          setNinthScaledObtainedMarks={setNinthScaledObtainedMarks}
          totalMarks={totalMarks}
          setTotalMarks={setTotalMarks}
          obtainedMarks={obtainedMarks}
          setObtainedMarks={setObtainedMarks}
          setScaledObtainedMarks={setScaledObtainedMarks}
          schoolName={schoolName}
          setSchoolName={setSchoolName}
          schoolType={schoolType}
          setSchoolType={setSchoolType}
          imageMarksheet={imageMarksheet}
          setImageMarksheet={setImageMarksheet}
          imageMarksheetName={imageMarksheetName}
          setImageMarksheetName={setImageMarksheetName}
          marksheetImage={marksheetImage}
          setMarksheetImage={setMarksheetImage}
          showMarksheetImage={showMarksheetImage}
          setShowMarksheetImage={setShowMarksheetImage}
          setAppliedId={setAppliedId}
          setFullName={setFullName}
          setFatherName={setFatherName}
          setEmail={setEmail}
          setMobileNumber={setMobileNumber}
          setGender={setGender}
          setFatherMobileNumber={setFatherMobileNumber}
          setFatherCnic={setFatherCnic}
          region={region}
          setRegion={setRegion}
          setFaculty={setFaculty}
          setSelectedColleges={setSelectedColleges}
          availInDataBase={availInDataBase}
          setAvailInDataBase={setAvailInDataBase}
          applied={applied}
          setApplied={setApplied}
          appliedId={appliedId}
          step={step}
          setStep={setStep}
          setAlert={setAlert}
          setOpenBackDrop={setOpenBackDrop}
          matricRollNo={matricRollNo}
          setMatricRollNo={setMatricRollNo}
          passingYear={passingYear}
          passingYearClass5={passingYearClass5}
          setPassingYearClass5={setPassingYearClass5}
          passingYearClass8={passingYearClass8}
          setPassingYearClass8={setPassingYearClass8}
          schoolNameClass5={schoolNameClass5}
          setSchoolNameClass5={setSchoolNameClass5}
          schoolNameClass8={schoolNameClass8}
          setSchoolNameClass8={setSchoolNameClass8}
          districtClass5={districtClass5}
          setDistrictClass5={setDistrictClass5}
          districtClass8={districtClass8}
          setDistrictClass8={setDistrictClass8}
        />
      )}

      {step === 3 && (
        <PersonalInfoForm
          fullName={fullName}
          setFullName={setFullName}
          fatherName={fatherName}
          setFatherName={setFatherName}
          email={email}
          setEmail={setEmail}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          gender={gender}
          setGender={setGender}
          nationality={nationality}
          setNationality={setNationality}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          placeOfBirth={placeOfBirth}
          setPlaceOfBirth={setPlaceOfBirth}
          religion={religion}
          setReligion={setReligion}
          fatherOccupation={fatherOccupation}
          setFatherOccupation={setFatherOccupation}
          cnic={cnic}
          setCnic={setCnic}
          fatherMobileNumber={fatherMobileNumber}
          setFatherMobileNumber={setFatherMobileNumber}
          fatherCnic={fatherCnic}
          setFatherCnic={setFatherCnic}
          motherCnic={motherCnic}
          setMotherCnic={setMotherCnic}
          domicileDistrict={domicileDistrict}
          setDomicileDistrict={setDomicileDistrict}
          fatherDomicileDistrict={fatherDomicileDistrict}
          setFatherDomicileDistrict={setFatherDomicileDistrict}
          addressRegion={addressRegion}
          setAddressRegion={setAddressRegion}
          addressDistrict={addressDistrict}
          setAddressDistrict={setAddressDistrict}
          addressTown={addressTown}
          setAddressTown={setAddressTown}
          homeAddress={homeAddress}
          setHomeAddress={setHomeAddress}
          studentGoal={studentGoal}
          setStudentGoal={setStudentGoal}
          whoFilledForm={whoFilledForm}
          setWhoFilledForm={setWhoFilledForm}
          unionCouncil={unionCouncil}
          setUnionCouncil={setUnionCouncil}
          ward={ward}
          setWard={setWard}
          availInDataBase={availInDataBase}
          setAlert={setAlert}
          step={step}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
          appliedId={appliedId}
          matricRollNo={matricRollNo}
        />
      )}

      {step === 4 && (
        <ChoiceOfFaculty
          qualification={qualification}
          studyGroup={studyGroup}
          obtainedMarks={obtainedMarks}
          scaledObtainedMarks={scaledObtainedMarks}
          ninthScaledObtainedMarks={ninthScaledObtainedMarks}
          subject={subject}
          region={region}
          setRegion={setRegion}
          faculty={faculty}
          setFaculty={setFaculty}
          zone={zone}
          setZone={setZone}
          setAlert={setAlert}
          step={step}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
        />
      )}

      {step === 5 && (
        <SelectColleges
          qualification={qualification}
          region={region}
          faculty={faculty}
          addressDistrict={addressDistrict}
          zone={zone}
          gender={gender}
          scaledObtainedMarks={scaledObtainedMarks}
          ninthScaledObtainedMarks={ninthScaledObtainedMarks}
          step={step}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
          setAlert={setAlert}
          selectedColleges={selectedColleges}
          setSelectedColleges={setSelectedColleges}
        />
      )}

      {step === 6 && (
        <Preview2
          qualification={qualification}
          fullName={fullName}
          fatherName={fatherName}
          email={email}
          mobileNumber={mobileNumber}
          gender={gender}
          cnic={cnic}
          nationality={nationality}
          dateOfBirth={dateOfBirth}
          placeOfBirth={placeOfBirth}
          religion={religion}
          fatherOccupation={fatherOccupation}
          fatherMobileNumber={fatherMobileNumber}
          fatherCnic={fatherCnic}
          motherCnic={motherCnic}
          ninthRollNo={ninthRollNo}
          matricRollNo={matricRollNo}
          passingYear={passingYear}
          province={province}
          board={board}
          studyGroup={studyGroup}
          subject={subject}
          ninthTotalMarks={ninthTotalMarks}
          ninthObtainedMarks={ninthObtainedMarks}
          ninthScaledObtainedMarks={ninthScaledObtainedMarks}
          totalMarks={totalMarks}
          obtainedMarks={obtainedMarks}
          scaledObtainedMarks={scaledObtainedMarks}
          schoolName={schoolName}
          schoolType={schoolType}
          region={region}
          faculty={faculty}
          zone={zone}
          selectedColleges={selectedColleges}
          setOpenBackDrop={setOpenBackDrop}
          availInDataBase={availInDataBase}
          setStep={setStep}
          imageMarksheet={imageMarksheet}
          imageMarksheetName={imageMarksheetName}
          marksheetImage={marksheetImage}
          passingYearClass5={passingYearClass5}
          setPassingYearClass5={setPassingYearClass5}
          passingYearClass8={passingYearClass8}
          schoolNameClass5={schoolNameClass5}
          schoolNameClass8={schoolNameClass8}
          districtClass5={districtClass5}
          districtClass8={districtClass8}
          domicileDistrict={domicileDistrict}
          fatherDomicileDistrict={fatherDomicileDistrict}
          addressRegion={addressRegion}
          addressDistrict={addressDistrict}
          addressTown={addressTown}
          homeAddress={homeAddress}
          whoFilledForm={whoFilledForm}
          studentGoal={studentGoal}
          unionCouncil={unionCouncil}
          ward={ward}
          applied={applied}
          appliedId={appliedId}
          step={step}
          setAlert={setAlert}
        />
      )}

      {step === 7 && (
        <Success
          setOpenBackDrop={setOpenBackDrop}
          setStep={setStep}
          setProvince={setProvince}
          setBoard={setBoard}
          setStudyGroup={setStudyGroup}
          setSubject={setSubject}
          setTotalMarks={setTotalMarks}
          setObtainedMarks={setObtainedMarks}
          setSchoolName={setSchoolName}
          setPassingYearClass8={setPassingYearClass8}
          setDistrictClass8={setDistrictClass8}
          setSchoolNameClass8={setSchoolNameClass8}
          setPassingYearClass5={setPassingYearClass5}
          setDistrictClass5={setDistrictClass5}
          setSchoolNameClass5={setSchoolNameClass5}
          setImageMarksheet={setImageMarksheet}
          setImageMarksheetName={setImageMarksheetName}
          setMarksheetImage={setMarksheetImage}
          setShowMarksheetImage={setShowMarksheetImage}
          setAppliedId={setAppliedId}
          setFullName={setFullName}
          setFatherName={setFatherName}
          setEmail={setEmail}
          setMobileNumber={setMobileNumber}
          setGender={setGender}
          setNationality={setNationality}
          setDateOfBirth={setDateOfBirth}
          setPlaceOfBirth={setPlaceOfBirth}
          setCnic={setCnic}
          setRegion={setRegion}
          setDomicileDistrict={setDomicileDistrict}
          setFatherMobileNumber={setFatherMobileNumber}
          setFatherCnic={setFatherCnic}
          setFatherOccupation={setFatherOccupation}
          setFatherDomicileDistrict={setFatherDomicileDistrict}
          setMotherCnic={setMotherCnic}
          setAddressRegion={setAddressRegion}
          setAddressDistrict={setAddressDistrict}
          setHomeAddress={setHomeAddress}
          setWhoFilledForm={setWhoFilledForm}
          setStudentGoal={setStudentGoal}
          setUnionCouncil={setUnionCouncil}
          setWard={setWard}
          setImageProfile={setImageProfile}
          setImageProfileName={setImageProfileName}
          setProfileImage={setProfileImage}
          setShowProfileImage={setShowProfileImage}
          setFaculty={setFaculty}
          setZone={setZone}
          setSelectedColleges={setSelectedColleges}
          setAvailInDataBase={setAvailInDataBase}
          setApplied={setApplied}
        />
      )}

      <CustomAlert
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

export default NewApplication;
