import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CustomAlert from "../../common/CustomAlert";
import isEmpty, { isEmail } from "../../../helpers/validation";
import axios from "axios";
import { getUser } from "../../../functions/functions";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  FragmentContainer,
  FragmentHeader,
  FragmentHeaderH1,
  FragmentContent,
} from "./FragmentsElements";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddCollege(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [districts, setDistricts] = useState([]);
  const [region, setRegion] = useState();
  const [addOption, setAddOption] = useState("1");
  const [regions, setRegions] = useState([]);
  const [district, setDistrict] = useState();
  const [subdivisions, setSubdivisions] = useState([]);
  const [subdivision, setSubdivision] = useState();
  const [genders, setGenders] = useState([]);
  const [gender, setGender] = useState();
  const [faculties, setFaculties] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [facultySeat, setFacultySeat] = useState([]);
  const [facultyGender, setFacultyGender] = useState([]);
  const [ddo, setDdo] = useState("");
  const [college, setCollege] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [seats, setSeats] = useState();
  const user = getUser(localStorage?.jwtToken);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    let resRegions;

    if (user?.userRole === "rd") {
      resRegions = await axios.get(
        `https://www.dgcs.gos.pk/seccap2/regions/${user?.userId}`
      );
    } else {
      resRegions = await axios.get("https://www.dgcs.gos.pk/seccap2/regions");
    }

    const genderRes = await axios.get(
      "https://www.dgcs.gos.pk/seccap2/colleges/genders"
    );

    const facultiesRes = await axios.get(
      "https://www.dgcs.gos.pk/seccap2/faculties"
    );

    setRegions(resRegions.data.data);
    // setDistricts(res.data.data);
    setGenders(genderRes.data.data);
    setFaculties(facultiesRes.data.data);
  }, []);

  const handleChange = (event) => {
    if (event.target.name === "addOption") {
      setDistrict("");
      setSubdivision("");
      setRegion("");
      setAddOption(event.target.value);
    } else if (event.target.name === "region") {
      setDistrict("");
      setSubdivision("");
      setRegion(event.target.value);
      getDistricts(event.target.value);
    } else if (event.target.name === "district") {
      setSubdivision("");
      setDistrict(event.target.value);
      getSubDivisions(event.target.value);
    } else if (event.target.name === "subdivision") {
      setSubdivision(event.target.value);
    } else if (event.target.name === "gender") {
      setFaculty([]);
      setFacultySeat([]);
      setFacultyGender([]);
      setGender(event.target.value);
    } else if (event.target.name === "ddo") {
      setDdo(event.target.value);
    } else if (event.target.name === "college") {
      setCollege(event.target.value);
    } else if (event.target.name === "seats") {
      setSeats(event.target.value);
    }
  };

  const getDistricts = async (region_id) => {
    const res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/districts/get",
      { region_id },
      {
        headers: {
          Authorization: `${localStorage.getItem("jwtToken")}`,
        },
      }
    );

    setDistricts(res.data.data);
  };

  const getSubDivisions = async (district_id) => {
    const res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/subdivisions/district",
      { district_id },
      {
        headers: {
          Authorization: `${localStorage.getItem("jwtToken")}`,
        },
      }
    );

    setSubdivisions(res.data.data);
  };

  const handleChangeMultiple = (event) => {
    if (isEmpty(gender)) {
      setAlert("Please select gender first!", "error");
    } else if (gender == 3) {
      setFaculty(event.target.value);
      setFacultyGender(() => {
        return [
          {
            gender_id: 1,
            faculty: event.target.value,
          },
          {
            gender_id: 2,
            faculty: event.target.value,
          },
        ];
      });
    } else {
      setFaculty(event.target.value);
      setFacultyGender(() => {
        return [
          {
            gender_id: gender,
            faculty: event.target.value,
          },
        ];
      });
    }
  };

  const handleClick = async () => {
    if (isEmpty(addOption)) {
      setAlert("Please select Add Option!", "error");
    } else if (parseInt(addOption) === 1 && isEmpty(region)) {
      setAlert("Please fill out region field!", "error");
    } else if (parseInt(addOption) === 1 && isEmpty(district)) {
      setAlert("Please fill out district field!", "error");
    } else if (parseInt(addOption) === 1 && isEmpty(subdivision)) {
      setAlert("Please select sub division!", "error");
    } else if (parseInt(addOption) === 1 && isEmpty(gender)) {
      setAlert("Please select gender!", "error");
    } else if (parseInt(addOption) === 1 && isEmpty(college.trim())) {
      setAlert("Please fill out college field!", "error");
    } else if (parseInt(addOption) === 1 && college.trim().length > 100) {
      setAlert(
        "College field cannot contain maximum than 100 characters!",
        "error"
      );
    } else if (parseInt(addOption) === 2 && isEmpty(ddo.trim())) {
      setAlert("Please fill out ddo code!", "error");
    } else if (parseInt(addOption) === 2 && ddo.trim().length > 100) {
      setAlert("Ddo code field contain maximum than 100 characters!", "error");
    } else if (isEmpty(email.trim())) {
      setAlert("Please fill out college email field!", "error");
    } else if (email.trim().length > 100) {
      setAlert(
        "College email field cannot contain maximum than 100 characters!",
        "error"
      );
    } else if (!isEmail(email.trim())) {
      setAlert("Please enter valid college email!", "error");
    } else if (isEmpty(password.trim())) {
      setAlert("Please fill out password field!", "error");
    } else if (password.trim().length < 8 && password.trim().length > 100) {
      setAlert("Password field contains between 8 to 100 characters!", "error");
    } else if (password.trim() !== confirmPassword.trim()) {
      setAlert("Passwords do not match!", "error");
    } else if (parseInt(addOption) === 1 && isEmpty(faculty)) {
      setAlert("Please select faculty!", "error");
    } else if (parseInt(addOption) === 1 && isEmpty(facultySeat)) {
      setAlert("Please fill out seats field!", "error");
    } else {
      let finalFacultiesSeats = [];
      let finalFaculties = facultyGender?.map((item) =>
        item?.faculty?.map((faculty) =>
          facultySeat?.filter(
            (obj) =>
              item?.gender_id === obj?.gender_id &&
              faculty === Object.keys(obj)[1]
          )
        )
      );
      let checkEmpty = finalFaculties.some((item) =>
        item.some((item2) => isEmpty(item2))
      );

      if (parseInt(addOption) === 1 && checkEmpty) {
        setAlert("Please fill out seats field!", "error");
      } else {
        finalFaculties = finalFaculties?.map((item) =>
          item?.map((item2) => item2?.slice(-1)[0] || [])
        );
        finalFaculties = [].concat(...finalFaculties);

        finalFaculties?.map((item) => {
          let facultyObj = faculties?.find(
            (faculty) => faculty?.faculty === Object.keys(item)[1]
          );
          let facultySeatGender = {
            gender_id: item?.gender_id,
            faculty_id: facultyObj?.id,
            seats: Object.values(item)[1],
          };
          if (facultySeatGender !== undefined) {
            finalFacultiesSeats.push(facultySeatGender);
          }
        });

        let checkEmptySeats = finalFacultiesSeats.some((item) =>
          isEmpty(item.seats)
        );
        let checkSeatsUnder = finalFacultiesSeats.some(
          (item) => item.seats < 1
        );
        let checkSeatsOver = finalFacultiesSeats.some(
          (item) => item.seats > 10000
        );

        if (parseInt(addOption) === 1 && checkEmptySeats) {
          setAlert("Please fill out seats field!", "error");
        } else if (parseInt(addOption) === 1 && checkSeatsUnder) {
          setAlert("Seats cannot be less than 1!", "error");
        } else if (parseInt(addOption) === 1 && checkSeatsOver) {
          setAlert("Seats cannot be more than 10000!", "error");
        } else {
          const data = {
            addOption,
            region_id: region,
            district_id: district,
            subdivision_id: subdivision,
            gender_id: gender,
            ddo_code: ddo,
            college_name: college,
            email,
            password,
            confirmPassword,
            faculties: finalFacultiesSeats,
          };

          const res = await axios.post(
            "https://www.dgcs.gos.pk/seccap2/colleges/add",
            data,
            { headers: { Authorization: localStorage.getItem("jwtToken") } }
          );

          if (res?.data?.status === "success") {
            setAlert("Data saved successfully!", "success");
            setTimeout(() => {
              props.setFragment("colleges");
            }, 2000);
          } else if (res?.data?.error?.message) {
            setAlert(res?.data?.error?.message, "error");
          } else {
            setAlert("Something went wrong, Please try again!", "error");
          }
        }
      }
    }
  };

  return (
    <FragmentContainer>
      <Container fixed>
        <FragmentHeader>
          <Grid container spacing={5}>
            <Grid item md={12}>
              <FragmentHeaderH1>Add College</FragmentHeaderH1>
            </Grid>
          </Grid>
        </FragmentHeader>
      </Container>
      <Divider />
      <Container>
        <FragmentContent>
          <div style={{ width: "100%" }}>
            <div>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Add Option
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={addOption}
                  onChange={handleChange}
                  label="Add Option"
                  name="addOption"
                >
                  <MenuItem value={1}>Add College</MenuItem>
                  <MenuItem value={2}>Add User</MenuItem>
                </Select>
              </FormControl>
            </div>

            {parseInt(addOption) === 1 && (
              <>
                {" "}
                <div>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Region
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={region}
                      onChange={handleChange}
                      label="Region"
                      name="region"
                    >
                      {regions?.map((item) => (
                        <MenuItem value={item?.id}>{item?.region}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      District
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={district}
                      onChange={handleChange}
                      label="District"
                      name="district"
                    >
                      {districts?.map((item) => (
                        <MenuItem value={item?.id}>{item?.district}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Sub Division
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={subdivision}
                      onChange={handleChange}
                      label="Sub Division"
                      name="subdivision"
                    >
                      {subdivisions?.length > 0 &&
                        subdivisions?.map((item) => (
                          <MenuItem value={item?.id}>
                            {item?.subdivision}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={gender}
                      onChange={handleChange}
                      label="Gender"
                      name="gender"
                    >
                      {genders?.length > 0 &&
                        genders?.map((item) => (
                          <MenuItem value={item?.id}>{item?.gender}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </>
            )}

            <div style={{ marginBottom: "20px" }}>
              <TextField
                fullWidth
                id="outlined-ddo"
                label="DDO Code"
                value={ddo}
                onChange={(e) =>
                  e.target.value.length < 11 && setDdo(e.target.value)
                }
                name="ddo"
                type="text"
                variant="outlined"
              />
            </div>

            {parseInt(addOption) === 1 && (
              <div style={{ marginBottom: "20px" }}>
                <TextField
                  fullWidth
                  id="outlined-college"
                  label="College"
                  value={college}
                  onChange={(e) =>
                    e.target.value.length < 101 && setCollege(e.target.value)
                  }
                  name="college"
                  type="text"
                  variant="outlined"
                />
              </div>
            )}

            <div style={{ marginBottom: "20px" }}>
              <TextField
                fullWidth
                id="outlined-email"
                label="College Email"
                value={email}
                onChange={(e) =>
                  e.target.value.length < 101 && setEmail(e.target.value)
                }
                name="email"
                type="email"
                variant="outlined"
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <TextField
                fullWidth
                id="outlined-password"
                label="Password"
                value={password}
                onChange={(e) =>
                  e.target.value.length < 101 && setPassword(e.target.value)
                }
                name="password"
                type="password"
                variant="outlined"
              />
            </div>

            <div style={{ marginBottom: "20px" }}>
              <TextField
                fullWidth
                id="outlined-confirm-password"
                label="Confirm Password"
                value={confirmPassword}
                onChange={(e) =>
                  e.target.value.length < 101 &&
                  setConfirmPassword(e.target.value)
                }
                name="confirmPassword"
                type="password"
                variant="outlined"
              />
            </div>

            {parseInt(addOption) === 1 && (
              <>
                <div style={{ marginBottom: "20px" }}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Faculty
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      multiple
                      value={faculty}
                      onChange={handleChangeMultiple}
                      input={<Input />}
                      MenuProps={MenuProps}
                    >
                      {faculties?.length > 0 &&
                        faculties.map((item) => (
                          <MenuItem
                            key={item?.id}
                            name={item?.faculty}
                            value={item?.faculty}
                            style={getStyles(item?.id, item?.faculty, theme)}
                          >
                            {item?.faculty}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>

                {facultyGender?.length > 0 &&
                  facultyGender?.map((item) =>
                    item?.faculty?.map((faculty) => (
                      <div style={{ marginBottom: "20px" }}>
                        <TextField
                          fullWidth
                          id="outlined-seat"
                          label={`No of Seats in ${faculty} for ${
                            item.gender_id == 1 ? "Boys" : "Girls"
                          }`}
                          onChange={(e) =>
                            e.target.value < 10000 &&
                            setFacultySeat((preValue) => {
                              return [
                                ...preValue,
                                {
                                  gender_id: item?.gender_id,
                                  [e.target.name]: e.target.value,
                                },
                              ];
                            })
                          }
                          name={faculty}
                          type="number"
                          variant="outlined"
                        />
                      </div>
                    ))
                  )}
              </>
            )}

            <div>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Add
              </Button>
            </div>

            <CustomAlert
              open={open}
              message={message}
              severity={severity}
              handleClose={handleClose}
            />
          </div>
        </FragmentContent>
      </Container>
    </FragmentContainer>
  );
}
