import styled from "styled-components";

export const AboutUsContainer = styled.div`
  background: #fff;
  height: 100%;
  // min-height: 100vh;
  margin-top: 80px;

  @media screen and (min-width: 1500px) {
    margin-top: 200px;
  }
`;

export const AboutUsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 0;
`;

export const AboutUsP = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1% 20%;
  font-size: 20px;
`;

export const AboutUsContent = styled.div`
  background: #fff;
  border: 1px solid #e4ebe4;
  width: 40rem;
  padding: 5% 5%;
`;

export const AboutUsItem = styled.div`
  padding: 5% 0;
`;