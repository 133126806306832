import React, { useState, useEffect } from "react";
import axios from "axios";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { DataGrid, getThemePaletteMode } from "@material-ui/data-grid";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { getUser } from "../../../functions/functions";
import {
  FragmentContainer,
  FragmentHeader,
  FragmentHeaderH1,
  FragmentContent,
} from "./FragmentsElements";

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => {
    const isDark = getThemePaletteMode(theme.palette) === "dark";

    return {
      root: {
        "& .MuiDataGrid-cell--editing": {
          backgroundColor: "rgb(255,215,115, 0.19)",
          color: "#1a3e72",
        },
        "& .Mui-error": {
          backgroundColor: `rgb(126,10,15, ${isDark ? 0 : 0.1})`,
          color: isDark ? "#ff4343" : "#750f0f",
        },
      },
    };
  },
  { defaultTheme }
);

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function Zones(props) {
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const classes = useStyles();
  const [colleges, setColleges] = useState([]);
  const [collegesFaculties, setCollegesFaculties] = useState([]);
  const [collegesSubjects, setCollegesSubjects] = useState([]);
  const user = getUser(localStorage?.jwtToken);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    window.scrollTo(0, 0);
    let res;
    let res2;
    let res3;

    if (user?.userRole === "rd") {
      res = await axios.get(
        `https://www.dgcs.gos.pk/seccap2/colleges/getCollegesInRegion/${user?.userId}`
      );
      res2 = await axios.get(
        `https://www.dgcs.gos.pk/seccap2/colleges/getCollegesFacultiesInRegion/${user?.userId}`
      );
    } else {
      res2 = await axios.get(
        "https://www.dgcs.gos.pk/seccap2/zones/getCollegesZones"
      );
    }
    setCollegesFaculties(res2?.data?.data);
  }, []);

  const handleEditCellChange = React.useCallback(
    async ({ id, field, props }) => {
      if (field === "ddo_code") {
        const data = {
          id,
          ddo_code: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/seccap2/colleges/updateDdoCode",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "college_name") {
        const data = {
          id,
          college_name: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/seccap2/colleges/updateCollegeName",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "merit_current_year") {
        const data = {
          id,
          merit_current_year: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/seccap2/colleges/updateMeritCurrentYear",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "seats") {
        const data = {
          id,
          seats: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/seccap2/colleges/updateFacultySeats",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "vacant") {
        const data = {
          id,
          vacant: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/ctipbackend/documents/updateVacant",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "closed") {
        const data = {
          id,
          closed: props.value,
        };

        await axios.post(
          "https://www.dgcs.gos.pk/seccap2/colleges/updateAdmissionClosed",
          data,
          {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          }
        );
      }

      if (field === "email") {
        const data = props; // Fix eslint value is missing in prop-types for JS files
        const isValid = validateEmail(data.value);
        const newState = {};
        newState[id] = {
          ...editRowsModel[id],
          email: { ...props, error: !isValid },
        };

        setEditRowsModel((state) => ({ ...state, ...newState }));
      }
    },
    [editRowsModel]
  );

  return (
    <div>
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item md={9}>
                <FragmentHeaderH1>Zones</FragmentHeaderH1>
              </Grid>
              <Grid item md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => props.setFragment("addzone")}
                >
                  Add Zone
                </Button>
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider />
        <Container fixed>
          <FragmentContent>
            <div style={{ height: 600, width: "100%" }}>
              {collegesFaculties.length > 0 ? (
                <DataGrid
                  className={classes.root}
                  rows={collegesFaculties}
                  columns={columns2}
                  editRowsModel={editRowsModel}
                  onEditCellChange={handleEditCellChange}
                />
              ) : (
                <h1
                  style={{
                    textAlign: "center",
                    color: "#c3c3c3",
                    margin: "10% auto",
                  }}
                >
                  Data not Found
                </h1>
              )}
            </div>
          </FragmentContent>
        </Container>
      </FragmentContainer>
    </div>
  );
}

const columns = [
  { field: "ddo_code", headerName: "DDO Code", width: 150, editable: true },
  {
    field: "college_name",
    headerName: "College Name",
    width: 200,
    editable: true,
  },
  { field: "gender", headerName: "Gender", width: 180, editable: false },
  {
    field: "subdivision",
    headerName: "Sub Division",
    width: 180,
    editable: false,
  },
  { field: "district", headerName: "District", width: 180, editable: false },
  { field: "region", headerName: "Region", width: 180, editable: false },
];

const columns2 = [
  {
    field: "zone",
    headerName: "Zones",
    width: 150,
    editable: false,
  },
  { field: "ddo_code", headerName: "DDO Code", width: 150, editable: false },
  {
    field: "college_name",
    headerName: "College Name",
    width: 500,
    editable: false,
  },
  {
    field: "subdivision",
    headerName: "Sub Division",
    width: 180,
    editable: false,
  },
  { field: "district", headerName: "District", width: 180, editable: false },
  { field: "region", headerName: "Region", width: 180, editable: false },
];

const columns3 = [
  { field: "vacant", headerName: "Post Available", width: 200, editable: true },
  { field: "applied", headerName: "Applied", width: 100, editable: false },
  { field: "subject_name", headerName: "Subject", width: 130, editable: false },
  { field: "ddo_code", headerName: "DDO Code", width: 150, editable: false },
  {
    field: "college_name",
    headerName: "College Name",
    width: 500,
    editable: false,
  },
];
