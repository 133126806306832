import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import {
  FragmentContainer,
  FragmentHeader,
  FragmentHeaderH1,
} from "./FragmentsElements";
import SimpleBackdrop from "./../../common/Backdrop";
import InputBase from "@material-ui/core/InputBase";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "gender", label: "Gender", minWidth: 170 },
  { id: "passing_year", label: "Passing Year", minWidth: 170 },
  { id: "faculty", label: "Choice of Faculty", minWidth: 170 },
];

function createData(
  id,
  name,
  gender,
  passing_year,
  faculty,
  marksheet_image,
  total_marks,
  obtained_marks,
  scaled_total_marks,
  scaled_obtained_marks,
  subject1,
  grade1,
  subject2,
  grade2,
  subject3,
  grade3,
  subject4,
  grade4,
  subject5,
  grade5,
  subject6,
  grade6,
  subject7,
  grade7,
  subject8,
  grade8,
  subject9,
  grade9
) {
  return {
    id,
    name,
    gender,
    passing_year,
    faculty,
    marksheet_image,
    total_marks,
    obtained_marks,
    scaled_total_marks,
    scaled_obtained_marks,
    subject1,
    grade1,
    subject2,
    grade2,
    subject3,
    grade3,
    subject4,
    grade4,
    subject5,
    grade5,
    subject6,
    grade6,
    subject7,
    grade7,
    subject8,
    grade8,
    subject9,
    grade9,
  };
}

let rows = [];

const useStyles = makeStyles((theme) => ({
  rootSearch: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 800,
  },
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function OlevelUnapprovedList(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [activity, setActivity] = useState(false);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);

  let history = useHistory();

  const handleChange = async (e) => {
    // if (e.target.value === "") {
    //   getStatsData();
    // }

    getUnapprovedStudents(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    await setOpenBackDrop(true);
    await getUnapprovedStudents("null");
    await setOpenBackDrop(false);
  }, []);

  const getUnapprovedStudents = async (search) => {
    if (search === "") {
      search = "null";
    }
    setOpenBackDrop(true);
    const res = await axios.get(
      `https://www.dgcs.gos.pk/seccap2/students/unapprovedOlevel/${search}`,
      {
        headers: {
          Authorization: `${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    if (res) {
      setOpenBackDrop(false);
    }
    if (res?.data?.status === "success") {
      rows = res?.data?.data.map((item) =>
        createData(
          item?.id,
          item?.name,
          item?.gender,
          item?.passing_year,
          item?.faculty,
          item?.marksheet_image,
          item?.total_marks,
          item?.obtained_marks,
          item?.scaled_total_marks,
          item?.scaled_obtained_marks,
          item?.subject1,
          item?.grade1,
          item?.subject2,
          item?.grade2,
          item?.subject3,
          item?.grade3,
          item?.subject4,
          item?.grade4,
          item?.subject5,
          item?.grade5,
          item?.subject6,
          item?.grade6,
          item?.subject7,
          item?.grade7,
          item?.subject8,
          item?.grade8,
          item?.subject9,
          item?.grade9
        )
      );
      await setActivity(true);
    } else if (
      res.data.error.message === "user is not verified" ||
      res.data.error.message === "user is not authorized"
    ) {
      // Remove token from localStorage
      localStorage.removeItem("jwtToken");
      // Redirect to login
      history.push("/signin");
    }
  };

  const handleRowClick = (item) => {
    props.setUnapprovedData(item);
    props.setFragment("olevelunapproveddetails");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <FragmentContainer>
      <SimpleBackdrop openBackDrop={openBackDrop} />
      <FragmentHeader>
        <FragmentHeaderH1>Olevel Unapproved Applications</FragmentHeaderH1>
      </FragmentHeader>
      <Divider />

      {rows?.length > 0 ? (
        <div style={{ marginTop: "2rem" }}>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
              padding: "2rem 0",
            }}
          >
            <Paper component="form" className={classes.rootSearch}>
              <InputBase
                className={classes.input}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleChange}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
                onClick={handleSubmit}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow style={{ background: "#137a4c" }}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          color: "#fff",
                          background: "#137a4c",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          onClick={() => handleRowClick(row)}
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      ) : (
        <h1
          style={{
            textAlign: "center",
            height: "5rem",
            color: "#c3c3c3",
            margin: "5%",
          }}
        >
          Data not Found
        </h1>
      )}
    </FragmentContainer>
  );
}
