import React, {useEffect} from "react";
import ScrollToTop from "../components/ScrollToTop";
import Dashboard from "../components/Dashboard";
import {useHistory} from 'react-router-dom';

const DashboardPage = () => {
  let history = useHistory();
  useEffect(() => {
    // Check for token
    if (!localStorage.jwtToken) {
        // Redirect to login
        history.push("/signin");
    }
  }, []);
  
  return (
    <>
      <ScrollToTop />
      <Dashboard />
    </>
  );
};

export default DashboardPage;
