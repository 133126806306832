import React, { useEffect } from "react";
import {
  RegistrationContainer,
  RegistrationContent,
  RegistrationItem,
  RegistrationHeading,
} from "./RegistrationFormElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import isEmpty from "../../../../helpers/validation";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import * as ApiCalls from "../../../../services/ApiCalls";
import background from "./../../../../images/background.jpg";
import Stepper from "../stepper/Stepper";
import Grid from "@material-ui/core/Grid";
import WhiteAd from "../../../Ad/WhiteAd";
import districtsData from "../../../../data/districts.json";
import { olevelGrades } from "../../../../data/olevelGrades";
import { olevelPassingYears } from "../../../../data/passingyears";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(0),
    width: "100%",
    minWidth: 120,
    // marginBottom: '1%'
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  multilineColor: {
    color: "red",
  },
}));

const RegistrationForm = (props) => {
  const classes = useStyles();

  const passingYearsClass5 = [
    props.passingYear - 8,
    props.passingYear - 7,
    props.passingYear - 6,
    props.passingYear - 5,
  ];

  useEffect(async () => {
    window.scrollTo(0, 0);
    props.setOpenBackDrop(false);
    if (props.marksheetImage)
      await props.setShowMarksheetImage(
        `${ApiCalls.olevelURL}/${props.marksheetImage}`
      );
    if (props.appliedId) await olevelAppliedGrades(props?.appliedId);
  }, []);

  const olevelAppliedGrades = async (applied_id) => {
    const res = await ApiCalls.olevelAppliedGrades(applied_id);
    if (res?.data?.status === "success") {
      const data = res?.data?.data;

      if (data) {
        props.setSubject1(data?.subject1);
        props.setSubject2(data?.subject2);
        props.setSubject3(data?.subject3);
        props.setSubject4(data?.subject4);
        props.setSubject5(data?.subject5);
        props.setSubject6(data?.subject6);
        props.setSubject7(data?.subject7);
        props.setSubject8(data?.subject8);
        props.setSubject9(data?.subject9);
        props.setGrade1(data?.grade1);
        props.setGrade2(data?.grade2);
        props.setGrade3(data?.grade3);
        props.setGrade4(data?.grade4);
        props.setGrade5(data?.grade5);
        props.setGrade6(data?.grade6);
        props.setGrade7(data?.grade7);
        props.setGrade8(data?.grade8);
        props.setGrade9(data?.grade9);
      }
    }
  };

  const handleImage = (e) => {
    const img = e.target.files[0];
    props.setShowMarksheetImage(URL.createObjectURL(img));
    props.setImageMarksheet(e.target.files[0]);
    props.setImageMarksheetName(e.target.files[0].name);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "schoolName") {
      if (value.length < 101) {
        props.setSchoolName(value);
      }
    }
    if (name === "subject1") {
      if (value.length < 101) {
        props.setSubject1(value);
      }
    }
    if (name === "subject2") {
      if (value.length < 101) {
        props.setSubject2(value);
      }
    }
    if (name === "subject3") {
      if (value.length < 101) {
        props.setSubject3(value);
      }
    }
    if (name === "subject4") {
      if (value.length < 101) {
        props.setSubject4(value);
      }
    }
    if (name === "subject5") {
      if (value.length < 101) {
        props.setSubject5(value);
      }
    }
    if (name === "subject6") {
      if (value.length < 101) {
        props.setSubject6(value);
      }
    }
    if (name === "subject7") {
      if (value.length < 101) {
        props.setSubject7(value);
      }
    }
    if (name === "subject8") {
      if (value.length < 101) {
        props.setSubject8(value);
      }
    }
    if (name === "subject9") {
      if (value.length < 101) {
        props.setSubject9(value);
      }
    }
    if (name === "grade1") {
      props.setGrade1(value);
    }
    if (name === "grade2") {
      props.setGrade2(value);
    }
    if (name === "grade3") {
      props.setGrade3(value);
    }
    if (name === "grade4") {
      props.setGrade4(value);
    }
    if (name === "grade5") {
      props.setGrade5(value);
    }
    if (name === "grade6") {
      props.setGrade6(value);
    }
    if (name === "grade7") {
      props.setGrade7(value);
    }
    if (name === "grade8") {
      props.setGrade8(value);
    }
    if (name === "grade9") {
      props.setGrade9(value);
    }
    if (name === "schoolType") {
      props.setSchoolType(value);
    }
    if (name === "passingYear") {
      props.setPassingYear(value);
    }
    if (name === "passingYearClass5") {
      props.setPassingYearClass5(value);
    }
    if (name === "districtClass5") {
      props.setDistrictClass5(value);
    }
    if (name === "schoolNameClass5") {
      if (value.length < 101) {
        props.setSchoolNameClass5(value);
      }
    }
  };

  const handleBack = () => {
    props.setSchoolName("");
    props.setImageMarksheet("");
    props.setImageMarksheetName("");
    props.setMarksheetImage("");
    props.setAppliedId("");
    props.setFullName("");
    props.setFatherName("");
    props.setEmail("");
    props.setMobileNumber("");
    props.setGender("");
    props.setFatherMobileNumber("");
    props.setFatherCnic("");
    props.setRegion("");
    props.setFaculty("");
    props.setSelectedColleges([]);
    props.setApplied(false);
    props.setPassingYearClass5("");
    props.setSchoolNameClass5("");
    props.setStep(1);
  };

  const handleClick = async () => {
    let gradeCount = 0;
    if (isEmpty(props.subject1) || isEmpty(props.grade1)) {
      gradeCount++;
    }
    if (isEmpty(props.subject2) || isEmpty(props.grade2)) {
      gradeCount++;
    }
    if (isEmpty(props.subject3) || isEmpty(props.grade3)) {
      gradeCount++;
    }
    if (isEmpty(props.subject4) || isEmpty(props.grade4)) {
      gradeCount++;
    }
    if (isEmpty(props.subject5) || isEmpty(props.grade5)) {
      gradeCount++;
    }
    if (isEmpty(props.subject6) || isEmpty(props.grade6)) {
      gradeCount++;
    }
    if (isEmpty(props.subject7) || isEmpty(props.grade7)) {
      gradeCount++;
    }
    if (isEmpty(props.subject8) || isEmpty(props.grade8)) {
      gradeCount++;
    }
    if (isEmpty(props.subject9) || isEmpty(props.grade9)) {
      gradeCount++;
    }

    if (isEmpty(props.subject1) && !isEmpty(props.grade1)) {
      props.setAlert("Please fill out Subject at position 1!", "error");
    } else if (!isEmpty(props.subject1) && isEmpty(props.grade1)) {
      props.setAlert("Please select Grade at position 1!", "error");
    } else if (isEmpty(props.subject2) && !isEmpty(props.grade2)) {
      props.setAlert("Please fill out Subject at position 2!", "error");
    } else if (!isEmpty(props.subject2) && isEmpty(props.grade2)) {
      props.setAlert("Please select Grade at position 2!", "error");
    } else if (isEmpty(props.subject3) && !isEmpty(props.grade3)) {
      props.setAlert("Please fill out Subject at position 3!", "error");
    } else if (!isEmpty(props.subject3) && isEmpty(props.grade3)) {
      props.setAlert("Please select Grade at position 3!", "error");
    } else if (isEmpty(props.subject4) && !isEmpty(props.grade4)) {
      props.setAlert("Please fill out Subject at position 4!", "error");
    } else if (!isEmpty(props.subject4) && isEmpty(props.grade4)) {
      props.setAlert("Please select Grade at position 4!", "error");
    } else if (isEmpty(props.subject5) && !isEmpty(props.grade5)) {
      props.setAlert("Please fill out Subject at position 5!", "error");
    } else if (!isEmpty(props.subject5) && isEmpty(props.grade5)) {
      props.setAlert("Please select Grade at position 5!", "error");
    } else if (isEmpty(props.subject6) && !isEmpty(props.grade6)) {
      props.setAlert("Please fill out Subject at position 6!", "error");
    } else if (!isEmpty(props.subject6) && isEmpty(props.grade6)) {
      props.setAlert("Please select Grade at position 6!", "error");
    } else if (isEmpty(props.subject7) && !isEmpty(props.grade7)) {
      props.setAlert("Please fill out Subject at position 7!", "error");
    } else if (!isEmpty(props.subject7) && isEmpty(props.grade7)) {
      props.setAlert("Please select Grade at position 7!", "error");
    } else if (isEmpty(props.subject8) && !isEmpty(props.grade8)) {
      props.setAlert("Please fill out Subject at position 8!", "error");
    } else if (!isEmpty(props.subject8) && isEmpty(props.grade8)) {
      props.setAlert("Please select Grade at position 8!", "error");
    } else if (isEmpty(props.subject9) && !isEmpty(props.grade9)) {
      props.setAlert("Please fill out Subject at position 9!", "error");
    } else if (!isEmpty(props.subject9) && isEmpty(props.grade9)) {
      props.setAlert("Please select Grade at position 9!", "error");
    } else if (gradeCount > 7) {
      props.setAlert("Please select at least 2 Subject's Grades!", "error");
    } else if (isEmpty(props.schoolName)) {
      props.setAlert("Please fill out School Name!", "error");
    } else if (props.schoolName.length > 100) {
      props.setAlert(
        "School Name field cannot contain maximum than 100 characters!",
        "error"
      );
    } else if (isEmpty(props.schoolType)) {
      props.setAlert("Please Select Type of School!", "error");
    } else if (props.schoolType != 1 && props.schoolType != 2) {
      props.setAlert("Please select valid Type of School!", "error");
    } else if (isEmpty(props.passingYearClass5)) {
      props.setAlert("Please select Class 5 Passing Year!", "error");
    } else if (isEmpty(props.districtClass5)) {
      props.setAlert("Please select Class 5 District!", "error");
    } else if (props.districtClass5.length > 100) {
      props.setAlert(
        "Class 5 District field cannot contain maximum than 100 characters!",
        "error"
      );
    } else if (isEmpty(props.schoolNameClass5)) {
      props.setAlert("Please fill out CLass 5 School Name!", "error");
    } else if (props.schoolNameClass5.length > 100) {
      props.setAlert(
        "CLass 5 School Name field cannot contain maximum than 100 characters!",
        "error"
      );
    } else if (
      isEmpty(props.imageMarksheetName) &&
      isEmpty(props.marksheetImage)
    ) {
      props.setAlert("Please upload Grade sheet!", "error");
    } else {
      props.setOpenBackDrop(true);
      setTimeout(() => {
        props.setStep(3);
      }, 1000);
    }
  };

  return (
    <div>
      <RegistrationContainer style={{ backgroundImage: `url(${background})` }}>
        <RegistrationContent>
          <Stepper step={props.step} />
          <RegistrationItem>
            <div style={{ marginBottom: "5%" }}>
              <RegistrationHeading>Educational Details</RegistrationHeading>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <p style={{ paddingTop: "20px" }}>1.</p>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: "0" }}>
                      <TextField
                        fullWidth
                        id="outlined-subject1"
                        label="Subject"
                        type="text"
                        variant="outlined"
                        name="subject1"
                        value={props.subject1}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-grade1">Grade</InputLabel>
                      <Select
                        native
                        id="outlined-grade1"
                        value={props.grade1}
                        onChange={handleChange}
                        label="Grade"
                        name="grade1"
                      >
                        <option aria-label="None" value="" />
                        {olevelGrades.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <p style={{ paddingTop: "20px" }}>2.</p>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: "0" }}>
                      <TextField
                        fullWidth
                        id="outlined-subject2"
                        label="Subject"
                        type="text"
                        variant="outlined"
                        name="subject2"
                        value={props.subject2}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-grade2">Grade</InputLabel>
                      <Select
                        native
                        id="outlined-grade2"
                        value={props.grade2}
                        onChange={handleChange}
                        label="Grade"
                        name="grade2"
                      >
                        <option aria-label="None" value="" />
                        {olevelGrades.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <p style={{ paddingTop: "20px" }}>3.</p>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: "0" }}>
                      <TextField
                        fullWidth
                        id="outlined-subject3"
                        label="Subject"
                        type="text"
                        variant="outlined"
                        name="subject3"
                        value={props.subject3}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-grade3">Grade</InputLabel>
                      <Select
                        native
                        id="outlined-grade3"
                        value={props.grade3}
                        onChange={handleChange}
                        label="Grade"
                        name="grade3"
                      >
                        <option aria-label="None" value="" />
                        {olevelGrades.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <p style={{ paddingTop: "20px" }}>4.</p>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: "0" }}>
                      <TextField
                        fullWidth
                        id="outlined-subject4"
                        label="Subject"
                        type="text"
                        variant="outlined"
                        name="subject4"
                        value={props.subject4}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-grade4">Grade</InputLabel>
                      <Select
                        native
                        id="outlined-grade4"
                        value={props.grade4}
                        onChange={handleChange}
                        label="Grade"
                        name="grade4"
                      >
                        <option aria-label="None" value="" />
                        {olevelGrades.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <p style={{ paddingTop: "20px" }}>5.</p>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: "0" }}>
                      <TextField
                        fullWidth
                        id="outlined-subject5"
                        label="Subject"
                        type="text"
                        variant="outlined"
                        name="subject5"
                        value={props.subject5}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-grade5">Grade</InputLabel>
                      <Select
                        native
                        id="outlined-grade5"
                        value={props.grade5}
                        onChange={handleChange}
                        label="Grade"
                        name="grade5"
                      >
                        <option aria-label="None" value="" />
                        {olevelGrades.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <p style={{ paddingTop: "20px" }}>6.</p>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: "0" }}>
                      <TextField
                        fullWidth
                        id="outlined-subject6"
                        label="Subject"
                        type="text"
                        variant="outlined"
                        name="subject6"
                        value={props.subject6}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-grade6">Grade</InputLabel>
                      <Select
                        native
                        id="outlined-grade6"
                        value={props.grade6}
                        onChange={handleChange}
                        label="Grade"
                        name="grade6"
                      >
                        <option aria-label="None" value="" />
                        {olevelGrades.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <p style={{ paddingTop: "20px" }}>7.</p>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: "0" }}>
                      <TextField
                        fullWidth
                        id="outlined-subject7"
                        label="Subject"
                        type="text"
                        variant="outlined"
                        name="subject7"
                        value={props.subject7}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-grade7">Grade</InputLabel>
                      <Select
                        native
                        id="outlined-grade7"
                        value={props.grade7}
                        onChange={handleChange}
                        label="Grade"
                        name="grade7"
                      >
                        <option aria-label="None" value="" />
                        {olevelGrades.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <p style={{ paddingTop: "20px" }}>8.</p>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: "0" }}>
                      <TextField
                        fullWidth
                        id="outlined-subject8"
                        label="Subject"
                        type="text"
                        variant="outlined"
                        name="subject8"
                        value={props.subject8}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-grade8">Grade</InputLabel>
                      <Select
                        native
                        id="outlined-grade8"
                        value={props.grade8}
                        onChange={handleChange}
                        label="Grade"
                        name="grade8"
                      >
                        <option aria-label="None" value="" />
                        {olevelGrades.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <p style={{ paddingTop: "20px" }}>9.</p>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ marginTop: "0" }}>
                      <TextField
                        fullWidth
                        id="outlined-subject9"
                        label="Subject"
                        type="text"
                        variant="outlined"
                        name="subject9"
                        value={props.subject9}
                        onChange={handleChange}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-grade9">Grade</InputLabel>
                      <Select
                        native
                        id="outlined-grade9"
                        value={props.grade9}
                        onChange={handleChange}
                        label="Grade"
                        name="grade9"
                      >
                        <option aria-label="None" value="" />
                        {olevelGrades.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
              style={{ marginTop: "50px" }}
            >
              <InputLabel htmlFor="outlined-passing-year">
                Select Olevel Passing Year
              </InputLabel>
              <Select
                native
                value={props.passingYear}
                onChange={handleChange}
                label="Select Olevel Passing Year"
                name="passingYear"
              >
                <option aria-label="None" value="" />

                {olevelPassingYears.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </Select>
            </FormControl>

            <Grid container spacing={2} style={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={12} md={6}>
                <div style={{ marginTop: "0" }}>
                  <TextField
                    fullWidth
                    id="outlined-school"
                    label="School Name"
                    type="text"
                    variant="outlined"
                    name="schoolName"
                    value={props.schoolName}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-schoolType">
                    Type of School
                  </InputLabel>
                  <Select
                    native
                    id="outlined-schoolType"
                    value={props.schoolType}
                    onChange={handleChange}
                    label="Type of School"
                    name="schoolType"
                  >
                    <option aria-label="None" value="" />
                    <option value="1">GOVERNMENT</option>
                    <option value="2">PRIVATE</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  style={{ marginTop: "0" }}
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-passing-year-class5">
                    Class 5 Passing Year
                  </InputLabel>
                  <Select
                    native
                    value={props.passingYearClass5}
                    onChange={handleChange}
                    label="Class 5 Passing Year"
                    name="passingYearClass5"
                    id="outlined-passing-year-class5"
                  >
                    <option aria-label="None" value="" />
                    {passingYearsClass5.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {districtsData?.length > 0 && (
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-district-class5">
                      Class 5 Passing District
                    </InputLabel>
                    <Select
                      native
                      value={props.districtClass5}
                      onChange={handleChange}
                      label="Class 5 Passing District"
                      name="districtClass5"
                      id="outlined-district-class5"
                    >
                      <option aria-label="None" value="" />
                      {districtsData?.length > 0 &&
                        districtsData?.map((item) => (
                          <option value={item?.id}>{item?.district}</option>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <div style={{ marginTop: "0" }}>
                  <TextField
                    fullWidth
                    id="outlined-school-class5"
                    label="Class 5 School Name"
                    type="text"
                    variant="outlined"
                    name="schoolNameClass5"
                    value={props.schoolNameClass5}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
            </Grid>

            <div>
              <div style={{ marginTop: "5%", marginLeft: "0px" }}>
                <div className={classes.root}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    onChange={handleImage}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <PhotoCamera /> Upload Grade sheet
                    </Button>
                  </label>
                  <input
                    accept="image/*"
                    className={classes.input}
                    onChange={handleImage}
                    id="icon-button-file"
                    type="file"
                  />
                </div>
              </div>
              <div style={{ marginTop: "3%" }}>
                <img
                  style={{ width: "20vw", height: "100%" }}
                  src={props.showMarksheetImage}
                  alt="Gradesheet"
                />
              </div>
            </div>

            <div style={{ marginTop: "5%" }}>
              <Button
                variant="contained"
                color="default"
                style={{ marginRight: "1rem" }}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Save {"&"} Continue
              </Button>
            </div>
          </RegistrationItem>
          <WhiteAd />
        </RegistrationContent>
      </RegistrationContainer>
    </div>
  );
};

export default RegistrationForm;
