import React, { useState, useEffect } from "react";
import {
  PersonalContainer,
  PersonalContent,
  PersonalItem,
  PersonalHeading,
} from "./PersonalInfoFormElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import isEmpty, {
  isNumber,
  isName,
  isEmail,
  isCNIC,
  isNumberOrDash,
} from "../../../../helpers/validation";
import * as ApiCalls from "../../../../services/ApiCalls";
import background from "./../../../../images/background.jpg";
// import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Stepper from "../stepper/Stepper";
import Grid from "@material-ui/core/Grid";

import RegionsData from "../../../../data/regions.json";
import DistrictsData from "../../../../data/districts.json";
import SubdivisionsData from "../../../../data/subdivisions.json";
import ReligionsData from "../../../../data/religions.json";
import { getUser } from "../../../../functions/functions";
import WhiteAd from "../../../Ad/WhiteAd";
import Ad from "../../../Ad";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  formControl: {
    margin: theme.spacing(0),
    width: "100%",
    minWidth: 120,
    // marginBottom: '1%'
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  multilineColor: {
    color: "red",
  },
}));

const PersonalInfoForm = (props) => {
  const [addressRegions, setAddressRegions] = useState([]);
  const [addressDistricts, setAddressDistricts] = useState([]);
  const [addressTowns, setAddressTowns] = useState([]);
  const [religions, setReligions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const classes = useStyles();

  const user = getUser(localStorage?.getItem("jwtToken"));

  useEffect(async () => {
    window.scrollTo(0, 0);
    await getAddressDistrictsByRegionId(props.addressRegion);
    await getAddressTownsByDistrictId(props.addressDistrict);
    await getReligions();
    // if (props.imageProfileName) await props.setShowProfileImage(`${ApiCalls.profileImageURL}/${props.imageProfileName}`);
    props.setOpenBackDrop(false);
  }, []);

  const getReligions = async () => {
    setReligions(ReligionsData);
  };

  const getAddressTownsByDistrictId = async (district_id) => {
    const subdivisions = SubdivisionsData?.filter(
      (item) => item.district_id == district_id
    );
    setAddressTowns(subdivisions);
  };

  const getAddressDistrictsByRegionId = async (region_id) => {
    const districts = DistrictsData?.filter(
      (item) => item.region_id == region_id
    );
    setAddressDistricts(districts);
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (name === "fullName") {
      if (isName(value) && value.length < 51) {
        props.setFullName(value);
      }
    }
    if (name === "fatherName") {
      if (isName(value) && value.length < 51) {
        props.setFatherName(value);
      }
    }
    if (name === "email") {
      if (value.length < 101) {
        props.setEmail(value);
      }
    }
    if (name === "mobileNumber") {
      if ((isNumber(value) || value === "") && value.length < 12) {
        props.setMobileNumber(value);
      }
    }
    if (name === "gender") {
      if (value === "1" || value === "2") {
        props.setGender(value);
      }
    }
    if (name === "nationality") {
      if (isName(value) && value.length < 51) {
        props.setNationality(value);
      }
    }
    if (name === "dateofbirth") {
      props.setDateOfBirth(value);
    }
    if (name === "placeofbirth") {
      if (isName(value) && value.length < 51) {
        props.setPlaceOfBirth(value);
      }
    }
    if (name === "cnic") {
      if (isNumberOrDash(value) && value.length < 16) {
        props.setCnic(value);
      }
    }
    if (name === "religion") {
      props.setReligion(value);
    }
    if (name === "domicile") {
      props.setDomicileDistrict(value);
    }
    if (name === "fatherMobileNumber") {
      if ((isNumber(value) || value === "") && value.length < 12) {
        props.setFatherMobileNumber(value);
      }
    }
    if (name === "fatherCnic") {
      if (isNumberOrDash(value) && value.length < 16) {
        props.setFatherCnic(value);
      }
    }
    if (name === "fatheroccupation") {
      if (isName(value) && value.length < 51) {
        props.setFatherOccupation(value);
      }
    }
    if (name === "fatherdomicile") {
      props.setFatherDomicileDistrict(value);
    }
    if (name === "motherCnic") {
      if (isNumberOrDash(value) && value.length < 16) {
        props.setMotherCnic(value);
      }
    }
    if (name === "region") {
      await props.setAddressRegion(value);
      await props.setAddressDistrict("");
      await props.setAddressTown("");
      await setAddressTowns([]);
      await setAddressDistricts([]);
      await getAddressDistrictsByRegionId(value);
    }
    if (name === "district") {
      await props.setAddressDistrict(value);
      await props.setAddressTown("");
      await setAddressTowns([]);
      getAddressTownsByDistrictId(value);
    }
    if (name === "town") {
      props.setAddressTown(value);
    }
    if (name === "unionCouncil") {
      if (value.length < 101) {
        props.setUnionCouncil(value);
      }
    }
    if (name === "ward") {
      if (value.length < 101) {
        props.setWard(value);
      }
    }
    if (name === "homeAddress") {
      if (value.length < 101) {
        props.setHomeAddress(value);
      }
    }
    if (name === "studentGoal") {
      if (value.length < 101) {
        props.setStudentGoal(value);
      }
    }
    if (name === "whoFilledForm") {
      if (value.length < 101) {
        props.setWhoFilledForm(value);
      }
    }
  };

  // const handleImage = (e) => {
  //   const img = e.target.files[0];
  //   props.setShowProfileImage(URL.createObjectURL(img));
  //   props.setImageProfile(e.target.files[0]);
  //   props.setImageProfileName(e.target.files[0].name);

  // }

  const handleBack = () => {
    props.setOpenBackDrop(true);
    setTimeout(() => {
      props.setStep(2);
    }, 1000);
  };

  function calculateAge(birthdateString) {
    // Parse the input date string into a Date object
    const birthdate = new Date(birthdateString);

    // Get the current date
    const today = new Date();

    // Calculate the difference in years
    let age = today.getFullYear() - birthdate.getFullYear();

    // Adjust for months and days
    const monthDifference = today.getMonth() - birthdate.getMonth();
    const dayDifference = today.getDate() - birthdate.getDate();

    // If the birthdate hasn't occurred yet this year, subtract 1 from the age
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  }

  const handleClick = async () => {
    let age;
    if (!isEmpty(props.dateOfBirth)) {
      age = calculateAge(props.dateOfBirth);
    }
    if (isEmpty(props.fullName)) {
      props.setAlert("Please fill out Full Name!", "error");
    } else if (!isName(props.fullName) && !props.availInDataBase) {
      props.setAlert(
        "Full Name field contains only letters and white spaces!",
        "error"
      );
    } else if (props.fullName.trim().length > 50) {
      props.setAlert(
        "Full Name field cannot contain maximum than 50 characters!",
        "error"
      );
    } else if (isEmpty(props.fatherName)) {
      props.setAlert("Please fill out Father Name!", "error");
    } else if (!isName(props.fatherName) && !props.availInDataBase) {
      props.setAlert(
        "Father Name field contains only letters and white spaces!",
        "error"
      );
    } else if (props.fatherName.length > 50) {
      props.setAlert(
        "Father Name field cannot contain maximum than 50 characters!",
        "error"
      );
    } else if (isEmpty(props.email)) {
      props.setAlert("Please fill out Email!", "error");
    } else if (!isEmail(props.email)) {
      props.setAlert("Please fill out valid Email!", "error");
    } else if (props.email.length > 100) {
      props.setAlert(
        "Email field cannot contain maximum than 100 characters!",
        "error"
      );
    } else if (isEmpty(props.mobileNumber)) {
      props.setAlert("Please fill out Mobile Number!", "error");
    } else if (!isNumber(props.mobileNumber)) {
      props.setAlert("Mobile Number field contains only numbers!", "error");
    } else if (props.mobileNumber.length !== 11) {
      props.setAlert("Mobile Number field contains only 11 numbers!", "error");
    } else if (isEmpty(props.gender)) {
      props.setAlert("Please select Gender!", "error");
    } else if (props.gender !== "1" && props.gender !== "2") {
      props.setAlert("Please select valid Gender!", "error");
    } else if (isEmpty(props.nationality)) {
      props.setAlert("Please fill out Nationality!", "error");
    } else if (isEmpty(props.dateOfBirth)) {
      props.setAlert("Please fill out Date of Birth!", "error");
    } else if (age < 10) {
      props.setAlert("Minimum age required is 10 years!", "error");
    } else if (age > 30) {
      props.setAlert("Please fill out valid Date of Birth!", "error");
    } else if (isEmpty(props.placeOfBirth)) {
      props.setAlert("Please fill out Place of Birth!", "error");
    } else if (isEmpty(props.cnic)) {
      props.setAlert("Please fill out CNIC / B-FORM No!", "error");
    } else if (!isCNIC(props.cnic)) {
      props.setAlert("Please fill out valid CNIC / B-FORM No!", "error");
    } else if (props.cnic.length !== 15) {
      props.setAlert(
        "CNIC / B-FORM No field contains only 15 characters!",
        "error"
      );
    } else if (isEmpty(props.religion)) {
      props.setAlert("Please select Religion!", "error");
    } else if (isEmpty(props.domicileDistrict)) {
      props.setAlert("Please select Domicile District!", "error");
    } else if (isEmpty(props.fatherMobileNumber)) {
      props.setAlert(
        "Please fill out Father's / Guardian's Mobile Number!",
        "error"
      );
    } else if (!isNumber(props.fatherMobileNumber)) {
      props.setAlert(
        "Father's / Guardian's Mobile Number field contains only numbers!",
        "error"
      );
    } else if (props.fatherMobileNumber.length !== 11) {
      props.setAlert(
        "Father's / Guardian's Mobile Number field contains only 11 numbers!",
        "error"
      );
    } else if (isEmpty(props.fatherCnic)) {
      props.setAlert("Please fill out Father's / Guardian's CNIC!", "error");
    } else if (!isCNIC(props.fatherCnic)) {
      props.setAlert(
        "Please fill out Father's / Guardian's valid CNIC!",
        "error"
      );
    } else if (props.fatherCnic.length !== 15) {
      props.setAlert(
        "Father's / Guardian's CNIC field contains only 15 characters!",
        "error"
      );
    } else if (isEmpty(props.fatherOccupation)) {
      props.setAlert("Please fill out Father's Occupation", "error");
    } else if (isEmpty(props.fatherDomicileDistrict)) {
      props.setAlert("Please fill out Father's Domicile District", "error");
    } else if (isEmpty(props.motherCnic)) {
      props.setAlert("Please fill out Mother's CNIC!", "error");
    } else if (!isCNIC(props.motherCnic)) {
      props.setAlert("Please fill out Mother's valid CNIC!", "error");
    } else if (props.motherCnic.length !== 15) {
      props.setAlert(
        "Mother's CNIC field contains only 15 characters!",
        "error"
      );
    } else if (isEmpty(props.addressRegion)) {
      props.setAlert("Please select Address Region!", "error");
    } else if (!isEmpty(addressDistricts) && isEmpty(props.addressDistrict)) {
      props.setAlert("Please select Address District!", "error");
    } else if (!isEmpty(addressTowns) && isEmpty(props.addressTown)) {
      props.setAlert("Please select Address Sub Division / Town!", "error");
    } else if (isEmpty(props.homeAddress)) {
      props.setAlert("Please Enter Home Address!", "error");
    } else if (props.email.homeAddress > 100) {
      props.setAlert(
        "Home Address field cannot contain maximum than 100 characters!",
        "error"
      );
    } else {
      props.setOpenBackDrop(true);

      const data = {
        cnic: props.cnic,
        matric_roll_no: props.matricRollNo,
        applied_id: props.appliedId,
      };

      let response;

      if (props.appliedId) {
        response = await ApiCalls.CheckSeccapCnicAndMatricRollNoAppliedId(data);
      } else {
        response = await ApiCalls.CheckSeccapCnicAndMatricRollNo(data);
      }

      if (response?.data?.data === false) {
        setTimeout(() => {
          props.setStep(4);
        }, 1000);
      } else if (response?.data?.data === true) {
        props.setOpenBackDrop(false);
        props.setAlert(
          "Cnic or Matric Roll No Already exists, please try another one!",
          "error"
        );
      } else {
        props.setOpenBackDrop(false);
        props.setAlert("Something went wrong, please try again!", "error");
      }
    }
  };

  return (
    <div>
      <PersonalContainer style={{ backgroundImage: `url(${background})` }}>
        <PersonalContent>
          <Stepper step={props.step} />
          <PersonalItem>
            <div style={{ marginBottom: "5%" }}>
              <PersonalHeading>Personal Details</PersonalHeading>
            </div>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-name"
                    label="Full Name"
                    type="text"
                    variant="outlined"
                    name="fullName"
                    value={props.fullName}
                    onChange={handleChange}
                    // disabled={
                    //   props.availInDataBase && user?.userRole !== "admin"
                    //     ? true
                    //     : false
                    // }
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-father"
                    label="Father Name"
                    type="text"
                    variant="outlined"
                    name="fatherName"
                    value={props.fatherName}
                    onChange={handleChange}
                    // disabled={
                    //   props.availInDataBase && user?.userRole !== "admin"
                    //     ? true
                    //     : false
                    // }
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-email"
                    placeholder="email@email.com"
                    label="Email Address"
                    type="email"
                    variant="outlined"
                    name="email"
                    value={props.email}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-mobile"
                    placeholder="03001234567"
                    label="Mobile Number"
                    type="text"
                    variant="outlined"
                    name="mobileNumber"
                    value={props.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-gender">
                    Select Gender
                  </InputLabel>
                  <Select
                    native
                    value={props.gender}
                    onChange={handleChange}
                    label="Select Gender"
                    name="gender"
                  >
                    <option aria-label="None" value="" />
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-nationality"
                    label="Nationality"
                    type="text"
                    placeholder="Eg: Pakistani"
                    variant="outlined"
                    name="nationality"
                    value={props.nationality}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <form className={classes.container} noValidate>
                    <TextField
                      style={{
                        width: "100%",
                      }}
                      name="dateofbirth"
                      id="outlined-date"
                      label="Date of Birth"
                      type="date"
                      variant="outlined"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={props.dateOfBirth}
                      onChange={handleChange}
                    />
                  </form>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-placeofbirth"
                    label="Place of Birth"
                    type="text"
                    placeholder="Eg: Karachi"
                    variant="outlined"
                    name="placeofbirth"
                    value={props.placeOfBirth}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-mycnic"
                    placeholder="12345-1234567-1"
                    label="CNIC / B-FORM No"
                    type="text"
                    variant="outlined"
                    name="cnic"
                    value={props.cnic}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              {religions?.length > 0 && (
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-religion">
                      Religion
                    </InputLabel>
                    <Select
                      native
                      value={props.religion}
                      onChange={handleChange}
                      label="Religion"
                      name="religion"
                      id="outlined-religion"
                    >
                      <option aria-label="None" value="" />
                      {religions?.length > 0 &&
                        religions?.map((item) => (
                          <option value={item?.id}>{item?.religion}</option>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {DistrictsData?.length > 0 && (
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-domicile">
                      Domicile District
                    </InputLabel>
                    <Select
                      native
                      value={props.domicileDistrict}
                      onChange={handleChange}
                      label="Domicile District"
                      name="domicile"
                      id="outlined-domicile"
                    >
                      <option aria-label="None" value="" />
                      {DistrictsData?.length > 0 &&
                        DistrictsData?.map((item) => (
                          <option value={item?.id}>{item?.district}</option>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-father-mobile"
                    placeholder="03001234567"
                    label="Father's / Guardian's Mobile Number"
                    type="text"
                    variant="outlined"
                    name="fatherMobileNumber"
                    value={props.fatherMobileNumber}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-cnic"
                    placeholder="12345-1234567-1"
                    label="Father's / Guardian's CNIC"
                    type="text"
                    variant="outlined"
                    name="fatherCnic"
                    value={props.fatherCnic}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-father-occupation"
                    label="Father's Occupation"
                    type="text"
                    placeholder="Eg: Government Employee"
                    variant="outlined"
                    name="fatheroccupation"
                    value={props.fatherOccupation}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              {DistrictsData?.length > 0 && (
                <Grid item xs={12} sm={12} md={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-father-domicile">
                      Father's Domicile District
                    </InputLabel>
                    <Select
                      native
                      value={props.fatherDomicileDistrict}
                      onChange={handleChange}
                      label="Father's Domicile District"
                      name="fatherdomicile"
                      id="outlined-father-domicile"
                    >
                      <option aria-label="None" value="" />
                      {DistrictsData?.length > 0 &&
                        DistrictsData?.map((item) => (
                          <option value={item?.id}>{item?.district}</option>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-mother-cnic"
                    placeholder="12345-1234567-1"
                    label="Mother's CNIC"
                    type="text"
                    variant="outlined"
                    name="motherCnic"
                    value={props.motherCnic}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                {RegionsData?.length > 0 && (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-region">
                      Address Region
                    </InputLabel>
                    <Select
                      native
                      value={props.addressRegion}
                      onChange={handleChange}
                      id="outlined-region"
                      label="Address Region"
                      name="region"
                    >
                      <option aria-label="None" value="" />
                      {RegionsData?.length > 0 &&
                        RegionsData?.map((item) => (
                          <option value={item?.id}>{item?.region}</option>
                        ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>

              {addressDistricts?.length > 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-district">
                      Address District
                    </InputLabel>
                    <Select
                      native
                      value={props.addressDistrict}
                      onChange={handleChange}
                      id="outlined-district"
                      label="Address District"
                      name="district"
                    >
                      <option aria-label="None" value="" />
                      {addressDistricts?.length > 0 &&
                        addressDistricts?.map((item) => (
                          <option value={item?.id}>{item?.district}</option>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {addressTowns?.length > 0 && (
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-town">
                      Address Sub Division / Town
                    </InputLabel>
                    <Select
                      native
                      value={props.addressTown}
                      onChange={handleChange}
                      id="outlined-town"
                      label="Address Sub Division / Town"
                      name="town"
                    >
                      <option aria-label="None" value="" />
                      {addressTowns?.length > 0 &&
                        addressTowns?.map((item) => (
                          <option value={item?.id}>{item?.subdivision}</option>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-uc"
                    label="Address Union Council"
                    type="text"
                    variant="outlined"
                    name="unionCouncil"
                    value={props.unionCouncil}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-ward"
                    label="Address Ward"
                    type="text"
                    variant="outlined"
                    name="ward"
                    value={props.ward}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-home-address"
                    label="Home Address"
                    type="text"
                    variant="outlined"
                    name="homeAddress"
                    value={props.homeAddress}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-student-goal"
                    label="What do you want to be in future?"
                    type="text"
                    placeholder="Eg: Teacher, Doctor, Engineer"
                    variant="outlined"
                    name="studentGoal"
                    value={props.studentGoal}
                    onChange={handleChange}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <TextField
                    fullWidth
                    id="outlined-who-filled-form"
                    label="Who filled this form?"
                    type="text"
                    variant="outlined"
                    name="whoFilledForm"
                    value={props.whoFilledForm}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
            </Grid>

            {/* <div>
            <div style={{marginTop: '5%', marginLeft: '0px'}}>
              <div className={classes.root}>
                <input
                  accept="image/*"
                  className={classes.input}
                  id="contained-button-file"
                  type="file"
                  onChange={handleImage}
                />
                <label htmlFor="contained-button-file">
                  <Button variant="contained" color="primary" component="span">
                    <PhotoCamera /> Upload Profile Picture
                  </Button>
                </label>
                <input accept="image/*" className={classes.input} onChange={handleImage} id="icon-button-file" type="file" />
              </div>
            </div>
              <div style={{marginTop: '3%', marginLeft: '8px'}}>
                <img style={{ height: '10rem'}} src={props.showProfileImage} alt="Profile" />
              </div>
            </div> */}

            <div style={{ marginTop: "5%" }}>
              <Button
                variant="contained"
                color="default"
                style={{ marginRight: "1rem" }}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Save {"&"} Continue
              </Button>
            </div>
          </PersonalItem>
          <WhiteAd />
        </PersonalContent>
      </PersonalContainer>
      <Ad />
    </div>
  );
};

export default PersonalInfoForm;
