import React, { useState, useEffect } from "react";
import { NewApplicationContainer, NewApplicationContent, NewApplicationItem, NewApplicationHeading, NewApplicationHeading2, NewApplicationHeading3, NewApplicationparagraph } from './NewApplicationElements';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RegistrationForm from "./components/registration-form/RegistrationForm";
import PersonalInfoForm from "./components/personal-info-form/PersonalInfoForm";
import ChoiceOfFaculty from "./components/choice-of-faculty/ChoiceOfFaculty";
import SelectColleges from "./components/select-colleges/SelectColleges";
import Preview from "./components/preview/Preview";
import Success from "./components/success/Success";
import isEmpty, { isNumber } from "../../helpers/validation";
import CustomAlert from "../common/CustomAlert";
import SimpleBackdrop from "../common/Backdrop";
import * as ApiCalls from '../../services/ApiCalls';
import { useLocation } from "react-router";
import background from "./../../images/background.jpg";


import { isCNIC, isNumberOrDash } from "../../helpers/validation";
import { useHistory } from 'react-router-dom';
import { passingYears } from '../../data/passingyears';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import _ from "lodash";

import UniversitiesData from '../../data/universities.json';
import DistrictsData from '../../data/districts.json';
import axios from 'axios';

import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
  multilineColor: {
    color: 'red'
  },
}));



const Seef = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  document.title = 'SEEF';

  const [familyMembers, setFamilyMembers] = useState('');

  const [data, setData] = useState({
    candidate: "",
    student_id: "",
    year_of_admission: "",
    study_with_semester: "",
    university: "",
    name: "",
    father_name: "",
    guardian_name: "",
    relationship_with_guardian: "",
    address: "",
    phone: "",
    mobile: "",
    email: "",
    domicile_district: "",
    cnic: "",
    bangalow_location: "",
    bangalow_area: "",
    bangalow_value: "",
    land_location: "",
    land_area: "",
    land_value: "",
    commercial_location: "",
    commercial_area: "",
    commercial_value: "",
    agriculture_location: "",
    agriculture_area: "",
    agriculture_value: "",
    family_details: {},
    academic_year: "",
    type_of_scholarship: "",
    amount_received: "",
    sponsoring_agency: "",
    image_profile: "",
    image_profile_name: "",
    pdf_filename: "",
    pdf_file: "",
  })

  const [responseData, setResponseData] = useState([]);

  const handleImage = (e) => {
    const img = e.target.files[0];
    setShowProfileImage(URL.createObjectURL(img));
    setData({
      ...data,
      image_profile_name: e.target.files[0].name,
      image_profile: e.target.files[0],
    });

  }

  const handlePdf = async (e) => {
    setData({
      ...data,
      pdf_filename: e.target.files[0].name,
      pdf_file: e.target.files[0],
    });
  }

  const [step, setStep] = useState(1);
  // Matric Profile
  const [ninthRollNo, setNinthRollNo] = useState('');
  const [matricRollNo, setMatricRollNo] = useState('');
  const [passingYear, setPassingYear] = useState('');
  const [vaccinated, setVaccinated] = useState('');
  const [passingYearClass5, setPassingYearClass5] = useState('');
  const [passingYearClass8, setPassingYearClass8] = useState('');
  const [province, setProvince] = useState('');
  const [board, setBoard] = useState('');
  const [studyGroup, setStudyGroup] = useState('');
  const [subject, setSubject] = useState('');
  const [totalMarks, setTotalMarks] = useState('');
  const [obtainedMarks, setObtainedMarks] = useState('');
  const [scaledObtainedMarks, setScaledObtainedMarks] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [schoolType, setSchoolType] = useState('');
  const [schoolNameClass5, setSchoolNameClass5] = useState('');
  const [schoolNameClass8, setSchoolNameClass8] = useState('');
  const [districtClass5, setDistrictClass5] = useState('');
  const [districtClass8, setDistrictClass8] = useState('');
  const [imageMarksheet, setImageMarksheet] = useState();
  const [imageMarksheetName, setImageMarksheetName] = useState('');
  const [marksheetImage, setMarksheetImage] = useState('');
  const [showMarksheetImage, setShowMarksheetImage] = useState(require("../../images/doc.png").default);
  const [showProfileImage, setShowProfileImage] = useState(require("../../images/profile.png").default);
  // Personal Profile
  const [appliedId, setAppliedId] = useState('');
  const [fullName, setFullName] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [gender, setGender] = useState('');
  const [nationality, setNationality] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [placeOfBirth, setPlaceOfBirth] = useState('');
  const [cnic, setCnic] = useState('');
  const [religion, setReligion] = useState('');
  const [fatherMobileNumber, setFatherMobileNumber] = useState('');
  const [fatherCnic, setFatherCnic] = useState('');
  const [fatherOccupation, setFatherOccupation] = useState('');
  const [motherCnic, setMotherCnic] = useState('');
  const [domicileDistrict, setDomicileDistrict] = useState('');
  const [fatherDomicileDistrict, setFatherDomicileDistrict] = useState('');
  const [imageProfile, setImageProfile] = useState();
  const [imageProfileName, setImageProfileName] = useState('');
  const [profileImage, setProfileImage] = useState('');
  const [addressRegion, setAddressRegion] = useState('');
  const [addressDistrict, setAddressDistrict] = useState('');
  const [addressTown, setAddressTown] = useState('');
  const [homeAddress, setHomeAddress] = useState('');

  // Apply for college
  const [region, setRegion] = useState('');
  const [faculty, setFaculty] = useState('');
  const [selectedColleges, setSelectedColleges] = useState([]);


  const [availInDataBase, setAvailInDataBase] = useState(false);
  const [applied, setApplied] = useState(false);

  let SortingUniversitiesData = UniversitiesData.slice(0);
  SortingUniversitiesData.sort(function (a, b) {
    let x = a.university_name.toLowerCase();
    let y = b.university_name.toLowerCase();
    return x < y ? -1 : x > y ? 1 : 0;
  });

  useEffect(async () => {
    window.scrollTo(0, 0);
    await setOpenBackDrop(false);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const handleChange = async (event) => {
    const { name, value } = event.target;

    if (value === "") {
      await setData((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        }
      });
    } else if (name === 'cnic') {
      if (!isNumberOrDash(value) || value.length > 15) {
        return;
      }
    } else if ((name === 'phone' || name === "mobile" || name === "bangalow_area" || name === "bangalow_value" || name === "land_area" || name === "land_value" || name === "commercial_area" || name === "commercial_value" || name === "agriculture_area" || name === "agriculture_value" || name === "amount_received" || name.substring(0, 7) === 'income_') && (!isNumber(value) || value.length > 11)) {
      return;
    } else if ((name === 'academic_year' || name === 'year_of_admission') && (!isNumber(value) || value.length > 4)) {
      return;
    } else if ((name.substring(0, 4) === 'age_') && (!isNumber(value) || value.length > 2)) {
      return;
    } else if (name.substring(0, 5) === 'name_' || name.substring(0, 4) === 'age_' || name.substring(0, 9) === 'relation_' || name.substring(0, 15) === 'marital_status_' || name.substring(0, 11) === 'occupation_' || name.substring(0, 12) === 'institution_' || name.substring(0, 15) === 'type_of_income_' || name.substring(0, 7) === 'income_') {
      let family_name = name.substr(-1);
      let objName = name.slice(0, name.length - 2);
      await setData((prevValue) => {
        return {
          ...prevValue,
          family_details: {
            ...prevValue.family_details,
            [family_name]: {
              ...prevValue.family_details[family_name],
              [objName]: value,
            }
          }
        }
      });
    }

    await setData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      }
    });

  };

  const handleClick = async () => {
    if (isEmpty(data?.candidate)) {
      setAlert('Please select one Fresh / Renewal Candidate!', 'error');
    } else if (isEmpty(data?.student_id)) {
      setAlert('Please fill out Registration / Student ID #!', 'error');
    } else if (isEmpty(data?.study_with_semester)) {
      setAlert('Please fill out Course of study with semester!', 'error');
    } else if (isEmpty(data?.university)) {
      setAlert("Please select Name of University / Institute!", 'error');
    } else if (isEmpty(data?.name)) {
      setAlert('Please fill out Name!', 'error');
    } else if (isEmpty(data?.father_name)) {
      setAlert('Please fill out Father Name!', 'error');
    } else if (isEmpty(data?.guardian_name)) {
      setAlert('Please fill out Guardian Name!', 'error');
    } else if (isEmpty(data?.relationship_with_guardian)) {
      setAlert('Please fill out Relationship with Guardian!', 'error');
    } else if (isEmpty(data?.address)) {
      setAlert('Please fill out Present Mailing Address!', 'error');
    } else if (isEmpty(data?.mobile)) {
      setAlert('Please fill out Mobile No!', 'error');
    } else if (isEmpty(data?.email)) {
      setAlert('Please fill out Email!', 'error');
    } else if (isEmpty(data?.domicile_district)) {
      setAlert('Please select Domicile District!', 'error');
    } else if (isEmpty(data?.cnic)) {
      setAlert('Please fill out Cnic!', 'error');
    } else if (isEmpty(data?.image_profile_name)) {
      setAlert('Please upload Profile Picture!', 'error');
    }
    // else if (isEmpty(data?.pdf_filename)) {
    //   setAlert('Please upload Pdf File!', 'error');
    // } 
    else {

      await setOpenBackDrop(true);

      let formdata = new FormData();
      // formdata.append('pdf_file', data?.pdf_file, data?.pdf_filename);
      formdata.append('image_profile', data?.image_profile, data?.image_profile_name);
      formdata.append('candidate', data?.candidate);
      formdata.append('student_id', data?.student_id);
      formdata.append('year_of_admission', data?.year_of_admission);
      formdata.append('study_with_semester', data?.study_with_semester);
      formdata.append('university', data?.university);
      formdata.append('name', data?.name);
      formdata.append('father_name', data?.father_name);
      formdata.append('guardian_name', data?.guardian_name);
      formdata.append('relationship_with_guardian', data?.relationship_with_guardian);
      formdata.append('address', data?.address);
      formdata.append('phone', data?.phone);
      formdata.append('mobile', data?.mobile);
      formdata.append('email', data?.email);
      formdata.append('domicile_district', data?.domicile_district);
      formdata.append('cnic', data?.cnic);
      formdata.append('bangalow_location', data?.bangalow_location);
      formdata.append('bangalow_area', data?.bangalow_area);
      formdata.append('bangalow_value', data?.bangalow_value);
      formdata.append('land_location', data?.land_location);
      formdata.append('land_area', data?.land_area);
      formdata.append('land_value', data?.land_value);
      formdata.append('commercial_location', data?.commercial_location);
      formdata.append('commercial_area', data?.commercial_area);
      formdata.append('commercial_value', data?.commercial_value);
      formdata.append('agriculture_location', data?.agriculture_location);
      formdata.append('agriculture_area', data?.agriculture_area);
      formdata.append('agriculture_value', data?.agriculture_value);
      formdata.append('family_details', JSON.stringify(data?.family_details));
      formdata.append('academic_year', data?.academic_year);
      formdata.append('type_of_scholarship', data?.type_of_scholarship);
      formdata.append('amount_received', data?.amount_received);
      formdata.append('sponsoring_agency', data?.sponsoring_agency);

      const resApplied = await axios.post('https://www.dgcs.gos.pk/seccap2/seefapplications/addApplication', formdata);

      if (resApplied?.data?.data?.length > 0) {

        await setResponseData(resApplied?.data?.data);

        await setOpenBackDrop(false);

        await setStep(6);

      } else if (resApplied?.data?.error?.message) {
        await setOpenBackDrop(false);
        setAlert(resApplied?.data?.error?.message, 'error');
      } else {
        await setOpenBackDrop(false);
        setAlert('Something went wrong, Please try again!', 'error');
      }

    }

  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />

      {step === 1 && (
        <div>
          <NewApplicationContainer style={{ backgroundImage: `url(${background})` }}>
            <NewApplicationContent>
              <NewApplicationItem>

                <div style={{ marginBottom: '3%' }}>
                  <NewApplicationHeading>
                    Application for Award of Endowment Fund Scholarship
                  </NewApplicationHeading>
                </div>

                <div style={{ marginBottom: '5%' }}>
                  <p style={{ textAlign: 'center' }}>
                    For any concerns, Please contact at 03002100041, 03123135320, 03052118323
                  </p>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10%' }}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-candidate">
                      Fresh / Renewal candidate
                    </InputLabel>
                    <Select
                      native
                      value={data?.candidate}
                      onChange={handleChange}
                      id="outlined-candidate"
                      label="Fresh / Renewal candidate"
                      name="candidate"
                    >
                      <option aria-label="None" value="" />
                      <option value="1">Fresh Candidate</option>
                      {/* <option value="2">Renewal Candidate</option> */}
                    </Select>
                  </FormControl>

                  <TextField style={{ marginTop: '2%' }} fullWidth id="outlined-semester" label="Registration / Student ID #" name="student_id" value={data?.student_id} onChange={handleChange} type="text" variant="outlined" />

                  <TextField style={{ marginTop: '2%' }} fullWidth id="outlined-admission-year" label="Year of Admission" name="year_of_admission" value={data?.year_of_admission} onChange={handleChange} type="text" variant="outlined" />

                  <TextField style={{ marginTop: '2%' }} fullWidth id="outlined-semester" label="Course of study with semester" name="study_with_semester" value={data?.study_with_semester} onChange={handleChange} type="text" variant="outlined" />

                  <FormControl style={{ marginTop: '2%' }} fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-institute">
                      Name of University / Institute
                    </InputLabel>
                    <Select
                      native
                      value={data.university}
                      onChange={handleChange}
                      id="outlined-institute"
                      label="Name of University / Institute"
                      name="university"
                    >
                      <option aria-label="None" value="" />
                      {SortingUniversitiesData?.length > 0 && SortingUniversitiesData?.map(item => (
                        <option value={item?.university_id}>{item?.university_name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </div>




                <NewApplicationHeading2>
                  Personal Information
                </NewApplicationHeading2>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10%' }}>

                  <div className="personal-info-item">
                    <TextField fullWidth id="outlined-name" label="Name" name="name" value={data?.name} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-father-name" label="Father’s name" name="father_name" value={data?.father_name} onChange={handleChange} type="text" variant="outlined" />
                  </div>

                  <div className="personal-info-item">
                    <TextField fullWidth id="outlined-guardian-name" label="Guardian’s name" name="guardian_name" value={data?.guardian_name} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-relationship-with-guardian" label="Relationship with Guardian" name="relationship_with_guardian" value={data?.relationship_with_guardian} onChange={handleChange} type="text" variant="outlined" />
                  </div>

                  <div className="personal-info-item">
                    <TextField fullWidth id="outlined-address" label="Present/Mailing Address" name="address" value={data?.address} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-phone" label="Phone Residence" name="phone" value={data?.phone} onChange={handleChange} type="text" variant="outlined" />
                  </div>

                  <div className="personal-info-item">
                    <TextField fullWidth id="outlined-name" label="Mobile" name="mobile" value={data?.mobile} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-email" label="Email Address" name="email" value={data?.email} onChange={handleChange} type="text" variant="outlined" />
                  </div>

                  <div className="personal-info-item">
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                      <InputLabel htmlFor="domicile-district">
                        Domicile District
                      </InputLabel>
                      <Select
                        native
                        onChange={handleChange}
                        id="domicile-district"
                        label="Domicile District"
                        name="domicile_district"
                        value={data?.domicile_district}
                      >
                        <option aria-label="None" value="" />
                        {DistrictsData?.length > 0 && DistrictsData?.map(item => (
                          <option value={item?.id}>{item?.district}</option>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField fullWidth id="outlined-mycnic" placeholder="12345-1234567-1" label="CNIC / B-FORM No" type="text" variant="outlined" name="cnic" value={data?.cnic} onChange={handleChange} />
                  </div>

                </div>

                <NewApplicationHeading2>
                  Particulars of Immediate Family Members
                </NewApplicationHeading2>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10%', marginTop: '2%' }}>

                  <TextField fullWidth id="outlined-family-members" label="How many family members do you have? Excluding you, Enter 1 to 9" name="familyMembers" value={familyMembers} onChange={(e) => (isNumber(e.target.value) || e.target.value === '') && e.target.value < 10 && setFamilyMembers(e.target.value)} type="number" variant="outlined" />

                  {familyMembers > 0 && _.times(familyMembers, (i) => (
                    <div className="family-info-container">
                      <div className="family-info-item1">
                        {i + 1}
                      </div>

                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor={`outlined-job-type-${i + 1}`}>
                          Job Type
                        </InputLabel>
                        <Select
                          native
                          onChange={handleChange}
                          id={`outlined-job-type-${i + 1}`}
                          label="Job Type"
                          name={`job_type_${i + 1}`}
                          value={data?.family_details?.[`${i + 1}`]?.[`job_type_${i + 1}`]}
                        >
                          <option aria-label="None" value="" />
                          <option value="1">Student</option>
                          <option value="2">House Wife</option>
                          <option value="3">Govt: Job</option>
                          <option value="4">Private Job</option>
                          <option value="5">Jobless</option>
                        </Select>
                      </FormControl>

                      <TextField fullWidth id={`outlined-name-${i + 1}`} label="Name" name={`name_${i + 1}`} value={data?.family_details?.[`${i + 1}`]?.[`name_${i + 1}`]} onChange={handleChange} type="text" variant="outlined" />

                      <TextField fullWidth id={`outlined-age-${i + 1}`} label="Age" name={`age_${i + 1}`} value={data?.family_details?.[`${i + 1}`]?.[`age_${i + 1}`]} onChange={handleChange} type="text" variant="outlined" />

                      <TextField fullWidth id={`outlined-relation-${i + 1}`} label="Relationship" name={`relation_${i + 1}`} value={data?.family_details?.[`${i + 1}`]?.[`relation_${i + 1}`]} onChange={handleChange} type="text" variant="outlined" />

                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor={`outlined-marital-status-${i + 1}`}>
                          Marital Status
                        </InputLabel>
                        <Select
                          native
                          onChange={handleChange}
                          id={`outlined-marital-status-${i + 1}`}
                          label="Marital Status"
                          name={`marital_status_${i + 1}`}
                          value={data?.family_details?.[`${i + 1}`]?.[`marital_status_${i + 1}`]}
                        >
                          <option aria-label="None" value="" />
                          <option value="1">Single</option>
                          <option value="2">Married</option>
                          <option value="3">Divorced</option>
                          <option value="4">Widow</option>
                        </Select>
                      </FormControl>

                      <TextField fullWidth id={`outlined-name-${i + 1}`} label="Occupation / Service / Business" name={`occupation_${i + 1}`} value={data?.family_details?.[`${i + 1}`]?.[`occupation_${i + 1}`]} onChange={handleChange} type="text" variant="outlined" />

                      <TextField fullWidth id={`outlined-institution-${i + 1}`} label="Institution / Organization" name={`institution_${i + 1}`} value={data?.family_details?.[`${i + 1}`]?.[`institution_${i + 1}`]} onChange={handleChange} type="text" variant="outlined" />

                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor={`outlined-type-of-income-${i + 1}`}>
                          Type of Income
                        </InputLabel>
                        <Select
                          native
                          onChange={handleChange}
                          id={`outlined-type-of-income-${i + 1}`}
                          label="Type of Income"
                          name={`type_of_income_${i + 1}`}
                          value={data?.family_details?.[`${i + 1}`]?.[`type_of_income_${i + 1}`]}
                        >
                          <option aria-label="None" value="" />
                          <option value="1">Salary / Pension</option>
                          <option value="2">Profit from Saving {"&"} Investment</option>
                          <option value="3">Rental Income</option>
                          <option value="4">Agricultural Income</option>
                          <option value="5">Business Income</option>
                          <option value="6">No Income</option>
                        </Select>
                      </FormControl>

                      <TextField fullWidth id={`outlined-income-${i + 1}`} label="Income (Pak Rs)" name={`income_${i + 1}`} value={data?.family_details?.[`${i + 1}`]?.[`income_${i + 1}`]} onChange={handleChange} type="text" variant="outlined" />
                    </div>
                  ))}

                </div>

                <NewApplicationHeading2>
                  Details of Property
                </NewApplicationHeading2>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10%' }}>

                  <div className="property-info-container">
                    <div className="property-info-item1">
                      <p>
                        Bungalow/Apartment
                      </p>
                    </div>


                    <TextField fullWidth id="outlined-bangalow-location" label="Exact Location" name="bangalow_location" value={data?.bangalow_location} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-bangalow-area" label="Area (In Acres/Sq. Yards)" name="bangalow_area" value={data?.bangalow_area} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-bangalow-value" label="Current Value (Pak Rs)" name="bangalow_value" value={data?.bangalow_value} onChange={handleChange} type="text" variant="outlined" />

                  </div>

                  <div className="property-info-container">
                    <div className="property-info-item1">
                      <p>
                        Land / Plot
                      </p>
                    </div>


                    <TextField fullWidth id="outlined-land-location" label="Exact Location" name="land_location" value={data?.land_location} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-land-area" label="Area (In Acres/Sq. Yards)" name="land_area" value={data?.land_area} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-land-value" label="Current Value (Pak Rs)" name="land_value" value={data?.land_value} onChange={handleChange} type="text" variant="outlined" />

                  </div>

                  <div className="property-info-container">
                    <div className="property-info-item1">
                      <p>
                        Commercial
                      </p>
                    </div>


                    <TextField fullWidth id="outlined-commercial-location" label="Exact Location" name="commercial_location" value={data?.commercial_location} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-commercial-area" label="Area (In Acres/Sq. Yards)" name="commercial_area" value={data?.commercial_area} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-commercial-value" label="Current Value (Pak Rs)" name="commercial_value" value={data?.commercial_value} onChange={handleChange} type="text" variant="outlined" />

                  </div>

                  <div className="property-info-container">
                    <div className="property-info-item1">
                      <p>
                        Agriculture
                      </p>
                    </div>


                    <TextField fullWidth id="outlined-agriculture-location" label="Exact Location" name="agriculture_location" value={data?.agriculture_location} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-agriculture-area" label="Area (In Acres/Sq. Yards)" name="agriculture_area" value={data?.agriculture_area} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-agriculture-value" label="Current Value (Pak Rs)" name="agriculture_value" value={data?.agriculture_value} onChange={handleChange} type="text" variant="outlined" />

                  </div>

                </div>

                <NewApplicationHeading3>
                  Details of Financial Assistance / Scholarship / Stipend received from concerned University / Institute Govt. of Sindh or any other sources.
                </NewApplicationHeading3>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '10%' }}>

                  <TextField style={{ marginTop: '2%' }} fullWidth id="outlined-academic-year" label="Academic Year" name="academic_year" value={data?.academic_year} onChange={handleChange} type="text" variant="outlined" />

                  <TextField style={{ marginTop: '2%' }} fullWidth id="outlined-type-of-scholarship" label="Type of Financial Assistance / Scholarship" name="type_of_scholarship" value={data?.type_of_scholarship} onChange={handleChange} type="text" variant="outlined" />

                  <TextField style={{ marginTop: '2%' }} fullWidth id="outlined-amount-received" label="Amount Received" name="amount_received" value={data?.amount_received} onChange={handleChange} type="text" variant="outlined" />

                  <TextField style={{ marginTop: '2%' }} fullWidth id="outlined-sponsoring-agency" label="Sponsoring Agency" name="sponsoring_agency" value={data?.sponsoring_agency} onChange={handleChange} type="text" variant="outlined" />

                </div>

                {/* <NewApplicationHeading2>
                  SOURCES OF INCOME PER ANNUM
                </NewApplicationHeading2>

                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '5%' }}>

                  <div className="income-info-container">
                    <div className="income-info-item1">
                      <p>
                        Profession
                      </p>
                    </div>


                    <TextField fullWidth id="outlined-name" label="Father’s" name="name" value={""} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-name" label="Mother's" name="name" value={""} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-name" label="Brother's" name="name" value={""} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-name" label="Sister's" name="name" value={""} onChange={handleChange} type="text" variant="outlined" />

                    <TextField fullWidth id="outlined-name" label="Guardian’s/Other’s" name="name" value={""} onChange={handleChange} type="text" variant="outlined" />

                  </div>

                </div> */}

                <div>
                  <div style={{ marginTop: '5%', marginLeft: '0px' }}>
                    <div className={classes.root}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={handleImage}
                      />
                      <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span">
                          <PhotoCamera /> Upload Profile Picture
                        </Button>
                      </label>
                      <input accept="image/*" className={classes.input} onChange={handleImage} id="icon-button-file" type="file" />
                    </div>
                  </div>
                  <div style={{ marginTop: '3%', marginLeft: '8px' }}>
                    <img style={{ height: '10rem' }} src={showProfileImage} alt="Profile" />
                  </div>
                </div>

                <div style={{ border: '1px solid #000', padding: '12px', marginTop: '10%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '10%' }}>

                  <div style={{ textAlign: 'center', marginBottom: '5%' }}>
                    <NewApplicationHeading2 style={{ marginBottom: '10px', fontWeight: 'bold' }}>Documents Required With Form</NewApplicationHeading2>
                    <NewApplicationHeading3 style={{ marginBottom: '10px', fontWeight: 'bold' }}>The mentioned below documents are mandatory and should be attested by Gazetted Officer {"("}B-17{")"} {"&"} above</NewApplicationHeading3>
                    <NewApplicationHeading3 style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '14px' }}>The following documents must be attached with printed copy of form and submit the same to your respective Institution.</NewApplicationHeading3>
                    {/* <NewApplicationHeading3 style={{ marginBottom: '10px', fontWeight: 'bold', fontSize: '14px' }}>All documents must be kept in the one pdf file.</NewApplicationHeading3>
                    <NewApplicationHeading3 style={{ fontWeight: 'bold', fontSize: '14px' }}>Incomplete applications will not be considered.</NewApplicationHeading3> */}

                  </div>



                  <NewApplicationparagraph>1. Copy of Student CNIC OR Student Identity Card of the University / Institute.</NewApplicationparagraph>
                  <NewApplicationparagraph>2. Copy of FORM ‘C’.</NewApplicationparagraph>
                  <NewApplicationparagraph>3. Copy of CNIC of Father / Guardian.</NewApplicationparagraph>
                  <NewApplicationparagraph>4. Copy of Fee Receipt of the University / Institute (Last paid).</NewApplicationparagraph>
                  <NewApplicationparagraph>5. Copy of Certificate of Domicile of the student.</NewApplicationparagraph>
                  <NewApplicationparagraph>6. Copy of Permanent Residence Certificate of Student.</NewApplicationparagraph>
                  <NewApplicationparagraph>7. Copy of Domicile Certificate of Father / Mother.</NewApplicationparagraph>
                  <NewApplicationparagraph>8. Copy of Academic Certificates including Mark-Sheets i.e. Matriculation, Intermediate, Graduation and previous year/term/semester.</NewApplicationparagraph>
                  <NewApplicationparagraph>9. Copy of House documents being the evidence of Residence/Rent document.</NewApplicationparagraph>
                  <NewApplicationparagraph>10. Original Salary Slip of Father / Guardian if employed in Government / Semi Government / Private organization.</NewApplicationparagraph>
                  <NewApplicationparagraph>11. Original Income Certificate issued by EDO Revenue of concerned District in case Father / Guardian is a non-salary person i.e. Business man / Landlord etc, along with Bank Statement of last six (6) months of personal and Business account. </NewApplicationparagraph>
                  <NewApplicationparagraph>12. Original Copy of Income Assessment / Return of Income duly issued by Income Tax Department, Government of Pakistan.</NewApplicationparagraph>
                  <NewApplicationparagraph>13. Copies of Utility Bills: Electricity / Gas / Telephone (Last paid).</NewApplicationparagraph>
                  <NewApplicationparagraph>14. Original Affidavit on Rs. 20/= Stamp paper.</NewApplicationparagraph>


                </div>

                <div style={{ marginBottom: '10%' }}>
                  <NewApplicationHeading2>
                    Affidavit Sample
                  </NewApplicationHeading2>
                  <div style={{ background: "#f3f3f3", padding: '10px', marginTop: '3%' }}>
                    <img style={{ width: '100%' }} src="/images/affidavit.png" alt="affidavit" />
                  </div>
                </div>

                {/* <div>
                  <div style={{ marginLeft: '0px' }}>
                    <div className={classes.root}>
                      <input
                        accept="application/pdf"
                        className={classes.input}
                        id="contained-button-file-pdf"
                        type="file"
                        onChange={handlePdf}
                      />
                      <label htmlFor="contained-button-file-pdf">
                        <Button variant="contained" color="primary" component="span">
                          <PictureAsPdfIcon /> Upload Pdf
                        </Button>
                      </label>
                      <input accept='application/pdf' className={classes.input} onChange={handlePdf} id="icon-button-file-pdf" type="file" />
                      <p>{data?.pdf_filename}</p>
                    </div>
                  </div>
                </div> */}

                <div style={{ marginTop: '10%', marginLeft: '8px' }}>
                  <Button variant="contained" color="primary" onClick={handleClick}>
                    Proceed
                  </Button>
                </div>
              </NewApplicationItem>
              
            </NewApplicationContent>
          </NewApplicationContainer>
          
        </div>
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      )}

      {/* Registration Form */}

      {step === 2 && (
        <RegistrationForm
          cnic={cnic}
          province={province}
          setProvince={setProvince}
          board={board}
          setBoard={setBoard}
          studyGroup={studyGroup}
          setStudyGroup={setStudyGroup}
          subject={subject}
          setSubject={setSubject}
          totalMarks={totalMarks}
          setTotalMarks={setTotalMarks}
          obtainedMarks={obtainedMarks}
          setObtainedMarks={setObtainedMarks}
          setScaledObtainedMarks={setScaledObtainedMarks}
          schoolName={schoolName}
          setSchoolName={setSchoolName}
          schoolType={schoolType}
          setSchoolType={setSchoolType}
          imageMarksheet={imageMarksheet}
          setImageMarksheet={setImageMarksheet}
          imageMarksheetName={imageMarksheetName}
          setImageMarksheetName={setImageMarksheetName}
          marksheetImage={marksheetImage}
          setMarksheetImage={setMarksheetImage}
          showMarksheetImage={showMarksheetImage}
          setShowMarksheetImage={setShowMarksheetImage}
          setAppliedId={setAppliedId}
          setFullName={setFullName}
          setFatherName={setFatherName}
          setEmail={setEmail}
          setMobileNumber={setMobileNumber}
          setGender={setGender}
          setFatherMobileNumber={setFatherMobileNumber}
          setFatherCnic={setFatherCnic}
          region={region}
          setRegion={setRegion}
          setFaculty={setFaculty}
          setSelectedColleges={setSelectedColleges}
          availInDataBase={availInDataBase}
          setAvailInDataBase={setAvailInDataBase}
          applied={applied}
          setApplied={setApplied}
          appliedId={appliedId}
          step={step}
          setStep={setStep}
          setAlert={setAlert}
          setOpenBackDrop={setOpenBackDrop}
          matricRollNo={matricRollNo}
          passingYear={passingYear}
          passingYearClass5={passingYearClass5}
          setPassingYearClass5={setPassingYearClass5}
          passingYearClass8={passingYearClass8}
          setPassingYearClass8={setPassingYearClass8}
          schoolNameClass5={schoolNameClass5}
          setSchoolNameClass5={setSchoolNameClass5}
          schoolNameClass8={schoolNameClass8}
          setSchoolNameClass8={setSchoolNameClass8}
          districtClass5={districtClass5}
          setDistrictClass5={setDistrictClass5}
          districtClass8={districtClass8}
          setDistrictClass8={setDistrictClass8}
          vaccinated={vaccinated}
        />
      )}

      {step === 3 && (
        <PersonalInfoForm
          fullName={fullName}
          setFullName={setFullName}
          fatherName={fatherName}
          setFatherName={setFatherName}
          email={email}
          setEmail={setEmail}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          gender={gender}
          setGender={setGender}
          nationality={nationality}
          setNationality={setNationality}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          placeOfBirth={placeOfBirth}
          setPlaceOfBirth={setPlaceOfBirth}
          religion={religion}
          setReligion={setReligion}
          fatherOccupation={fatherOccupation}
          setFatherOccupation={setFatherOccupation}
          cnic={cnic}
          setCnic={setCnic}
          fatherMobileNumber={fatherMobileNumber}
          setFatherMobileNumber={setFatherMobileNumber}
          fatherCnic={fatherCnic}
          setFatherCnic={setFatherCnic}
          motherCnic={motherCnic}
          setMotherCnic={setMotherCnic}
          domicileDistrict={domicileDistrict}
          setDomicileDistrict={setDomicileDistrict}
          fatherDomicileDistrict={fatherDomicileDistrict}
          setFatherDomicileDistrict={setFatherDomicileDistrict}
          addressRegion={addressRegion}
          setAddressRegion={setAddressRegion}
          addressDistrict={addressDistrict}
          setAddressDistrict={setAddressDistrict}
          addressTown={addressTown}
          setAddressTown={setAddressTown}
          homeAddress={homeAddress}
          setHomeAddress={setHomeAddress}
          availInDataBase={availInDataBase}
          setAlert={setAlert}
          step={step}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
          appliedId={appliedId}
        />

      )}

      {step === 4 && (
        <ChoiceOfFaculty
          studyGroup={studyGroup}
          obtainedMarks={obtainedMarks}
          scaledObtainedMarks={scaledObtainedMarks}
          subject={subject}
          region={region}
          setRegion={setRegion}
          faculty={faculty}
          setFaculty={setFaculty}
          setAlert={setAlert}
          step={step}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
        />
      )}

      {step === 5 && (
        <SelectColleges
          region={region}
          faculty={faculty}
          gender={gender}
          scaledObtainedMarks={scaledObtainedMarks}
          step={step}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
          setAlert={setAlert}
          selectedColleges={selectedColleges}
          setSelectedColleges={setSelectedColleges}
        />
      )}

      {step === 6 && (
        <Preview
          responseData={responseData}
          vaccinated={vaccinated}
          fullName={fullName}
          fatherName={fatherName}
          email={email}
          mobileNumber={mobileNumber}
          gender={gender}
          cnic={cnic}
          nationality={nationality}
          dateOfBirth={dateOfBirth}
          placeOfBirth={placeOfBirth}
          religion={religion}
          fatherOccupation={fatherOccupation}
          fatherMobileNumber={fatherMobileNumber}
          fatherCnic={fatherCnic}
          motherCnic={motherCnic}
          ninthRollNo={ninthRollNo}
          matricRollNo={matricRollNo}
          passingYear={passingYear}
          province={province}
          board={board}
          studyGroup={studyGroup}
          subject={subject}
          totalMarks={totalMarks}
          obtainedMarks={obtainedMarks}
          scaledObtainedMarks={scaledObtainedMarks}
          schoolName={schoolName}
          schoolType={schoolType}
          region={region}
          faculty={faculty}
          selectedColleges={selectedColleges}
          setOpenBackDrop={setOpenBackDrop}
          availInDataBase={availInDataBase}
          setStep={setStep}
          imageMarksheet={imageMarksheet}
          imageMarksheetName={imageMarksheetName}
          marksheetImage={marksheetImage}
          passingYearClass5={passingYearClass5}
          setPassingYearClass5={setPassingYearClass5}
          passingYearClass8={passingYearClass8}
          schoolNameClass5={schoolNameClass5}
          schoolNameClass8={schoolNameClass8}
          districtClass5={districtClass5}
          districtClass8={districtClass8}
          domicileDistrict={domicileDistrict}
          fatherDomicileDistrict={fatherDomicileDistrict}
          addressRegion={addressRegion}
          addressDistrict={addressDistrict}
          homeAddress={homeAddress}
          applied={applied}
          appliedId={appliedId}
          step={step}
          setAlert={setAlert}
        />
      )}

      {step === 7 && (
        <Success
          setOpenBackDrop={setOpenBackDrop}
          setStep={setStep}
          setProvince={setProvince}
          setBoard={setBoard}
          setStudyGroup={setStudyGroup}
          setSubject={setSubject}
          setTotalMarks={setTotalMarks}
          setObtainedMarks={setObtainedMarks}
          setSchoolName={setSchoolName}
          setPassingYearClass8={setPassingYearClass8}
          setDistrictClass8={setDistrictClass8}
          setSchoolNameClass8={setSchoolNameClass8}
          setPassingYearClass5={setPassingYearClass5}
          setDistrictClass5={setDistrictClass5}
          setSchoolNameClass5={setSchoolNameClass5}
          setImageMarksheet={setImageMarksheet}
          setImageMarksheetName={setImageMarksheetName}
          setMarksheetImage={setMarksheetImage}
          setShowMarksheetImage={setShowMarksheetImage}
          setAppliedId={setAppliedId}
          setFullName={setFullName}
          setFatherName={setFatherName}
          setEmail={setEmail}
          setMobileNumber={setMobileNumber}
          setGender={setGender}
          setNationality={setNationality}
          setDateOfBirth={setDateOfBirth}
          setPlaceOfBirth={setPlaceOfBirth}
          setCnic={setCnic}
          setRegion={setRegion}
          setDomicileDistrict={setDomicileDistrict}
          setFatherMobileNumber={setFatherMobileNumber}
          setFatherCnic={setFatherCnic}
          setFatherOccupation={setFatherOccupation}
          setFatherDomicileDistrict={setFatherDomicileDistrict}
          setMotherCnic={setMotherCnic}
          setAddressRegion={setAddressRegion}
          setAddressDistrict={setAddressDistrict}
          setHomeAddress={setHomeAddress}
          setImageProfile={setImageProfile}
          setImageProfileName={setImageProfileName}
          setProfileImage={setProfileImage}
          setShowProfileImage={setShowProfileImage}
          setFaculty={setFaculty}
          setSelectedColleges={setSelectedColleges}
          setAvailInDataBase={setAvailInDataBase}
          setApplied={setApplied}
        />
      )}

      <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />

    </div>

  );
};

export default Seef;
