import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import { FragmentContainer, FragmentHeader, FragmentHeaderH1 } from './FragmentsElements';
import SimpleBackdrop from './../../common/Backdrop';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'gender', label: 'Gender', minWidth: 170 },
  { id: 'matric_roll_no', label: 'Matric Roll No', minWidth: 170 },
  { id: 'passing_year', label: 'Passing Year', minWidth: 170 },
  { id: 'scaled_total_marks', label: 'Total Marks', minWidth: 170 },
  { id: 'scaled_obtained_marks', label: 'Obtained Marks', minWidth: 170 },
  { id: 'faculty', label: 'Choice of Faculty', minWidth: 170 }
];

function createData(id, name, gender, matric_roll_no, passing_year, total_marks, obtained_marks, scaled_total_marks, scaled_obtained_marks, faculty, marksheet_image) {
  
  return { id, name, gender, matric_roll_no, passing_year, total_marks, obtained_marks, scaled_total_marks, scaled_obtained_marks, faculty, marksheet_image };
}

let rows = [];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export default function UnapprovedList(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [activity, setActivity] = useState(false);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);

  useEffect(async () => {
    window.scrollTo(0, 0);
    await setOpenBackDrop(true);
    await getUnapprovedStudents();
    await setOpenBackDrop(false)
  }, []);

  const getUnapprovedStudents = async () => {
    const res = await axios.get('https://www.dgcs.gos.pk/seccap2/students/unapproved',{
      headers: {
        'Authorization': `${localStorage.getItem('jwtToken')}`
      }});
    if (res?.data?.status === 'success') {

      rows = res?.data?.data.map(item => createData(item?.id, item?.name, item?.gender, item?.matric_roll_no, item?.passing_year, item?.total_marks, item?.obtained_marks, item?.scaled_total_marks, item?.scaled_obtained_marks, item?.faculty, item?.marksheet_image));
      setActivity(true);
      
    }
  }

  const handleRowClick = (item) => {
    props.setUnapprovedData(item);
    console.log(item);
    props.setFragment('unapproveddetails');
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <FragmentContainer>
      <SimpleBackdrop openBackDrop={openBackDrop} />
      <FragmentHeader>
        <FragmentHeaderH1>Unapproved Applications</FragmentHeaderH1>
      </FragmentHeader>
      <Divider />

      {rows?.length > 0 ? (
        <div style={{ marginTop: '2rem' }}>
          <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow style={{ background: '#137a4c' }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: '#fff', background: '#137a4c' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow onClick={() => handleRowClick(row)} hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
      ) : (
        <h1 style={{ textAlign: 'center', height: '5rem', color: '#c3c3c3', margin: '5%'}}>Data not Found</h1>
      )}
      
              
      
    </FragmentContainer>
  );
}

