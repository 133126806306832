import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { DataGrid, getThemePaletteMode } from '@material-ui/data-grid';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { FragmentContainer, FragmentHeader, FragmentHeaderH1, FragmentContent } from './FragmentsElements';
import { getUser } from '../../../functions/functions';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => {
    const isDark = getThemePaletteMode(theme.palette) === 'dark';

    return {
      root: {
        '& .MuiDataGrid-cell--editing': {
          backgroundColor: 'rgb(255,215,115, 0.19)',
          color: '#1a3e72',
        },
        '& .Mui-error': {
          backgroundColor: `rgb(126,10,15, ${isDark ? 0 : 0.1})`,
          color: isDark ? '#ff4343' : '#750f0f',
        },
      },
    };
  },
  { defaultTheme },
);

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function Subdivisions(props) {
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const classes = useStyles();
  const [subdivisions, setSubdivisions] = useState([]);
  const user = getUser(localStorage?.jwtToken);

  useEffect(async () => {
    window.scrollTo(0, 0);
    let res;

    if (user?.userRole === 'rd') {
      res = await axios.get(`https://www.dgcs.gos.pk/seccap2/subdivisions/${user?.userId}`);
    } else {
      res = await axios.get("https://www.dgcs.gos.pk/seccap2/subdivisions");
    }

      setSubdivisions(res.data.data);
  }, []);

  const handleEditCellChange = React.useCallback(
    ({ id, field, props }) => {
      console.log(props)
      if (field === 'email') {
        const data = props; // Fix eslint value is missing in prop-types for JS files
        const isValid = validateEmail(data.value);
        const newState = {};
        newState[id] = {
          ...editRowsModel[id],
          email: { ...props, error: !isValid },
        };

        setEditRowsModel((state) => ({ ...state, ...newState }));
      }
      updateSubdivision(id, props.value);
    },
    [editRowsModel],
  );

  const updateSubdivision = async (id, subdivision) => {
    const res = await axios.post(`https://www.dgcs.gos.pk/seccap2/subdivisions/edit/${id}`, {subdivision}, {
      headers: {
        'Authorization': `${localStorage.getItem('jwtToken')}`
      }});
  }

  return (
    <FragmentContainer>
      <Container fixed>
        <FragmentHeader>
          <Grid container spacing={5}>
            <Grid item md={9}>
              <FragmentHeaderH1>Sub divisions</FragmentHeaderH1>
            </Grid>
            <Grid item md={3}>
              <Button variant="contained" color="primary"  onClick={() => props.setFragment('addsubdivision')}>
                Add Sub division
              </Button>
            </Grid>
          </Grid>
        </FragmentHeader>
      </Container>
      <Divider />
      <Container fixed>
        <FragmentContent>
        <div style={{ height: 400, width: '100%' }}>
            {subdivisions?.length > 0 ? (
              <DataGrid
                className={classes.root}
                rows={subdivisions}
                columns={columns}
                editRowsModel={editRowsModel}
                onEditCellChange={handleEditCellChange}
              />
            ) : (
              <h1 style={{ textAlign: 'center', color: '#c3c3c3', margin: '10% auto'}}>Sub Divisions not Found</h1>
            )}
            
          </div>
        </FragmentContent>
      </Container>
      
    </FragmentContainer>
  );
}

const columns = [
  { field: 'subdivision', headerName: 'Sub Division', width: 180, editable: true },
  { field: 'district', headerName: 'District', width: 200, editable: false },
];
