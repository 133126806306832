import React from 'react';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CustomAlert from "../../common/CustomAlert";
import SimpleBackdrop from '../../common/Backdrop';
import { FragmentContainer, FragmentHeader, FragmentHeaderH1, FragmentContent } from './FragmentsElements';

export default function Admission(props) {

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [openBackDrop, setOpenBackDrop] = React.useState(false);

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick = async () => {
    setOpenBackDrop(true);

    const res = await axios.get('https://www.dgcs.gos.pk/seccap2/admissions', { headers: { 'Authorization': localStorage.getItem('jwtToken') } });
    setOpenBackDrop(false);

      if(res?.data?.status === 'success') {
        setAlert('Admissions has been Announced Successfully!', 'success');
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, 'error');
        if (res?.data?.error?.message === "Please manage last date to apply to Announce Admissions!") {
          setTimeout(() => {
            props.setFragment('lastdate');
          }, 2000);
        }
      } else {
        setAlert('Something went wrong, Please try again!', 'error');
      }
  }

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item md={12}>
                <FragmentHeaderH1>Announce Admissions</FragmentHeaderH1>
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider />
        <Container fixed>
          <FragmentContent>
              <Button style={{ marginTop: '3%' }} variant="contained" color="primary" onClick={handleClick}>
                Announce
              </Button>
          </FragmentContent>
          <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
        </Container>
      </FragmentContainer>
    </div>
  );
}
