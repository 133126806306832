import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Button } from "../ButtonElements";
import styled from "styled-components";
import VerticalAd from "../Ad/VerticalAd";
import WhiteAd from "../Ad/WhiteAd";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const onHover = () => {
    setHover(!hover);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onMouseEnter={onHover}
        onMouseLeave={onHover}
        primary="true"
        dark="true"
        big="true"
        smooth={true}
        duration={500}
        spy={true}
        exact="true"
        offset={-80}
        onClick={handleClickOpen}
      >
        {props?.text ? props?.text : "How to Apply"}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props?.text ? props?.text : "How to Apply"}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Skip
            </Button>
          </Toolbar>
        </AppBar>
        <Container>
          <VideoContainer>
            <VideoCover>
              <iframe
                width="100%"
                height="100%"
                src={
                  props?.src
                    ? props?.src
                    : "https://www.youtube.com/embed/GEgAu76ABlo"
                }
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </VideoCover>
          </VideoContainer>
          <VerticalAdContainer>
            <VerticalAd />
          </VerticalAdContainer>
        </Container>

        <WhiteAd />
      </Dialog>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 760px) {
    flex-direction: column;
  }
`;

const VerticalAdContainer = styled.div`
  margin: 2%;
  width: 40vw;
`;

const VideoContainer = styled.div`
  margin-top: 2%;
  margin-bottom: 1%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 760px) {
    margin: 1% 1%;
  }
`;

const VideoCover = styled.div`
  width: 660px;
  height: 315px;

  @media screen and (max-width: 760px) {
    width: 100%;
    height: 315px;
  }
`;
