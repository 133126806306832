import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { DataGrid, getThemePaletteMode } from '@material-ui/data-grid';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { getUser } from '../../../functions/functions';
import { FragmentContainer, FragmentHeader, FragmentHeaderH1, FragmentContent } from './FragmentsElements';
import WhiteAd from '../../Ad/WhiteAd';
import Ad2 from '../../Ad/Ad2';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => {
    const isDark = getThemePaletteMode(theme.palette) === 'dark';

    return {
      root: {
        '& .MuiDataGrid-cell--editing': {
          backgroundColor: 'rgb(255,215,115, 0.19)',
          color: '#1a3e72',
        },
        '& .Mui-error': {
          backgroundColor: `rgb(126,10,15, ${isDark ? 0 : 0.1})`,
          color: isDark ? '#ff4343' : '#750f0f',
        },
      },
    };
  },
  { defaultTheme },
);

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function AppUsersInCollegesList(props) {
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const classes = useStyles();
  const [colleges, setColleges] = useState([]);
  const [collegesFaculties, setCollegesFaculties] = useState([]);
  const [collegesAppUsers, setCollegesAppUsers] = useState([]);
  const user = getUser(localStorage?.jwtToken);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    window.scrollTo(0, 0);
    let res = await axios.get("https://www.dgcs.gos.pk/seccap2/colleges/getCollegeAppUsers", {
      headers: {
        'Authorization': `${localStorage.getItem('jwtToken')}`
      }
    });

    setCollegesAppUsers(res?.data?.data);
  }, []);

  const handleEditCellChange = React.useCallback(
    async ({ id, field, props }) => {

      if (field === 'active') {
        const data = {
          id,
          active: props.value,
        }

        await axios.post("https://www.dgcs.gos.pk/seccap2/colleges/updateAppUserActive", data, {
          headers: {
            'Authorization': `${localStorage.getItem('jwtToken')}`
          }
        });
      }

      if (field === 'password') {
        const data = {
          id,
          password: props.value,
        }

        await axios.post("https://www.dgcs.gos.pk/seccap2/users/changePasswordAppUser", data, {
          headers: {
            'Authorization': `${localStorage.getItem('jwtToken')}`
          }
        });
      }

      if (field === 'college_name') {
        const data = {
          id,
          college_name: props.value,
        }

        await axios.post("https://www.dgcs.gos.pk/seccap2/colleges/updateCollegeName", data, {
          headers: {
            'Authorization': `${localStorage.getItem('jwtToken')}`
          }
        });
      }

      if (field === 'merit_current_year') {

        const data = {
          id,
          merit_current_year: props.value,
        }

        await axios.post("https://www.dgcs.gos.pk/seccap2/colleges/updateMeritCurrentYear", data, {
          headers: {
            'Authorization': `${localStorage.getItem('jwtToken')}`
          }
        });
      }

      if (field === "seats") {
        const data = {
          id,
          seats: props.value,
        }

        await axios.post("https://www.dgcs.gos.pk/seccap2/colleges/updateFacultySeats", data, {
          headers: {
            'Authorization': `${localStorage.getItem('jwtToken')}`
          }
        });
      }

      if (field === "closed") {
        const data = {
          id,
          closed: props.value,
        }

        await axios.post("https://www.dgcs.gos.pk/seccap2/colleges/updateAdmissionClosed", data, {
          headers: {
            'Authorization': `${localStorage.getItem('jwtToken')}`
          }
        });

      }


      if (field === 'email') {
        const data = props; // Fix eslint value is missing in prop-types for JS files
        const isValid = validateEmail(data.value);
        const newState = {};
        newState[id] = {
          ...editRowsModel[id],
          email: { ...props, error: !isValid },
        };

        setEditRowsModel((state) => ({ ...state, ...newState }));
      }
    },
    [editRowsModel],
  );

  return (
    <div>
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item md={9}>
                <FragmentHeaderH1>App Users</FragmentHeaderH1>
              </Grid>
              <Grid item md={3}>
                <Button variant="contained" color="primary" onClick={() => props.setFragment('addappuserincollegeslist')}>
                  Add User
                </Button>
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider />
      </FragmentContainer>
      <div style={{ marginTop: '3rem' }}>
        <FragmentContainer>
          <Container fixed>
            <FragmentContent>
              <div style={{ height: 600, width: '100%' }}>
                {collegesAppUsers?.length > 0 ? (
                  <DataGrid
                    className={classes.root}
                    rows={collegesAppUsers}
                    columns={columns2}
                    editRowsModel={editRowsModel}
                    onEditCellChange={handleEditCellChange}
                  />
                ) : (
                  <h1 style={{ textAlign: 'center', color: '#c3c3c3', margin: '10% auto' }}>Users not Found</h1>
                )}

              </div>
            </FragmentContent>
          </Container>
        </FragmentContainer>
      </div>
      <WhiteAd />
      <Ad2 />
    </div>
  );
}

const columns2 = [

  { field: 'active', headerName: 'User Active', width: 150, editable: true },
  { field: 'personnel_no', headerName: 'Personnel No', width: 170, editable: false },
  { field: 'name', headerName: 'Name', width: 200, editable: false },
  { field: 'gender', headerName: 'Gender', width: 130, editable: false },
  { field: 'email', headerName: 'Email', width: 180, editable: false },
  { field: 'mobile', headerName: 'Mobile No', width: 180, editable: false },
  { field: 'password', headerName: 'Password (contains atleast 8 characters', width: 180, editable: true },
];
