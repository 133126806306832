import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import axios from 'axios';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import SimpleBackdrop from '../../../common/Backdrop';
import { getUser } from '../../../../functions/functions';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 800,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function createData(student_id, year_of_admission, study_with_semester, university_name, name, father_name, guardian_name, relationship_with_guardian, address, phone, mobile, email, district, cnic, bangalow_location, bangalow_area, bangalow_value, land_location, land_area, land_value, commercial_location, commercial_area, commercial_value, agriculture_location, agriculture_area, agriculture_value, academic_year, type_of_scholarship, amount_received, sponsoring_agency, family_details) {
  return {
    student_id,
    year_of_admission,
    study_with_semester,
    university_name,
    name,
    father_name,
    guardian_name,
    relationship_with_guardian,
    address,
    phone,
    mobile,
    email,
    district,
    cnic,
    bangalow_location,
    bangalow_area,
    bangalow_value,
    land_location,
    land_area,
    land_value,
    commercial_location,
    commercial_area,
    commercial_value,
    agriculture_location,
    agriculture_area,
    agriculture_value,
    academic_year,
    type_of_scholarship,
    amount_received,
    sponsoring_agency,
    family_details,
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.student_id}</TableCell>
        <TableCell align="right">{row.year_of_admission}</TableCell>
        <TableCell align="right">{row.study_with_semester}</TableCell>
        <TableCell align="right">{row.university_name}</TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">{row.father_name}</TableCell>
        <TableCell align="right">{row.guardian_name}</TableCell>
        <TableCell align="right">{row.relationship_with_guardian}</TableCell>
        <TableCell align="right">{row.address}</TableCell>
        <TableCell align="right">{row.phone}</TableCell>
        <TableCell align="right">{row.mobile}</TableCell>
        <TableCell align="right">{row.email}</TableCell>
        <TableCell align="right">{row.district}</TableCell>
        <TableCell align="right">{row.cnic}</TableCell>
        <TableCell align="right">{row.bangalow_location}</TableCell>
        <TableCell align="right">{row.bangalow_area}</TableCell>
        <TableCell align="right">{row.bangalow_value}</TableCell>
        <TableCell align="right">{row.land_location}</TableCell>
        <TableCell align="right">{row.land_area}</TableCell>
        <TableCell align="right">{row.land_value}</TableCell>
        <TableCell align="right">{row.commercial_location}</TableCell>
        <TableCell align="right">{row.commercial_area}</TableCell>
        <TableCell align="right">{row.commercial_value}</TableCell>
        <TableCell align="right">{row.agriculture_location}</TableCell>
        <TableCell align="right">{row.agriculture_area}</TableCell>
        <TableCell align="right">{row.agriculture_value}</TableCell>
        <TableCell align="right">{row.academic_year}</TableCell>
        <TableCell align="right">{row.type_of_scholarship}</TableCell>
        <TableCell align="right">{row.amount_received}</TableCell>
        <TableCell align="right">{row.sponsoring_agency}</TableCell>
      </TableRow>
      <TableRow style={{ background: '#f3f3f3' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell>Relationship</TableCell>
                    <TableCell>Marital Status</TableCell>
                    <TableCell>Occupation / Service / Business</TableCell>
                    <TableCell>Institution / Organization</TableCell>
                    <TableCell>Type of Income</TableCell>
                    <TableCell align="right">Income Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.family_details?.map((detail) => (
                    <TableRow key={detail?.id}>
                      <TableCell component="th" scope="row">
                        {detail?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {detail?.age}
                      </TableCell>
                      <TableCell>{detail?.relation}</TableCell>
                      <TableCell>{detail?.marital_status}</TableCell>
                      <TableCell>{detail?.occupation}</TableCell>
                      <TableCell>{detail?.institution}</TableCell>
                      <TableCell>{detail?.type_of_income}</TableCell>
                      <TableCell align="right">{detail?.income}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function StudentsData() {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const user = localStorage?.jwtToken ? getUser(localStorage?.jwtToken) : null
  useEffect(() => {

    if (user?.userRole === 'rd') {
      getStatsDataInRegion(user?.userId);
    } else if (user?.userRole === 'seefAdmin') {
      getStatsData();
    }



  }, []);

  const handleChange = async (e) => {
    // if (e.target.value === "") {
    //   getStatsData();
    // }
    const data = {
      value: e.target.value,
    };
    let res;
    if (user?.userRole == 'rd') {
      res = await axios.post(`https://www.dgcs.gos.pk/seccap2/statistics/StatsCollegesInRegion/${user?.userId}`, data);
    } else {
      res = await axios.post('https://www.dgcs.gos.pk/seccap2/statistics', data);
    }

    if (res?.data?.data) {
      setData(res?.data?.data);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  }

  const getStatsData = async () => {
    // await setOpenBackDrop(true);
    const res = await axios.get('https://www.dgcs.gos.pk/seccap2/statistics/getseefstudentsdata');
    if (res?.data?.data) {
      setData(res?.data?.data);
    }
    await setOpenBackDrop(false);
  }

  const getStatsDataInRegion = async (userId) => {
    // await setOpenBackDrop(true);
    const res = await axios.get(`https://www.dgcs.gos.pk/seccap2/statistics/StatsCollegesInRegion/${userId}`);
    if (res?.data?.data) {
      setData(res?.data?.data);
    }
    await setOpenBackDrop(false);
  }

  const rows =
    data?.map(item => {
      return createData(item?.student_id, item?.year_of_admission, item?.study_with_semester, item?.university_name, item?.name, item?.father_name, item?.guardian_name, item?.relationship_with_guardian, item?.address, item?.phone, item?.mobile, item?.email, item?.district, item?.cnic, item?.bangalow_location, item?.bangalow_area, item?.bangalow_value, item?.land_location, item?.land_area, item?.land_value, item?.commercial_location, item?.commercial_area, item?.commercial_value, item?.agriculture_location, item?.agriculture_area, item?.agriculture_value, item?.academic_year, item?.type_of_scholarship, item?.amount_received, item?.sponsoring_agency, item?.family_details?.map(detail => detail))
    })
    ;
  return (
    <div style={{ padding: '3rem 0' }}>
      {/* <div style={{ display: 'flex', alignContent: 'center', textAlign: 'center', justifyContent: 'center', padding: '2rem 0' }}>
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleChange}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleSubmit}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </div> */}
      <SimpleBackdrop openBackDrop={openBackDrop} />
      <div style={{ marginBottom: '5px' }}>
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText={(<img alt="" src="https://img.icons8.com/material/24/000000/xls-export.png" />)}
        />
      </div>
      <TableContainer style={{ height: '30rem' }} component={Paper}>
        <Table id="table-to-xls" aria-label="collapsible table">
          <TableHead style={{ background: '#137a4c', color: '#fff' }}>
            <TableRow>
              <TableCell />
              <TableCell style={{ color: '#fff' }}>Student ID</TableCell>
              <TableCell style={{ color: '#fff' }}>Year of Admission</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Course of Study with Semester</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Name of University</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Name of Student</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Father's Name</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Guardian's Name</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Relationship With Guardian</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Address</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Phone</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Mobile</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Email</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Domicile District</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Cnic</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Bangalow Location</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Bangalow Area</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Bangalow Value</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Land Location</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Land Area</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Land Value</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Commercial Location</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Commercial Area</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Commercial Value</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Agriculture Location</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Agriculture Area</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Agriculture Value</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Academic Year</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Type of Financial Assistance / Scholarship</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Amount Recieved</TableCell>
              <TableCell style={{ color: '#fff' }} align="right">Sponsoring Agency</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <>
                <Row key={row.college_name} row={row} />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
