import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import CustomAlert from "../../common/CustomAlert";
import isEmpty, { isEmail, isNumber } from "../../../helpers/validation";
import axios from 'axios';
import { getUser } from '../../../functions/functions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FragmentContainer, FragmentHeader, FragmentHeaderH1, FragmentContent } from './FragmentsElements';



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddAppUserInCollegesList(props) {

  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [districts, setDistricts] = useState([]);
  const [region, setRegion] = useState();
  const [regions, setRegions] = useState([]);
  const [district, setDistrict] = useState();
  const [subdivisions, setSubdivisions] = useState([]);
  const [subdivision, setSubdivision] = useState();
  const [genders, setGenders] = useState([]);

  const [faculties, setFaculties] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [facultySeat, setFacultySeat] = useState([]);
  const [facultyGender, setFacultyGender] = useState([]);
  const [ddo, setDdo] = useState('');
  const [college, setCollege] = useState('');
  const [seats, setSeats] = useState();
  const user = getUser(localStorage?.jwtToken);

  const [personnelNo, setPersonnelNo] = useState('');
  const [name, setName] = useState('');
  const [gender, setGender] = useState();
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  useEffect(async () => {
    window.scrollTo(0, 0);
    let resRegions;

    if (user?.userRole === 'rd') {
      resRegions = await axios.get(`https://www.dgcs.gos.pk/seccap2/regions/${user?.userId}`);
    } else {
      resRegions = await axios.get("https://www.dgcs.gos.pk/seccap2/regions");
    }

    const genderRes = await axios.get("https://www.dgcs.gos.pk/seccap2/colleges/genders");

    const facultiesRes = await axios.get("https://www.dgcs.gos.pk/seccap2/faculties");

    setRegions(resRegions.data.data);
    // setDistricts(res.data.data);
    setGenders(genderRes.data.data);
    setFaculties(facultiesRes.data.data);
  }, []);

  const handleChange = (event) => {
    if (event.target.name === 'region') {
      setDistrict('');
      setSubdivision('');
      setRegion(event.target.value);
      getDistricts(event.target.value);
    } else if (event.target.name === 'district') {
      setSubdivision('');
      setDistrict(event.target.value);
      getSubDivisions(event.target.value);
    } else if (event.target.name === 'subdivision') {
      setSubdivision(event.target.value);
    } else if (event.target.name === 'gender') {
      setFaculty([]);
      setFacultySeat([]);
      setFacultyGender([]);
      setGender(event.target.value);
    } else if (event.target.name === 'ddo') {
      setDdo(event.target.value);
    } else if (event.target.name === 'college') {
      setCollege(event.target.value);
    } else if (event.target.name === 'seats') {
      setSeats(event.target.value);
    }
    console.log(event);
  };

  const getDistricts = async (region_id) => {
    const res = await axios.post("https://www.dgcs.gos.pk/seccap2/districts/get", { region_id }, {
      headers: {
        'Authorization': `${localStorage.getItem('jwtToken')}`
      }
    });

    setDistricts(res.data.data);
  }

  const getSubDivisions = async (district_id) => {
    const res = await axios.post("https://www.dgcs.gos.pk/seccap2/subdivisions/district", { district_id }, {
      headers: {
        'Authorization': `${localStorage.getItem('jwtToken')}`
      }
    });

    setSubdivisions(res.data.data);
  }

  const handleChangeMultiple = (event) => {
    if (isEmpty(gender)) {
      setAlert('Please select gender first!', 'error');
    } else if (gender == 3) {
      setFaculty(event.target.value);
      setFacultyGender(() => {
        return [
          {
            gender_id: 1,
            faculty: event.target.value,
          },
          {
            gender_id: 2,
            faculty: event.target.value,
          },
        ]

      })
    } else {
      setFaculty(event.target.value);
      setFacultyGender(() => {
        return [
          {
            gender_id: gender,
            faculty: event.target.value,
          },
        ]

      })
    }

  };

  const handleClick = async () => {

    if (isEmpty(personnelNo.trim())) {
      setAlert('Please fill out Personnel No!', 'error');
    } else if (personnelNo.trim().length < 5) {
      setAlert('Password contains atleast 5 numbers!', 'error');
    } else if (isEmpty(name.trim())) {
      setAlert('Please fill out Name!', 'error');
    } else if (name.trim().length < 3) {
      setAlert('Password contains atleast 3 letters!', 'error');
    } else if (isEmpty(gender)) {
      setAlert('Please select gender!', 'error');
    } else if (isEmpty(email.trim())) {
      setAlert('Please fill out Email!', 'error');
    } else if (!isEmail(email.trim())) {
      setAlert('Please enter valid Email!', 'error');
    } else if (isEmpty(mobile.trim())) {
      setAlert('Please fill out Mobile No!', 'error');
    } else if (mobile.trim().length < 11) {
      setAlert('Please enter valid Mobile No!', 'error');
    } else if (isEmpty(password.trim())) {
      setAlert('Please fill out Password!', 'error');
    } else if (password.trim().length < 8) {
      setAlert('Password contains atleast 8 letters!', 'error');
    } else if (password.trim() !== confirmPassword.trim()) {
      setAlert('Passwords do not match!', 'error');
    } else {

      const data = {
        personnel_no: personnelNo,
        name,
        gender_id: gender,
        email,
        mobile,
        password,
      }

      const res = await axios.post('https://www.dgcs.gos.pk/seccap2/users/addAppUser', data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });

      if (res?.data?.status === 'success') {
        setAlert('User has been added successfully!', 'success');
        setTimeout(() => {
          props.setFragment('appusersincollegeslist');
        }, 2000);
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, 'error');
      } else {
        setAlert('Something went wrong, Please try again!', 'error');
      }

    }

  }

  return (
    <FragmentContainer>
      <Container fixed>
        <FragmentHeader>
          <Grid container spacing={5}>
            <Grid item md={12}>
              <FragmentHeaderH1>Add App User</FragmentHeaderH1>
            </Grid>
          </Grid>
        </FragmentHeader>
      </Container>
      <Divider />
      <Container>
        <FragmentContent>
          <div style={{ width: '100%' }}>

            <div style={{ marginBottom: '20px' }}>
              <TextField fullWidth id="outlined-personnel-no" label="Personnel No" value={personnelNo} onChange={(e) => (isNumber(e.target.value) || e.target.value === '') && e.target.value.length < 11 && setPersonnelNo(e.target.value)} name="personnel_no" type="text" variant="outlined" />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <TextField fullWidth id="outlined-name" label="Name" value={name} onChange={(e) => e.target.value.length < 255 && setName(e.target.value)} name="name" type="text" variant="outlined" />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={gender}
                  onChange={handleChange}
                  label="Gender"
                  name="gender"
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={{ marginBottom: '20px' }}>
              <TextField fullWidth id="outlined-email" label="Email" value={email} onChange={(e) => e.target.value.length < 255 && setEmail(e.target.value)} name="email" type="email" variant="outlined" />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <TextField fullWidth id="outlined-mobile" label="Mobile no" value={mobile} onChange={(e) => (isNumber(e.target.value) || e.target.value === '') && e.target.value.length < 12 && setMobile(e.target.value)} name="mobile" type="text" variant="outlined" />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <TextField fullWidth id="outlined-password" label="Password" value={password} onChange={(e) => e.target.value.length < 255 && setPassword(e.target.value)} name="password" type="password" variant="outlined" />
            </div>

            <div style={{ marginBottom: '20px' }}>
              <TextField fullWidth id="outlined-confirmPassword" label="Confirm Password" value={confirmPassword} onChange={(e) => e.target.value.length < 255 && setConfirmPassword(e.target.value)} name="confirmPassword" type="password" variant="outlined" />
            </div>



            <div>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Add
              </Button>
            </div>

            <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
          </div>
          
          
          
        </FragmentContent>
      </Container>

    </FragmentContainer>
  );
}
