import React from "react";
import SimpleCard from "../../../common/Card";
import Grid from "@material-ui/core/Grid";

const Cards = (props) => {
  return (
    <div>
      <Grid container spacing={1}>
        {props?.cardData?.map((item) => (
          <Grid item xs={12} md={4} spacing={6}>
            <SimpleCard item={item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Cards;
