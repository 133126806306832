import React, { useState, useEffect } from "react";
import {
  PreviewContainer,
  PreviewContent,
  PreviewHeading1,
  PreviewHeading2,
  PreviewHeading4,
  PreviewParagraph,
  ButtonContent,
} from "./Preview2Elements";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { Button as Btn } from "../../../ButtonElements";
import jsPDF from "jspdf";
import * as ApiCalls from "../../../../services/ApiCalls";
import background from "./../../../../images/background.jpg";
import GetAppIcon from "@material-ui/icons/GetApp";
import ProvincesData from "../../../../data/provinces2.json";
import ReligionsData from "../../../../data/religions.json";
import RegionsData from "../../../../data/regions.json";
import DistrictsData from "../../../../data/districts.json";
import BoardsData from "../../../../data/boards.json";
import StudygroupsData from "../../../../data/studygroups.json";
import SciencegroupsData from "../../../../data/sciencegroups.json";
import FacultiesData from "../../../../data/faculties.json";
import html2canvas from "html2canvas";

const Preview2 = (props) => {
  // Get the current date
  const today = new Date();

  // Add 15 days to the current date
  const futureDate = new Date();
  futureDate.setDate(today.getDate() + 15);

  // Define options for formatting the date
  const options = { day: "2-digit", month: "long", year: "numeric" };

  // Format the future date
  const formattedDate = futureDate.toLocaleDateString("en-GB", options);

  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };

  const [reason, setReason] = useState("");
  const [college, setCollege] = useState();
  const [province, setProvince] = useState("");
  const [addressRegion, setAddressRegion] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [districtClass8, setDistrictClass8] = useState("");
  const [districtClass5, setDistrictClass5] = useState("");
  const [domicileDistrict, setDomicileDistrict] = useState("");
  const [fatherDomicileDistrict, setFatherDomicileDistrict] = useState("");
  const [board, setBoard] = useState("");
  const [studyGroup, setStudyGroup] = useState("");
  const [subject, setSubject] = useState("");
  const [region, setRegion] = useState("");
  const [faculty, setFaculty] = useState("");
  const [zone, setZone] = useState("");
  const [religion, setReligion] = useState("");
  const [qrcodepath, setQrcodepath] = useState(props.qrCodePath);
  const [appliedId, setAppliedId] = useState(props.appliedId);
  const [appliedDate, setAppliedDate] = useState(props?.appliedDate);
  const [colleges, setColleges] = useState([]);
  const [serialNo, setSerialNo] = useState("");

  useEffect(async () => {
    window.scrollTo(0, 0);
    getSeccapAppliedCollegesByAppliedId(props?.appliedId);
    getProvinceByProvinceId(props.province);
    await getAddressRegionByRegionId(props.addressRegion);
    props.collegeId && (await getCollegeByCollegeId(props.collegeId));
    props.addressDistrict &&
      (await getAddressDistrictByDistrictId(props.addressDistrict));
    await getDomicileDistrictByDistrictId(props.domicileDistrict);
    await getFatherDomicileDistrictByDistrictId(props.fatherDomicileDistrict);
    await getDistrictClass8ByDistrictId(props.districtClass8);
    await getDistrictClass5ByDistrictId(props.districtClass5);
    await getBoardByBoardId(props.board);
    await getStudyGroupByGroupId(props.studyGroup);
    props.subject && (await getSubGroupBySubGroupId(props.subject));
    await getRegionByRegionId(props.region);
    await getReligionByReligionId(props.religion);
    props?.zone && (await getZoneByZoneId(props?.zone));
    if (props.facultyId == 1) {
      setFaculty("PRE-MEDICAL");
    } else if (props.facultyId == 2) {
      setFaculty("PRE-ENGINEERING");
    } else if (props.facultyId == 3) {
      setFaculty("COMPUTER SCIENCE");
    } else if (props.facultyId == 4) {
      setFaculty("COMMERCE");
    } else if (props.facultyId == 5) {
      setFaculty("HUMANITIES");
    } else if (props.facultyId == 6) {
      setFaculty("HOME ECONOMICS");
    } else {
      await getFacultyByAppliedId(props.appliedId);
    }

    if (props.admissionStatus == 1) {
      await getSerialNo(props.appliedId, props.collegeId);
    }

    if (props.admissionStatus == 2) {
      await getCancelledAdmissionByAppliedId(props.appliedId);
    } else {
      if (props.approved == 2) {
        await getCancelledByAppliedId(props.appliedId);
      }
    }
    await props.setOpenBackDrop(false);
  }, []);

  const getSerialNo = async (applied_id, college_id) => {
    const res = await ApiCalls.getSeccapConfirmedSerialNo(
      applied_id,
      college_id
    );
    if (res?.data?.status === "success") {
      setSerialNo(res?.data?.data);
    }
  };

  const getSeccapAppliedCollegesByAppliedId = async (applied_id) => {
    const res = await ApiCalls.getSeccapAppliedCollegesByAppliedId(applied_id);
    if (res?.data?.status === "success") {
      setColleges(res?.data?.data);
    }
  };

  const getFacultyByAppliedId = async (applied_id) => {
    const res = await ApiCalls.getSeccapFacultyByAppliedId(applied_id);
    if (res?.data?.status === "success") {
      setFaculty(res?.data?.data.faculty);
    }
  };

  const getCollegeByCollegeId = async (college_id) => {
    const res = await ApiCalls.getCollegeByCollegeId(college_id);
    if (res?.data?.status === "success") {
      setCollege(res?.data?.data.college_name);
    }
  };

  const getProvinceByProvinceId = (province_id) => {
    ProvincesData.forEach((item) => {
      if (item.id == province_id) {
        setProvince(item?.province);
      }
    });
  };

  const getCancelledByAppliedId = async (applied_id) => {
    const res = await ApiCalls.getSeccapAppliedCancelledByAppliedId(applied_id);
    if (res?.data?.status === "success") {
      setReason(res?.data?.data.reason);
    }
  };

  const getCancelledAdmissionByAppliedId = async (applied_id) => {
    const res = await ApiCalls.getSeccapCancelledAdmissionByAppliedId(
      applied_id
    );
    if (res?.data?.status === "success") {
      setReason(res?.data?.data.reason);
    }
  };

  const getReligionByReligionId = async (religion_id) => {
    ReligionsData.forEach((item) => {
      if (item.id == religion_id) {
        setReligion(item?.religion);
      }
    });
  };

  const getAddressRegionByRegionId = async (region_id) => {
    RegionsData.forEach((item) => {
      if (item.id == region_id) {
        setAddressRegion(item?.region);
      }
    });
  };

  const getAddressDistrictByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setAddressDistrict(item?.district);
      }
    });
  };

  const getDistrictClass8ByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setDistrictClass8(item?.district);
      }
    });
  };

  const getDistrictClass5ByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setDistrictClass5(item?.district);
      }
    });
  };

  const getDomicileDistrictByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setDomicileDistrict(item?.district);
      }
    });
  };

  const getFatherDomicileDistrictByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setFatherDomicileDistrict(item?.district);
      }
    });
  };

  const getBoardByBoardId = async (board_id) => {
    BoardsData.forEach((item) => {
      if (item.id == board_id) {
        setBoard(item?.board);
      }
    });
  };

  const getStudyGroupByGroupId = async (group_id) => {
    StudygroupsData.forEach((item) => {
      if (item.id == group_id) {
        setStudyGroup(item?.group_name);
      }
    });
  };

  const getSubGroupBySubGroupId = async (sub_group_id) => {
    SciencegroupsData.forEach((item) => {
      if (item.id == sub_group_id) {
        setSubject(item?.science);
      }
    });
  };

  const getRegionByRegionId = async (region_id) => {
    RegionsData.forEach((item) => {
      if (item.id == region_id) {
        setRegion(item?.region);
      }
    });
  };

  const getFacultyByFacultyId = async (faculty_id) => {
    FacultiesData.forEach((item) => {
      if (item.id == faculty_id) {
        setFaculty(item?.faculty);
      }
    });
  };

  const getZoneByZoneId = async (zone_id) => {
    const res = await ApiCalls.getZone(zone_id);
    if (res?.data?.data?.zone) {
      setZone(res?.data?.data?.zone);
    }
  };

  function generatePDF() {
    let printBtn = document.querySelector("#print-button");
    printBtn.style.display = "none";
    const input = document.getElementById("application");
    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");

      /*
        Here are the numbers (paper width and height) that I found to work. 
        It still creates a little overlap part between the pages, but good enough for me.
        if you can find an official number from jsPDF, use them.
        */
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("seccapletter.pdf");
    });
    printBtn.style.display = "flex";
  }

  return (
    <div>
      <PreviewContainer style={{ backgroundImage: `url(${background})` }}>
        <PreviewContent id="application">
          <div
            id="print-button"
            style={{ display: "flex", flexDirection: "row-reverse" }}
          >
            <div style={{ width: "50px", marginBottom: "1%" }}>
              <Button
                variant="contained"
                color="primary"
                style={{ padding: "4px", borderRadius: "4px" }}
                onClick={generatePDF}
              >
                <GetAppIcon />
              </Button>
            </div>
          </div>
          <div style={{ border: "1px solid #4b5563" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 1%",
                borderBottom: "1px solid #4b5563",
                width: "100%",
              }}
            >
              <div
                style={{ width: "60%", display: "flex", alignItems: "center" }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img
                    id="logo-image"
                    style={{
                      width: "5vw",
                    }}
                    src={"./images/logo.png"}
                    alt="logo"
                  />
                </div>
                <div>
                  <PreviewHeading1 style={{ color: "#137a4c" }}>
                    Sindh Electronic Centralized College Admission Program
                    2024-25
                  </PreviewHeading1>
                </div>
              </div>
              <div>
                {appliedId && (
                  <img
                    id="qrcode-image"
                    style={{
                      width: "6vw",
                    }}
                    src={qrcodepath}
                    alt="qrcode"
                  />
                )}

                <div>
                  {appliedId && (
                    <PreviewParagraph>Applied Id: {appliedId}</PreviewParagraph>
                  )}

                  {appliedDate && (
                    <PreviewParagraph>
                      Date: {appliedDate.slice(0, 10)}
                    </PreviewParagraph>
                  )}
                </div>
              </div>

              {/* <div>
                <img
                  style={{ marginRight: "10px" }}
                  src={require("./../../../../images/logo.png").default}
                  alt="logo"
                />
              </div> */}
            </div>
            {props.admissionStatus ? (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div
                    style={{
                      borderRadius: "5px",
                      padding: "0.3rem 0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: `${
                        (props.admissionStatus == 0 ||
                          props.admissionStatus == 1) &&
                        props?.claimCount > 0
                          ? "#0000ff24"
                          : props.admissionStatus == 0 ||
                            props.admissionStatus == 1
                          ? "#00800038"
                          : props.admissionStatus == 2
                          ? "#0000ff14"
                          : "#ff000014"
                      }`,
                    }}
                  >
                    {props.admissionStatus == 0 ? (
                      <div style={{ textAlign: "center", padding: "12px" }}>
                        {props?.claimCount > 0 && (
                          <PreviewHeading2
                            style={{
                              fontWeight: "bold",
                              color: "gray",
                              marginBottom: "0.3rem",
                            }}
                          >
                            Claim Form #{props?.claimCount}
                          </PreviewHeading2>
                        )}
                        <PreviewHeading2
                          style={{
                            fontWeight: "bold",
                            color: "green",
                            marginBottom: "0.3rem",
                          }}
                        >
                          {props.fullName} has been selected in college
                        </PreviewHeading2>
                        <PreviewHeading2
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            marginBottom: "0.3rem",
                          }}
                        >
                          {college}
                        </PreviewHeading2>
                        <PreviewHeading2
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            marginBottom: "0.3rem",
                          }}
                        >
                          in {faculty}
                        </PreviewHeading2>
                        <PreviewHeading2
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            marginBottom: "0.3rem",
                          }}
                        >
                          for Provisional Admission
                        </PreviewHeading2>
                        <PreviewHeading2 style={{ color: "gray" }}>
                          Please report at college along with your ninth or
                          matric documents to confirm your Admission.
                        </PreviewHeading2>
                        {/* <p
                          style={{
                            color: "grey",
                            textAlign: "start",
                            marginTop: "15px",
                          }}
                        >
                          Required Documents:
                        </p>
                        <p
                          style={{
                            color: "grey",
                            textAlign: "start",
                            marginTop: "10px",
                          }}
                        >
                          1) SSC or Ninth Marksheet
                        </p>
                        <p
                          style={{
                            color: "grey",
                            textAlign: "start",
                            marginTop: "5px",
                          }}
                        >
                          2) Detail Marks / Provisional Certificate of the last
                          exam passed
                        </p>
                        <p
                          style={{
                            color: "grey",
                            textAlign: "start",
                            marginTop: "5px",
                          }}
                        >
                          3) Character Certificate
                        </p>
                        <p
                          style={{
                            color: "grey",
                            textAlign: "start",
                            marginTop: "5px",
                          }}
                        >
                          4) Domicile PRC
                        </p>
                        <p
                          style={{
                            color: "grey",
                            textAlign: "start",
                            marginTop: "5px",
                          }}
                        >
                          5) Self/Father B-Form/CNIC
                        </p>
                        <p
                          style={{
                            color: "grey",
                            textAlign: "start",
                            marginTop: "5px",
                          }}
                        >
                          6) Colored Photograph
                        </p>
                        <p
                          style={{
                            color: "grey",
                            textAlign: "start",
                            marginTop: "5px",
                          }}
                        >
                          7) Undertaking to ensure 75% attendance in black and
                          white by Parent/Guardian
                        </p> */}
                      </div>
                    ) : props.admissionStatus == 1 ? (
                      <div style={{ textAlign: "center" }}>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "green",
                            marginBottom: "0.5rem",
                          }}
                        >
                          Your Provisional admission is confirmed by concerned
                          principal at college
                        </p>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            marginBottom: "0.5rem",
                          }}
                        >
                          {college}
                        </p>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            marginBottom: "0.5rem",
                          }}
                        >
                          in {faculty}
                        </p>
                      </div>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            marginBottom: "0.5rem",
                          }}
                        >
                          YOUR ADMISSION HAS BEEN CANCELLED, TRY AGAIN
                        </p>
                        <p style={{ fontSize: "14px", color: "gray" }}>
                          {reason}
                        </p>
                      </div>
                    )}
                  </div>
                  {props.admissionStatus == 2 && (
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Btn
                        to="/edit-application"
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary="true"
                        dark="true"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        style={{ width: "12rem" }}
                      >
                        Re Apply
                      </Btn>
                    </div>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div
                    id="short-note"
                    style={{
                      borderRadius: "5px",
                      padding: "0.3rem 0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: `${
                        props.approved == 1
                          ? "#00800038"
                          : props.approved == 0
                          ? "#0000ff14"
                          : "#ff000014"
                      }`,
                    }}
                  >
                    {props.approved == 1 ? (
                      <div style={{ textAlign: "center", padding: "10px" }}>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "green",
                          }}
                        >
                          YOUR APPLICATION HAS BEEN APPROVED
                        </p>
                        {/* <p style={{ fontSize: '14px', color: 'gray' }}>Please complete your application.</p><Link to="/edit-application">Edit Application</Link> */}
                        <p style={{ fontSize: "14px", color: "gray" }}>
                          Your Admission will be Announced soon in one of your
                          concerned Colleges, Please Keep Checking.
                        </p>
                        <p
                          style={{
                            margin: "10px",
                            fontSize: "14px",
                            color: "gray",
                          }}
                        >
                          OR
                        </p>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Btn
                            to="/edit-application"
                            onMouseEnter={onHover}
                            onMouseLeave={onHover}
                            primary="true"
                            dark="true"
                            smooth={true}
                            duration={500}
                            spy={true}
                            exact="true"
                            offset={-80}
                            style={{ width: "12rem" }}
                          >
                            Re Apply
                          </Btn>
                        </div>
                      </div>
                    ) : props.approved == 0 ? (
                      <div style={{ textAlign: "center" }}>
                        <p style={{ fontWeight: "bold", color: "blue" }}>
                          WAITING FOR APPROVAL
                        </p>
                        {/* <p style={{ fontSize: '14px', color: 'gray' }}>Please complete your application.</p><Link to="/edit-application">Edit Application</Link> */}
                        {/* <p style={{ fontSize: '14px', color: 'gray'}}>It takes a day to review your application, Please Keep Checking.</p> */}
                      </div>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            marginBottom: "0.5rem",
                          }}
                        >
                          YOUR APPLICATION HAS BEEN CANCELLED, TRY AGAIN
                        </p>
                        <p style={{ fontSize: "14px", color: "gray" }}>
                          {reason}
                        </p>
                      </div>
                    )}
                  </div>
                  {props.approved == 2 && (
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Btn
                        to="/edit-application"
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary="true"
                        dark="true"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        style={{ width: "12rem" }}
                      >
                        Re Apply
                      </Btn>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1%",
                width: "100%",
              }}
            >
              {props.admissionStatus == 1 && (
                <div>
                  <PreviewParagraph>s# {serialNo}</PreviewParagraph>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: `${
                    props.admissionStatus == 1 ? "center" : "right"
                  }`,
                  width: "60%",
                }}
              >
                <div>
                  <PreviewHeading1>
                    {props.admissionStatus
                      ? "Admission Form"
                      : "Application Form"}
                  </PreviewHeading1>
                  {/* <PreviewParagraph
                    style={{
                      color: "red",
                    }}
                  >
                    {parseInt(props.admissionStatus) === 0 ||
                    parseInt(props.admissionStatus) === 1
                      ? `This form is valid upto ${formattedDate}`
                      : ""}
                  </PreviewParagraph> */}
                </div>
              </div>
              <div>
                <img
                  id="profile-image"
                  style={{
                    width: "6vw",
                    border: "1px solid #aaa",
                    borderRadius: "5px",
                  }}
                  src={"./images/profile.png"}
                  alt="profile"
                />
              </div>
            </div>

            {/* <div>
              <div
                id="app-header"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "1%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ marginRight: "10px" }}
                    src={require("./../../../../images/logo.png").default}
                    alt="logo"
                  />{" "}
                  <PreviewHeading1 style={{ color: "#137a4c" }}>
                    SECCAP
                  </PreviewHeading1>
                </div>

                <PreviewHeading1>Provisional Admission</PreviewHeading1>

                <div>
                  <img
                    id="profile-image"
                    style={{
                      width: "100px",
                      height: "100px",
                      border: "1px solid #aaa",
                      borderRadius: "5px",
                    }}
                    src="/images/profile.png"
                    alt="profile"
                  />
                </div>
              </div>
            </div> */}

            <div
              style={{
                padding: "1%",
                borderBottom: "1px solid #4b5563",
                background: "#137a4c",
                color: "#fff",
              }}
            >
              <PreviewHeading2>PERSONAL INFORMATION</PreviewHeading2>
            </div>

            <div>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Name</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.fullName}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Father Name</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.fatherName}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Gender</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>
                    {parseInt(props.gender) === 1
                      ? "Male"
                      : parseInt(props.gender) === 2
                      ? "Female"
                      : ""}
                  </PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Email Address</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.email}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>CNIC / B-FORM No</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.cnic}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Mobile No</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.mobileNumber}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Date of Birth</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.dateOfBirth}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Place of Birth</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.placeOfBirth}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Nationality</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.nationality}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Religion</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{religion}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Domicile District</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{domicileDistrict}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Father's Domicile District</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{fatherDomicileDistrict}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Father' s Occupation</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.fatherOccupation}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Father's Mobile No</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>
                    {props.fatherMobileNumber}
                  </PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Father's CNIC</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.fatherCnic}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Mother's CNIC</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.motherCnic}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Home Address</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={10}
                >
                  <PreviewParagraph>{props.homeAddress}</PreviewParagraph>
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                padding: "1%",
                borderBottom: "1px solid #4b5563",
                background: "#137a4c",
                color: "#fff",
              }}
            >
              <PreviewHeading2>EDUCATIONAL INFORMATION</PreviewHeading2>
            </div>

            <div>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Ninth Roll No</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{props.ninthRollNo}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Ninth Passing Year</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>
                    {props.ninthObtainedMarks && props.ninthPassingYear}
                  </PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Matric Roll No</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{props.matricRollNo}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Matric Passing Year</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>
                    {props.obtainedMarks > 0 && props.passingYear}
                  </PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Province</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{province}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Board</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{board}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Study Group</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{studyGroup}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Subject</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>
                    {parseInt(props.studyGroup) === 1 && subject}
                  </PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>School Name</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={9}
                >
                  <PreviewParagraph>{props.schoolName}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Total Marks</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>
                    {props.ninthTotalMarks
                      ? props.ninthTotalMarks
                      : props.totalMarks}
                  </PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Obtained Marks</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>
                    {props.ninthObtainedMarks
                      ? props.ninthObtainedMarks
                      : props.obtainedMarks}
                  </PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Scaled Total Marks</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>850</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Scaled Obtained Marks</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>
                    {props.ninthScaledObtainedMarks
                      ? props.ninthScaledObtainedMarks
                      : props.scaledObtainedMarks}
                  </PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Class 8 Passing District</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{districtClass8}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Class 8 Passing Year</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{props.passingYearClass8}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Class 8 School Name</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={9}
                >
                  <PreviewParagraph>{props.schoolNameClass8}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Class 5 Passing District</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{districtClass5}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Class 5 Passing Year</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{props.passingYearClass5}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Class 5 School Name</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={9}
                >
                  <PreviewParagraph>{props.schoolNameClass5}</PreviewParagraph>
                </Grid>
              </Grid>
            </div>
            {props.admissionStatus != 0 && (
              <>
                <div
                  style={{
                    padding: "1%",
                    borderBottom: "1px solid #4b5563",
                    background: "#137a4c",
                    color: "#fff",
                  }}
                >
                  <PreviewHeading2>SELECTED COLLEGES</PreviewHeading2>
                </div>

                <div>
                  <Grid className="my-row" container>
                    <Grid
                      style={{
                        borderBottom: "1px solid #4b5563",
                        borderRight: "1px solid #4b5563",
                        padding: "1%",
                      }}
                      className="my-item"
                      item
                      xs={2}
                    >
                      <PreviewHeading4>College Board</PreviewHeading4>
                    </Grid>
                    <Grid
                      style={{
                        borderBottom: "1px solid #4b5563",
                        borderRight: "1px solid #4b5563",
                        padding: "1%",
                      }}
                      className="my-item"
                      item
                      xs={2}
                    >
                      <PreviewParagraph>{region}</PreviewParagraph>
                    </Grid>
                    <Grid
                      style={{
                        borderBottom: "1px solid #4b5563",
                        borderRight: "1px solid #4b5563",
                        padding: "1%",
                      }}
                      className="my-item"
                      item
                      xs={2}
                    >
                      <PreviewHeading4>Zone</PreviewHeading4>
                    </Grid>
                    <Grid
                      style={{
                        borderBottom: "1px solid #4b5563",
                        borderRight: "1px solid #4b5563",
                        padding: "1%",
                      }}
                      className="my-item"
                      item
                      xs={2}
                    >
                      <PreviewParagraph>{zone}</PreviewParagraph>
                    </Grid>
                    <Grid
                      style={{
                        borderBottom: "1px solid #4b5563",
                        borderRight: "1px solid #4b5563",
                        padding: "1%",
                      }}
                      className="my-item"
                      item
                      xs={2}
                    >
                      <PreviewHeading4>Choice of Faculty</PreviewHeading4>
                    </Grid>
                    <Grid
                      style={{
                        borderBottom: "1px solid #4b5563",
                        padding: "1%",
                      }}
                      className="my-item"
                      item
                      xs={2}
                    >
                      <PreviewParagraph>{faculty}</PreviewParagraph>
                    </Grid>
                  </Grid>

                  <Grid className="my-row" container>
                    <Grid
                      style={{
                        borderRight: "1px solid #4b5563",
                        padding: "1%",
                      }}
                      className="my-item"
                      item
                      xs={2}
                    >
                      <PreviewHeading4>Chosen Colleges</PreviewHeading4>
                    </Grid>
                    <Grid
                      style={{
                        padding: "1%",
                      }}
                      className="my-item"
                      item
                      xs={10}
                    >
                      {colleges?.map((item, index) => (
                        <PreviewParagraph>
                          {`${index + 1}) ${item?.ddo_code} ${
                            item?.college_name
                          }`}{" "}
                        </PreviewParagraph>
                      ))}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}

            {props.admissionStatus == 0 && (
              <>
                <div
                  style={{
                    padding: "1%",
                    borderBottom: "1px solid #4b5563",
                    background: "#137a4c",
                    color: "#fff",
                  }}
                >
                  <PreviewHeading2>REQUIRED DOCUMENTS</PreviewHeading2>
                </div>

                <div>
                  <Grid className="my-row" container>
                    <Grid
                      style={{
                        padding: "1%",
                      }}
                      className="my-item"
                      item
                      xs={12}
                    >
                      <PreviewParagraph>
                        1) SSC or Ninth Marksheet
                      </PreviewParagraph>
                      <PreviewParagraph>
                        2) Detail Marks / Provisional Certificate of the last
                        exam passed
                      </PreviewParagraph>
                      <PreviewParagraph>
                        3) Character Certificate
                      </PreviewParagraph>
                      <PreviewParagraph>4) Domicile PRC</PreviewParagraph>
                      <PreviewParagraph>
                        5) Self/Father B-Form/CNIC
                      </PreviewParagraph>
                      <PreviewParagraph>6) Colored Photograph</PreviewParagraph>
                      <PreviewParagraph>
                        7) Undertaking to ensure 75% attendance in black and
                        white by Parent/Guardian
                      </PreviewParagraph>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
          </div>
        </PreviewContent>
      </PreviewContainer>
    </div>
  );
};

export default Preview2;
