import React, { useState, useRef, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { AiFillLike } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import axios from "axios";
import WhiteAd from "../../Ad/WhiteAd";
import CommentList from "../CommentList/CommentList";
import * as ApiCalls from "../../../services/ApiCalls";
import NavBar from "../NavBar/NavBar";
import CustomAlert from "../../common/CustomAlert";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import SimpleBackdrop from "../../common/Backdrop";
import ReactTimeAgo from "react-time-ago";
import { MdInsertComment } from "react-icons/md";
import { IoEyeOutline } from "react-icons/io5";

const Post = () => {
  const params = useParams();
  const history = useHistory();
  const editor = useRef(null);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [discussionData, setDiscussionData] = useState({});
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [rootComments, setRootComments] = useState([]);
  const [rootCommentsCount, setRootCommentsCount] = useState([]);
  const [comment, setComment] = useState("");
  const [nextRows, setNextRows] = useState(100);
  const [activity, setActivity] = useState(false);

  const getDiscussionData = async (id) => {
    const res = await ApiCalls.getDiscussion(id);
    if (res?.data?.data) {
      setDiscussionData(res?.data?.data);
    }
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleLike = async (post_id) => {
    const res = await ApiCalls.likePost(post_id);
    let disData = discussionData;
    if (parseInt(disData?.id) === parseInt(post_id)) {
      if (disData.my_like === true) {
        disData.likes = parseInt(disData.likes) - 1;
        disData.my_like = false;
      } else if (disData.my_like === false) {
        disData.likes = parseInt(disData.likes) + 1;
        disData.my_like = true;
      }
    }
    setDiscussionData(disData);
    setActivity(!activity);
  };

  const handleClick = async () => {
    const data = {
      post_id: params?.id,
      comment,
    };

    if (comment.trim() === "") {
      setAlert("Comment is required!", "error");
    } else {
      await setOpenBackDrop(true);

      let res = await axios.post(
        "https://www.dgcs.gos.pk/seccap2/discussions/comment",
        data,
        {
          headers: {
            Authorization: `${localStorage.getItem("discussionsJwtToken")}`,
          },
        }
      );

      if (res) {
        await setOpenBackDrop(false);
      }

      if (res?.data?.status === "success") {
        setComment("");
        setAlert("Comment has been added successfully!", "success");
        getDiscussionData(params?.id);
        getRootCommentsData(nextRows, params?.id);
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, "error");
      } else {
        setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  const seenPost = async (post_id) => {
    const data = {
      post_id,
    };

    let res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/discussions/seenPost",
      data,
      {
        headers: {
          Authorization: `${localStorage.getItem("discussionsJwtToken")}`,
        },
      }
    );
  };

  useEffect(() => {
    // Check for token
    if (!localStorage?.discussionsJwtToken) {
      // Redirect to login
      history.push("/login");
    }
    seenPost(params?.id);
    getDiscussionData(params?.id);
    getRootCommentsData(nextRows, params?.id);
  }, []);

  const getRootCommentsData = async (nextRows, post_id) => {
    let searchFilters = "";
    if (searchFilters?.length === 0) {
      searchFilters = 0;
    }
    const res = await ApiCalls.getRootComments(
      nextRows,
      0,
      post_id,
      searchFilters
    );
    if (res?.data?.data) {
      setRootComments(res?.data?.data);
      setRootCommentsCount(res?.data?.count);
    }
  };

  return (
    <div>
      <div className="navbar">
        <NavBar />
      </div>

      <div className="main-container">
        <SimpleBackdrop openBackDrop={openBackDrop} />
        <div>
          <Link
            to={`/discussions/${params?.category}`}
            style={{
              display: "flex",
              color: "#6b7280",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
              width: "100px",
              height: "32px",
              border: "1px solid #9ca3af",
              borderRadius: "8px",
            }}
          >
            <IoIosArrowBack style={{ marginRight: "4px" }} /> Back
          </Link>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "70%" }}>
            <div style={{ marginTop: "25px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Link
                  className="link"
                  to={`/discussions/profile/${discussionData?.role}/${discussionData?.user_id}`}
                >
                  <div>
                    <img
                      style={{
                        width: "50px",
                        marginRight: "10px",
                        borderRadius: "50%",
                      }}
                      src={require("./../../../images/profile.png").default}
                    />
                  </div>
                </Link>
                <div>
                  <p
                    style={{
                      fontSize: "12px",
                      color: "#5f6368",
                      fontWeight: "bold",
                    }}
                  >
                    {discussionData?.name} <br /> {discussionData?.college_name}{" "}
                    <br />
                    posted{" "}
                    {discussionData?.created_at && (
                      <ReactTimeAgo date={discussionData?.created_at} />
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "30px",
              width: "25%",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleLike(discussionData?.id)}
                >
                  {discussionData?.my_like ? <AiFillLike /> : <AiOutlineLike />}
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  {discussionData?.likes} likes
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <p
                  style={{
                    fontSize: "12px",
                    color: "#5f6368",
                    display: "flex",
                    alignContent: "center",
                  }}
                >
                  <IoEyeOutline
                    style={{ marginRight: "5px", fontSize: "14px" }}
                  />{" "}
                  {discussionData?.views} views
                </p>
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "30px" }}>
          <h1>{discussionData?.title}</h1>
        </div>

        <div
          style={{ marginTop: "30px" }}
          dangerouslySetInnerHTML={{ __html: discussionData?.content }}
        />
        <div style={{ marginTop: "30px" }}>
          <hr />
        </div>
        <div style={{ marginTop: "30px" }}>
          <div>
            <h2 style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "15px" }}>
                <MdInsertComment />
              </div>
              <div>{discussionData?.comments} Comments</div>
            </h2>
          </div>
        </div>
        <div
          style={{
            border: "1px solid #c3c3c3",
            padding: "25px",
            borderRadius: "10px",
            marginTop: "50px",
          }}
        >
          <div style={{ marginTop: "50px" }}>
            <RichTextEditor content={comment} setContent={setComment} />
          </div>
          <div
            style={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <div
              style={{
                display: "flex",
                background: "#000",
                color: "#fff",
                padding: "10px 15px",
                borderRadius: "25px",
                fontWeight: "bold",
                fontSize: "14px",
                cursor: "pointer",
                width: "129px",
              }}
              onClick={handleClick}
            >
              <div>
                <p>Post Comment</p>
              </div>
            </div>
          </div>
        </div>

        <section>
          <div style={{ marginTop: "50px" }}>
            <CommentList
              comments={rootComments}
              setComments={setRootComments}
              commentType="root"
            />
          </div>
        </section>

        <CustomAlert
          open={open}
          message={message}
          severity={severity}
          handleClose={handleClose}
        />
      </div>
      <WhiteAd />
    </div>
  );
};

export default Post;
