import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main:'#137a4c',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
  // overrides: {
  //   MuiInputBase: {
  //     input: {
  //       fontSize: '1.2vw',
  //     },
  //   },
  // },
});

export default theme;