import React, { useState, useEffect } from "react";
import { PreviewContainer, PreviewContent } from "./PreviewElements";
import { Button } from "../ButtonElements";
import jsPDF from "jspdf";
import * as ApiCalls from "../../services/ApiCalls";
import background from "./../../images/background.jpg";
import GetAppIcon from "@material-ui/icons/GetApp";
import WhiteAd from "../Ad/WhiteAd";
import Ad2 from "../Ad/Ad2";
import Ad3 from "../Ad/Ad3";
import Ad4 from "../Ad/Ad4";
import Ad from "../Ad";
import VerticalAd from "../Ad/VerticalAd";
import { useParams } from "react-router-dom";
import Cards from "../Dashboard/reports/Cards/Cards";
import Grid from "@material-ui/core/Grid";

import "./styles.css";

const cardData = [{ heading: "Present", data: 10 }];

const StudentAttendance = () => {
  const params = useParams();
  const applied_id = params?.applied_id;

  const [permissionData, setPermissionData] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    getStudentAttendanceReport(applied_id);
    document.getElementsByTagName("a")[0].href.includes("https");
    let myLink = "";

    const myCollection = document.getElementsByTagName("a");
    for (let i = 0; i < myCollection.length; i++) {
      // if (myCollection[i]?.href?.includes("http")) {
      //   myLink = myCollection[i]?.href;
      // }
    }

    // console.log(myLink);
  }, []);

  const generatePDF = () => {
    let doc = new jsPDF("potrait", "pt", "a4", "false");

    doc.html(document.querySelector("#application"), {
      margin: [3, 3, 3, 3],
      autoPaging: "text",
      callback: function (pdf) {
        pdf.save(`student-attendance-report.pdf`);
      },
    });
  };

  // function generatePDF() {
  //   let printBtn = document.querySelector("#print-button");
  //   printBtn.style.display = "none";
  //   const input = document.getElementById("application");
  //   html2canvas(input)
  //     .then((canvas) => {
  //       var imgData = canvas.toDataURL('image/png');
  //       var imgWidth = 210;
  //       var pageHeight = 295;
  //       var imgHeight = canvas.height * imgWidth / canvas.width;
  //       var heightLeft = imgHeight;

  //       var doc = new jsPDF('p', 'mm');
  //       var position = 0;

  //       doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         doc.addPage();
  //         doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }
  //       doc.save('permissionletter.pdf');

  //     })
  //   printBtn.style.display = "flex";
  // }

  const getStudentAttendanceReport = async (applied_id) => {
    const res = await ApiCalls.getStudentAttendanceReport(applied_id);
    if (res?.data?.status === "success") {
      setAttendanceData(res?.data?.data[0]);
    }
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        <VerticalAd />
      </div>

      <PreviewContainer style={{ backgroundImage: `url(${background})` }}>
        <PreviewContent>
          <div>
            <div
              id="print-button"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <div style={{ width: "50px", marginBottom: "1%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: "4px", borderRadius: "4px" }}
                  onClick={generatePDF}
                >
                  <GetAppIcon />
                </Button>
              </div>
            </div>
            <div style={{ padding: "0 2%" }}>
              <h1>Attendance Report</h1>
            </div>

            <div style={{ padding: "0 2%", marginTop: "20px" }}>
              <h4 style={{ marginBottom: "5px", color: "#535353" }}>
                {attendanceData?.student_name}
              </h4>
              <h4 style={{ marginBottom: "5px", color: "#535353" }}>
                {attendanceData?.student_father_name}
              </h4>
              <h4 style={{ marginBottom: "5px", color: "#535353" }}>
                {attendanceData?.student_college_name}
              </h4>
            </div>

            <div className="card-container" id="application">
              <div
                style={{
                  background: "#009879",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                <h3>This Week</h3>
              </div>
              <div style={{ position: "relative" }}>
                <div style={{ position: "absolute", top: 0 }}>
                  <div style={{ opacity: 0 }}>
                    <Ad />
                  </div>
                </div>
                <div style={{ position: "absolute", bottom: 0 }}>
                  <div style={{ opacity: 0 }}>
                    <WhiteAd />
                  </div>
                </div>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Presents
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.this_week_presents}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Absents
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.this_week_absents}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Leaves
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.this_week_leaves}
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      {attendanceData?.this_week?.map((item) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px 0",
                            }}
                          >
                            {attendanceData?.current_date === item?.date1 ? (
                              <h4
                                style={{
                                  color: "#535353",
                                  fontSize: "16px",
                                  padding: "0",
                                  fontFamily: "inherit",
                                  fontWeight: 700,
                                  lineHeight: "1.1",
                                }}
                              >
                                {item?.date}
                              </h4>
                            ) : (
                              <h4
                                style={{
                                  color: "#535353",
                                  fontSize: "14px",
                                  padding: "0",
                                  fontFamily: "inherit",
                                  fontWeight: 500,
                                  lineHeight: "1.1",
                                }}
                              >
                                {item?.date}
                              </h4>
                            )}

                            {item?.result?.attendance_status && (
                              <span
                                style={{
                                  background: "#d1e7dd",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    color: "#009879",
                                    fontSize: "12px",
                                    fontFamily: "inherit",
                                    fontWeight: 500,
                                    lineHeight: "1.1",
                                  }}
                                >
                                  {item?.result?.attendance_status}
                                </h4>
                              </span>
                            )}
                          </div>
                          <hr
                            style={{ padding: 0, margin: "5px", color: "#eee" }}
                          />
                        </>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div>
                <Ad3 />
              </div>

              <div
                style={{
                  background: "#009879",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                <h3>This Month</h3>
              </div>
              <div style={{ position: "relative" }}>
                <div style={{ position: "absolute", top: 0 }}>
                  <div style={{ opacity: 0 }}>
                    <Ad4 />
                  </div>
                </div>
                <div style={{ position: "absolute", top: 380 }}>
                  <div style={{ opacity: 0 }}></div>
                </div>
                <div style={{ position: "absolute", top: 770 }}>
                  <div style={{ opacity: 0 }}></div>
                </div>
                <div style={{ position: "absolute", bottom: 0 }}>
                  <div style={{ opacity: 0 }}></div>
                </div>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Presents
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.this_month_presents}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Absents
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.this_month_absents}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Leaves
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.this_month_leaves}
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      {attendanceData?.this_month?.map((item) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px 0",
                            }}
                          >
                            {attendanceData?.current_date === item?.date1 ? (
                              <h4
                                style={{
                                  color: "#535353",
                                  fontSize: "16px",
                                  padding: "0",
                                  fontFamily: "inherit",
                                  fontWeight: 700,
                                  lineHeight: "1.1",
                                }}
                              >
                                {item?.date}
                              </h4>
                            ) : (
                              <h4
                                style={{
                                  color: "#535353",
                                  fontSize: "14px",
                                  padding: "0",
                                  fontFamily: "inherit",
                                  fontWeight: 500,
                                  lineHeight: "1.1",
                                }}
                              >
                                {item?.date}
                              </h4>
                            )}

                            {item?.result?.attendance_status && (
                              <span
                                style={{
                                  background: "#d1e7dd",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    color: "#009879",
                                    fontSize: "12px",
                                    fontFamily: "inherit",
                                    fontWeight: 500,
                                    lineHeight: "1.1",
                                  }}
                                >
                                  {item?.result?.attendance_status}
                                </h4>
                              </span>
                            )}
                          </div>
                          <hr
                            style={{ padding: 0, margin: "5px", color: "#eee" }}
                          />
                        </>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </div>

              <Ad2 />

              <div
                style={{
                  background: "#009879",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                <h3>Last Month</h3>
              </div>
              <div style={{ position: "relative" }}>
                <div style={{ position: "absolute", top: 0 }}>
                  <div style={{ opacity: 0 }}>
                    <Ad2 />
                  </div>
                </div>
                <div style={{ position: "absolute", top: 380 }}>
                  <div style={{ opacity: 0 }}></div>
                </div>
                <div style={{ position: "absolute", top: 770 }}>
                  <div style={{ opacity: 0 }}></div>
                </div>
                <div style={{ position: "absolute", bottom: 0 }}>
                  <div style={{ opacity: 0 }}></div>
                </div>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Presents
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.last_month_presents}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Absents
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.last_month_absents}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Leaves
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.last_month_leaves}
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      {attendanceData?.last_month?.map((item) => (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "10px 0",
                            }}
                          >
                            {attendanceData?.current_date === item?.date1 ? (
                              <h4
                                style={{
                                  color: "#535353",
                                  fontSize: "16px",
                                  padding: "0",
                                  fontFamily: "inherit",
                                  fontWeight: 700,
                                  lineHeight: "1.1",
                                }}
                              >
                                {item?.date}
                              </h4>
                            ) : (
                              <h4
                                style={{
                                  color: "#535353",
                                  fontSize: "14px",
                                  padding: "0",
                                  fontFamily: "inherit",
                                  fontWeight: 500,
                                  lineHeight: "1.1",
                                }}
                              >
                                {item?.date}
                              </h4>
                            )}

                            {item?.result?.attendance_status && (
                              <span
                                style={{
                                  background: "#d1e7dd",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                <h4
                                  style={{
                                    color: "#009879",
                                    fontSize: "12px",
                                    fontFamily: "inherit",
                                    fontWeight: 500,
                                    lineHeight: "1.1",
                                  }}
                                >
                                  {item?.result?.attendance_status}
                                </h4>
                              </span>
                            )}
                          </div>
                          <hr
                            style={{ padding: 0, margin: "5px", color: "#eee" }}
                          />
                        </>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </div>

              <WhiteAd />

              <div
                style={{
                  background: "#009879",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                <h3>This Year</h3>
              </div>
              <div>
                <div style={{ position: "absolute" }}>
                  <div style={{ opacity: 0 }}>
                    <Ad />
                  </div>
                </div>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Presents
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.this_year_presents}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Absents
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.this_year_absents}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Leaves
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.this_year_leaves}
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 0,
                          }}
                        >
                          <h4
                            style={{
                              fontWeight: "bold",
                              padding: 0,
                              fontSize: "16px",
                            }}
                          >
                            Month
                          </h4>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: 0,
                            }}
                          >
                            <span style={{ padding: "0 10px" }}>
                              <h4
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Presents
                              </h4>
                            </span>
                            <span style={{ padding: "0 10px" }}>
                              <h4
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Absents
                              </h4>
                            </span>
                            <span style={{ padding: "0 10px" }}>
                              <h4
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Leaves
                              </h4>
                            </span>
                          </div>
                        </div>
                        <hr style={{ padding: 0, margin: "5px" }} />
                      </div>
                      {attendanceData?.this_year?.map((item) => (
                        <>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 0,
                              }}
                            >
                              <h4
                                style={{
                                  padding: 0,
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {item?.month}
                              </h4>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: 0,
                                }}
                              >
                                <span style={{ padding: "0 30px" }}>
                                  <h4
                                    style={{
                                      fontSize: "16px",
                                      color: "#009879",
                                    }}
                                  >
                                    {item?.presents}
                                  </h4>
                                </span>
                                <span style={{ padding: "0 30px" }}>
                                  <h4
                                    style={{
                                      fontSize: "16px",
                                      color: "#FF0000",
                                    }}
                                  >
                                    {item?.absents}
                                  </h4>
                                </span>
                                <span style={{ padding: "0 30px" }}>
                                  <h4 style={{ fontSize: "16px" }}>
                                    {item?.leaves}
                                  </h4>
                                </span>
                              </div>
                            </div>
                            <hr style={{ padding: 0, margin: "5px" }} />
                          </div>
                        </>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </div>

              <Ad4 />

              <div
                style={{
                  background: "#009879",
                  padding: "10px",
                  color: "#fff",
                }}
              >
                <h3>Last Year</h3>
              </div>
              <div>
                <div style={{ position: "absolute" }}>
                  <Ad3 />
                </div>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Presents
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.last_year_presents}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Absents
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.last_year_absents}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={4} spacing={3}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <h4
                        style={{
                          color: "#535353",
                          fontSize: "18px",
                          margin: "10px 0",
                          fontFamily: "inherit",
                          fontWeight: 500,
                          lineHeight: "1.1",
                        }}
                      >
                        Leaves
                      </h4>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "#009879",
                          marginBottom: "30px",
                        }}
                      >
                        {attendanceData?.last_year_leaves}
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <div
                      style={{
                        fontWeight: 700,
                        boxShadow: "0 1px 6px rgb(57 73 76 / 35%)",
                        borderRadius: "5px",
                        margin: "2%",
                        padding: "2%",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 0,
                          }}
                        >
                          <h4
                            style={{
                              fontWeight: "bold",
                              padding: 0,
                              fontSize: "16px",
                            }}
                          >
                            Month
                          </h4>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: 0,
                            }}
                          >
                            <span style={{ padding: "0 10px" }}>
                              <h4
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Presents
                              </h4>
                            </span>
                            <span style={{ padding: "0 10px" }}>
                              <h4
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Absents
                              </h4>
                            </span>
                            <span style={{ padding: "0 10px" }}>
                              <h4
                                style={{ fontWeight: "bold", fontSize: "16px" }}
                              >
                                Leaves
                              </h4>
                            </span>
                          </div>
                        </div>
                        <hr style={{ padding: 0, margin: "5px" }} />
                      </div>
                      {attendanceData?.last_year?.map((item) => (
                        <>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 0,
                              }}
                            >
                              <h4
                                style={{
                                  padding: 0,
                                  fontSize: "16px",
                                  fontWeight: "500",
                                }}
                              >
                                {item?.month}
                              </h4>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: 0,
                                }}
                              >
                                <span style={{ padding: "0 30px" }}>
                                  <h4
                                    style={{
                                      fontSize: "16px",
                                      color: "#009879",
                                    }}
                                  >
                                    {item?.presents}
                                  </h4>
                                </span>
                                <span style={{ padding: "0 30px" }}>
                                  <h4
                                    style={{
                                      fontSize: "16px",
                                      color: "#FF0000",
                                    }}
                                  >
                                    {item?.absents}
                                  </h4>
                                </span>
                                <span style={{ padding: "0 30px" }}>
                                  <h4 style={{ fontSize: "16px" }}>
                                    {item?.leaves}
                                  </h4>
                                </span>
                              </div>
                            </div>
                            <hr style={{ padding: 0, margin: "5px" }} />
                          </div>
                        </>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            {/* <div>
              <div style={{ position: 'relative' }}>
                <div style={{ opacity: 0.2 }}>
                  <VerticalAd />
                </div>
                <div style={{ left: '30%', top: '50%', position: 'absolute' }}>
                  <h2 style={{ color: '#ebeb01', textShadow: '2px 2px 4px #000000' }}>Click here to send us stars</h2>
                </div>
              </div>
            </div> */}
          </div>
        </PreviewContent>
      </PreviewContainer>
    </div>
  );
};

export default StudentAttendance;
