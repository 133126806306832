import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import isEmpty from "../../helpers/validation";
import { CollegesContainer, CollegesContent, ButtonElement } from '../NewApplication/components/select-colleges/SelectCollegesElements';
import * as ApiCalls from '../../services/ApiCalls';
import background from "./../../images/background.jpg";



const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 500,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function SelectTalent(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(async () => {
    window.scrollTo(0, 0);
    await props.setOpenBackDrop(false);
    await getSindhTalents();
  }, []);

  const getSindhTalents = async () => {
    let resSindhTalents;
    let games = [];

    resSindhTalents = await ApiCalls.getSindhTalents();

    if (resSindhTalents?.data?.data?.length > 0) {

      resSindhTalents?.data?.data?.map(item => {
        let isSindhTalents = right?.some(item2 => item.id === item2.id);
        if (isSindhTalents === false) {
          games.push(item)
        }
      });

      setLeft(games);
    } else {
      props.setAlert(`Talents Not Found!`, 'error');
      props.setStep(1);
    }
  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleBack = () => {
    setRight([]);
    props.setSelectedTalent([]);
    props.setOpenBackDrop(true);
    setTimeout(() => {
      props.setStep(1);
    }, 1000);
  }

  const handleClick = () => {
    if (isEmpty(right)) {
      props.setAlert(`Please Choose Talent!`, 'error');
    } else {

      // props.setOpenBackDrop(true);

      // const collegesIds = right.map(item => item.id);

      props.setSelectedTalent(right);
      setTimeout(() => {
        props.setStep(4);
      }, 1000);
    }
  }

  const customList = (title, items) => (

    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items?.length && items?.length !== 0}
            indeterminate={numberOfChecked(items) !== items?.length && numberOfChecked(items) !== 0}
            disabled={items?.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items?.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items?.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value?.talent}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>

  );

  return (
    <div>
      <CollegesContainer style={{ backgroundImage: `url(${background})` }}>
        <CollegesContent>

          <div style={{ marginBottom: '50px', marginTop: '50px', marginLeft: '3%' }}>
            <h1>Select Your Talent</h1>
          </div>
          <div style={{ marginBottom: '50px', marginTop: '50px', marginLeft: '3%' }}>
            <p>To Select Talent:</p>
            <p>1) Select Talent on Left Panel</p>
            <p>2) Shift Selected Talent to Right Panel Using Right Arrow Button</p>
            <p>3) Click on Save {'&'} Continue Button</p>
          </div>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.root}
          >
            <Grid item>{customList('Choices', left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked?.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList('Chosen', right)}</Grid>
          </Grid>
          <ButtonElement>
            <Button variant="contained" color="default" style={{ marginRight: '1rem' }} onClick={handleBack} >
              Back
            </Button>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Save {'&'} Continue
            </Button>
          </ButtonElement>

        </CollegesContent>
      </CollegesContainer>

    </div>
  );
}
