import React, { useState, useEffect } from "react";
import {
  NewApplicationContainer,
  NewApplicationContent,
  NewApplicationItem,
  NewApplicationHeading,
} from "./NewApplicationElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import RegistrationForm from "./components/registration-form/RegistrationForm";
import PersonalInfoForm from "./components/personal-info-form/PersonalInfoForm";
import ChoiceOfFaculty from "./components/choice-of-faculty/ChoiceOfFaculty";
import SelectColleges from "./components/select-colleges/SelectColleges";
import Preview from "./components/preview/Preview";
import Success from "./components/success/Success";
import isEmpty, { isNumber } from "../../helpers/validation";
import CustomAlert from "../common/CustomAlert";
import SimpleBackdrop from "../common/Backdrop";
import * as ApiCalls from "../../services/ApiCalls";
import { useLocation } from "react-router";
import background from "./../../images/background.jpg";

import { isCNIC, isNumberOrDash } from "../../helpers/validation";
import { useHistory } from "react-router-dom";
import FullScreenDialog from "../common/FullScreenDialog";

import { passingYears } from "../../data/passingyears";
import BoardsData from "../../data/boards.json";
import WhiteAd from "../Ad/WhiteAd";
import Ad from "../Ad";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  multilineColor: {
    color: "red",
  },
}));

const Claim = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const [step, setStep] = useState(1);
  // Matric Profile
  const [ninthRollNo, setNinthRollNo] = useState("");
  const [matricRollNo, setMatricRollNo] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [vaccinated, setVaccinated] = useState("");
  const [province, setProvince] = useState("");
  const [board, setBoard] = useState("");
  const [studyGroup, setStudyGroup] = useState("");
  const [subject, setSubject] = useState("");
  const [ninthTotalMarks, setNinthTotalMarks] = useState("");
  const [ninthObtainedMarks, setNinthObtainedMarks] = useState("");
  const [ninthScaledObtainedMarks, setNinthScaledObtainedMarks] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [obtainedMarks, setObtainedMarks] = useState("");
  const [scaledObtainedMarks, setScaledObtainedMarks] = useState("");
  // Personal Profile
  const [appliedId, setAppliedId] = useState("");
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [cnic, setCnic] = useState("");
  const [addressRegion, setAddressRegion] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [addressTown, setAddressTown] = useState("");
  // Apply for college
  const [region, setRegion] = useState("");
  const [faculty, setFaculty] = useState("");
  const [selectedColleges, setSelectedColleges] = useState([]);

  const [availInDataBase, setAvailInDataBase] = useState(false);
  const [applied, setApplied] = useState(false);

  useEffect(async () => {
    window.scrollTo(0, 0);
    await setOpenBackDrop(false);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "province") {
      setBoard("");
      setProvince(value);
    }

    if (name === "board") {
      setBoard(value);
    }

    if (name === "cnic") {
      if (isNumberOrDash(value) && value.length < 16) {
        setCnic(value);
      }
    }

    if (name === "ninthRollNo") {
      if ((isNumber(value) || value === "") && value.length < 11) {
        setNinthRollNo(value);
      }
    }

    if (name === "matricRollNo") {
      if ((isNumber(value) || value === "") && value.length < 11) {
        setMatricRollNo(value);
      }
    }

    if (name === "passingYear") {
      if ((isNumber(value) || value === "") && value.length < 5) {
        setPassingYear(value);
      }
    }

    if (name === "vaccinated") {
      setVaccinated(value);
    }
  };

  const handleClick = async () => {
    if (isEmpty(cnic)) {
      setAlert("Please fill out CNIC / B-FORM No!", "error");
    } else if (!isCNIC(cnic)) {
      setAlert("Please fill out valid CNIC / B-FORM No!", "error");
    } else if (cnic.length !== 15) {
      setAlert("CNIC / B-FORM No field contains only 15 characters!", "error");
    } else if (isEmpty(matricRollNo)) {
      setAlert("Please fill out Maric Roll No!", "error");
    } else if (matricRollNo.length > 10) {
      setAlert(
        "Maric Roll No field cannot contain maximum than 10 numbers!",
        "error"
      );
    } else {
      setOpenBackDrop(true);

      const data = {
        matric_roll_no: matricRollNo,
        cnic,
      };

      const resApplied = await ApiCalls.isApplied(data);
      if (resApplied?.data?.status === "success") {
        if (resApplied?.data?.data?.length === 0) {
          await setOpenBackDrop(false);
          setAlert("Enter Valid Cnic or Matric roll no!", "error");
        } else {
          const dataApplied = resApplied?.data?.data;
          await setApplied(true);
          const resAdmissions = await ApiCalls.CheckAdmissionByAppliedId({
            applied_id: dataApplied.id,
          });

          if (resAdmissions.data.data.length == 0) {
            await setOpenBackDrop(false);
            setAlert(
              "You cannot claim college, Please check your status!",
              "error"
            );
            // setTimeout(() => {
            //   history.push("/status");
            // }, 1000);
          } else if (resAdmissions?.data?.data[0]?.class_id == 2) {
            await setOpenBackDrop(false);
            setAlert(
              "You cannot claim college, Please check your status!",
              "error"
            );
          } else if (resAdmissions?.data?.data[0]?.claim_count > 1000) {
            await setOpenBackDrop(false);
            setAlert("You cannot claim college more than 3 times!", "error");
          } else if (resAdmissions?.data?.data[0]?.status_id == 1) {
            await setOpenBackDrop(false);
            setAlert(
              "You cannot claim college, Your admission is confirmed!",
              "error"
            );
          } else if (resAdmissions?.data?.data[0]?.status_id == 2) {
            await setOpenBackDrop(false);
            setAlert(
              "You cannot claim college, Please check your status!",
              "error"
            );
          } else {
            dataApplied.group_id && (await setStudyGroup(dataApplied.group_id));
            await setTotalMarks(dataApplied?.total_marks);
            await setObtainedMarks(dataApplied?.obtained_marks);
            await setNinthObtainedMarks(dataApplied?.ninth_obtained_marks);
            await setNinthTotalMarks(dataApplied?.ninth_total_marks);
            await setFullName(dataApplied?.name);
            await setAppliedId(dataApplied?.id);
            dataApplied?.sub_group_id &&
              (await setSubject(dataApplied?.sub_group_id));
            await setCnic(dataApplied?.cnic);
            await setGender(dataApplied?.gender_id);
            await setAddressRegion(dataApplied?.address_region_id);
            await setRegion(dataApplied?.region_id);
            await setScaledObtainedMarks(dataApplied?.scaled_obtained_marks);
            await setNinthScaledObtainedMarks(
              dataApplied?.ninth_scaled_obtained_marks
            );
            dataApplied?.address_district_id &&
              (await setAddressDistrict(dataApplied?.address_district_id));
            dataApplied?.address_town_id &&
              (await setAddressTown(dataApplied?.address_town_id));
            await setStep(4);
          }
        }
      } else if (resApplied?.data?.error?.message) {
        await setOpenBackDrop(false);
        setAlert(resApplied?.data?.error?.message, "error");
      } else {
        await setOpenBackDrop(false);
        setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />

      {step === 1 && (
        <div>
          <NewApplicationContainer
            style={{ backgroundImage: `url(${background})` }}
          >
            <NewApplicationContent>
              <NewApplicationItem>
                <div style={{ marginBottom: "5%" }}>
                  <NewApplicationHeading>
                    {location.pathname === "/claim-college"
                      ? "Claim College"
                      : "Claim College"}
                  </NewApplicationHeading>
                </div>

                <div style={{ marginBottom: "5%" }}>
                  <FullScreenDialog
                    text={"IMPORTANT NOTE BEFORE CLAIM"}
                    src={"https://www.youtube.com/embed/yGevBD6x4xU"}
                  />
                </div>

                <div style={{ marginBottom: "2%" }}>
                  <TextField
                    fullWidth
                    id="outlined-mycnic"
                    placeholder="12345-1234567-1"
                    label="CNIC / B-FORM No"
                    type="text"
                    variant="outlined"
                    name="cnic"
                    value={cnic}
                    onChange={handleChange}
                  />
                </div>

                <div style={{ marginBottom: "2%" }}>
                  <TextField
                    fullWidth
                    id="outlined-college"
                    label="Enter Your Matric Roll No"
                    name="matricRollNo"
                    value={matricRollNo}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                  />
                </div>
                <div style={{ marginTop: "5%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    Proceed
                  </Button>
                </div>
              </NewApplicationItem>
              <WhiteAd />
            </NewApplicationContent>
          </NewApplicationContainer>
          <Ad />
        </div>
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      )}

      {/* Registration Form */}

      {step === 4 && (
        <ChoiceOfFaculty
          appliedId={appliedId}
          cnic={cnic}
          matricRollNo={matricRollNo}
          studyGroup={studyGroup}
          obtainedMarks={obtainedMarks}
          scaledObtainedMarks={scaledObtainedMarks}
          ninthObtainedMarks={ninthObtainedMarks}
          ninthScaledObtainedMarks={ninthScaledObtainedMarks}
          gender={gender}
          subject={subject}
          region={region}
          setRegion={setRegion}
          faculty={faculty}
          setFaculty={setFaculty}
          setAlert={setAlert}
          step={step}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
        />
      )}

      {step === 7 && (
        <Success
          setOpenBackDrop={setOpenBackDrop}
          setStep={setStep}
          setBoard={setBoard}
          setStudyGroup={setStudyGroup}
          setSubject={setSubject}
          setTotalMarks={setTotalMarks}
          setObtainedMarks={setObtainedMarks}
          setAppliedId={setAppliedId}
          setFullName={setFullName}
          setGender={setGender}
          setCnic={setCnic}
          setRegion={setRegion}
          setAddressRegion={setAddressRegion}
          setAddressDistrict={setAddressDistrict}
          setFaculty={setFaculty}
          setSelectedColleges={setSelectedColleges}
          setAvailInDataBase={setAvailInDataBase}
          setApplied={setApplied}
        />
      )}

      <CustomAlert
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Claim;
