import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import SimpleBackdrop from './../../common/Backdrop';
import WhiteAd from '../../Ad/WhiteAd';
import Ad2 from '../../Ad/Ad2';

import PrintIcon from '@material-ui/icons/Print';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getUser } from '../../../functions/functions';


const columns = [
  { id: 'stid', label: 'ID', minWidth: 170 },
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'father_name', label: 'Father Name', minWidth: 170 },
  { id: 'gender', label: 'Gender', minWidth: 170 },
  { id: 'mobile', label: 'Contact No', minWidth: 170 },
  { id: 'passing_year', label: 'Passing Year', minWidth: 170 },
  { id: 'obtained_marks', label: 'Obtained Marks', minWidth: 170 },
  { id: 'faculty', label: 'Choice of Faculty', minWidth: 170 }
];

function createData(id, name, gender, passing_year, school_name, obtained_marks, faculty, status_id, father_name, email, mobile, nationality, religion, cnic, date_of_birth, place_of_birth, domicile_district_id, address_region_id, address_district_id, home_address, father_occupation, father_mobile, father_domicile_district_id, father_cnic, mother_cnic, marksheet_image) {

  let f;

  if (faculty === 'PRE-MEDICAL') {
    f = 'PM';
  } else if (faculty === 'PRE-ENGINEERING') {
    f = 'PE';
  } else if (faculty === 'COMPUTER SCIENCE') {
    f = 'CS';
  } else if (faculty === 'COMMERCE') {
    f = 'CM';
  } else if (faculty === 'HUMANITIES') {
    f = 'HM';
  } else if (faculty === 'HOME ECONOMICS') {
    f = 'HE';
  }

  const stid = `23-${f}-${id}`;

  return { id, stid, name, gender, passing_year, school_name, obtained_marks, faculty, status_id, father_name, email, mobile, nationality, religion, cnic, date_of_birth, place_of_birth, domicile_district_id, address_region_id, address_district_id, home_address, father_occupation, father_mobile, father_domicile_district_id, father_cnic, mother_cnic, marksheet_image };
}

let rows = [];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 800,
  },
  container: {
    maxHeight: 440,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function OlevelSelectedInCollegesList(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [activity, setActivity] = useState(false);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSelectedStudents();
  }, []);

  const downloadpdf = () => {
    const doc = new jsPDF();

    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.id })),
      body: rows
    });
    doc.save('olevelselectedstudents.pdf');
  }

  const getSelectedStudents = async () => {
    await setOpenBackDrop(true);
    const user = getUser(localStorage.getItem('jwtToken'));
    const res = await axios.get(`https://www.dgcs.gos.pk/seccap2/statistics/olevelSelectedInCollege/${user.collegeId}`, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });
    if (res?.data?.status === 'success') {

      rows = res?.data?.data.map(item => createData(item?.applied_id, item?.name, item?.gender, item?.passing_year, item?.school_name, item?.obtained_marks, item?.faculty, item?.status_id, item?.father_name, item?.email, item?.mobile, item?.nationality, item?.religion, item?.cnic, item?.date_of_birth, item?.place_of_birth, item?.domicile_district_id, item?.address_region_id, item?.address_district_id, item?.home_address, item?.father_occupation, item?.father_mobile, item?.father_domicile_district_id, item?.father_cnic, item?.mother_cnic, item?.marksheet_image));
      setActivity(!activity);

    }
    await setOpenBackDrop(false);
  }

  const handleChange = async (e) => {
    const data = {
      value: e.target.value,
    };
    const user = getUser(localStorage.getItem('jwtToken'));
    const res = await axios.post(`https://www.dgcs.gos.pk/seccap2/statistics/filterOlevelSelectedInCollege/${user.collegeId}`, data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });
    if (res?.data?.status === 'success') {

      rows = res?.data?.data.map(item => createData(item?.applied_id, item?.name, item?.gender, item?.passing_year, item?.school_name, item?.obtained_marks, item?.faculty, item?.status_id, item?.father_name, item?.email, item?.mobile, item?.nationality, item?.religion, item?.cnic, item?.date_of_birth, item?.place_of_birth, item?.domicile_district_id, item?.address_region_id, item?.address_district_id, item?.home_address, item?.father_occupation, item?.father_mobile, item?.father_domicile_district_id, item?.father_cnic, item?.mother_cnic, item?.marksheet_image));
      setActivity(!activity);

    }

  }

  const handleRowClick = (item) => {
    props.setStudentDetailsInCollege(item);
    props.setFragment('olevelstudentdetailsincollege');
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />
      <div style={{ display: 'flex', alignContent: 'center', textAlign: 'center', justifyContent: 'center', padding: '2rem 0' }}>
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Search"
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleChange}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>

      <TableContainer className={classes.container}>
        <button style={{ margin: '10px' }} onClick={downloadpdf}>
          <PrintIcon />
        </button>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <TableRow onClick={() => handleRowClick(row)} hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <WhiteAd />
      <Ad2 />

    </div>
  );
}

