import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import CustomAlert from "../../common/CustomAlert";
import isEmpty from "../../../helpers/validation";
import { getUser } from '../../../functions/functions';
import { FragmentContainer, FragmentHeader, FragmentHeaderH1, FragmentContent } from './FragmentsElements';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddSubdivision(props) {

  const classes = useStyles();
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState('');
  const [districts, setDistricts] = useState([]);
  const [district, setDistrict] = useState('');
  const [subdivision, setSubdivision] = useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const user = getUser(localStorage?.jwtToken);

  useEffect(async () => {
    window.scrollTo(0, 0);
    let resRegions;

    if (user?.userRole === 'rd') {
      resRegions = await axios.get(`https://www.dgcs.gos.pk/seccap2/regions/${user?.userId}`);
    } else {
      resRegions = await axios.get("https://www.dgcs.gos.pk/seccap2/regions");
    }
    setRegions(resRegions?.data?.data);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const handleChange = async (event) => {
    if (event.target.name === 'region') {
      setDistrict('');
      setRegion(event.target.value);
      const data = {
        region_id: event.target.value,
      }
      const res = await axios.post("https://www.dgcs.gos.pk/seccap2/districts/get", data);
      setDistricts(res.data.data);

    }

    if (event.target.name === 'district') {
      setDistrict(event.target.value);
    }
  };

  const handleClick = async () => {
    if (isEmpty(region)) {
      setAlert('Please select region!', 'error');
    } else if (isEmpty(district)) {
      setAlert('Please select district!', 'error');
    } else if (isEmpty(subdivision)) {
      setAlert('Please fill out sub division field!', 'error');
    } else if (subdivision.length > 30) {
      setAlert("Sub division field cannot contain maximum than 30 characters!", 'error');
    } else {
      const data = {
        district_id: district,
        subdivision,
      }

      const res = await axios.post('https://www.dgcs.gos.pk/seccap2/subdivisions/add', data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } })
      if(res.data.status === 'success') {
        setAlert('Sub division has been added successfully!', 'success');
        setTimeout(() => {
          props.setFragment('subdivisions');
        }, 2000);
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, 'error');
      } else {
        setAlert('Something went wrong, Please try again!', 'error');
      }
    }
    
  }

  return (
    <FragmentContainer>
      <Container fixed>
        <FragmentHeader>
          <Grid container spacing={5}>
            <Grid item md={12}>
              <FragmentHeaderH1>Add Sub Division</FragmentHeaderH1>
            </Grid>
          </Grid>
        </FragmentHeader>
      </Container>
      <Divider />
      <Container fixed>
        <FragmentContent>
          <div style={{ width: '100%' }}>
            <div style={{marginBottom: '20px'}}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Region</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={region}
                  onChange={handleChange}
                  label="Region"
                  name="region"
                >
                  {regions?.map(item => (
                    <MenuItem value={item?.id}>{item?.region}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{marginBottom: '20px'}}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">District</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={district}
                  onChange={handleChange}
                  label="District"
                  name="district"
                >
                  {districts?.map(item => (
                    <MenuItem value={item?.id}>{item?.district}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div style={{marginBottom: '20px', marginLeft: '8px'}}>
              <TextField fullWidth id="outlined-subdivision" label="Sub Division" value={subdivision} onChange={(e) => e.target.value.length < 31 && setSubdivision(e.target.value)} name="subdivision" type="text" variant="outlined" />
            </div>
            
            <div style={{marginBottom: '20px', marginLeft: '8px'}}>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Add
              </Button>
            </div>

            <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
          </div>
        </FragmentContent>
      </Container>
      
    </FragmentContainer>
  );
}
