import React, { Component } from 'react';
import { AdContainerWhite } from './AdElements';
import "./adStyle.css";

class Ad4 extends Component {
  // componentDidMount() {
  //   (window.adsbygoogle = window.adsbygoogle || []).push({})
  // }

  render() {
    return (
      <AdContainerWhite>
        <div style={{ opacity: "0" }}>
          {/* <ins className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-2605536906605283"
            data-ad-slot="9427207223"
            data-ad-format="auto"
            data-full-width-responsive="true">
          </ins> */}
        </div>
      </AdContainerWhite>
    )
  }

}

export default Ad4;
