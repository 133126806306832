import React, { useState, useEffect } from "react";
import {
  NewApplicationContainer,
  NewApplicationContent,
  NewApplicationItem,
  NewApplicationHeading,
} from "../Complaint/NewApplicationElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import isEmpty, { isNumber } from "../../helpers/validation";
import CustomAlert from "../common/CustomAlert";
import SimpleBackdrop from "../common/Backdrop";
import * as ApiCalls from "../../services/ApiCalls";
import background from "./../../images/background.jpg";
import StudentCard from "./StudentCard";

import { isCNIC, isNumberOrDash } from "../../helpers/validation";
import { olevelPassingYears } from "../../data/passingyears";
import { ApplyOnlineItem } from "../ApplyOnline/ApplyOnlineElements";
import FullScreenDialog from "../common/FullScreenDialog";
import Ad4 from "../Ad/Ad4";
import Ad from "../Ad";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  input: {
    display: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  multilineColor: {
    color: "red",
  },
}));

const StudentCardForm = () => {
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [studentData, setStudentData] = React.useState([]);

  const [step, setStep] = useState(1);
  // Personal Profile
  const [matricRollNo, setMatricRollNo] = useState("");
  const [cnic, setCnic] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [olevelStudent, setOlevelStudent] = useState("No");
  const [error, setError] = useState(false);

  useEffect(async () => {
    window.scrollTo(0, 0);
    await setOpenBackDrop(false);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;

    if (name === "olevelStudent") {
      await setOlevelStudent(value);
      await setCnic("");
      await setMatricRollNo("");
      await setPassingYear("");
    }

    if (name === "cnic") {
      if (isNumberOrDash(value) && value.length < 16) {
        setCnic(value);
      }
    }

    if (name === "matricRollNo") {
      if ((isNumber(value) || value === "") && value.length < 11) {
        setMatricRollNo(value);
      }
    }

    if (name === "passingYear") {
      if ((isNumber(value) || value === "") && value.length < 5) {
        setPassingYear(value);
      }
    }
  };

  const handleClick = async () => {
    let err = false;

    if (isEmpty(cnic.trim())) {
      setAlert("Please fill out CNIC / B-FORM No!", "error");
    } else if (!isCNIC(cnic.trim())) {
      setAlert("Please fill out valid CNIC / B-FORM No!", "error");
    } else if (cnic.length !== 15) {
      setAlert("CNIC / B-FORM No field contains only 15 characters!", "error");
    } else if (olevelStudent === "No" && isEmpty(matricRollNo)) {
      setAlert("Please fill out Maric Roll No!", "error");
    } else if (olevelStudent === "No" && matricRollNo.length > 10) {
      setAlert(
        "Maric Roll No field cannot contain maximum than 10 numbers!",
        "error"
      );
    } else if (olevelStudent === "Yes" && isEmpty(passingYear)) {
      setAlert("Please fill out Passing Year!", "error");
    } else if (olevelStudent === "Yes" && passingYear.length > 4) {
      setAlert(
        "Passing Year field cannot contain maximum than 4 numbers!",
        "error"
      );
    } else {
      setOpenBackDrop(true);
      const data = {
        olevel_student: olevelStudent,
        matric_roll_no: matricRollNo,
        cnic,
        passing_year: passingYear,
      };

      let resApplied = await ApiCalls.getStudentCardData(data);

      if (resApplied?.data?.status === "success") {
        if (resApplied?.data?.data?.length === 0) {
          await setOpenBackDrop(false);
          setAlert(
            "Please make sure your admission is confirmed by College, Card will issue after submission of Documents at College!",
            "error"
          );
          err = true;
          await setError(true);
        } else {
          setStudentData(resApplied?.data?.data[0]);
        }
      } else if (resApplied?.data?.error?.message) {
        await setOpenBackDrop(false);
        setAlert(resApplied?.data?.error?.message, "error");
        err = true;
        await setError(true);
      } else {
        await setOpenBackDrop(false);
        setAlert("Something went wrong, Please try again!", "error");
        err = true;
        await setError(true);
      }

      if (err === false && studentData) {
        await setOpenBackDrop(false);
        setStep(2);
        // let url = `/#/student-card/${applied_id}`;
        // let win = window.open(url, "_blank");
        // win.focus();
      }
    }
  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />

      {step === 1 && (
        <div>
          <NewApplicationContainer
            style={{ backgroundImage: `url(${background})` }}
          >
            <NewApplicationContent>
              <ApplyOnlineItem>
                <FullScreenDialog
                  text={"HOW TO GET DIGITAL ID CARD"}
                  src={"https://www.youtube.com/embed/c0EQbAwjWmM"}
                />
              </ApplyOnlineItem>
              <NewApplicationItem>
                <div style={{ marginBottom: "5%" }}>
                  <NewApplicationHeading style={{ fontSize: "24px" }}>
                    Get Digital College Id Card
                  </NewApplicationHeading>
                </div>

                <div style={{ marginBottom: "5%" }}>
                  <img
                    style={{ width: "300px", height: "150px" }}
                    src={"./images/student-card.jpg"}
                    alt="student-card"
                  />
                </div>

                <div style={{ marginBottom: "2%" }}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-olevelStudent">
                      Olevel Student
                    </InputLabel>
                    <Select
                      native
                      value={olevelStudent}
                      onChange={handleChange}
                      label="Olevel Student"
                      name="olevelStudent"
                    >
                      <option aria-label="None" value="" />
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Select>
                  </FormControl>
                </div>

                <div style={{ marginBottom: "2%" }}>
                  <TextField
                    fullWidth
                    id="outlined-mycnic"
                    placeholder="12345-1234567-1"
                    label="CNIC / B-FORM No"
                    type="text"
                    variant="outlined"
                    name="cnic"
                    value={cnic}
                    onChange={handleChange}
                  />
                </div>

                {olevelStudent === "No" && (
                  <div style={{ marginBottom: "2%" }}>
                    <TextField
                      fullWidth
                      id="outlined-matric-roll-no"
                      label="Enter Your Matric Roll No"
                      name="matricRollNo"
                      value={matricRollNo}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                    />
                  </div>
                )}

                {olevelStudent === "Yes" && (
                  <div style={{ marginBottom: "2%" }}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-passingYear">
                        Passing Year
                      </InputLabel>
                      <Select
                        native
                        value={passingYear}
                        onChange={handleChange}
                        label="Passing Year"
                        name="passingYear"
                      >
                        <option aria-label="None" value="" />
                        {olevelPassingYears.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}

                <div style={{ marginTop: "3%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    Check
                  </Button>
                </div>

                <Ad4 />
              </NewApplicationItem>
            </NewApplicationContent>
          </NewApplicationContainer>
          <Ad />
        </div>
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      )}

      {step === 2 && <StudentCard studentData={studentData} />}

      <CustomAlert
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

export default StudentCardForm;
