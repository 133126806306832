import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const location = useLocation();

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav} pathname={location.pathname}>
          <NavbarContainer>
            <NavLogo
              to="/"
              onClick={toggleHome}
              scrollNav={scrollNav}
              pathname={location.pathname}
            >
              <img
                style={{ marginRight: "10px" }}
                src={
                  location.pathname === "/" && !scrollNav
                    ? "./images/logo_white.png"
                    : "./images/logo.png"
                }
                alt="logo"
              />{" "}
              SECCAP
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks
                  to="/"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  scrollNav={scrollNav}
                  pathname={location.pathname}
                >
                  Home
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="/about-us"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  scrollNav={scrollNav}
                  pathname={location.pathname}
                >
                  Admission Policy
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="/colleges"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  scrollNav={scrollNav}
                  pathname={location.pathname}
                >
                  Colleges
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="/status"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  scrollNav={scrollNav}
                  pathname={location.pathname}
                >
                  Check Status
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks
                  to="/cut-off"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  scrollNav={scrollNav}
                  pathname={location.pathname}
                >
                  Cut Off
                </NavLinks>
              </NavItem>
              {/* <NavItem>
                <NavLinks
                  to="/"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  scrollNav={scrollNav}
                  pathname={location.pathname}
                >
                  Merit Lists
                </NavLinks>
              </NavItem> */}
              {/* <NavItem>
                <NavLinks
                  to="/"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  scrollNav={scrollNav}
                  pathname={location.pathname}
                >
                  Downloads
                </NavLinks>
              </NavItem> */}
            </NavMenu>
            {location.pathname === "/" && (
              <NavBtn>
                <NavBtnLink
                  to="/signin"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  pathname={location.pathname}
                >
                  Sign In
                </NavBtnLink>
              </NavBtn>
            )}
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
