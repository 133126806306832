import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import isEmpty, { isNumber, isNumberOrDash } from "../../../helpers/validation";
import CustomAlert from "../../common/CustomAlert";
import ConfirmationDialog from "../../common/ConfirmationDialog";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import * as ApiCalls from "../../../services/ApiCalls";
import { useHistory } from "react-router-dom";
import {
  FragmentContainer,
  FragmentHeader,
  FragmentHeaderH1,
  FragmentContent,
} from "./FragmentsElements";

import { olevelPassingYears } from "../../../data/passingyears";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function CancelAdmission(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [cnic, setCnic] = useState("");
  const [matricRollNo, setMatricRollNo] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [groupId, setGroupId] = useState("");
  const [subGroupId, setSubGroupId] = useState("");
  const [scaledObtainedMarks, setScaledObtainedMarks] = useState("");
  const [olevelStudent, setOlevelStudent] = useState("No");
  const [appliedId, setAppliedId] = useState("");
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [faculty, setFaculty] = useState("");
  const [college, setCollege] = useState("");
  const [genderId, setGenderId] = useState("");
  const [classId, setClassId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [reason, setReason] = useState("");

  const [faculties, setFaculties] = useState([]);
  const [colleges, setColleges] = useState([]);

  const [regionId, setRegionId] = useState("");
  const [facultyId, setFacultyId] = useState("");
  const [collegeId, setCollegeId] = useState("");

  let history = useHistory();

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = async (event) => {
    const { name, value } = event.target;

    if (name === "olevelStudent") {
      setOlevelStudent(value);
      setCnic("");
      setMatricRollNo("");
      setPassingYear("");
      setGroupId("");
      setSubGroupId("");
      setScaledObtainedMarks("");
      setAppliedId("");
      setName("");
      setFatherName("");
      setClassId("");
      setStatusId("");
      setFaculty("");
      setCollege("");
      setGenderId("");
      setRegionId("");
      setFacultyId("");
      setCollegeId("");
      setFaculties([]);
      setColleges([]);
    }

    if (name === "cnic") {
      if (isNumberOrDash(value) && value.length < 16) {
        setCnic(value);
      }
    }

    if (name === "matricRollNo") {
      if ((isNumber(value) || value === "") && value.length < 11) {
        setMatricRollNo(value);
      }
    }

    if (name === "passingYear") {
      if ((isNumber(value) || value === "") && value.length < 5) {
        setPassingYear(value);
      }
    }

    if (name === "region") {
      if (isNumber(value) || value === "") {
        getFacultiesByStudyGroupIdAndSubjectId(
          groupId,
          subGroupId,
          scaledObtainedMarks
        );
        await setColleges([]);
        await setFacultyId("");
        await setRegionId(value);
      }
    }

    if (name === "faculty") {
      if (isNumber(value) || value === "") {
        await setColleges([]);
        await setFacultyId(value);
        const data = {
          region_id: regionId,
          faculty_id: value,
          gender_id: genderId,
          scaled_obtained_marks: scaledObtainedMarks,
        };

        const res = await ApiCalls.getCollegesWithToken(data);
        await setColleges(res?.data?.data);
      }
    }

    if (name === "college") {
      if (isNumber(value) || value === "") {
        setCollegeId(value);
      }
    }
  };

  const getFacultiesByStudyGroupIdAndSubjectId = async (
    group_id,
    sub_group_id,
    scaled_obtained_marks
  ) => {
    const res = await ApiCalls.getFacultiesByStudyGroupIdAndSubjectId(
      group_id,
      sub_group_id,
      scaled_obtained_marks
    );
    if (res?.data?.status === "success") {
      setFaculties(res?.data?.data);
    }
  };

  const handleClick = async () => {
    if (isEmpty(cnic)) {
      setAlert("Please select Cnic!", "error");
    } else if (olevelStudent === "No" && isEmpty(matricRollNo)) {
      setAlert("Please select Matric Roll No!", "error");
    } else if (olevelStudent === "Yes" && isEmpty(passingYear)) {
      setAlert("Please select Passing Year!", "error");
    } else {
      const data = {
        matric_roll_no: matricRollNo,
        passing_year: passingYear,
        cnic,
        olevel_student: olevelStudent,
      };

      const res = await axios.post(
        "https://www.dgcs.gos.pk/seccap2/students/checkSeccapStudent",
        data,
        { headers: { Authorization: localStorage.getItem("jwtToken") } }
      );

      if (res?.data?.data?.length === 0) {
        setAlert("Data not Found!", "error");
      } else if (res?.data?.status === "success") {
        await setAppliedId(res?.data?.data?.applied_id);
        await setName(res?.data?.data?.name);
        await setFatherName(res?.data?.data?.father_name);
        await setFaculty(res?.data?.data?.faculty);
        await setFacultyId(res?.data?.data?.faculty_id);
        await setCollege(res?.data?.data?.college_name);
        await setGenderId(res?.data?.data?.gender_id);
        await setClassId(res?.data?.data?.class_id);
        await setStatusId(res?.data?.data?.status_id);
        await setGroupId(res?.data?.data?.group_id);
        await setSubGroupId(res?.data?.data?.sub_group_id);
        if (res?.data?.data?.ninth_scaled_obtained_marks) {
          await setScaledObtainedMarks(
            res?.data?.data?.ninth_scaled_obtained_marks
          );
        } else if (res?.data?.data?.scaled_obtained_marks) {
          await setScaledObtainedMarks(res?.data?.data?.scaled_obtained_marks);
        }
      } else if (
        res.data.error.message === "user is not verified" ||
        res.data.error.message === "user is not authorized"
      ) {
        // Remove token from localStorage
        localStorage.removeItem("jwtToken");
        // Redirect to login
        history.push("/signin");
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, "error");
      } else {
        setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  const handleCancel = async () => {
    if (isEmpty(reason)) {
      setAlert("Please fill out Reason!", "error");
    } else if (reason.length < 10) {
      setAlert("Reason field contains minimum 10 characters!", "error");
    } else if (reason.length > 100) {
      setAlert(
        "Reason field cannot contain maximum than 100 characters!",
        "error"
      );
    } else {
      const data = {
        applied_id: appliedId,
        reason,
      };

      const res = await ApiCalls.cancelAdmissionByAdmin(data);

      if (res?.data?.status === "success") {
        if (res?.data?.data === true) {
          setAlert("Admission has been cancelled successfully!", "success");
          setCnic("");
          setMatricRollNo("");
          setPassingYear("");
          setGroupId("");
          setSubGroupId("");
          setScaledObtainedMarks("");
          setAppliedId("");
          setName("");
          setFatherName("");
          setClassId("");
          setStatusId("");
          setFaculty("");
          setCollege("");
          setGenderId("");
          setRegionId("");
          setFacultyId("");
          setCollegeId("");
          setFaculties([]);
          setColleges([]);
          window.scrollTo(0, 0);
        } else {
          setAlert("Something went wrong, Please try again!", "error");
        }
      } else if (
        res.data.error.message === "user is not verified" ||
        res.data.error.message === "user is not authorized"
      ) {
        // Remove token from localStorage
        localStorage.removeItem("jwtToken");
        // Redirect to login
        history.push("/signin");
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, "error");
      } else {
        setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  return (
    <div>
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item md={12}>
                <FragmentHeaderH1>Cancel Admission</FragmentHeaderH1>
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider style={{ marginBottom: "20px" }} />
        <Container fixed>
          <FragmentContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-olevel-student">
                    Olevel Student
                  </InputLabel>
                  <Select
                    native
                    value={olevelStudent}
                    onChange={handleChange}
                    label="Olevel Student"
                    name="olevelStudent"
                  >
                    <option aria-label="None" value="" />
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5}>
                <div style={{ marginBottom: "2%" }}>
                  <TextField
                    fullWidth
                    id="outlined-mycnic"
                    placeholder="12345-1234567-1"
                    label="CNIC / B-FORM No"
                    type="text"
                    variant="outlined"
                    name="cnic"
                    value={cnic}
                    onChange={handleChange}
                  />
                </div>
              </Grid>
              {olevelStudent === "No" && (
                <Grid item xs={12} md={5}>
                  <div style={{ marginBottom: "2%" }}>
                    <TextField
                      fullWidth
                      id="outlined-college"
                      label="Enter Matric Roll No"
                      name="matricRollNo"
                      value={matricRollNo}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                    />
                  </div>
                </Grid>
              )}

              {olevelStudent === "Yes" && (
                <Grid item xs={12} md={5}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-passing-year">
                      Select Passing Year
                    </InputLabel>
                    <Select
                      native
                      value={passingYear}
                      onChange={handleChange}
                      label="Select Passing Year"
                      name="passingYear"
                    >
                      <option aria-label="None" value="" />
                      {olevelPassingYears.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={2}>
                <Button
                  style={{ marginTop: "3%" }}
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                >
                  Show
                </Button>
              </Grid>
            </Grid>

            {appliedId && (
              <div>
                <Divider style={{ marginTop: "50px", marginBottom: "20px" }} />
                <h2 style={{ marginBottom: "20px" }}>Student Details</h2>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <h4>Name</h4>
                    <p>{name}</p>
                  </Grid>
                  <Grid item xs={4}>
                    <h4>Father name</h4>
                    <p>{fatherName}</p>
                  </Grid>
                  <Grid item xs={4}>
                    <h4>Gender</h4>
                    <p>{genderId == 1 ? "Male" : "Female"}</p>
                  </Grid>
                  <Grid item xs={4}>
                    <h4>Marks</h4>
                    <p>{Math.floor((scaledObtainedMarks / 850) * 550)}</p>
                  </Grid>
                  <Grid item xs={4}>
                    <h4>Faculty</h4>
                    <p>{faculty}</p>
                  </Grid>
                  <Grid item xs={4}>
                    <h4>Class</h4>
                    <p>
                      {parseInt(classId) === 1
                        ? "XI"
                        : parseInt(classId) === 2
                        ? "XII"
                        : "Archived"}
                    </p>
                  </Grid>
                  <Grid item xs={4}>
                    <h4>Admission Status</h4>
                    <p>
                      {parseInt(statusId) === 0
                        ? "Pending"
                        : parseInt(statusId) === 1
                        ? "Confirmed"
                        : "Cancelled"}
                    </p>
                  </Grid>
                  <Grid item xs={8}>
                    <h4>College</h4>
                    <p>{college}</p>
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: "50px", marginBottom: "20px" }} />
                <h2 style={{ marginBottom: "20px" }}>Cancel Admission</h2>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Enter Reason"
                      multiline
                      fullWidth
                      rows={4}
                      value={reason}
                      onChange={(e) =>
                        e.target.value.length < 101 && setReason(e.target.value)
                      }
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ConfirmationDialog
                      btnText="Cancel Admission"
                      title="Cancel Admission"
                      text=""
                      submitBtnText="Cancel"
                      handleApproved={handleCancel}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
          </FragmentContent>
          <CustomAlert
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
          />
        </Container>
      </FragmentContainer>
    </div>
  );
}
