import React, {useState} from "react";
import {
  Container,
  Form,
  FormButton,
  FormContent,
  FormH1,
  FormP,
  FormLabel,
  FormWrap,
  FormInput,
  Text,
  Icon,
} from "./SigninElements";
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import CustomAlert from "../common/CustomAlert";
import isEmpty from "../../helpers/validation";
import SimpleBackdrop from "../common/Backdrop";
import background from "../../images/background.jpg";
import { setTokenInLocalStorage, getUser } from '../../functions/functions';

const SignIn = () => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [openBackDrop, setOpenBackDrop] = useState(false);

  let history = useHistory();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isEmpty(email)) {
      setAlert('Please fill out Email!', 'error');
    } else if (isEmpty(password)) {
      setAlert("Please fill out Password!", 'error');
    } else {

      const userData = {
        email: email,
        password: password,
      };

      setOpenBackDrop(true);

      const res = await axios.post('https://www.dgcs.gos.pk/seccap2/users/login', userData);

      if (res?.data?.data){
        
        const { token } = res.data.data;

        if (token) {
          // Save to localStorage
          setTokenInLocalStorage(token);
          // Decode token to get user data
          const user = getUser(token);
        } else {
          setOpenBackDrop(false);
          setAlert("Invalid Email or  Password!", 'error');
        }
    
      } else {
        setOpenBackDrop(false);
        setAlert("Invalid Email or  Password!", 'error');
      }

    }

    // Check for token
    if (localStorage.jwtToken) {
      // Redirect to login
      history.push("/dashboard");
      // window.location.href = "/dashboard";
    }
  }

  return (
    <>
      <Container style={{ backgroundImage: `url(${background})` }}>
        <SimpleBackdrop openBackDrop={openBackDrop} />
        <FormWrap>
          <Icon to="/">SECCAP</Icon>
          <FormContent>
            <Form onSubmit={onSubmit}>
              <FormH1>Sign in to your account</FormH1>
              <FormP>Only for Admin</FormP>
              <FormLabel htmlFor="for">Email</FormLabel>
              <FormInput name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
              <FormLabel htmlFor="for">Password</FormLabel>
              <FormInput  name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
              <FormButton type="submit">Continue</FormButton>
              <Text>Forgot password</Text>
            </Form>
          </FormContent>
        </FormWrap>
        
        <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
      </Container>
    </>
  );
};

export default SignIn;
