import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import SimpleBackdrop from "./../../common/Backdrop";
import WhiteAd from "../../Ad/WhiteAd";
import Ad2 from "../../Ad/Ad2";

import PrintIcon from "@material-ui/icons/Print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getUser } from "../../../functions/functions";

import ReactHTMLTableToExcel from "react-html-table-to-excel";

const columns = [
  { id: "stid", key: "stid", label: "Seccap Id", minWidth: 140 },
  { id: "name", label: "Name", minWidth: 140 },
  { id: "father_name", label: "Father Name", minWidth: 140 },
  { id: "gender", label: "Gender", minWidth: 100 },
  { id: "mobile", label: "Contact No", minWidth: 150 },
  { id: "matric_roll_no", label: "Matric Roll No", minWidth: 120 },
  { id: "matric_passing_year", label: "Matric Passing Year", minWidth: 100 },
  {
    id: "scaled_obtained_marks",
    label: "Matric Obtained Marks",
    minWidth: 100,
  },
  { id: "faculty", label: "Choice of Faculty", minWidth: 170 },
];

function createData(
  id,
  name,
  gender,
  matric_roll_no,
  matric_passing_year,
  province_id,
  board_id,
  group_id,
  sub_group_id,
  school_name,
  total_marks,
  obtained_marks,
  scaled_total_marks,
  scaled_obtained_marks,
  class8_passing_district_id,
  class8_passing_year,
  class8_school_name,
  class5_passing_district_id,
  class5_passing_year,
  class5_school_name,
  faculty,
  status_id,
  xii_status,
  father_name,
  email,
  mobile,
  nationality,
  religion,
  cnic,
  date_of_birth,
  place_of_birth,
  domicile_district_id,
  address_region_id,
  address_district_id,
  home_address,
  father_occupation,
  father_mobile,
  father_domicile_district_id,
  father_cnic,
  mother_cnic,
  profile_image,
  religion_id,
  faculty_id,
  admission_year,
  class_id
) {
  let f;

  if (faculty === "PRE-MEDICAL") {
    f = "PM";
  } else if (faculty === "PRE-ENGINEERING") {
    f = "PE";
  } else if (faculty === "COMPUTER SCIENCE") {
    f = "CS";
  } else if (faculty === "COMMERCE") {
    f = "CM";
  } else if (faculty === "HUMANITIES") {
    f = "HM";
  } else if (faculty === "HOME ECONOMICS") {
    f = "HE";
  }

  const stid = `${admission_year.slice(2)}-${f}-${id}`;

  return {
    id,
    stid,
    name,
    gender,
    matric_roll_no,
    matric_passing_year,
    province_id,
    board_id,
    group_id,
    sub_group_id,
    school_name,
    total_marks,
    obtained_marks,
    scaled_total_marks,
    scaled_obtained_marks,
    class8_passing_district_id,
    class8_passing_year,
    class8_school_name,
    class5_passing_district_id,
    class5_passing_year,
    class5_school_name,
    faculty,
    status_id,
    xii_status,
    father_name,
    email,
    mobile,
    nationality,
    religion,
    cnic,
    date_of_birth,
    place_of_birth,
    domicile_district_id,
    address_region_id,
    address_district_id,
    home_address,
    father_occupation,
    father_mobile,
    father_domicile_district_id,
    father_cnic,
    mother_cnic,
    profile_image,
    religion_id,
    faculty_id,
    admission_year,
    class_id,
  };
}

let rows = [];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 800,
  },
  container: {
    maxHeight: 440,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function SelectedInXiiInCollegesList(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [activity, setActivity] = useState(false);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);

  window.scrollTo(0, 0);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSelectedStudents();
    props.setPrevFragment("selectedxii");
  }, []);

  const downloadpdf = () => {
    const doc = new jsPDF();

    doc.autoTable({
      theme: "grid",
      columns: columns.map((col) => ({ ...col, dataKey: col.id })),
      body: rows,
    });
    doc.save("selectedstudents.pdf");
  };

  const getSelectedStudents = async () => {
    await setOpenBackDrop(true);
    const user = getUser(localStorage.getItem("jwtToken"));
    const res = await axios.get(
      `https://www.dgcs.gos.pk/seccap2/statistics/selectedInCollege/${user.collegeId}`,
      { headers: { Authorization: localStorage.getItem("jwtToken") } }
    );
    console.log(res);
    if (res?.data?.status === "success") {
      rows = res?.data?.data.map((item) =>
        createData(
          item?.applied_id,
          item?.name,
          item?.gender,
          item?.matric_roll_no,
          item?.matric_passing_year,
          item?.province_id,
          item?.board_id,
          item?.group_id,
          item?.sub_group_id,
          item?.school_name,
          item?.total_marks,
          item?.obtained_marks,
          item?.scaled_total_marks,
          item?.scaled_obtained_marks,
          item?.class8_passing_district_id,
          item?.class8_passing_year,
          item?.class8_school_name,
          item?.class5_passing_district_id,
          item?.class5_passing_year,
          item?.class5_school_name,
          item?.faculty,
          item?.status_id,
          item?.xii_status,
          item?.father_name,
          item?.email,
          item?.mobile,
          item?.nationality,
          item?.religion,
          item?.cnic,
          item?.date_of_birth,
          item?.place_of_birth,
          item?.domicile_district_id,
          item?.address_region_id,
          item?.address_district_id,
          item?.home_address,
          item?.father_occupation,
          item?.father_mobile,
          item?.father_domicile_district_id,
          item?.father_cnic,
          item?.mother_cnic,
          item?.profile_image,
          item?.religion_id,
          item?.faculty_id,
          item?.admission_year,
          item?.class_id
        )
      );
      setActivity(!activity);
    }
    await setOpenBackDrop(false);
  };

  const handleChange = async (e) => {
    const data = {
      value: e.target.value,
    };
    const user = getUser(localStorage.getItem("jwtToken"));
    const res = await axios.post(
      `https://www.dgcs.gos.pk/seccap2/statistics/filterSelectedInCollege/${user.collegeId}`,
      data,
      { headers: { Authorization: localStorage.getItem("jwtToken") } }
    );
    if (res?.data?.status === "success") {
      rows = res?.data?.data.map((item) =>
        createData(
          item?.applied_id,
          item?.name,
          item?.gender,
          item?.matric_roll_no,
          item?.matric_passing_year,
          item?.province_id,
          item?.board_id,
          item?.group_id,
          item?.sub_group_id,
          item?.school_name,
          item?.total_marks,
          item?.obtained_marks,
          item?.scaled_total_marks,
          item?.scaled_obtained_marks,
          item?.class8_passing_district_id,
          item?.class8_passing_year,
          item?.class8_school_name,
          item?.class5_passing_district_id,
          item?.class5_passing_year,
          item?.class5_school_name,
          item?.faculty,
          item?.status_id,
          item?.xii_status,
          item?.father_name,
          item?.email,
          item?.mobile,
          item?.nationality,
          item?.religion,
          item?.cnic,
          item?.date_of_birth,
          item?.place_of_birth,
          item?.domicile_district_id,
          item?.address_region_id,
          item?.address_district_id,
          item?.home_address,
          item?.father_occupation,
          item?.father_mobile,
          item?.father_domicile_district_id,
          item?.father_cnic,
          item?.mother_cnic,
          item?.profile_image,
          item?.religion_id,
          item?.faculty_id,
          item?.admission_year,
          item?.class_id
        )
      );
      setActivity(!activity);
    }
  };

  const handleRowClick = (item) => {
    props.setStudentDetailsInCollege(item);
    props.setFragment("studentdetailsxiiincollege");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />
      <div
        style={{
          display: "flex",
          alignContent: "center",
          textAlign: "center",
          justifyContent: "center",
          padding: "2rem 0",
        }}
      >
        <Paper component="form" className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            onChange={handleChange}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            type="submit"
            className={classes.iconButton}
            aria-label="search"
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>

      <TableContainer className={classes.container}>
        <button style={{ margin: "10px" }} onClick={downloadpdf}>
          <img
            alt=""
            src="https://img.icons8.com/material/24/000000/export-pdf.png"
          />
        </button>

        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="tablexls"
          sheet="tablexls"
          buttonText={
            <img
              alt=""
              src="https://img.icons8.com/material/24/000000/xls-export.png"
            />
          }
        />
        <Table id="table-to-xls" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    onClick={() => handleRowClick(row)}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.code}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100, 500, rows.length]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <WhiteAd />
      <Ad2 />
    </div>
  );
}
