import React, { useState, useEffect } from "react";
import "./App.css";
import { Switch, Route, useLocation } from "react-router-dom";
import Home from "./pages";
import SigninPage from "./pages/signin";
import DashboardPage from "./pages/dashboard";
import ApplyOnlinePage from "./pages/applyOnline";
import AboutUsPage from "./pages/aboutUs";
import CollegesPage from "./pages/colleges";
import Navbar from "./components/Navbar";
import SeefNavbar from "./components/SeefNavbar";
import SeefSignin from "./components/Seef/components/Signin/index";
import TotalApplications from "./components/Seef/components/total-applications/TotalApplications";
import Sidebar from "./components/Sidebar";
import NewApplication from "./components/NewApplication";
import Claim from "./components/Claim";
import OlevelClaim from "./components/OlevelClaim";
import OlevelNewApplication from "./components/OlevelNewApplication";
import Status from "./components/Status";
import SeccapResult from "./components/SeccapResult";
import ClaimCollege from "./components/ClaimCollege";
import OlevelStatus from "./components/OlevelStatus";
import PrivateRoute from "./components/common/PrivateRoute";
import OlevelPage from "./pages/olevel";
import CutOff from "./components/CutOff";
import CutOff1 from "./components/CutOff1";
import CutOff2 from "./components/CutOff2";
import CutOff3 from "./components/CutOff3";
import CutOff4 from "./components/CutOff4";
import CutOff5 from "./components/CutOff5";
import CutOff6 from "./components/CutOff6";
import CutOff7 from "./components/CutOff7";
import CutOff8 from "./components/CutOff8";
import CutOff9 from "./components/CutOff9";
import CutOff10 from "./components/CutOff10";
import { getUser } from "./functions/functions";
import Seef from "./components/Seef";
import Preview from "./components/PermissionLetter/Preview";
import PreviewXii from "./components/PermissionLetterXii/PreviewXii";
import StudentCard from "./components/StudentCard/StudentCard";
import StudentAttendance from "./components/StudentAttendance/StudentAttendance";
import StudentCardForm from "./components/StudentCard/StudentCardForm";
import StudentAttendanceForm from "./components/StudentAttendance/StudentAttendanceForm";
import SindhGames from "./components/SindhGames/SindhGames";
import SindhTalent from "./components/SindhTalent/SindhTalent";
import Complaint from "./components/Complaint/Complaint";
import CutOff2023 from "./components/CufOff2023";
import StudentsGuide from "./components/StudentsGuide";
import QuizApp from "./components/QuizApp";
import Discussions from "./components/Discussions/Discussions";
import TestCategories from "./components/QuizApp/TestCategories/TestCategories";
import Forum from "./components/Discussions/Forum/Forum";
import NewTopic from "./components/Discussions/NewTopic/NewTopic";
import Post from "./components/Discussions/Post/Post";
import Login from "./components/Discussions/Login/Login";
import Profile from "./components/Discussions/Profile/Profile";
import CutOff11 from "./components/CutOff11";
import SeatsAvailability from "./components/SeatsAvailability";
import MapComponent from "./components/MapComponent/MapComponent";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const user = getUser(localStorage?.jwtToken);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (user?.userRole === "admin") {
    return (
      <>
        <Sidebar isOpen={isOpen} toggle={toggle} />

        <Switch>
          <Route path="/" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <Home />
          </Route>
          <Route path="/map" exact>
            <MapComponent />
          </Route>
          <Route path="/seef-signin" exact>
            <SeefNavbar toggle={toggle} pathname={location.pathname} />
            <SeefSignin />
          </Route>
          <Route path="/quiz-app" exact>
            <QuizApp />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/discussions" exact>
            <Discussions />
          </Route>
          <Route path="/discussions/profile/:role/:id" exact>
            <Profile />
          </Route>
          <Route path="/discussions/:category" exact>
            <Forum />
          </Route>
          <Route path="/discussions/:category/new" exact>
            <NewTopic />
          </Route>
          <Route path="/discussions/:category/:id" exact>
            <Post />
          </Route>
          <Route path="/discussions/:profile" exact>
            <Profile />
          </Route>

          {/* <Route path="/seef" exact >
            <SeefNavbar toggle={toggle} pathname={location.pathname} />
            <Seef />
          </Route> */}
          <Route path="/seef-total-applications" exact>
            <SeefNavbar toggle={toggle} pathname={location.pathname} />
            <TotalApplications />
          </Route>
          <Route path="/cut-off" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff />
          </Route>
          <Route path="/cutoff-2024" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff2023 />
          </Route>
          <Route path="/students-guide" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <StudentsGuide />
          </Route>
          <Route path="/cutoff-pre-medical-male" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff1 />
          </Route>
          <Route path="/cutoff-pre-medical-female" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff2 />
          </Route>
          <Route path="/cutoff-pre-engineering-male" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff3 />
          </Route>
          <Route path="/cutoff-pre-engineering-female" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff4 />
          </Route>
          <Route path="/cutoff-computer-science-male" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff5 />
          </Route>
          <Route path="/cutoff-computer-science-female" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff6 />
          </Route>
          <Route path="/cutoff-commerce-male" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff7 />
          </Route>
          <Route path="/cutoff-commerce-female" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff8 />
          </Route>
          <Route path="/cutoff-humanities-male" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff9 />
          </Route>
          <Route path="/cutoff-humanities-female" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff10 />
          </Route>

          <Route path="/cutoff-home-economics" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff11 />
          </Route>

          <Route path="/cutoff-pre-medical-male-2022" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff1 year={"2022"} />
          </Route>
          <Route path="/cutoff-pre-medical-female-2022" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff2 year={"2022"} />
          </Route>
          <Route path="/cutoff-pre-engineering-male-2022" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff3 year={"2022"} />
          </Route>
          <Route path="/cutoff-pre-engineering-female-2022" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff4 year={"2022"} />
          </Route>
          <Route path="/cutoff-computer-science-male-2022" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff5 year={"2022"} />
          </Route>
          <Route path="/cutoff-computer-science-female-2022" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff6 year={"2022"} />
          </Route>
          <Route path="/cutoff-commerce-male-2022" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff7 year={"2022"} />
          </Route>
          <Route path="/cutoff-commerce-female-2022" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff8 year={"2022"} />
          </Route>
          <Route path="/cutoff-humanities-male-2022" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff9 year={"2022"} />
          </Route>
          <Route path="/cutoff-humanities-female-2022" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CutOff10 year={"2022"} />
          </Route>

          <Route path="/signin" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <SigninPage />
          </Route>
          <Route path="/apply-online" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <ApplyOnlinePage />
          </Route>
          <Route path="/o-level" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <OlevelPage />
          </Route>
          <Route path="/about-us" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <AboutUsPage />
          </Route>
          <Route path="/colleges" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <CollegesPage />
          </Route>
          <Route path="/new-application" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <NewApplication />
          </Route>
          <Route path="/claim-college" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <Claim />
          </Route>
          <Route path="/available-seats" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <SeatsAvailability />
          </Route>
          <Route path="/olevel-claim-college" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <OlevelClaim />
          </Route>
          <Route path="/o-level-new-application" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <OlevelNewApplication />
          </Route>
          <Route path="/o-level-edit-application" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <OlevelNewApplication />
          </Route>
          <Route path="/edit-application" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <NewApplication />
          </Route>
          <Route path="/claim" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <ClaimCollege />
          </Route>
          <Route path="/seccap-result" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <SeccapResult />
          </Route>
          <Route path="/status" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <Status />
          </Route>
          <Route path="/o-level-status" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <OlevelStatus />
          </Route>
          <Route path="/permission-letter/:applied_id" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <Preview />
          </Route>
          <Route path="/permission-letter-xii/:applied_id" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <PreviewXii />
          </Route>
          <Route path="/student-card/:applied_id" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <StudentCard />
          </Route>
          <Route path="/student-attendance/:applied_id" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <StudentAttendance />
          </Route>
          <Route path="/student-card" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <StudentCardForm />
          </Route>
          <Route path="/student-attendance" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <StudentAttendanceForm />
          </Route>
          <Route path="/sindh-college-games" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <SindhGames />
          </Route>
          <Route path="/sindh-college-talent-hunt" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <SindhTalent />
          </Route>

          <Route path="/complaint" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
            <Complaint />
          </Route>
          <Route path="/ad" exact>
            <Navbar toggle={toggle} pathname={location.pathname} />
          </Route>
          <PrivateRoute
            exact
            path="/dashboard"
            component={DashboardPage}
            auth={true}
          />
        </Switch>
      </>
    );
  }
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />

      <Switch>
        <Route path="/" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <Home />
        </Route>
        <Route path="/map" exact>
          <MapComponent />
        </Route>
        <Route path="/seef-signin" exact>
          <SeefNavbar toggle={toggle} pathname={location.pathname} />
          <SeefSignin />
        </Route>
        <Route path="/quiz-app" exact>
          <QuizApp />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/discussions" exact>
          <Discussions />
        </Route>
        <Route path="/discussions/profile/:role/:id" exact>
          <Profile />
        </Route>
        <Route path="/discussions/:category" exact>
          <Forum />
        </Route>
        <Route path="/discussions/:category/new" exact>
          <NewTopic />
        </Route>
        <Route path="/discussions/:category/:id" exact>
          <Post />
        </Route>

        <Route path="/test" exact>
          <TestCategories />
        </Route>
        {/* <Route path="/seef" exact >
          <SeefNavbar toggle={toggle} pathname={location.pathname} />
          <Seef />
        </Route> */}
        <Route path="/seef-total-applications" exact>
          <SeefNavbar toggle={toggle} pathname={location.pathname} />
          <TotalApplications />
        </Route>
        <Route path="/apply-online" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <ApplyOnlinePage />
        </Route>
        <Route path="/o-level" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <OlevelPage />
        </Route>
        <Route path="/cut-off" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff />
        </Route>
        <Route path="/cutoff-2024" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff2023 />
        </Route>
        <Route path="/students-guide" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <StudentsGuide />
        </Route>
        <Route path="/cutoff-pre-medical-male" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff1 />
        </Route>
        <Route path="/cutoff-pre-medical-female" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff2 />
        </Route>
        <Route path="/cutoff-pre-engineering-male" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff3 />
        </Route>
        <Route path="/cutoff-pre-engineering-female" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff4 />
        </Route>
        <Route path="/cutoff-computer-science-male" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff5 />
        </Route>
        <Route path="/cutoff-computer-science-female" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff6 />
        </Route>
        <Route path="/cutoff-commerce-male" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff7 />
        </Route>
        <Route path="/cutoff-commerce-female" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff8 />
        </Route>
        <Route path="/cutoff-humanities-male" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff9 />
        </Route>
        <Route path="/cutoff-humanities-female" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff10 />
        </Route>

        <Route path="/cutoff-home-economics" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff11 />
        </Route>

        <Route path="/cutoff-pre-medical-male-2022" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff1 year={"2022"} />
        </Route>
        <Route path="/cutoff-pre-medical-female-2022" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff2 year={"2022"} />
        </Route>
        <Route path="/cutoff-pre-engineering-male-2022" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff3 year={"2022"} />
        </Route>
        <Route path="/cutoff-pre-engineering-female-2022" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff4 year={"2022"} />
        </Route>
        <Route path="/cutoff-computer-science-male-2022" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff5 year={"2022"} />
        </Route>
        <Route path="/cutoff-computer-science-female-2022" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff6 year={"2022"} />
        </Route>
        <Route path="/cutoff-commerce-male-2022" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff7 year={"2022"} />
        </Route>
        <Route path="/cutoff-commerce-female-2022" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff8 year={"2022"} />
        </Route>
        <Route path="/cutoff-humanities-male-2022" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff9 year={"2022"} />
        </Route>
        <Route path="/cutoff-humanities-female-2022" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CutOff10 year={"2022"} />
        </Route>

        <Route path="/signin" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <SigninPage />
        </Route>

        <Route path="/about-us" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <AboutUsPage />
        </Route>
        <Route path="/colleges" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <CollegesPage />
        </Route>
        <Route path="/new-application" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <NewApplication />
        </Route>
        <Route path="/claim-college" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <Claim />
        </Route>
        <Route path="/available-seats" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <SeatsAvailability />
        </Route>
        <Route path="/olevel-claim-college" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <OlevelClaim />
        </Route>
        <Route path="/o-level-new-application" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <OlevelNewApplication />
        </Route>
        <Route path="/o-level-edit-application" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <OlevelNewApplication />
        </Route>
        <Route path="/edit-application" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <NewApplication />
        </Route>
        <Route path="/claim" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <ClaimCollege />
        </Route>
        <Route path="/seccap-result" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <SeccapResult />
        </Route>
        <Route path="/status" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <Status />
        </Route>
        <Route path="/o-level-status" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <OlevelStatus />
        </Route>
        <Route path="/permission-letter/:applied_id" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <Preview />
        </Route>
        <Route path="/permission-letter-xii/:applied_id" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <PreviewXii />
        </Route>
        <Route path="/student-card/:applied_id" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <StudentCard />
        </Route>
        <Route path="/student-attendance/:applied_id" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <StudentAttendance />
        </Route>
        <Route path="/student-card" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <StudentCardForm />
        </Route>
        <Route path="/student-attendance" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <StudentAttendanceForm />
        </Route>
        <Route path="/sindh-college-games" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <SindhGames />
        </Route>
        <Route path="/sindh-college-talent-hunt" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <SindhTalent />
        </Route>
        <Route path="/complaint" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
          <Complaint />
        </Route>
        <Route path="/ad" exact>
          <Navbar toggle={toggle} pathname={location.pathname} />
        </Route>
        <PrivateRoute
          exact
          path="/dashboard"
          component={DashboardPage}
          auth={true}
        />
      </Switch>
    </>
  );
}

export default App;
