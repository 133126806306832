import React, { useState, useEffect } from "react";
import { StatusContainer, StatusContent, StatusItem } from "./StatusElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Preview2 from "./components/preview/Preview2";
import Admission from "./components/admission/Admission";
import isEmpty, { isNumber } from "../../helpers/validation";
import CustomAlert from "../common/CustomAlert";
import SimpleBackdrop from "../common/Backdrop";
import * as ApiCalls from "../../services/ApiCalls";
import background from "./../../images/background.jpg";
import { isCNIC, isNumberOrDash } from "../../helpers/validation";
import { useHistory } from "react-router-dom";
import { passingYears } from "../../data/passingyears";
import { getUser } from "../../functions/functions";
import WhiteAd from "../Ad/WhiteAd";
import Ad from "../Ad";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  multilineColor: {
    color: "red",
  },
}));

const Status = () => {
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const user = getUser(localStorage?.getItem("jwtToken"));

  const [step, setStep] = useState(1);
  // Matric Profile
  const [ninthRollNo, setNinthRollNo] = useState("");
  const [matricRollNo, setMatricRollNo] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [ninthPassingYear, setNinthPassingYear] = useState("");
  const [vaccinated, setVaccinated] = useState("");
  const [passingYearClass5, setPassingYearClass5] = useState("");
  const [passingYearClass8, setPassingYearClass8] = useState("");
  const [province, setProvince] = useState("");
  const [board, setBoard] = useState("");
  const [studyGroup, setStudyGroup] = useState("");
  const [subject, setSubject] = useState("");
  const [ninthTotalMarks, setNinthTotalMarks] = useState("");
  const [ninthObtainedMarks, setNinthObtainedMarks] = useState("");
  const [ninthScaledObtainedMarks, setNinthScaledObtainedMarks] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [obtainedMarks, setObtainedMarks] = useState("");
  const [scaledObtainedMarks, setScaledObtainedMarks] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoolNameClass5, setSchoolNameClass5] = useState("");
  const [schoolNameClass8, setSchoolNameClass8] = useState("");
  const [districtClass5, setDistrictClass5] = useState("");
  const [districtClass8, setDistrictClass8] = useState("");
  const [imageMarksheet, setImageMarksheet] = useState();
  const [imageMarksheetName, setImageMarksheetName] = useState("");
  const [marksheetImage, setMarksheetImage] = useState("");
  const [showMarksheetImage, setShowMarksheetImage] = useState(
    require("../../images/doc.png").default
  );
  const [showProfileImage, setShowProfileImage] = useState(
    require("../../images/profile.png").default
  );
  // Personal Profile
  const [appliedId, setAppliedId] = useState("");
  const [appliedDate, setAppliedDate] = useState("");
  const [fullName, setFullName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [cnic, setCnic] = useState("");
  const [religion, setReligion] = useState("");
  const [fatherMobileNumber, setFatherMobileNumber] = useState("");
  const [fatherCnic, setFatherCnic] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [motherCnic, setMotherCnic] = useState("");
  const [domicileDistrict, setDomicileDistrict] = useState("");
  const [fatherDomicileDistrict, setFatherDomicileDistrict] = useState("");
  const [imageProfile, setImageProfile] = useState();
  const [imageProfileName, setImageProfileName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [addressRegion, setAddressRegion] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [addressTown, setAddressTown] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [approved, setApproved] = useState("");
  const [claimCount, setClaimCount] = useState();

  // Apply for college
  const [region, setRegion] = useState("");
  const [faculty, setFaculty] = useState("");
  const [selectedColleges, setSelectedColleges] = useState([]);

  const [collegeId, setCollegeId] = useState("");
  const [facultyId, setFacultyId] = useState("");
  const [admissionStatus, setAdmissionStatus] = useState("");
  const [qrCodePath, setQrCodePath] = useState("");
  const [zone, setZone] = useState("");

  const [availInDataBase, setAvailInDataBase] = useState(false);
  const [applied, setApplied] = useState(false);

  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    setOpenBackDrop(false);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "cnic") {
      if (user?.userRole === "admin" && value.length < 16) {
        setCnic(value);
      } else {
        if (isNumberOrDash(value) && value.length < 16) {
          setCnic(value);
        }
      }
    }

    if (name === "matricRollNo") {
      if ((isNumber(value) || value === "") && value.length < 11) {
        setMatricRollNo(value);
      }
    }

    if (name === "passingYear") {
      if ((isNumber(value) || value === "") && value.length < 5) {
        setPassingYear(value);
      }
    }
  };

  const handleClick = async () => {
    const showStatus = true;

    if (!showStatus) {
      setAlert(
        "Seccap result will be announced today for Karachi region!",
        "error"
      );
    } else {
      if (isEmpty(cnic)) {
        setAlert("Please fill out CNIC / B-FORM No!", "error");
      } else if (user?.userRole !== "admin" && !isCNIC(cnic)) {
        setAlert("Please fill out valid CNIC / B-FORM No!", "error");
      } else if (cnic.length !== 15) {
        setAlert(
          "CNIC / B-FORM No field contains only 15 characters!",
          "error"
        );
      } else if (isEmpty(matricRollNo)) {
        setAlert("Please fill out Maric Roll No!", "error");
      } else if (matricRollNo.length > 10) {
        setAlert(
          "Maric Roll No field cannot contain maximum than 10 numbers!",
          "error"
        );
      } else {
        setOpenBackDrop(true);

        const data = {
          matric_roll_no: matricRollNo,
          cnic,
        };

        const resApplied = await ApiCalls.isApplied(data);
        if (resApplied?.data?.status === "success") {
          if (resApplied?.data?.data?.length === 0) {
            setOpenBackDrop(false);
            setAlert("Enter Valid Cnic no Or Matric Roll No!", "error");
          } else if (resApplied?.data?.data) {
            setApplied(true);
            const data = resApplied?.data?.data;

            if (isEmpty(data.region_id)) {
              setAlert("Complete your Application!", "error");
              history.push("/edit-application");
            } else {
              setAppliedId(data?.id);
              setApproved(data?.approved);
              setFullName(data?.name);
              setFatherName(data?.father_name);
              setEmail(data?.email);
              setMobileNumber(data?.mobile);
              setGender(data?.gender_id);
              setNationality(data.nationality);
              setDateOfBirth(data.date_of_birth);
              setPlaceOfBirth(data.place_of_birth);
              setReligion(data.religion_id);
              setCnic(data?.cnic);
              setFatherMobileNumber(data?.father_mobile);
              setFatherCnic(data?.father_cnic);
              setMotherCnic(data?.mother_cnic);
              setNinthPassingYear(data?.ninth_passing_year);
              setPassingYear(data?.matric_passing_year);
              setProvince(data?.province_id);
              setBoard(data?.board_id);
              setStudyGroup(data?.group_id);
              data?.sub_group_id && setSubject(data?.sub_group_id);
              setNinthRollNo(data?.ninth_roll_no);
              setNinthTotalMarks(data?.ninth_total_marks);
              setNinthObtainedMarks(data?.ninth_obtained_marks);
              setNinthScaledObtainedMarks(data?.ninth_scaled_obtained_marks);
              setTotalMarks(data?.total_marks);
              setObtainedMarks(data?.obtained_marks);
              setScaledObtainedMarks(data?.scaled_obtained_marks);
              setSchoolName(data?.school_name);
              setPassingYearClass8(data.class8_passing_year);
              setDistrictClass8(data.class8_passing_district_id);
              setSchoolNameClass8(data.class8_school_name);
              setPassingYearClass5(data.class5_passing_year);
              setDistrictClass5(data.class5_passing_district_id);
              setSchoolNameClass5(data.class5_school_name);
              setDomicileDistrict(data.domicile_district_id);
              setFatherDomicileDistrict(data.father_domicile_district_id);
              setFatherOccupation(data.father_occupation);
              setAddressRegion(data.address_region_id);
              data?.address_district_id &&
                setAddressDistrict(data?.address_district_id);
              setHomeAddress(data?.home_address);
              data?.marksheet_image && setMarksheetImage(data.marksheet_image);
              setRegion(data.region_id);
              setQrCodePath(data?.qr_code_path);
              setZone(data?.zone_id);
              setAppliedDate(data?.created_at);

              const resAdmissions = await ApiCalls.isInAdmissions(data);

              if (resAdmissions?.data?.data?.length === 0) {
                setStep(2);
              } else if (resAdmissions?.data?.data) {
                setCollegeId(resAdmissions?.data?.data.college_id);
                setFacultyId(resAdmissions?.data?.data.faculty_id);
                setAdmissionStatus(resAdmissions?.data?.data.status_id);
                setClaimCount(resAdmissions?.data?.data.claim_count);
                if (parseInt(resAdmissions?.data?.data.class_id) === 1) {
                  setStep(2);
                } else {
                  setAlert("Something went wrong, Please try again!", "error");
                }
              } else {
                setStep(2);
              }
            }
          } else {
            setOpenBackDrop(false);
            setAlert("Something went wrong, Please try again!", "error");
          }
        } else if (resApplied?.data?.error?.message) {
          setOpenBackDrop(false);
          setAlert(resApplied?.data?.error?.message, "error");
        } else {
          setOpenBackDrop(false);
          setAlert("Something went wrong, Please try again!", "error");
        }
      }
    }
  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />
      {step === 1 && (
        <div>
          <StatusContainer style={{ backgroundImage: `url(${background})` }}>
            <StatusContent>
              <StatusItem>
                <div style={{ marginBottom: "30px" }}>
                  <h1>Check Status</h1>
                </div>
                <div style={{ marginBottom: "2%" }}>
                  <TextField
                    fullWidth
                    id="outlined-mycnic"
                    placeholder="12345-1234567-1"
                    label="CNIC / B-FORM No"
                    type="text"
                    variant="outlined"
                    name="cnic"
                    value={cnic}
                    onChange={handleChange}
                  />
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <TextField
                    fullWidth
                    id="outlined-college"
                    label="Enter Your Matric Roll No"
                    name="matricRollNo"
                    value={matricRollNo}
                    onChange={handleChange}
                    type="text"
                    variant="outlined"
                  />
                </div>
                <div style={{ marginTop: "30px", marginLeft: "8px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    Check
                  </Button>
                </div>
              </StatusItem>
              <WhiteAd />
            </StatusContent>
          </StatusContainer>
          <Ad />
        </div>
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      )}

      {step === 2 && (
        <Preview2
          province={province}
          board={board}
          studyGroup={studyGroup}
          subject={subject}
          ninthTotalMarks={ninthTotalMarks}
          ninthObtainedMarks={ninthObtainedMarks}
          ninthScaledObtainedMarks={ninthScaledObtainedMarks}
          totalMarks={totalMarks}
          obtainedMarks={obtainedMarks}
          scaledObtainedMarks={scaledObtainedMarks}
          schoolName={schoolName}
          imageMarksheet={imageMarksheet}
          imageMarksheetName={imageMarksheetName}
          appliedId={appliedId}
          appliedDate={appliedDate}
          approved={approved}
          fullName={fullName}
          fatherName={fatherName}
          email={email}
          mobileNumber={mobileNumber}
          gender={gender}
          nationality={nationality}
          dateOfBirth={dateOfBirth}
          placeOfBirth={placeOfBirth}
          religion={religion}
          fatherOccupation={fatherOccupation}
          cnic={cnic}
          facultyId={facultyId}
          fatherMobileNumber={fatherMobileNumber}
          fatherCnic={fatherCnic}
          motherCnic={motherCnic}
          domicileDistrict={domicileDistrict}
          fatherDomicileDistrict={fatherDomicileDistrict}
          imageProfile={imageProfile}
          imageProfileName={imageProfileName}
          profileImage={profileImage}
          showProfileImage={showProfileImage}
          addressRegion={addressRegion}
          addressDistrict={addressDistrict}
          homeAddress={homeAddress}
          ninthRollNo={ninthRollNo}
          matricRollNo={matricRollNo}
          passingYear={passingYear}
          ninthPassingYear={ninthPassingYear}
          passingYearClass5={passingYearClass5}
          passingYearClass8={passingYearClass8}
          schoolNameClass5={schoolNameClass5}
          schoolNameClass8={schoolNameClass8}
          districtClass5={districtClass5}
          districtClass8={districtClass8}
          region={region}
          faculty={faculty}
          selectedColleges={selectedColleges}
          setOpenBackDrop={setOpenBackDrop}
          availInDataBase={availInDataBase}
          setStep={setStep}
          collegeId={collegeId}
          admissionStatus={admissionStatus}
          claimCount={claimCount}
          qrCodePath={qrCodePath}
          zone={zone}
        />
      )}

      {step === 3 && (
        <Admission
          collegeId={collegeId}
          facultyId={facultyId}
          admissionStatus={admissionStatus}
          setOpenBackDrop={setOpenBackDrop}
        />
      )}

      <CustomAlert
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Status;
