import React, { useState, useEffect } from "react";
import { NewApplicationContainer, NewApplicationContent, NewApplicationItem, NewApplicationHeading } from './NewApplicationElements';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import isEmpty, { isNumber } from "../../helpers/validation";
import CustomAlert from "../common/CustomAlert";
import SimpleBackdrop from "../common/Backdrop";
import * as ApiCalls from '../../services/ApiCalls';
import { useLocation } from "react-router";
import background from "./../../images/background.jpg";


import { isCNIC, isNumberOrDash } from "../../helpers/validation";
import { useHistory } from 'react-router-dom';
import { passingYears } from '../../data/passingyears';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  input: {
    display: 'none',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  multilineColor: {
    color: 'red'
  },
}));



const Complaint = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');

  const [step, setStep] = useState(1);
  // Personal Profile
  const [appliedId, setAppliedId] = useState('');
  const [reviewedBy, setReviewBy] = useState('');
  const [fullName, setFullName] = useState('');
  const [college, setCollege] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [comment, setComment] = useState('');
  const [rating, setRating] = useState(0);
  const [matricRollNo, setMatricRollNo] = useState('');
  const [cnic, setCnic] = useState('');
  const [passingYear, setPassingYear] = useState('');
  const [olevelStudent, setOlevelStudent] = useState('No');
  const [showImage, setShowImage] = useState('');
  const [image, setImage] = useState('');
  const [imageName, setImageName] = useState('');
  const [error, setError] = useState(false);



  const [availInDataBase, setAvailInDataBase] = useState(false);
  const [applied, setApplied] = useState(false);

  useEffect(async () => {
    window.scrollTo(0, 0);
    await setOpenBackDrop(false);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const handleImage = (e) => {
    const img = e.target.files[0];
    setShowImage(URL.createObjectURL(img));
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);

  }

  const handleChange = async (event) => {
    const { name, value } = event.target;

    if (name === 'olevelStudent') {
      await setOlevelStudent(value);
      await setAppliedId('');
      await setCnic('');
      await setMatricRollNo('');
      await setPassingYear('');
      await setFullName('');
      await setContactNo('');
      await setComment('');
      await setImage('');
      await setImageName('');
      await setShowImage('');
    }

    if (name === 'cnic') {
      if (isNumberOrDash(value) && value.length < 16) {
        setCnic(value);
      }
    }

    if (name === 'matricRollNo') {
      if ((isNumber(value) || value === '') && value.length < 11) {
        setMatricRollNo(value);
      }
    }

    if (name === 'passingYear') {

      if ((isNumber(value) || value === '') && value.length < 5) {
        setPassingYear(value);
      }

    }

    if (name === 'reviewBy') {
      await setReviewBy(value);
      await setAppliedId('');
      await setCnic('');
      await setMatricRollNo('');
      await setPassingYear('');
      await setFullName('');
      await setContactNo('');
      await setComment('');
      await setImage('');
      await setImageName('');
      await setShowImage('');
      await setOlevelStudent('No');
    } else if (name === 'name' && value.length < 101) {
      setFullName(value);
    } else if (name === 'contact' && ((isNumber(value) && value.length < 12) || value === '')) {
      setContactNo(value);
    } else if (name === 'comment') {
      if (value?.length < 1001) {
        setComment(value);
      }
    }
  };

  const handleClick = async () => {

    let applied_id = '';

    if (isEmpty(reviewedBy.trim())) {
      setAlert("Please select Complaint By!", 'error');
    } else if (reviewedBy.trim() === '1' && isEmpty(cnic.trim())) {
      setAlert("Please fill out CNIC / B-FORM No!", 'error');
    } else if (reviewedBy.trim() === '1' && !isCNIC(cnic.trim())) {
      setAlert("Please fill out valid CNIC / B-FORM No!", 'error');
    } else if (reviewedBy.trim() === '1' && cnic.length !== 15) {
      setAlert("CNIC / B-FORM No field contains only 15 characters!", 'error');
    } else if (reviewedBy.trim() === '1' && olevelStudent === 'No' && isEmpty(matricRollNo)) {
      setAlert('Please fill out Maric Roll No!', 'error');
    } else if (reviewedBy.trim() === '1' && olevelStudent === 'No' && matricRollNo.length > 10) {
      setAlert("Maric Roll No field cannot contain maximum than 10 numbers!", 'error');
    } else if (reviewedBy.trim() === '1' && olevelStudent === 'Yes' && isEmpty(passingYear)) {
      setAlert('Please fill out Passing Year!', 'error');
    } else if (reviewedBy.trim() === '1' && olevelStudent === 'Yes' && passingYear.length > 4) {
      setAlert("Passing Year field cannot contain maximum than 4 numbers!", 'error');
    } else if ((reviewedBy.trim() === '2' || reviewedBy.trim() === '3') && isEmpty(fullName.trim())) {
      setAlert("Please Enter Your Name!", 'error');
    } else if ((reviewedBy.trim() === '2' || reviewedBy.trim() === '3') && isEmpty(contactNo.trim())) {
      setAlert("Please Enter Your Contact No!", 'error');
    } else if ((reviewedBy.trim() === '2' || reviewedBy.trim() === '3') && contactNo.trim().length < 10) {
      setAlert("Please Enter Valid Contact No!", 'error');
    } else if (isEmpty(comment.trim())) {
      setAlert("Please Enter Complaint!", 'error');
    } else if (comment.trim().length < 20) {
      setAlert("Complaint Field contains atleast 20 Characters!", 'error');
    } else {

      setOpenBackDrop(true);

      if (reviewedBy === '1' && olevelStudent === 'No') {
        const data = {
          matric_roll_no: matricRollNo,
          cnic,
        };

        const resApplied = await ApiCalls.isApplied(data);
        if (resApplied?.data?.status === 'success') {

          if (resApplied?.data?.data?.length === 0) {

            await setOpenBackDrop(false);
            setAlert('Enter Valid Cnic or Matric roll no!', 'error');
            await setError(true);

          } else {
            const dataApplied = resApplied?.data?.data;
            applied_id = dataApplied?.id;
            await setAppliedId(dataApplied?.id);

          }

        } else if (resApplied?.data?.error?.message) {
          await setOpenBackDrop(false);
          setAlert(resApplied?.data?.error?.message, 'error');
          await setError(true);
        } else {
          await setOpenBackDrop(false);
          setAlert('Something went wrong, Please try again!', 'error');
          await setError(true);
        }
      }

      if (reviewedBy === '1' && olevelStudent === 'Yes') {
        const data = {
          passing_year: passingYear,
          cnic,
        };

        const resApplied = await ApiCalls.seccapAppliedOlevel(data);
        if (resApplied?.data?.status === 'success') {

          if (resApplied?.data?.data?.length === 0) {

            await setOpenBackDrop(false);
            setAlert('Enter Valid Cnic or Passing Year!', 'error');
            await setError(true);

          } else {
            const dataApplied = resApplied?.data?.data;
            applied_id = dataApplied?.id;
            await setAppliedId(dataApplied?.id);

          }

        } else if (resApplied?.data?.error?.message) {
          await setOpenBackDrop(false);
          setAlert(resApplied?.data?.error?.message, 'error');
          await setError(true);
        } else {
          await setOpenBackDrop(false);
          setAlert('Something went wrong, Please try again!', 'error');
          await setError(true);
        }
      }

      if (error === false) {

        let image_name;

        if (imageName) {
          image_name = `image-${new Date().getTime()}-${reviewedBy}.png`;
          let formData = null;
          formData = new FormData();
          formData.append("image_url", image, image_name);

          await ApiCalls.saveImageComplaint(formData);
        }

        const data = {
          applied_id,
          complaint_by_id: reviewedBy,
          name: fullName,
          contact_no: contactNo,
          complaint: comment,
          image_name,
        };

        const resApplied = await ApiCalls.addComplaint(data);
        if (resApplied?.data?.status === 'success') {

          await setOpenBackDrop(false);

          await setReviewBy('');
          await setAppliedId('');
          await setCnic('');
          await setMatricRollNo('');
          await setPassingYear('');
          await setFullName('');
          await setContactNo('');
          await setComment('');
          await setImage('');
          await setImageName('');
          await setShowImage('');
          await setOlevelStudent('No');

          setAlert('Your Complaint has been Submitted Successfully!', 'success');

        } else if (resApplied?.data?.error?.message) {
          await setOpenBackDrop(false);
          setAlert(resApplied?.data?.error?.message, 'error');
        } else {
          await setOpenBackDrop(false);
          setAlert('Something went wrong, Please try again!', 'error');
        }

      }

    }

  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />

      {step === 1 && (
        <div>
          <NewApplicationContainer style={{ backgroundImage: `url(${background})` }}>
            <NewApplicationContent>
              <NewApplicationItem>
                <div style={{ marginBottom: '5%' }}>
                  <NewApplicationHeading>
                    Complaint Form
                  </NewApplicationHeading>
                </div>

                <div style={{ marginBottom: '5%' }}>
                  <FormControl fullWidth variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-reviewBy">Complaint By</InputLabel>
                    <Select
                      native
                      value={reviewedBy}
                      onChange={handleChange}
                      label="Complaint By"
                      name="reviewBy"
                    >
                      <option aria-label="None" value="" />
                      <option value="1">Student</option>
                      <option value="2">Parent</option>
                      <option value="3">Public</option>
                    </Select>
                  </FormControl>
                </div>

                {reviewedBy === '1' && (
                  <div style={{ marginBottom: '5%' }}>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                      <InputLabel htmlFor="outlined-olevelStudent">Olevel Student</InputLabel>
                      <Select
                        native
                        value={olevelStudent}
                        onChange={handleChange}
                        label="Olevel Student"
                        name="olevelStudent"
                      >
                        <option aria-label="None" value="" />
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Select>
                    </FormControl>
                  </div>
                )}

                {reviewedBy === '1' && (
                  <div style={{ marginBottom: '5%' }}>
                    <TextField fullWidth id="outlined-mycnic" placeholder="12345-1234567-1" label="CNIC / B-FORM No" type="text" variant="outlined" name="cnic" value={cnic} onChange={handleChange} />
                  </div>
                )}

                {reviewedBy === '1' && olevelStudent === 'No' && (
                  <div style={{ marginBottom: '5%' }}>
                    <TextField fullWidth id="outlined-matric-roll-no" label="Enter Your Matric Roll No" name="matricRollNo" value={matricRollNo} onChange={handleChange} type="text" variant="outlined" />
                  </div>
                )}

                {reviewedBy === '1' && olevelStudent === 'Yes' && (
                  <div style={{ marginBottom: '5%' }}>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                      <InputLabel htmlFor="outlined-passingYear">Passing Year</InputLabel>
                      <Select
                        native
                        value={passingYear}
                        onChange={handleChange}
                        label="Passing Year"
                        name="passingYear"
                      >
                        <option aria-label="None" value="" />
                        {passingYears.map(item => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}

                {(reviewedBy === '2' || reviewedBy === '3') && (
                  <>
                    <div style={{ marginBottom: '5%' }}>
                      <TextField fullWidth id="outlined-name" placeholder="" label="Enter Your Name" type="text" variant="outlined" name="name" value={fullName} onChange={handleChange} />
                    </div>

                    <div style={{ marginBottom: '5%' }}>
                      <TextField fullWidth id="outlined-contact" placeholder="" label="Enter Your Contact No" type="text" variant="outlined" name="contact" value={contactNo} onChange={handleChange} />
                    </div>
                  </>
                )}

                <div style={{ marginBottom: '5%' }}>
                  <TextareaAutosize minRows={4} style={{ width: '100%', padding: '18.5px 14px', borderRadius: '4px', border: '1px solid #c2c2c2' }} id="outlined-comment" placeholder="Enter Your Complaint Details Here..." type="text" variant="outlined" name="comment" value={comment} onChange={handleChange} />
                  <p>{1000 - comment.length}</p>
                </div>

                <div>
                  <div style={{ marginTop: '5%', marginLeft: '0px' }}>
                    <div className={classes.root}>
                      <p style={{ color: 'gray', fontSize: '14px', marginBottom: '5px' }}>{"(Optional)"}</p>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        type="file"
                        onChange={handleImage}
                      />
                      <label htmlFor="contained-button-file">
                        <Button variant="contained" color="primary" component="span">
                          <PhotoCamera /> Upload Image
                        </Button>
                      </label>
                      <input accept="image/*" className={classes.input} onChange={handleImage} id="icon-button-file" type="file" />
                    </div>
                  </div>
                  {showImage && (
                    <div style={{ marginTop: '5%' }}>
                      <img style={{ width: '20vw', height: '100%' }} src={showImage} alt="Image" />
                    </div>
                  )}

                </div>

                <div style={{ marginTop: '10%' }}>
                  <Button variant="contained" color="primary" onClick={handleClick}>
                    Submit
                  </Button>
                </div>
                
              </NewApplicationItem>
            </NewApplicationContent>
          </NewApplicationContainer>
          
        </div>
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      )}

      <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />

    </div>

  );
};

export default Complaint;
