import React, { useState, useEffect } from "react";
import {
  PreviewContainer,
  PreviewContent,
  PreviewHeading1,
  PreviewHeading4,
  PreviewParagraph,
  ButtonContent,
} from "./PreviewElements";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Button } from "../ButtonElements";
import jsPDF from "jspdf";
import * as ApiCalls from "../../services/ApiCalls";
import background from "./../../images/background.jpg";
import GetAppIcon from "@material-ui/icons/GetApp";
import html2canvas from "html2canvas";
import ProvincesData from "../../data/provinces2.json";
import ReligionsData from "../../data/religions.json";
import RegionsData from "../../data/regions.json";
import DistrictsData from "../../data/districts.json";
import BoardsData from "../../data/boards.json";
import StudygroupsData from "../../data/studygroups.json";
import SciencegroupsData from "../../data/sciencegroups.json";
import { Link, useParams } from "react-router-dom";
import getScaledObtainedMarks from "../../functions/getScaledObtainedMarks";

const Preview = (props) => {
  const params = useParams();
  const applied_id = params?.applied_id;

  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };

  const [reason, setReason] = useState("");
  const [colleges, setColleges] = useState([]);
  const [college, setCollege] = useState();

  const [permissionData, setPermissionData] = useState([]);

  const [province, setProvince] = useState("");
  const [addressRegion, setAddressRegion] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [districtClass8, setDistrictClass8] = useState("");
  const [districtClass5, setDistrictClass5] = useState("");
  const [domicileDistrict, setDomicileDistrict] = useState("");
  const [fatherDomicileDistrict, setFatherDomicileDistrict] = useState("");
  const [board, setBoard] = useState("");
  const [studyGroup, setStudyGroup] = useState("");
  const [subject, setSubject] = useState("");
  const [region, setRegion] = useState("");
  const [faculty, setFaculty] = useState("");
  const [religion, setReligion] = useState("");

  const scaled_obtained_marks = getScaledObtainedMarks(
    props.totalMarks,
    props.obtainedMarks,
    props.passingYear
  );

  const scaled_total_marks = 850;

  useEffect(async () => {
    window.scrollTo(0, 0);
    getPermissionDataByAppliedId(applied_id, 1);
    getSeccapAppliedCollegesByAppliedId(props?.appliedId);
    await getProvinceByProvinceId(props.province);
    await getAddressRegionByRegionId(props.addressRegion);
    props.collegeId && (await getCollegeByCollegeId(props.collegeId));
    props.addressDistrict &&
      (await getAddressDistrictByDistrictId(props.addressDistrict));
    await getDomicileDistrictByDistrictId(props.domicileDistrict);
    await getFatherDomicileDistrictByDistrictId(props.fatherDomicileDistrict);
    await getDistrictClass8ByDistrictId(props.districtClass8);
    await getDistrictClass5ByDistrictId(props.districtClass5);
    await getBoardByBoardId(props.board);
    await getStudyGroupByGroupId(props.studyGroup);
    props.subject && (await getSubGroupBySubGroupId(props.subject));
    await getRegionByRegionId(props.region);
    await getReligionByReligionId(props.religion);
    if (props.facultyId == 1) {
      setFaculty("PRE-MEDICAL");
    } else if (props.facultyId == 2) {
      setFaculty("PRE-ENGINEERING");
    } else if (props.facultyId == 3) {
      setFaculty("COMPUTER SCIENCE");
    } else if (props.facultyId == 4) {
      setFaculty("COMMERCE");
    } else if (props.facultyId == 5) {
      setFaculty("HUMANITIES");
    } else if (props.facultyId == 6) {
      setFaculty("HOME ECONOMICS");
    } else {
      await getFacultyByAppliedId(props.appliedId);
    }

    // await getCollegesByAppliedId(props.appliedId);
    if (props.admissionStatus == 2) {
      await getCancelledAdmissionByAppliedId(props.appliedId);
    } else {
      if (props.approved == 2) {
        await getCancelledByAppliedId(props.appliedId);
      }
    }

    // await props.setOpenBackDrop(false);
  }, []);

  function generatePDF() {
    let printBtn = document.querySelector("#print-button");
    printBtn.style.display = "none";
    const input = document.getElementById("application");
    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF("p", "mm");
      var position = 0;

      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save("permissionletter.pdf");
    });
    printBtn.style.display = "flex";
  }

  const getPermissionDataByAppliedId = async (applied_id, class_id) => {
    const res = await ApiCalls.getPermissionDataByAppliedId(
      applied_id,
      class_id
    );
    console.log(res?.data?.data[0]);
    if (res?.data?.status === "success") {
      setPermissionData(res?.data?.data[0]);
      // setCollege(res?.data?.data.college_name);
    }
  };

  const getCollegeByCollegeId = async (college_id) => {
    const res = await ApiCalls.getCollegeByCollegeId(college_id);
    if (res?.data?.status === "success") {
      setCollege(res?.data?.data.college_name);
    }
  };

  const getSeccapAppliedCollegesByAppliedId = async (applied_id) => {
    const res = await ApiCalls.getSeccapAppliedCollegesByAppliedId(applied_id);
    if (res?.data?.status === "success") {
      setColleges(res?.data?.data);
    }
  };

  // const getCollegesByAppliedId = async (applied_id) => {
  //   const res = await ApiCalls.getCollegesByAppliedId(applied_id);
  //   if (res?.data?.status === 'success') {
  //     setColleges(res?.data?.data);
  //   }
  // }

  const getProvinceByProvinceId = (province_id) => {
    ProvincesData.forEach((item) => {
      if (item.id == province_id) {
        setProvince(item?.province);
      }
    });
    // const res = await ApiCalls.getProvinceByProvinceId(province_id);
    // if (res?.data?.status === 'success') {
    //   setProvince(res?.data?.data.province);
    // }
  };

  const getReligionByReligionId = async (religion_id) => {
    ReligionsData.forEach((item) => {
      if (item.id == religion_id) {
        setReligion(item?.religion);
      }
    });
  };

  const getAddressRegionByRegionId = async (region_id) => {
    RegionsData.forEach((item) => {
      if (item.id == region_id) {
        setAddressRegion(item?.region);
      }
    });
    // const res = await ApiCalls.getRegionByRegionId(region_id);
    // if (res?.data?.status === 'success') {
    //   setAddressRegion(res?.data?.data.region);
    // }
  };

  const getAddressDistrictByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setAddressDistrict(item?.district);
      }
    });
    // const res = await ApiCalls.getDistrictByDistrictId(district_id);
    // if (res?.data?.status === 'success') {
    //   setAddressDistrict(res?.data?.data?.district);
    // }
  };

  const getDistrictClass8ByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setDistrictClass8(item?.district);
      }
    });
    // const res = await ApiCalls.getDistrictByDistrictId(district_id);
    // if (res?.data?.status === 'success') {
    //   setDistrictClass8(res?.data?.data?.district);
    // }
  };

  const getDistrictClass5ByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setDistrictClass5(item?.district);
      }
    });
    // const res = await ApiCalls.getDistrictByDistrictId(district_id);
    // if (res?.data?.status === 'success') {
    //   setDistrictClass5(res?.data?.data?.district);
    // }
  };

  const getDomicileDistrictByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setDomicileDistrict(item?.district);
      }
    });
    // const res = await ApiCalls.getDistrictByDistrictId(district_id);
    // if (res?.data?.status === 'success') {
    //   setDomicileDistrict(res?.data?.data?.district);
    // }
  };

  const getFatherDomicileDistrictByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setFatherDomicileDistrict(item?.district);
      }
    });
    // const res = await ApiCalls.getDistrictByDistrictId(district_id);
    // if (res?.data?.status === 'success') {
    //   setFatherDomicileDistrict(res?.data?.data?.district);
    // }
  };

  const getBoardByBoardId = async (board_id) => {
    BoardsData.forEach((item) => {
      if (item.id == board_id) {
        setBoard(item?.board);
      }
    });
    // const res = await ApiCalls.getBoardByBoardId(board_id);
    // if (res?.data?.status === 'success') {
    //   setBoard(res?.data?.data.board);
    // }
  };

  const getStudyGroupByGroupId = async (group_id) => {
    StudygroupsData.forEach((item) => {
      if (item.id == group_id) {
        setStudyGroup(item?.group_name);
      }
    });
    // const res = await ApiCalls.getStudyGroupByGroupId(group_id);
    // if (res?.data?.status === 'success') {
    //   setStudyGroup(res?.data?.data.group_name);
    // }
  };

  const getSubGroupBySubGroupId = async (sub_group_id) => {
    SciencegroupsData.forEach((item) => {
      if (item.id == sub_group_id) {
        setSubject(item?.science);
      }
    });
    // const res = await ApiCalls.getSubGroupBySubGroupId(sub_group_id);
    // if (res?.data?.status === 'success') {
    //   setSubject(res?.data?.data.science);
    // }
  };

  const getRegionByRegionId = async (region_id) => {
    RegionsData.forEach((item) => {
      if (item.id == region_id) {
        setRegion(item?.region);
      }
    });
    // const res = await ApiCalls.getRegionByRegionId(region_id);
    // if (res?.data?.status === 'success') {
    //   setRegion(res?.data?.data.region);
    // }
  };

  const getFacultyByAppliedId = async (applied_id) => {
    const res = await ApiCalls.getSeccapFacultyByAppliedId(applied_id);
    if (res?.data?.status === "success") {
      setFaculty(res?.data?.data.faculty);
    }
  };

  const getCancelledByAppliedId = async (applied_id) => {
    const res = await ApiCalls.getSeccapAppliedCancelledByAppliedId(applied_id);
    if (res?.data?.status === "success") {
      setReason(res?.data?.data.reason);
    }
  };

  const getCancelledAdmissionByAppliedId = async (applied_id) => {
    const res = await ApiCalls.getSeccapCancelledAdmissionByAppliedId(
      applied_id
    );
    if (res?.data?.status === "success") {
      setReason(res?.data?.data.reason);
    }
  };

  return (
    <div>
      <PreviewContainer style={{ backgroundImage: `url(${background})` }}>
        <PreviewContent id="application">
          <div>
            <div
              id="print-button"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <div style={{ width: "50px", marginBottom: "1%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: "4px", borderRadius: "4px" }}
                  onClick={generatePDF}
                >
                  <GetAppIcon />
                </Button>
              </div>
            </div>

            {props.admissionStatus || true ? (
              <Grid container spacing={2} style={{ marginBottom: "2rem" }}>
                <Grid item md={12}>
                  {/* <div style={{ borderRadius: '5px', padding: '1.5rem 0', display: 'flex', alignItems: 'center', justifyContent: 'center', background: `${(props.admissionStatus == 0 || props.admissionStatus == 1) && props?.claimCount > 0 ? "#0000ff24" : props.admissionStatus == 0 || props.admissionStatus == 1 ? "#00800038" : props.admissionStatus == 2 ? "#0000ff14" : "#ff000014"}` }}>
                    {props.admissionStatus == 0 ? (
                      <div style={{ textAlign: 'center', padding: '12px' }}>
                        {props?.claimCount > 0 && (
                          <p style={{ fontWeight: 'bold', color: 'gray', marginBottom: '0.5rem' }}>Claim Form #{props?.claimCount}</p>
                        )}
                        <p style={{ fontWeight: 'bold', color: 'green', marginBottom: '0.5rem' }}>
                          {props.fullName} has been selected in college</p>
                        <p style={{ fontWeight: 'bold', color: 'gray', marginBottom: '0.5rem' }}>{college}</p>
                        <p style={{ fontWeight: 'bold', color: 'gray', marginBottom: '0.5rem' }}>in {faculty}</p>
                        <p style={{ fontWeight: 'bold', color: 'gray', marginBottom: '0.5rem' }}>for Provisional Admission</p>
                        <p style={{ fontSize: '14px', color: 'gray' }}>Please report at college along with your ninth or matric documents to confirm your Admission.</p>
                        <p style={{ color: 'grey', textAlign: 'start', marginTop: '15px' }}>Required Documents:</p>
                        <p style={{ color: 'grey', textAlign: 'start', marginTop: '10px' }}>1) SSC Certificate or Ninth Marksheet for admission at any level of studies</p>
                        <p style={{ color: 'grey', textAlign: 'start', marginTop: '5px' }}>2) Detail Marks / Provisional Certificate of the last exam passed</p>
                        <p style={{ color: 'grey', textAlign: 'start', marginTop: '5px' }}>3) Character Certificate issued by the learning institution last attended. Where the candidate has passed the last examination in private capacity such certificate shall be signed by a gazetted officer</p>
                        <p style={{ color: 'grey', textAlign: 'start', marginTop: '5px' }}>4) Domicile Certificate</p>
                        <p style={{ color: 'grey', textAlign: 'start', marginTop: '5px' }}>5) Self / father CNIC /B-Form</p>
                        <p style={{ color: 'grey', textAlign: 'start', marginTop: '5px' }}>6) Required number of colored photographs as per college policy.</p>
                        <p style={{ color: 'grey', textAlign: 'start', marginTop: '5px' }}>7) Covid-19 Vaccination Card.</p>
                      </div>
                    ) : props.admissionStatus == 1 ? (
                      <div style={{ textAlign: 'center' }}>
                        <p style={{ fontWeight: 'bold', color: 'green', marginBottom: '0.5rem' }}>Your Provisional admission is confirmed by concerned principal at college</p>
                        <p style={{ fontWeight: 'bold', color: 'gray', marginBottom: '0.5rem' }}>{college}</p>
                        <p style={{ fontWeight: 'bold', color: 'gray', marginBottom: '0.5rem' }}>in {faculty}</p>
                      </div>
                    ) : (
                      <div style={{ textAlign: 'center' }}>
                        <p style={{ fontWeight: 'bold', color: 'red', marginBottom: '0.5rem' }}>YOUR ADMISSION HAS BEEN CANCELLED, TRY AGAIN</p>
                        <p style={{ fontSize: '14px', color: 'gray' }}>{reason}</p>
                      </div>
                    )}
                  </div> */}
                  {props.admissionStatus == 2 && (
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        to="/edit-application"
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary="true"
                        dark="true"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        style={{ width: "12rem" }}
                      >
                        Re Apply
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} style={{ marginBottom: "2rem" }}>
                <Grid item md={12}>
                  <div
                    style={{
                      borderRadius: "5px",
                      padding: "1.5rem 0",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: `${
                        props.approved == 1
                          ? "#00800038"
                          : props.approved == 0
                          ? "#0000ff14"
                          : "#ff000014"
                      }`,
                    }}
                  >
                    {props.approved == 1 ? (
                      <div style={{ textAlign: "center" }}>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "green",
                            marginBottom: "0.5rem",
                          }}
                        >
                          YOUR APPLICATION HAS BEEN APPROVED
                        </p>
                        {/* <p style={{ fontSize: '14px', color: 'gray' }}>Please complete your application.</p><Link to="/edit-application">Edit Application</Link> */}
                        <p style={{ fontSize: "14px", color: "gray" }}>
                          Your Admission will be Announced soon in one of your
                          concerned Colleges, Please Keep Checking.
                        </p>
                      </div>
                    ) : props.approved == 0 ? (
                      <div style={{ textAlign: "center" }}>
                        <p style={{ fontWeight: "bold", color: "blue" }}>
                          WAITING FOR APPROVAL
                        </p>
                        {/* <p style={{ fontSize: '14px', color: 'gray' }}>Please complete your application.</p><Link to="/edit-application">Edit Application</Link> */}
                        {/* <p style={{ fontSize: '14px', color: 'gray'}}>It takes a day to review your application, Please Keep Checking.</p> */}
                      </div>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <p
                          style={{
                            fontWeight: "bold",
                            color: "red",
                            marginBottom: "0.5rem",
                          }}
                        >
                          YOUR APPLICATION HAS BEEN CANCELLED, TRY AGAIN
                        </p>
                        <p style={{ fontSize: "14px", color: "gray" }}>
                          {reason}
                        </p>
                      </div>
                    )}
                  </div>
                  {props.approved == 2 && (
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        to="/edit-application"
                        onMouseEnter={onHover}
                        onMouseLeave={onHover}
                        primary="true"
                        dark="true"
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact="true"
                        offset={-80}
                        style={{ width: "12rem" }}
                      >
                        Re Apply
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            <div
              id="app-header"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "7%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "10px" }}
                  src={"./images/logo.png"}
                  alt="logo"
                />{" "}
                <PreviewHeading1 style={{ color: "#137a4c" }}>
                  SECCAP 2024-25
                </PreviewHeading1>
              </div>

              <div>
                {/* <img style={{ width: '100px', height: '100px', border: "1px solid #aaa", borderRadius: '5px' }} src="/images/profile.png" alt="profile" /> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PreviewHeading1 style={{ marginBottom: "5%", marginTop: "5%" }}>
                PERMISSION LETTER FOR ATTENDING THE CLASSES
              </PreviewHeading1>
            </div>
            <Grid
              className="my-row"
              container
              spacing={1}
              style={{ marginBottom: "20%" }}
            >
              <Grid className="my-item" item xs={12}>
                <PreviewParagraph>
                  {permissionData?.name}{" "}
                  {permissionData?.gender_id == 1 ? "S/O" : "D/O"}{" "}
                  {permissionData?.father_name}, student of class XI{" "}
                  {permissionData?.faculty} at{" "}
                  {permissionData?.admission_college_name} is hereby allowed to
                  attend the classes at{" "}
                  {permissionData?.permission_college_name}
                </PreviewParagraph>
              </Grid>
              <Grid
                className="my-item"
                item
                xs={12}
                style={{ marginTop: "10%" }}
              >
                <PreviewParagraph>
                  This letter is not valid for Board Enrollment and Examination
                </PreviewParagraph>
              </Grid>
              <Grid
                className="my-item"
                item
                xs={12}
                style={{ marginTop: "10%" }}
              >
                <PreviewParagraph
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  Director General Colleges Sindh
                </PreviewParagraph>
              </Grid>
              <Grid
                className="my-item"
                item
                xs={12}
                style={{ marginTop: "5%" }}
              >
                <PreviewParagraph style={{ textAlign: "left" }}>
                  Copy for information to:-
                </PreviewParagraph>
                <PreviewParagraph style={{ textAlign: "left" }}>
                  1. Regional Director concerned
                </PreviewParagraph>
                <PreviewParagraph style={{ textAlign: "left" }}>
                  2. Principal concerned
                </PreviewParagraph>
                <PreviewParagraph style={{ textAlign: "left" }}>
                  3. I.T unit
                </PreviewParagraph>
              </Grid>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                <img
                  style={{ width: "10vw", height: "10vw" }}
                  src={`${permissionData?.qr_code_path}`}
                  alt="qrcode"
                />
              </div>
            </Grid>
          </div>
        </PreviewContent>
      </PreviewContainer>
    </div>
  );
};

export default Preview;
