import React from "react";
import { Link, useHistory } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { getUser } from "../../../functions/functions";
import { IoIosNotificationsOutline } from "react-icons/io";
import { MdClose } from "react-icons/md";
import "./NavBar.css";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const user = getUser(localStorage?.discussionsJwtToken);
  const [showNotification, setShowNotification] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link
              to="/discussions"
              style={{ color: "white", textDecoration: "none" }}
            >
              <Typography variant="h6" noWrap>
                SECCAP FORUM
              </Typography>
            </Link>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <div style={{ marginRight: "20px" }}>
                <div
                  style={{ cursor: "pointer", position: "relative" }}
                  onClick={() => setShowNotification(!showNotification)}
                >
                  <IoIosNotificationsOutline
                    style={{ fontSize: "24px", marginTop: "7px" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: -8,
                      background: "red",
                      fontSize: "11px",
                      borderRadius: "50%",
                      padding: "4px",
                    }}
                  >
                    12
                  </div>
                </div>

                {showNotification && (
                  <div className="notification-container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "16px",
                        fontWeight: "bold",
                        color: "#000",
                        padding: "12px",
                        borderBottom: "1px solid #000",
                      }}
                    >
                      <p>Notifications</p>
                      <p
                        style={{
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowNotification(!showNotification)}
                      >
                        <MdClose />
                      </p>
                    </div>
                    <p>Notification</p>
                    <p>Notification</p>
                    <p>Notification</p>
                    <p>Notification</p>
                    <p>Notification</p>
                    <p>Notification</p>
                    <p>Notification</p>
                    <p>Notification</p>
                    <p>Notification</p>
                    <p>Notification</p>
                  </div>
                )}
              </div> */}

              <div style={{ cursor: "pointer" }}>
                <Typography
                  variant="h6"
                  noWrap
                  onClick={() => {
                    // Remove token from localStorage
                    localStorage.removeItem("discussionsJwtToken");
                    // Redirect to login
                    history.push("/login");
                  }}
                >
                  Logout
                </Typography>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <div style={{ marginTop: "100px", paddingLeft: "24px" }}>
        <p
          style={{ fontWeight: "bold", color: "#6b7280", marginBottom: "10px" }}
        >
          {user?.name}
        </p>
        <p style={{ fontWeight: "bold", color: "#6b7280" }}>
          {user?.collegeName}
        </p>
      </div>
    </div>
  );
};

export default NavBar;
