import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import isEmpty from "../../../helpers/validation";
import CustomAlert from "../../common/CustomAlert";
import { FragmentContainer, FragmentHeader, FragmentHeaderH1, FragmentContent } from './FragmentsElements';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function LastDate(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [lastDate, setLastDate] = useState('');

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    window.scrollTo(0, 0);
    const res = await axios.get("https://www.dgcs.gos.pk/seccap2/students/getLastDateToApply");
    if (res?.data?.data?.date) {
      setLastDate(res?.data?.data?.date);
    }
    
  }, []);

  const handleClick = async () => {

    if (isEmpty(lastDate)) {
      setAlert('Please select last date!', 'error');
    } else {

      const data = {
        date: lastDate,
      }

      const res = await axios.post('https://www.dgcs.gos.pk/seccap2/students/updateLastDateToApply', data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });

      if(res?.data?.status === 'success') {
        setAlert('Last date to apply has been updated successfully!', 'success');
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, 'error');
      } else {
        setAlert('Something went wrong, Please try again!', 'error');
      }

    }
  }

  return (
    <div>
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item md={12}>
                <FragmentHeaderH1>Last Date to Apply</FragmentHeaderH1>
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider />
        <Container fixed>
          <FragmentContent>
            <form className={classes.container} noValidate>
              <TextField
                id="date"
                label="Set Last Date to Apply"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                value={lastDate}
                onChange={e => setLastDate(e.target.value)}
              />
            </form>
              <Button style={{ marginTop: '3%' }} variant="contained" color="primary" onClick={handleClick}>
                Update
              </Button>
          </FragmentContent>
          <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
        </Container>
      </FragmentContainer>
    </div>
  );
}
