import React, { useState, useEffect } from "react";
import {
  NewApplicationContainer,
  NewApplicationContent,
  NewApplicationItem,
} from "./NewApplicationElements";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";

import isEmpty, { isNumber } from "../../helpers/validation";
import CustomAlert from "../common/CustomAlert";
import SimpleBackdrop from "../common/Backdrop";
import * as ApiCalls from "../../services/ApiCalls";
import background from "./../../images/background.jpg";

import WhiteAd from "../Ad/WhiteAd";
import Ad from "../Ad";

const SeatsAvailability = () => {
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const [gender, setGender] = useState("1");
  const [data, setData] = useState([]);

  useEffect(async () => {
    window.scrollTo(0, 0);
    await setOpenBackDrop(false);
    await getData(1);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleChangeGender = (value) => {
    setGender(value);
    getData(value);
  };

  const getData = async (gender_id) => {
    await setOpenBackDrop(true);
    const res = await ApiCalls.getAvailableSeats(parseInt(gender_id));

    if (res) {
      setOpenBackDrop(false);
    }

    if (res?.data?.data) {
      console.log(data);
      setData(res?.data?.data);
    }
  };

  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);

  const buttonStyle = {
    backgroundColor: gender === "2" ? "#137a4c" : "white",
    color: gender === "2" ? "white" : "#137a4c",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Shadow-md equivalent
    padding: "8px", // p-2 equivalent (2 * 4px = 8px)
    borderRadius: "8px", // rounded-md equivalent
    cursor: "pointer",
    lineHeight: "1.5", // Adjust as needed for vertical centering (e.g., 24px for better centering if height is fixed)
    display: "inline-flex", // Use flexbox to center text vertically
    fontWeight: "bold",
    alignItems: "center", // Center text vertically
    justifyContent: "center", // Center text horizontally
    height: "40px", // Set a fixed height to ensure vertical centering
    width: "150px", // Optional: Set a fixed width if needed
    marginRight: "16px", // mr-4 equivalent (4 * 4px = 16px)
    transition: "background-color 0.2s, color 0.2s", // Transition for active state
    marginRight: "16px", // mr-4 equivalent (4 * 4px = 16px)
    transition: "background-color 0.2s, color 0.2s", // Transition for active state
    ...(isActive && gender === "2"
      ? {
          backgroundColor: "darkgreen", // active:bg-green-700 equivalent
          color: "white", // active:text-white equivalent
        }
      : {}),
  };

  const buttonStyle2 = {
    backgroundColor: gender === "1" ? "#137a4c" : "white",
    color: gender === "1" ? "white" : "#137a4c",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Shadow-md equivalent
    padding: "8px", // p-2 equivalent (2 * 4px = 8px)
    borderRadius: "8px", // rounded-md equivalent
    cursor: "pointer",
    lineHeight: "1.5", // Adjust as needed for vertical centering (e.g., 24px for better centering if height is fixed)
    display: "inline-flex", // Use flexbox to center text vertically
    fontWeight: "bold",
    alignItems: "center", // Center text vertically
    justifyContent: "center", // Center text horizontally
    height: "40px", // Set a fixed height to ensure vertical centering
    width: "150px", // Optional: Set a fixed width if needed
    marginRight: "16px", // mr-4 equivalent (4 * 4px = 16px)
    transition: "background-color 0.2s, color 0.2s", // Transition for active state
    ...(isActive2 && gender === "1"
      ? {
          backgroundColor: "darkgreen", // active:bg-green-700 equivalent
          color: "white", // active:text-white equivalent
        }
      : {}),
  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />

      <div>
        <NewApplicationContainer
          style={{ backgroundImage: `url(${background})` }}
        >
          <NewApplicationContent>
            <NewApplicationItem>
              <div style={{ marginBottom: "5%" }}>
                <h2>Check Seats Availability</h2>
              </div>
              <div style={{ marginBottom: "30px" }}>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div
                    style={buttonStyle2}
                    onMouseDown={() => setIsActive2(true)} // Handle active state
                    onMouseUp={() => setIsActive2(false)}
                    onMouseLeave={() => setIsActive2(false)} // Ensure active state is removed if mouse leaves
                    onClick={() => handleChangeGender("1")}
                  >
                    Male
                  </div>
                  <div
                    style={buttonStyle}
                    onMouseDown={() => setIsActive(true)} // Handle active state
                    onMouseUp={() => setIsActive(false)}
                    onMouseLeave={() => setIsActive(false)} // Ensure active state is removed if mouse leaves
                    onClick={() => handleChangeGender("2")}
                  >
                    Female
                  </div>
                </div>
              </div>
              {data?.length > 0 && (
                <Grid container spacing={2}>
                  {data?.map((item, index) => (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                      <Card style={{ borderTop: "5px solid #137a4c" }}>
                        <CardContent>
                          <div style={{ marginBottom: "20px" }}>
                            <Typography
                              style={{ fontWeight: "bold", fontSize: "18px" }}
                              color="textSecondary"
                            >
                              {item?.college_name}
                            </Typography>
                            <Typography
                              style={{ fontWeight: "bold", fontSize: "18px" }}
                              color="textSecondary"
                            >
                              {item?.ddo_code}
                            </Typography>
                          </div>
                          <div>
                            <Typography>Seats Available in</Typography>
                            {item?.faculties?.map((item2, index2) => (
                              <div
                                key={index2}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography>
                                  <div style={{ display: "flex" }}>
                                    <p
                                      style={{
                                        color: "gray",
                                        fontWeight: "bold",
                                        marginRight: "5px",
                                      }}
                                    >
                                      {item2?.faculty}
                                    </p>

                                    <p
                                      style={{
                                        color: "#b91c1c",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item2?.ninth_merit_current_year}
                                    </p>
                                  </div>
                                </Typography>
                                <Typography
                                  style={{
                                    color: "#137a4c",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item2?.free_seats}
                                </Typography>
                              </div>
                            ))}
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              )}
            </NewApplicationItem>
            <WhiteAd />
          </NewApplicationContent>
        </NewApplicationContainer>
        <Ad />
      </div>

      <CustomAlert
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

export default SeatsAvailability;
