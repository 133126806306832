import React, {useState} from 'react';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import isEmpty from "../../../helpers/validation";
import CustomAlert from "../../common/CustomAlert";
import { FragmentContainer, FragmentHeader, FragmentHeaderH1, FragmentContent } from './FragmentsElements';

export default function AddFaculty(props) {
  const [faculty, setFaculty] = useState('');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const handleClick = async () => {
    if (isEmpty(faculty)) {
      setAlert('Please fill out faculty field!', 'error');
    } else if (faculty.length > 30) {
      setAlert("Faculty field cannot contain maximum than 30 characters!", 'error');
    } else {
      const res = await axios.post('https://www.dgcs.gos.pk/seccap2/faculties/add', {faculty}, { headers: { 'Authorization': localStorage.getItem('jwtToken') } })
      if(res.data.status === 'success') {
        setAlert('Faculty has been added successfully!', 'success');
        setTimeout(() => {
          props.setFragment('faculties');
        }, 2000);
      } else if (res.data.error.message) {
        setAlert(res.data.error.message, 'error');
      } else {
        setAlert('Something went wrong, Please try again!', 'error');
      }
    }
    
  }

  return (
    <FragmentContainer>
      <Container fixed>
        <FragmentHeader>
          <Grid container spacing={5}>
            <Grid item md={12}>
              <FragmentHeaderH1>Add Faculty</FragmentHeaderH1>
            </Grid>
          </Grid>
        </FragmentHeader>
      </Container>
      <Divider />
      <Container fixed>
        <FragmentContent>
          <div style={{ width: '100%' }}>
            <div style={{marginBottom: '20px'}}>
              <TextField fullWidth id="outlined-faculty" label="Faculty" type="faculty" variant="outlined" name="faculty" value={faculty} onChange={(e) => e.target.value.length < 31 && setFaculty(e.target.value)} />
            </div>
            
            <div>
            <Button variant="contained" color="primary" onClick={handleClick}>
              Add
            </Button>
            </div>

            <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
            
          </div>
        </FragmentContent>
      </Container>
      
    </FragmentContainer>
  );
}
