import React, { useState, useEffect } from "react";
import {
  SuccessContainer,
  SuccessContent,
  SuccessCenter,
} from "./SuccessElements";
import { Button } from "../../../ButtonElements";
import background from "./../../../../images/background.jpg";

const Success = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    props.setOpenBackDrop(false);
  }, []);

  const editApplication = () => {
    props.setProvince("");
    props.setBoard("");
    props.setStudyGroup("");
    props.setSubject("");
    props.setTotalMarks("");
    props.setObtainedMarks("");
    props.setSchoolName("");
    props.setPassingYearClass8("");
    props.setDistrictClass8("");
    props.setSchoolNameClass8("");
    props.setPassingYearClass5("");
    props.setDistrictClass5("");
    props.setSchoolNameClass5("");
    props.setImageMarksheet("");
    props.setImageMarksheetName("");
    props.setMarksheetImage("");
    props.setAppliedId("");
    props.setFullName("");
    props.setFatherName("");
    props.setEmail("");
    props.setMobileNumber("");
    props.setGender("");
    props.setNationality("");
    props.setDateOfBirth("");
    props.setPlaceOfBirth("");
    props.setCnic("");
    props.setRegion("");
    props.setDomicileDistrict("");
    props.setFatherMobileNumber("");
    props.setFatherCnic("");
    props.setFatherOccupation("");
    props.setFatherDomicileDistrict("");
    props.setMotherCnic("");
    props.setAddressRegion("");
    props.setAddressDistrict("");
    props.setHomeAddress("");
    props.setImageProfile("");
    props.setImageProfileName("");
    props.setProfileImage("");
    props.setFatherMobileNumber("");
    props.setFatherCnic("");
    props.setRegion("");
    props.setFaculty("");
    props.setSelectedColleges([]);
    props.setAvailInDataBase(false);
    props.setApplied(false);
    props.setStep(1);
  };

  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };
  return (
    <div>
      <SuccessContainer style={{ backgroundImage: `url(${background})` }}>
        <SuccessContent>
          <SuccessCenter>
            <img width="100" src={"./images/success.png"} alt="success" />
          </SuccessCenter>
          <SuccessCenter style={{ marginTop: "3%" }}>
            <p style={{ color: "#65735b", fontSize: "1.5vw" }}>
              You have applied successfully.
            </p>
          </SuccessCenter>
          <SuccessCenter style={{ marginTop: "5%" }}>
            <Button
              to="/edit-application"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              style={{ width: "50%" }}
              onClick={editApplication}
            >
              Edit Application
            </Button>
          </SuccessCenter>
          <SuccessCenter style={{ marginTop: "3%" }}>
            <Button
              to="/status"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              style={{ width: "50%" }}
            >
              Check Status
            </Button>
          </SuccessCenter>
          <SuccessCenter style={{ marginTop: "3%" }}>
            <Button
              to="/"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
              style={{ width: "50%" }}
            >
              Go to Homepage
            </Button>
          </SuccessCenter>
        </SuccessContent>
      </SuccessContainer>
    </div>
  );
};

export default Success;
