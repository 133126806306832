import React, { useEffect, useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import WhiteAd from "../Ad/WhiteAd";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function SimpleBackdrop(props) {
  const classes = useStyles();

  const [mins, setMins] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const d = new Date();
      setSeconds(d.getSeconds());
      setMins(d.getMinutes());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Backdrop className={classes.backdrop} open={props.openBackDrop}>
        <CircularProgress color="inherit" />
        {mins === 1 && seconds === 1 && <WhiteAd />}
        {mins === 16 && seconds === 1 && <WhiteAd />}
        {mins === 31 && seconds === 1 && <WhiteAd />}
        {mins === 46 && seconds === 1 && <WhiteAd />}
      </Backdrop>
    </div>
  );
}
