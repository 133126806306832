import React from "react";
import VerticalAd from "../../Ad/VerticalAd";

const TestCategories = () => {
  return (
    <>
      <VerticalAd />
      <div className="my-app">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "40px",
          }}
        >
          <div
            style={{
              background: "#fff",
              color: "#777",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              fontWeight: "bold",
              fontSize: "20px",
              width: "300px",
              height: "180px",
              boxShadow:
                "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
              marginRight: "20px",
            }}
          >
            XI BIOLOGY
          </div>
          <div
            style={{
              background: "#fff",
              color: "#777",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              fontWeight: "bold",
              fontSize: "20px",
              width: "300px",
              height: "180px",
              boxShadow:
                "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
              marginLeft: "20px",
            }}
          >
            XII BIOLOGY
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "40px",
          }}
        >
          <div
            style={{
              background: "#fff",
              color: "#777",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              fontWeight: "bold",
              fontSize: "20px",
              width: "300px",
              height: "180px",
              boxShadow:
                "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
              marginRight: "20px",
            }}
          >
            XI PHYSICS
          </div>
          <div
            style={{
              background: "#fff",
              color: "#777",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              fontWeight: "bold",
              fontSize: "20px",
              width: "300px",
              height: "180px",
              boxShadow:
                "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
              marginLeft: "20px",
            }}
          >
            XII PHYSICS
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "40px",
          }}
        >
          <div
            style={{
              background: "#fff",
              color: "#777",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              fontWeight: "bold",
              fontSize: "20px",
              width: "300px",
              height: "180px",
              boxShadow:
                "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
              marginRight: "20px",
            }}
          >
            XI CHEMISTRY
          </div>
          <div
            style={{
              background: "#fff",
              color: "#777",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              fontWeight: "bold",
              fontSize: "20px",
              width: "300px",
              height: "180px",
              boxShadow:
                "0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)",
              marginLeft: "20px",
            }}
          >
            XII CHEMISTRY
          </div>
        </div>
      </div>
      <VerticalAd />
    </>
  );
};

export default TestCategories;
