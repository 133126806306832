import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import axios from "axios";
import ReactTimeAgo from "react-time-ago";
import { makeStyles } from "@material-ui/core/styles";
import { FaPlus } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { AiFillLike } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import WhiteAd from "../../Ad/WhiteAd";
import * as ApiCalls from "../../../services/ApiCalls";
import NavBar from "../NavBar/NavBar";
import SimpleBackdrop from "../../common/Backdrop";
import "./../Discussions.css";
import VerticalAd from "../../Ad/VerticalAd";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { IoEyeOutline } from "react-icons/io5";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 800,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const Forum = () => {
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [discussionsData, setDiscussionsData] = useState([]);
  const [discussionsCount, setDiscussionsCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [nextRows, setNextRows] = useState(10);
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  let sub = params.category;
  let subject_id;
  let sub_heading;

  if (sub === "english") {
    subject_id = 1;
    sub_heading =
      "Unlock the power of words, where every sentence is an opportunity and every paragraph a journey.";
  } else if (sub === "biology") {
    subject_id = 12;
    sub_heading =
      "Dive into the intricate dance of life, where every cell tells a story waiting to be discovered.";
  } else if (sub === "mathematics") {
    subject_id = 8;
    sub_heading =
      "Embrace the beauty of numbers, where each equation is a puzzle and every solution is a triumph.";
  } else if (sub === "physics") {
    subject_id = 7;
    sub_heading =
      "Unravel the mysteries of the universe, where every equation is a window into the cosmic symphony.";
  } else if (sub === "chemistry") {
    subject_id = 6;
    sub_heading =
      "Explore the magic happening at the molecular level, where atoms come together to create the wonders of our world.";
  } else if (sub === "computer-science") {
    sub = sub.split("-").join(" ");
    subject_id = 13;
    sub_heading =
      "Enter the realm of algorithms and innovation, where discussions pave the way for groundbreaking solutions and technological marvels.";
  }

  const handleLoadMore = (nextRows, subject_id) => {
    getDiscussionsData(nextRows + 5, subject_id, searchValue);
    setNextRows(nextRows + 5);
  };

  const handleLike = async (post_id) => {
    const res = await ApiCalls.likePost(post_id);
    let newData = [];
    let disData = discussionsData;
    disData.map((item) => {
      if (item?.id === post_id) {
        if (item.my_like === true) {
          item.likes = parseInt(item.likes) - 1;
          item.my_like = false;
        } else if (item.my_like === false) {
          item.likes = parseInt(item.likes) + 1;
          item.my_like = true;
        }
      }
      newData.push(item);
    });
    setDiscussionsData(newData);
  };

  const handleChange = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      getDiscussionsData(nextRows, subject_id, e.target.value);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    getDiscussionsData(nextRows, subject_id, searchValue);
  };

  const getDiscussionsData = async (nextRows, subject_id, searchValue) => {
    let searchFilters = searchValue;
    if (searchFilters?.length === 0) {
      searchFilters = 0;
    }
    await setOpenBackDrop(true);
    const res = await ApiCalls.getAllDiscussions(
      nextRows,
      0,
      subject_id,
      searchFilters
    );

    if (res) {
      await setOpenBackDrop(false);
    }
    if (res?.data?.data) {
      setDiscussionsData(res?.data?.data);
      setDiscussionsCount(res?.data?.count);
    }
  };

  const visitTopicsPage = async (subject_id) => {
    const data = {
      subject_id,
    };

    let res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/discussions/visitTopicsPage",
      data,
      {
        headers: {
          Authorization: `${localStorage.getItem("discussionsJwtToken")}`,
        },
      }
    );
  };

  useEffect(() => {
    // Check for token
    if (!localStorage?.discussionsJwtToken) {
      // Redirect to login
      history.push("/login");
    }
    visitTopicsPage(subject_id);
    getDiscussionsData(nextRows, subject_id, searchValue);
  }, []);

  return (
    <div>
      <div className="navbar">
        <NavBar />
      </div>

      <div className="main-container">
        <SimpleBackdrop openBackDrop={openBackDrop} />
        <div>
          <Link
            to="/discussions"
            style={{
              display: "flex",
              color: "#6b7280",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
              width: "100px",
              height: "32px",
              border: "1px solid #9ca3af",
              borderRadius: "8px",
            }}
          >
            <IoIosArrowBack style={{ marginRight: "4px" }} /> Back
          </Link>
        </div>

        <Grid container spacing={1}>
          <Grid item xs={12} md={10} spacing={3}>
            <div>
              <h1 style={{ marginTop: "30px" }}>
                {sub.charAt(0).toUpperCase() + sub.slice(1)}
              </h1>
              <p style={{ width: "70%", color: "#5f6368", marginTop: "15px" }}>
                {sub_heading}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={2}>
            <div style={{ marginTop: "30px", width: "130px" }}>
              <Link className="link" to={`/discussions/${params.category}/new`}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "#000",
                    color: "#fff",
                    padding: "10px 15px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  <div style={{ marginRight: "10px" }}>
                    <FaPlus />
                  </div>
                  <div>
                    <p>New Topic</p>
                  </div>
                </div>
              </Link>
            </div>
          </Grid>
        </Grid>

        <div style={{ marginTop: "90px" }}>
          <h2>Topics</h2>

          <div
            style={{
              display: "flex",
              alignContent: "center",
              textAlign: "center",
              justifyContent: "center",
              padding: "2rem 0",
            }}
          >
            <Paper component="form" className={classes.root}>
              <InputBase
                className={classes.input}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleChange}
              />
              <Divider className={classes.divider} orientation="vertical" />
              <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="search"
                onClick={handleSubmit}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          <div>
            {discussionsData?.length > 0 ? (
              discussionsData?.map((item, index) => {
                return (index + 1) % 3 === 0 ? (
                  <div key={index}>
                    <div className="forum-card">
                      <div
                        style={{
                          width: "70%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            className="link"
                            to={`/discussions/profile/${item?.role}/${item?.user_id}`}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "2px",
                                border: `2px solid ${
                                  item?.role === "student"
                                    ? "#9333ea"
                                    : "#3b82f6"
                                } `,
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                            >
                              <img
                                style={{
                                  width: "50px",
                                  borderRadius: "50%",
                                }}
                                src={
                                  require("./../../../images/profile.png")
                                    .default
                                }
                              />
                            </div>
                          </Link>
                          <Link
                            className="link"
                            to={`/discussions/${params?.category}/${item?.id}`}
                          >
                            <div>
                              {item?.seen === true ? (
                                <h3
                                  style={{
                                    marginBottom: "5px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {item?.title}
                                </h3>
                              ) : (
                                <h3 style={{ marginBottom: "5px" }}>
                                  {item?.title}
                                </h3>
                              )}

                              <p style={{ fontSize: "12px", color: "#5f6368" }}>
                                {item?.name} - {item?.college_name} - posted{" "}
                                <ReactTimeAgo date={item?.created_at} />
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleLike(item?.id)}
                            >
                              {item?.my_like ? (
                                <AiFillLike />
                              ) : (
                                <AiOutlineLike />
                              )}
                            </div>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              {item?.likes} likes
                            </div>
                          </div>

                          <div style={{ marginTop: "10px" }}>
                            <p style={{ fontSize: "12px", color: "#5f6368" }}>
                              {item?.comments} comments
                            </p>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#5f6368",
                                display: "flex",
                                alignContent: "center",
                              }}
                            >
                              <IoEyeOutline
                                style={{ marginRight: "5px", fontSize: "14px" }}
                              />{" "}
                              {item?.views} views
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <VerticalAd />
                  </div>
                ) : (
                  <div key={index}>
                    <div className="forum-card">
                      <div
                        style={{
                          width: "70%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            className="link"
                            to={`/discussions/profile/${item?.role}/${item?.user_id}`}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "2px",
                                border: `2px solid ${
                                  item?.role === "student"
                                    ? "#9333ea"
                                    : "#3b82f6"
                                } `,
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                            >
                              <img
                                style={{
                                  width: "50px",
                                  borderRadius: "50%",
                                }}
                                src={
                                  require("./../../../images/profile.png")
                                    .default
                                }
                              />
                            </div>
                          </Link>
                          <Link
                            className="link"
                            to={`/discussions/${params?.category}/${item?.id}`}
                          >
                            <div>
                              {item?.seen === true ? (
                                <h3
                                  style={{
                                    marginBottom: "5px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {item?.title}
                                </h3>
                              ) : (
                                <h3 style={{ marginBottom: "5px" }}>
                                  {item?.title}
                                </h3>
                              )}

                              <p style={{ fontSize: "12px", color: "#5f6368" }}>
                                {item?.name} - {item?.college_name} - posted{" "}
                                <ReactTimeAgo date={item?.created_at} />
                              </p>
                            </div>
                          </Link>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "row-reverse",
                        }}
                      >
                        <div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "18px",
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleLike(item?.id)}
                            >
                              {item?.my_like ? (
                                <AiFillLike />
                              ) : (
                                <AiOutlineLike />
                              )}
                            </div>
                            <div
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                            >
                              {item?.likes} likes
                            </div>
                          </div>

                          <div style={{ marginTop: "10px" }}>
                            <p style={{ fontSize: "12px", color: "#5f6368" }}>
                              {item?.comments} comments
                            </p>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "#5f6368",
                                display: "flex",
                                alignContent: "center",
                              }}
                            >
                              <IoEyeOutline
                                style={{ marginRight: "5px", fontSize: "14px" }}
                              />{" "}
                              {item?.views} views
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <p style={{ fontWeight: "bold", color: "#9ca3af" }}>
                  Data not found
                </p>
              </div>
            )}
          </div>

          <div>
            {discussionsData?.length < parseInt(discussionsCount) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    background: "#000",
                    color: "#fff",
                    padding: "10px 15px",
                    borderRadius: "25px",
                    fontWeight: "bold",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleLoadMore(nextRows, subject_id)}
                >
                  <div>
                    <p>Load more</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <WhiteAd />
    </div>
  );
};

export default Forum;
