import React, { useEffect } from "react";
import {
  FacultyContainer,
  FacultyContent,
  FacultyItem,
} from "./ChoiceOfFacultyElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import isEmpty, { isNumber } from "../../../../helpers/validation";
import background from "./../../../../images/background.jpg";
import Stepper from "../stepper/Stepper";
import WhiteAd from "../../../Ad/WhiteAd";
import FacultiesData from "../../../../data/faculties.json";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "98%",
    minWidth: 120,
    marginBottom: "2%",
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  multilineColor: {
    color: "red",
  },
}));

const ChoiceOfFaculty = (props) => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setOpenBackDrop(false);
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "faculty") {
      if (isNumber(value) && !isEmpty(value)) {
        props.setFaculty(value);
      }
    }
    if (name === "region") {
      if (isNumber(value) && !isEmpty(value)) {
        props.setRegion(value);
      }
    }
  };

  const handleBack = () => {
    props.setRegion("");
    props.setFaculty("");
    props.setOpenBackDrop(true);
    setTimeout(() => {
      props.setStep(3);
    }, 1000);
  };

  const handleClick = async () => {
    if (isEmpty(props.region)) {
      props.setAlert("Please select Board!", "error");
    } else if (isEmpty(props.faculty)) {
      props.setAlert("Please select Faculty!", "error");
    } else {
      props.setOpenBackDrop(true);
      setTimeout(() => {
        props.setStep(5);
      }, 1000);
    }
  };

  return (
    <div>
      <FacultyContainer style={{ backgroundImage: `url(${background})` }}>
        <FacultyContent>
          <Stepper step={props.step} />
          <FacultyItem>
            <div style={{ marginBottom: "5%", marginLeft: "8px" }}>
              <h1>Choice of Faculty</h1>
            </div>

            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-region">Select Board</InputLabel>
              <Select
                id="outlined-region"
                native
                value={props.region}
                onChange={handleChange}
                label="Select Board"
                name="region"
              >
                <option aria-label="None" value="" />
                <option value="1">KARACHI</option>
                <option value="2">HYDERABAD</option>
                <option value="3">MIRPURKHAS</option>
                <option value="4">SHAHEED BENAZIRABAD</option>
                <option value="5">LARKANA</option>
                <option value="6">SUKKUR</option>
              </Select>
            </FormControl>

            {FacultiesData?.length > 0 && (
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-faculty">
                  Select Choice of Faculty
                </InputLabel>
                <Select
                  native
                  id="outlined-faculty"
                  value={props.faculty}
                  onChange={handleChange}
                  label="Select Choice of Faculty"
                  name="faculty"
                >
                  <option aria-label="None" value="" />
                  {FacultiesData?.length > 0 &&
                    FacultiesData?.map((item) => (
                      <option value={item?.id}>{item?.faculty}</option>
                    ))}
                </Select>
              </FormControl>
            )}

            <div style={{ marginTop: "5%", marginLeft: "8px" }}>
              <Button
                variant="contained"
                color="default"
                style={{ marginRight: "1rem" }}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleClick}>
                Save {"&"} Continue
              </Button>
            </div>
          </FacultyItem>
          <WhiteAd />
        </FacultyContent>
      </FacultyContainer>
    </div>
  );
};

export default ChoiceOfFaculty;
