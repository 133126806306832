import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import "./Discussions.css";
import "./styles.css";
import WhiteAd from "../Ad/WhiteAd";
import NavBar from "./NavBar/NavBar";
import SimpleBackdrop from "./../common/Backdrop";
import { MdForum } from "react-icons/md";
import * as ApiCalls from "./../../services/ApiCalls";
import { BsDot } from "react-icons/bs";

const Discussions = () => {
  const history = useHistory();
  const [postsCountData, setPostsCountData] = useState([]);
  const [openBackDrop, setOpenBackDrop] = useState(false);

  const visitDiscussionsPage = async () => {
    let res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/discussions/visitDiscussionsPage",
      {},
      {
        headers: {
          Authorization: `${localStorage.getItem("discussionsJwtToken")}`,
        },
      }
    );
  };

  const getPostsCountData = async () => {
    await setOpenBackDrop(true);
    const res = await ApiCalls.getPostsCount();

    if (res) {
      await setOpenBackDrop(false);
    }
    if (res?.data?.data) {
      setPostsCountData(res?.data?.data);
    }
  };

  useEffect(() => {
    getPostsCountData();
    visitDiscussionsPage();
    // Check for token
    if (!localStorage?.discussionsJwtToken) {
      // Redirect to login
      history.push("/login");
    }
  }, []);

  return (
    <div>
      <div className="navbar">
        <NavBar />
      </div>

      <div className="main-container">
        <SimpleBackdrop openBackDrop={openBackDrop} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: "100px" }}>
            <h1 className="discussion-main-heading">Discussions</h1>
            <p
              className="discussion-paragraph"
              style={{ color: "#5f6368", marginTop: "15px" }}
            >
              Discuss the Subject's Topics what you have learned or what you
              want to learn – this includes sharing feedback, asking questions,
              giving answers and more.
            </p>
          </div>
          <div className="discussion-top-right">
            <img
              style={{ width: "250px" }}
              src={require("./../../images/discussion.jpg").default}
            />
          </div>
        </div>
        <div className="discussion-body">
          <h2
            style={{ display: "flex", alignContent: "center" }}
            className="discussion-heading2"
          >
            <div style={{ marginRight: "15px" }}>
              <MdForum />
            </div>
            <div>Forums</div>
          </h2>
          <div>
            {postsCountData?.map((item) => (
              <Link
                key={item?.subject_id}
                className="link"
                to={`/discussions/${
                  parseInt(item?.subject_id) === 1
                    ? "english"
                    : parseInt(item?.subject_id) === 12
                    ? "biology"
                    : parseInt(item?.subject_id) === 8
                    ? "mathematics"
                    : parseInt(item?.subject_id) === 7
                    ? "physics"
                    : parseInt(item?.subject_id) === 6
                    ? "chemistry"
                    : parseInt(item?.subject_id) === 13
                    ? "computer-science"
                    : ""
                }`}
              >
                <div className="forum-card">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <img
                        className="discussion-forum-image"
                        style={{ marginRight: "10px" }}
                        src={
                          parseInt(item?.subject_id) === 1
                            ? require("./../../images/xi_english.png").default
                            : parseInt(item?.subject_id) === 12
                            ? require("./../../images/biology-1.png").default
                            : parseInt(item?.subject_id) === 8
                            ? require("./../../images/xi_maths.png").default
                            : parseInt(item?.subject_id) === 7
                            ? require("./../../images/xi_physics.png").default
                            : parseInt(item?.subject_id) === 6
                            ? require("./../../images/xii_chemistry.png")
                                .default
                            : parseInt(item?.subject_id) === 13
                            ? require("./../../images/computer-science.png")
                                .default
                            : ""
                        }
                      />
                    </div>
                    <div>
                      <h3 style={{ marginBottom: "5px" }}>
                        {parseInt(item?.subject_id) === 1
                          ? "English"
                          : parseInt(item?.subject_id) === 12
                          ? "Biology"
                          : parseInt(item?.subject_id) === 8
                          ? "Mathematics"
                          : parseInt(item?.subject_id) === 7
                          ? "Physics"
                          : parseInt(item?.subject_id) === 6
                          ? "Chemistry"
                          : parseInt(item?.subject_id) === 13
                          ? "Computer Science"
                          : ""}
                      </h3>
                      <p style={{ fontSize: "12px", color: "#5f6368" }}>
                        {parseInt(item?.subject_id) === 1
                          ? "Unlock the power of words, where every sentence is an opportunity and every paragraph a journey."
                          : parseInt(item?.subject_id) === 12
                          ? "Dive into the intricate dance of life, where every cell tells a story waiting to be discovered."
                          : parseInt(item?.subject_id) === 8
                          ? "Embrace the beauty of numbers, where each equation is a puzzle and every solution is a triumph."
                          : parseInt(item?.subject_id) === 7
                          ? "Unravel the mysteries of the universe, where every equation is a window into the cosmic symphony."
                          : parseInt(item?.subject_id) === 6
                          ? "Explore the magic happening at the molecular level, whereatoms come together to create the wonders of our world."
                          : parseInt(item?.subject_id) === 13
                          ? "Enter the realm of algorithms and innovation, where discussions pave the way for groundbreaking solutions and technological marvels."
                          : ""}
                      </p>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1px",
                            alignItems: "center",
                            marginRight: "5px",
                          }}
                        >
                          <BsDot
                            style={{ fontSize: "24px", color: "#3b82f6" }}
                          />
                          <p
                            style={{
                              fontSize: "12px",
                              color: "#5f6368",
                            }}
                          >
                            {item?.posts_count} posts
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1px",
                            alignItems: "center",
                          }}
                        >
                          <BsDot
                            style={{ fontSize: "24px", color: "#ec4899" }}
                          />
                          <p
                            style={{
                              fontSize: "12px",
                              color: "#5f6368",
                            }}
                          >
                            {item?.comments_count} comments
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div></div>
                    <p></p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <WhiteAd />
    </div>
  );
};

export default Discussions;
