import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import isEmpty from "../../../../helpers/validation";
import { CollegesContainer, CollegesContent, ButtonElement } from './SelectCollegesElements';
import * as ApiCalls from '../../../../services/ApiCalls';
import background from "./../../../../images/background.jpg";
import Stepper from "../stepper/Stepper";


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 500,
    height: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function SelectColleges(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    window.scrollTo(0, 0);
    getCollegesByFacultyIdGenderIdAndRegionId(props.faculty, props.gender, props.region);
    props.setOpenBackDrop(false);
  }, [])

  const getCollegesByFacultyIdGenderIdAndRegionId = async (faculty_id, gender_id, region_id) => {

    const resColleges = await ApiCalls.getCollegesByFacultyIdGenderIdAndRegionId(faculty_id, gender_id, region_id);
    
    const colleges = [];

    if (resColleges?.data?.data?.length > 0) {

      resColleges?.data?.data?.map(item => {
        let isCollege = right?.some(item2 => item.id === item2.id);
        if(isCollege === false) {
          colleges.push(item)
        }
      } );
  
      setLeft(colleges);

    } else {
      props.setAlert(`Colleges Not Found!`, 'error');
      props.setStep(4);
    }
  
    

  }

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleBack = () => {
    setRight([]);
    props.setSelectedColleges([]);
    props.setOpenBackDrop(true);
    setTimeout(() => {
      props.setStep(4);
    }, 1000);
  }

  const handleClick = () => {
    if (isEmpty(right)) {
      props.setAlert(`Please Choose Colleges!`, 'error');
    } else if (props.region == 1 && (right.length + left.length > 9) && right.length < 10) {
      props.setAlert('Please Choose atleast 10 Colleges!', 'error');
    } else if (props.region == 1 && (right.length + left.length < 10) && right.length !== right.length + left.length) {
      props.setAlert('Please Choose all Colleges!', 'error');
    } else if (right.length > 10) {
      props.setAlert('Please Choose only 10 Colleges!', 'error');
    } else {

      props.setOpenBackDrop(true);

      // const collegesIds = right.map(item => item.id);

      props.setSelectedColleges(right);
      setTimeout(() => {
        props.setStep(6);
      }, 1000);
    }
  }

  const customList = (title, items) => (
    
        <Card>
          <CardHeader
            className={classes.cardHeader}
            avatar={
              <Checkbox
                onClick={handleToggleAll(items)}
                checked={numberOfChecked(items) === items?.length && items?.length !== 0}
                indeterminate={numberOfChecked(items) !== items?.length && numberOfChecked(items) !== 0}
                disabled={items?.length === 0}
                inputProps={{ 'aria-label': 'all items selected' }}
              />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items?.length} selected`}
          />
          <Divider />
          <List className={classes.list} dense component="div" role="list">
            {items?.map((value) => {
              const labelId = `transfer-list-all-item-${value}-label`;

              return (
                <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${value?.college_name}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Card>
      
  );

  return (
    <div>
    <CollegesContainer style={{ backgroundImage: `url(${background})` }}>
      <CollegesContent>
          <div style={{ margin: '0 3%'}}>
            <Stepper step={props.step} />
          </div>
          
          <div style={{marginBottom: '50px', marginTop: '50px', marginLeft: '3%'}}>
            <h1>Choose your concerned colleges</h1>
          </div>
          <div style={{marginBottom: '50px', marginTop: '50px', marginLeft: '3%'}}>
            <p>To choose Colleges:</p>
            <p>1) Select Colleges on Left Panel</p>
            <p>2) Shift Selected Colleges to Right Panel Using Right Arrow Button</p>
            <p>3) Click on Preview Button</p>
          </div>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{customList('Choices', left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked?.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList('Chosen', right)}</Grid>
        </Grid>
        <ButtonElement>
          <Button variant="contained" color="default" style={{marginRight: '1rem'}} onClick={handleBack} >
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Preview
          </Button>
        </ButtonElement>
      </CollegesContent>
    </CollegesContainer>
    
    </div>
  );
}
