import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { DataGrid, getThemePaletteMode } from '@material-ui/data-grid';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { getUser } from '../../../functions/functions';
import './styles.css';
import { FragmentContainer, FragmentHeader, FragmentHeaderH1, FragmentContent } from './FragmentsElements';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) => {
    const isDark = getThemePaletteMode(theme.palette) === 'dark';

    return {
      root: {
        '& MuiDataGrid-columnHeaderWrapper': {
          backgroundColor: 'rgb(255,215,115, 0.19) !important',
          color: '#1a3e72',
        },
        '& .MuiDataGrid-cell--editing': {
          backgroundColor: 'rgb(255,215,115, 0.19)',
          color: '#1a3e72',
        },
        '& .Mui-error': {
          backgroundColor: `rgb(126,10,15, ${isDark ? 0 : 0.1})`,
          color: isDark ? '#ff4343' : '#750f0f',
        },
      },
    };
  },
  { defaultTheme },
);

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function Districts(props) {
  const [editRowsModel, setEditRowsModel] = React.useState({});
  const classes = useStyles();
  const [districts, setDistricts] = useState([]);
  const user = getUser(localStorage?.jwtToken);

  useEffect(async () => {
    window.scrollTo(0, 0);
    let res;

    if (user?.userRole === 'rd') {
      res = await axios.get(`https://www.dgcs.gos.pk/seccap2/districts/${user?.userId}`);
    } else {
      res = await axios.get("https://www.dgcs.gos.pk/seccap2/districts");
    }
    
    setDistricts(res.data.data);
  }, [])

  const handleEditCellChange = React.useCallback(
    ({ id, field, props }) => {
      if (field === 'email') {
        const data = props; // Fix eslint value is missing in prop-types for JS files
        const isValid = validateEmail(data.value);
        const newState = {};
        newState[id] = {
          ...editRowsModel[id],
          email: { ...props, error: !isValid },
        };

        setEditRowsModel((state) => ({ ...state, ...newState }));
      }
      updateDistrict(id, props.value);
    },
    [editRowsModel],
    
  );

  const updateDistrict = async (id, district) => {
    const res = await axios.post(`https://www.dgcs.gos.pk/seccap2/districts/edit/${id}`, {district}, {
      headers: {
        'Authorization': `${localStorage.getItem('jwtToken')}`
      }});
  }

  return (
    <FragmentContainer>
      <Container fixed>
        <FragmentHeader>
          <Grid container spacing={5}>
            <Grid item md={9}>
              <FragmentHeaderH1>Districts</FragmentHeaderH1>
            </Grid>
            <Grid item md={3}>
              <Button variant="contained" color="primary" onClick={() => props.setFragment('adddistrict')}>
                Add District
              </Button>
            </Grid>
          </Grid>
        </FragmentHeader>
      </Container>
      <Divider />
      <Container fixed>
        <FragmentContent>
          <div style={{ height: 400, width: '100%' }}>
            {districts?.length > 0 ? (
              <DataGrid
                className={classes.root}
                rows={districts}
                columns={columns}
                editRowsModel={editRowsModel}
                onEditCellChange={handleEditCellChange}
              />
            ) : (
              <h1 style={{ textAlign: 'center', color: '#c3c3c3', margin: '10% auto'}}>Districts not Found</h1>
            )}
            
          </div>
        </FragmentContent>
      </Container>
      
    </FragmentContainer>
  );
}

const columns = [
  { field: 'district', headerName: 'District', width: 180, editable: true },
  { field: 'region', headerName: 'Region', width: 180, editable: false },
];
