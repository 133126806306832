import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import isEmpty from "../../../helpers/validation";
import CustomAlert from "../../common/CustomAlert";
import Divider from '@material-ui/core/Divider';
import { FragmentContainer, FragmentHeader, FragmentHeaderH1, FragmentContent } from './FragmentsElements';
import SimpleBackdrop from './../../common/Backdrop';



const UnapprovedDetails = (props) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [colleges, setColleges] = useState([]);
  const [reason, setReason] = useState('');
  const [openBackDrop, setOpenBackDrop] = React.useState(false);

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCollegesByAppliedId(props.unapprovedData.id);
  }, []);

  const handleApproved =  async () => {
    await setOpenBackDrop(true);
    const data = {
      applied_id: props.unapprovedData.id,
    };
    await axios.post('https://www.dgcs.gos.pk/seccap2/students/approve', data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });
    await setOpenBackDrop(false);
    props.setFragment('unapproved');
  }

  const handleCancelled =  async () => {

    if (isEmpty(reason)) {
      setAlert('Please fill out Reason!', 'error');
    } else if (reason.length < 10) {
      setAlert("Reason field contains minimum 10 characters!", 'error');
    } else if (reason.length > 100) {
      setAlert("Reason field cannot contain maximum than 100 characters!", 'error');
    } else {

      await setOpenBackDrop(true);

      const data = {
        applied_id: props.unapprovedData.id,
        reason,
      };
      await axios.post('https://www.dgcs.gos.pk/seccap2/students/cancel', data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });
      await setOpenBackDrop(false);
      props.setFragment('unapproved');

    }
    
  }

  const getCollegesByAppliedId = async (applied_id) => {
    const data = {
      applied_id,
    };

    const res = await axios.post('https://www.dgcs.gos.pk/seccap2/colleges/applied', data);
    if (res?.data?.status === 'success') {
      setColleges(res?.data?.data);
    }
  }

  return (
    <div>
      <FragmentContainer>
      <SimpleBackdrop openBackDrop={openBackDrop} />
        <FragmentHeader>
          <FragmentHeaderH1>Applicant's Detail</FragmentHeaderH1>
        </FragmentHeader>
        <Divider />
      </FragmentContainer>
      <FragmentContainer>
        <Container fixed>
          <FragmentContent>
            <Grid container spacing={2}>
              <Grid container item xs={12} md={6}>
                <Grid container spacing={2} style={{marginBottom: '1rem'}}>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: '0.5rem'}}>Name</h4>
                    <p>{props?.unapprovedData?.name}</p>
                  </Grid>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: '0.5rem'}}>Gender</h4>
                    <p>{props?.unapprovedData?.gender}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginBottom: '1rem'}}>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: '0.5rem'}}>Matric Roll No</h4>
                    <p>{props?.unapprovedData?.matric_roll_no}</p>
                  </Grid>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: '0.5rem'}}>Passing Year</h4>
                    <p>{props?.unapprovedData?.passing_year}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginBottom: '1rem'}}>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: '0.5rem'}}>Total Marks</h4>
                    <p>{props?.unapprovedData?.total_marks}</p>
                  </Grid>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: '0.5rem'}}>Obtained Marks</h4>
                    <p>{props?.unapprovedData?.obtained_marks}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginBottom: '1rem'}}>
                <Grid item md={6}>
                    <h4 style={{ marginBottom: '0.5rem'}}>Scaled Total Marks</h4>
                    <p>{props?.unapprovedData?.scaled_total_marks}</p>
                  </Grid>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: '0.5rem'}}>Scaled Obtained Marks</h4>
                    <p>{props?.unapprovedData?.scaled_obtained_marks}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginBottom: '1rem'}}>
                  <Grid item md={12}>
                    <h4 style={{ marginBottom: '0.5rem'}}>Choice of Faculty</h4>
                    <p>{props?.unapprovedData?.faculty}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginBottom: '1rem'}}>
                  <Grid item md={12}>
                    <h4 style={{ marginBottom: '0.5rem'}}>Chosen Colleges</h4>
                      {colleges?.map((item, index) => (
                        <p style={{ marginBottom: '0.5rem'}}>{`${index+1}) ${item?.college_name}`}</p>
                      ))}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginBottom: '1rem'}}>
                  <Grid item md={6}>
                    <Button variant="contained" color="primary" onClick={handleApproved}>
                      Approve
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Button variant="contained" style={{ background: '#dc004e', color: '#fff'}}  onClick={handleCancelled}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginBottom: '1rem'}}>
                  <Grid item md={12}>
                  <TextField
                    id="outlined-multiline-static"
                    label="If Cancel Enter Reason"
                    multiline
                    fullWidth
                    rows={4}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    variant="outlined"
                  />
                   
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <img width="500" src={`https://www.dgcs.gos.pk/seccap2/public/images/${props.unapprovedData.marksheet_image}`} alt="Marksheet" />
              </Grid>
            </Grid>
            <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
          </FragmentContent>
        </Container>
      </FragmentContainer>
      
      
      
    </div>
  )
}

export default UnapprovedDetails;
