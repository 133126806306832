import axios from "axios";

const BASE_URL = "https://www.dgcs.gos.pk/seccap2";

export const imageURL = `${BASE_URL}/public/images`;
export const olevelURL = `${BASE_URL}/public/olevelimages2022`;
export const profileImageURL = `${BASE_URL}/public/studentprofiles`;

export const isApplied = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/seccapApplied`, data);
  return response;
};

export const isAppliedConfirmed = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/seccapAppliedConfirmed`,
    data
  );
  return response;
};

export const getStudentCardData = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/getStudentCardData`,
    data
  );
  return response;
};

export const isSeccapAppliedSindhGames = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/sindhgames/seccapApplied`,
    data
  );
  return response;
};

export const sindhGamesApplied = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/sindhgames/sindhGamesApplied`,
    data
  );
  return response;
};

export const sindhTalentApplied = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/sindhtalents/sindhTalentApplied`,
    data
  );
  return response;
};

export const isSeccapAppliedSindhGamesXII = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/sindhgames/seccapAppliedXII`,
    data
  );
  return response;
};

export const isAppliedConfirmedXII = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/seccapAppliedConfirmedXII`,
    data
  );
  return response;
};

export const isOlevelApplied = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/olevelApplied`, data);
  return response;
};

export const isAppliedOlevel = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/appliedOlevel`, data);
  return response;
};

export const seccapAppliedOlevel = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/seccapAppliedOlevel`,
    data
  );
  return response;
};

export const seccapAppliedConfirmedOlevel = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/seccapAppliedConfirmedOlevel`,
    data
  );
  return response;
};

export const isSeccapAppliedSindhGamesOlevel = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/sindhgames/seccapAppliedOlevel`,
    data
  );
  return response;
};

export const sindhGamesApply = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/sindhgames/sindhGamesApply`,
    data
  );
  return response;
};

export const sindhTalentApply = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/sindhtalents/sindhTalentApply`,
    data
  );
  return response;
};

export const isSeccapAppliedSindhGamesOlevelXII = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/sindhgames/seccapAppliedOlevelXII`,
    data
  );
  return response;
};

export const seccapAppliedConfirmedOlevelXII = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/seccapAppliedConfirmedOlevelXII`,
    data
  );
  return response;
};

export const isInAdmissions = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/inSeccapAdmissions`,
    data
  );
  return response;
};

export const isInOlevelAdmissions = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/inOlevelAdmissions`,
    data
  );
  return response;
};

export const isConfirmAdmission = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/isConfirmAdmission`,
    data
  );
  return response;
};

export const CheckAdmissionByAppliedId = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/CheckSeccapAdmissionByAppliedId`,
    data
  );
  return response;
};

export const CheckOlevelAdmissionByAppliedId = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/CheckOlevelAdmissionByAppliedId`,
    data
  );
  return response;
};

export const CheckSeccapOlevelAdmissionByAppliedId = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/CheckSeccapOlevelAdmissionByAppliedId`,
    data
  );
  return response;
};

export const checkConfirmAdmission = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/checkConfirmAdmission`,
    data
  );
  return response;
};

export const getSeccapClaimColleges = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/getSeccapClaimColleges`,
    data
  );
  return response;
};

export const getClaimColleges = async (data) => {
  // if (localStorage?.jwtToken) {
  //   const response = await axios.post(`${BASE_URL}/students/getClaimCollegesWithToken`, data, {
  //     headers: {
  //       'Authorization': `${localStorage.getItem('jwtToken')}`
  //     }
  //   });
  //   return response;
  // } else {
  //   const response = await axios.post(`${BASE_URL}/students/getClaimColleges`, data);
  //   return response;
  // }
  const response = await axios.post(
    `${BASE_URL}/students/getClaimColleges`,
    data
  );
  return response;
};

export const getCollegesWithToken = async (data) => {
  if (localStorage?.jwtToken) {
    const response = await axios.post(
      `${BASE_URL}/students/getSeccapCollegesWithToken`,
      data,
      {
        headers: {
          Authorization: `${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response;
  } else {
    const response = await axios.post(
      `${BASE_URL}/students/getClaimColleges`,
      data
    );
    return response;
  }
};

export const isInIxBoard = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/ixBoard`, data);
  return response;
};

export const isInBoard = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/board2`, data);
  return response;
};

export const getProvinces = async () => {
  const response = await axios.get(`${BASE_URL}/provinces`);
  return response;
};

export const getCollegesByDistrictId = async (district_id) => {
  const response = await axios.get(
    `${BASE_URL}/sindhgames/getCollegesByDistrictId/${district_id}`
  );
  return response;
};

export const getCollegesLocation = async () => {
  const response = await axios.get(`${BASE_URL}/colleges/collegesLocation`);
  return response;
};

export const getAvailableSeats = async (gender_id) => {
  const response = await axios.get(
    `${BASE_URL}/faculties/availableSeats/${gender_id}`
  );
  return response;
};

export const getSindhGames = async () => {
  const response = await axios.get(`${BASE_URL}/sindhgames`);
  return response;
};

export const getSindhTalents = async () => {
  const response = await axios.get(`${BASE_URL}/sindhtalents`);
  return response;
};

export const getLastDateToApply = async () => {
  const response = await axios.get(`${BASE_URL}/students/getLastDateToApply`);
  return response;
};

export const getRegions = async () => {
  const response = await axios.get(`${BASE_URL}/regions/getAllRegions`);
  return response;
};

export const getSindhRegions = async () => {
  const response = await axios.get(`${BASE_URL}/regions/getSindhRegions`);
  return response;
};

export const getDistricts = async () => {
  const response = await axios.get(`${BASE_URL}/districts/getAllDistricts`);
  return response;
};

export const getReligions = async () => {
  const response = await axios.get(`${BASE_URL}/students/getAllReligions`);
  return response;
};

export const getDistrictsByRegionId = async (region_id) => {
  const response = await axios.post(`${BASE_URL}/districts/get`, { region_id });
  return response;
};

export const getTownsByDistrictId = async (district_id) => {
  const response = await axios.post(`${BASE_URL}/subdivisions/district`, {
    district_id,
  });
  return response;
};

export const getProvinceByProvinceId = async (province_id) => {
  const response = await axios.post(`${BASE_URL}/provinces/province`, {
    province_id,
  });
  return response;
};

export const getReligionByReligionId = async (religion_id) => {
  const response = await axios.post(`${BASE_URL}/students/religion`, {
    religion_id,
  });
  return response;
};

export const getBoardByBoardId = async (board_id) => {
  const response = await axios.post(`${BASE_URL}/boards/board`, {
    board_id,
  });
  return response;
};

export const getStudyGroupByGroupId = async (group_id) => {
  const response = await axios.post(`${BASE_URL}/studyGroups/studyGroup`, {
    group_id,
  });
  return response;
};

export const getSubGroupBySubGroupId = async (sub_group_id) => {
  const response = await axios.post(`${BASE_URL}/studyGroups/subGroup`, {
    sub_group_id,
  });
  return response;
};

export const getRegionByRegionId = async (region_id) => {
  const response = await axios.post(`${BASE_URL}/regions/region`, {
    region_id,
  });
  return response;
};

export const getDistrictByDistrictId = async (district_id) => {
  const response = await axios.post(`${BASE_URL}/districts/district`, {
    district_id,
  });
  return response;
};

export const getFacultyByFacultyId = async (faculty_id) => {
  const response = await axios.post(`${BASE_URL}/faculties/faculty`, {
    faculty_id,
  });
  return response;
};

export const getBoardsByProvinceId = async (province_id) => {
  const response = await axios.post(`${BASE_URL}/boards/province`, {
    province_id,
  });
  return response;
};

export const getStudyGroups = async () => {
  const response = await axios.get(`${BASE_URL}/studyGroups`);
  return response;
};

export const getSubGroupsByGroupId = async (group_id) => {
  const response = await axios.post(`${BASE_URL}/studyGroups/subGroups`, {
    group_id,
  });
  return response;
};

export const getFacultiesByStudyGroupIdAndSubjectId = async (
  group_id,
  sub_group_id,
  scaled_obtained_marks
) => {
  const data = {
    group_id,
    sub_group_id,
    scaled_obtained_marks,
  };
  const response = await axios.post(`${BASE_URL}/students/faculties`, data);
  return response;
};

export const getZones = async (region_id) => {
  const data = {
    region_id,
  };
  const response = await axios.post(`${BASE_URL}/zones/region`, data);
  return response;
};

export const getZone = async (zone_id) => {
  const response = await axios.get(`${BASE_URL}/zones/zone/${zone_id}`);
  return response;
};

export const getFacultiesByStudyGroupIdAndSubjectIdWithCollegeId = async (
  group_id,
  sub_group_id,
  scaled_obtained_marks,
  college_id,
  gender_id
) => {
  const data = {
    group_id,
    sub_group_id,
    scaled_obtained_marks,
    college_id,
    gender_id,
  };
  const response = await axios.post(
    `${BASE_URL}/students/facultiesWithCollegeId`,
    data
  );
  return response;
};

export const getSeccapAppliedCollegesByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/getSeccapAppliedCollegesByAppliedId`,
    data
  );
  return response;
};

export const getSeccapConfirmedSerialNo = async (applied_id, college_id) => {
  const response = await axios.get(
    `${BASE_URL}/students/seccapConfirmedSerialNo/${applied_id}/${college_id}`
  );
  return response;
};

export const getAllDiscussions = async (
  nextRows,
  offsetRows,
  subject_id,
  searchFilters
) => {
  const response = await axios.get(
    `${BASE_URL}/discussions/all/${nextRows}/${offsetRows}/${subject_id}/${searchFilters}`,
    {
      headers: { Authorization: localStorage.getItem("discussionsJwtToken") },
    }
  );
  return response;
};

export const getUserDiscussions = async (
  nextRows,
  offsetRows,
  role,
  id,
  searchFilters
) => {
  const response = await axios.get(
    `${BASE_URL}/discussions/userPosts/${nextRows}/${offsetRows}/${role}/${id}/${searchFilters}`,
    {
      headers: { Authorization: localStorage.getItem("discussionsJwtToken") },
    }
  );
  return response;
};

export const getUserProfile = async (role, id) => {
  const response = await axios.get(
    `${BASE_URL}/discussions/profile/${role}/${id}`,
    {
      headers: { Authorization: localStorage.getItem("discussionsJwtToken") },
    }
  );
  return response;
};

export const getPostsCount = async () => {
  const response = await axios.get(`${BASE_URL}/discussions/postsCount`, {
    headers: { Authorization: localStorage.getItem("discussionsJwtToken") },
  });
  return response;
};

export const getRootComments = async (
  nextRows,
  offsetRows,
  post_id,
  searchFilters
) => {
  const response = await axios.get(
    `${BASE_URL}/discussions/rootComments/${nextRows}/${offsetRows}/${post_id}/${searchFilters}`,
    {
      headers: { Authorization: localStorage.getItem("discussionsJwtToken") },
    }
  );
  return response;
};

export const getChildComments = async (parent_id, searchFilters) => {
  const response = await axios.get(
    `${BASE_URL}/discussions/childComments/${parent_id}//${searchFilters}`,
    {
      headers: { Authorization: localStorage.getItem("discussionsJwtToken") },
    }
  );
  return response;
};

export const getDiscussion = async (id) => {
  const response = await axios.get(`${BASE_URL}/discussions/discussion/${id}`, {
    headers: { Authorization: localStorage.getItem("discussionsJwtToken") },
  });
  return response;
};

export const likePost = async (id) => {
  const response = await axios.get(`${BASE_URL}/discussions/like/${id}`, {
    headers: { Authorization: localStorage.getItem("discussionsJwtToken") },
  });
  return response;
};

export const likeComment = async (id) => {
  const response = await axios.post(
    `${BASE_URL}/discussions/likeComment/${id}`,
    {},
    {
      headers: { Authorization: localStorage.getItem("discussionsJwtToken") },
    }
  );
  return response;
};

export const getCollegeByCollegeId = async (college_id) => {
  const data = {
    college_id,
  };

  const response = await axios.post(`${BASE_URL}/students/college`, data);
  return response;
};

export const getPermissionDataByAppliedId = async (applied_id, class_id) => {
  const data = {
    applied_id,
    class_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/getPermissionDataByAppliedId`,
    data
  );
  return response;
};

export const getPermissionXiiDataByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/getPermissionXiiDataByAppliedId`,
    data
  );
  return response;
};

export const getStudentCardDataByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/getStudentCardDataByAppliedId`,
    data
  );
  return response;
};

export const getStudentAttendanceReport = async (applied_id) => {
  const response = await axios.get(
    `${BASE_URL}/students/getStudentAttendanceReport/${applied_id}`
  );
  return response;
};

export const adClicksLog = async (
  applied_id,
  xiOrXiiStudent,
  olevelStudent
) => {
  const response = await axios.get(
    `${BASE_URL}/students/adClicksLog/${applied_id}/${xiOrXiiStudent}/${olevelStudent}`
  );
  return response;
};

export const getCollegesByZoneFacultyGender = async (
  zone_id,
  faculty_id,
  gender_id,
  district_id
) => {
  const data = {
    zone_id,
    faculty_id,
    gender_id,
    district_id,
  };

  const response = await axios.post(`${BASE_URL}/students/zoneColleges`, data);
  return response;

  // if (localStorage?.jwtToken) {
  //   const response = await axios.post(`${BASE_URL}/students/getPrincipalCollegesWithToken`, data, {
  //     headers: {
  //       'Authorization': `${localStorage.getItem('jwtToken')}`
  //     }
  //   });
  //   return response;
  // } else {
  //   const response = await axios.post(`${BASE_URL}/students/getColleges`, data);
  //   return response;
  // }
};

export const getCollegesByFacultyIdGenderIdAndRegionId = async (
  faculty_id,
  gender_id,
  region_id,
  scaled_obtained_marks
) => {
  const data = {
    region_id,
    faculty_id,
    gender_id,
    scaled_obtained_marks,
  };

  const response = await axios.post(`${BASE_URL}/students/getColleges`, data);
  return response;

  // if (localStorage?.jwtToken) {
  //   const response = await axios.post(`${BASE_URL}/students/getPrincipalCollegesWithToken`, data, {
  //     headers: {
  //       'Authorization': `${localStorage.getItem('jwtToken')}`
  //     }
  //   });
  //   return response;
  // } else {
  //   const response = await axios.post(`${BASE_URL}/students/getColleges`, data);
  //   return response;
  // }
};

export const getOlevelCollegesByFacultyIdGenderIdAndRegionId = async (
  faculty_id,
  gender_id,
  region_id
) => {
  const data = {
    region_id,
    faculty_id,
    gender_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/getOlevelColleges`,
    data
  );
  return response;

  // if (localStorage?.jwtToken) {
  //   const response = await axios.post(`${BASE_URL}/students/getPrincipalCollegesWithToken`, data, {
  //     headers: {
  //       'Authorization': `${localStorage.getItem('jwtToken')}`
  //     }
  //   });
  //   return response;
  // } else {
  //   const response = await axios.post(`${BASE_URL}/students/getColleges`, data);
  //   return response;
  // }
};

export const getCollegesByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/appliedColleges`,
    data
  );
  return response;
};

export const getOlevelCollegesByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/olevelAppliedColleges`,
    data
  );
  return response;
};

export const olevelAppliedGrades = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/olevelAppliedGrades`,
    data
  );
  return response;
};

export const getOlevelFacultyByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/faculties/olevelApplied`,
    data
  );
  return response;
};

export const getSeccapFacultyByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/faculties/seccapAppliedFaculty`,
    data
  );
  return response;
};

export const getFacultyByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(`${BASE_URL}/faculties/applied`, data);
  return response;
};

export const getOlevelCancelledByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/olevelCancelled`,
    data
  );
  return response;
};

export const getSeccapAppliedCancelledByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/seccapAppliedCancelled`,
    data
  );
  return response;
};

export const getCancelledByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(`${BASE_URL}/students/cancelled`, data);
  return response;
};

export const getOlevelCancelledAdmissionByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/olevelCancelledAdmission`,
    data
  );
  return response;
};

export const getSeccapCancelledAdmissionByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/seccapCancelledAdmission`,
    data
  );
  return response;
};

export const getCancelledAdmissionByAppliedId = async (applied_id) => {
  const data = {
    applied_id,
  };

  const response = await axios.post(
    `${BASE_URL}/students/cancelledAdmission`,
    data
  );
  return response;
};

export const isEmailInApplied = async (email, applied_id) => {
  const data = {
    email,
    applied_id,
  };
  const response = await axios.post(`${BASE_URL}/students/email`, data);
  return response;
};

export const isCnicInApplied = async (cnic, applied_id) => {
  const data = {
    cnic,
    applied_id,
  };
  const response = await axios.post(`${BASE_URL}/students/cnic`, data);
  return response;
};

export const isMobileInApplied = async (mobile, applied_id) => {
  const data = {
    mobile,
    applied_id,
  };
  const response = await axios.post(`${BASE_URL}/students/mobile`, data);
  return response;
};

export const saveImage = async (formData) => {
  await axios.post(`${BASE_URL}/students/saveImage`, formData);
};

export const saveImageOlevel = async (formData) => {
  await axios.post(`${BASE_URL}/students/saveImageOlevel`, formData);
};

export const saveImageComplaint = async (formData) => {
  await axios.post(`${BASE_URL}/students/saveImageComplaint`, formData);
};

export const addComplaint = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/addComplaint`, data);
  return response;
};

export const saveProfileImage = async (formData) => {
  await axios.post(`${BASE_URL}/students/saveProfileImage`, formData);
};

export const InsertApplication = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/apply`, data);
  return response;
};

export const Apply1 = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/apply1`, data, {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  });
  return response;
};

export const Apply2 = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/apply2`, data, {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  });
  return response;
};

export const CheckSeccapCnicAndMatricRollNoAppliedId = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/CheckSeccapCnicAndMatricRollNoAppliedId`,
    data
  );
  return response;
};

export const CheckSeccapCnicAndMatricRollNo = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/CheckSeccapCnicAndMatricRollNo`,
    data
  );
  return response;
};

export const CheckSeccapOlevelCnicAndPassingYearAppliedId = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/CheckSeccapOlevelCnicAndPassingYearAppliedId`,
    data
  );
  return response;
};

export const CheckSeccapOlevelCnicAndPassingYear = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/CheckSeccapOlevelCnicAndPassingYear`,
    data
  );
  return response;
};

export const UpdateCollegeInAdmission = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/updateCollegeInAdmission`,
    data,
    {
      headers: {
        Authorization: `${localStorage.getItem("jwtToken")}`,
      },
    }
  );
  return response;
};

export const cancelAdmissionByAdmin = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/cancelAdmissionByAdmin`,
    data,
    {
      headers: {
        Authorization: `${localStorage.getItem("jwtToken")}`,
      },
    }
  );
  return response;
};

export const updateAdminCollegeInAdmission = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/updateAdminCollegeInAdmission`,
    data,
    {
      headers: {
        Authorization: `${localStorage.getItem("jwtToken")}`,
      },
    }
  );
  return response;
};

export const updateCollegeInOlevelAdmission = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/updateCollegeInOlevelAdmission`,
    data,
    {
      headers: {
        Authorization: `${localStorage.getItem("jwtToken")}`,
      },
    }
  );
  return response;
};

export const SeccapApply = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/SeccapApply`, data, {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  });
  return response;
};

export const SeccapApplyAdmin = async (data) => {
  const response = await axios.post(
    `${BASE_URL}/students/SeccapApplyAdmin`,
    data,
    {
      headers: {
        Authorization: `${localStorage.getItem("jwtToken")}`,
      },
    }
  );
  return response;
};

export const Apply3 = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/apply3`, data, {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  });
  return response;
};

export const InsertApplicationOlevel = async (data) => {
  const response = await axios.post(`${BASE_URL}/students/applyOlevel`, data, {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  });
  return response;
};
