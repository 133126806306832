import React, { useEffect } from "react";
import { PreviewContainer, PreviewContent } from "./PreviewElements";
import { Button } from "../ButtonElements";
import jsPDF from "jspdf";
import background from "./../../images/background.jpg";
import GetAppIcon from "@material-ui/icons/GetApp";

import { useParams } from "react-router-dom";

import "./styles.css";
import Ad3 from "../Ad/Ad3";
import Ad from "../Ad";
import WhiteAd from "../Ad/WhiteAd";

const StudentCard = ({ studentData }) => {
  const params = useParams();
  const applied_id = params?.applied_id;

  useEffect(async () => {
    window.scrollTo(0, 0);
  }, []);

  const generatePDF = () => {
    let doc = new jsPDF("potrait", "pt", "a4", "false");

    doc.html(document.querySelector("#application"), {
      margin: [3, 3, 3, 3],
      autoPaging: "text",
      callback: function (pdf) {
        pdf.save(
          `student-card-${studentData?.name?.split(" ")[0]}-${
            studentData?.name?.split(" ")[1]
          }.pdf`
        );
      },
    });
  };

  // function generatePDF() {
  //   let printBtn = document.querySelector("#print-button");
  //   printBtn.style.display = "none";
  //   const input = document.getElementById("application");
  //   html2canvas(input)
  //     .then((canvas) => {
  //       var imgData = canvas.toDataURL('image/png');
  //       var imgWidth = 210;
  //       var pageHeight = 295;
  //       var imgHeight = canvas.height * imgWidth / canvas.width;
  //       var heightLeft = imgHeight;

  //       var doc = new jsPDF('p', 'mm');
  //       var position = 0;

  //       doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;

  //       while (heightLeft >= 0) {
  //         position = heightLeft - imgHeight;
  //         doc.addPage();
  //         doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  //         heightLeft -= pageHeight;
  //       }
  //       doc.save('permissionletter.pdf');

  //     })
  //   printBtn.style.display = "flex";
  // }

  return (
    <div>
      <PreviewContainer style={{ backgroundImage: `url(${background})` }}>
        <PreviewContent>
          <div>
            <div
              id="print-button"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <div style={{ width: "50px", marginBottom: "1%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ padding: "4px", borderRadius: "4px" }}
                  onClick={generatePDF}
                >
                  <GetAppIcon />
                </Button>
              </div>
            </div>
            <div style={{ padding: "0 2%" }}>
              <h3>
                Download and Get Print for Principal's Signature and Stamp
              </h3>
            </div>

            <div className="card-container" id="application">
              <div className="card">
                <div className="card-header">
                  <div className="card-header-left">
                    <div className="card-logo">
                      <img
                        className="logo-img"
                        src={"./images/logo.png"}
                        alt="logo"
                      />
                    </div>
                  </div>
                  <div className="card-header-right">
                    <div className="card-heading-1">
                      <div className="card-heading-1-left">
                        <h1></h1>
                      </div>
                      <div className="card-heading-1-right">
                        <h1>Government of Sindh</h1>
                        <h1>College Education Department</h1>
                      </div>
                    </div>
                    <div className="card-heading">
                      <h1>{studentData?.college_name}</h1>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="card-body-left">
                    <div className="card-body-left-up">
                      <div className="card-body-left-up-left">
                        <div className="card-profile-img">
                          <img
                            className="profile-img"
                            src="/images/profile.png"
                            alt="profile"
                          />
                        </div>
                      </div>
                      <div className="card-body-left-up-right">
                        <div className="card-body-info">
                          <div className="card-body-info-item">
                            <p className="card-body-info-item-left">Name:</p>
                            <h2 className="card-body-info-item-right">
                              {studentData?.name}
                            </h2>
                          </div>
                          <div className="card-body-info-item">
                            <p className="card-body-info-item-left">
                              Father Name:
                            </p>
                            <h2 className="card-body-info-item-right">
                              {studentData?.father_name}
                            </h2>
                          </div>
                          <div className="card-body-info-item">
                            <p className="card-body-info-item-left">
                              Date of Birth:
                            </p>
                            <h2 className="card-body-info-item-right">
                              {studentData?.date_of_birth}
                            </h2>
                          </div>
                          <div
                            style={{ marginTop: "4%" }}
                            className="card-body-info-item"
                          >
                            <p className="card-body-info-item-left">Class:</p>
                            <h2 className="card-body-info-item-right">
                              {studentData?.class_id == 1 ? "XI" : "XII"}
                            </h2>
                          </div>
                          <div className="card-body-info-item">
                            <p className="card-body-info-item-left">Group:</p>
                            <h2 className="card-body-info-item-right">
                              {studentData?.faculty}
                            </h2>
                          </div>
                          <div className="card-body-info-item">
                            <p className="card-body-info-item-left">Section:</p>
                            <h2 className="card-body-info-item-right"></h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body-left-down">
                      <div className="card-body-info">
                        <div
                          style={{ marginTop: "4%" }}
                          className="card-body-info-item"
                        >
                          <h2 className="card-body-info-item-left">
                            Parent's Mobile:
                          </h2>
                          <p className="card-body-info-item-right">
                            {studentData?.father_mobile}
                          </p>
                        </div>
                        <div
                          className="card-body-info-item"
                          style={{ alignItems: "center" }}
                        >
                          <h2 className="card-body-info-item-left">Address:</h2>
                          <p
                            className="card-body-info-item-right"
                            style={{ paddingRight: "10px" }}
                          >
                            {studentData?.home_address}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body-right">
                    <div className="card-qrcode">
                      <img
                        className="qrcode-img"
                        src={studentData?.qr_code_path}
                        alt="qr-code"
                      />
                      {/* <img
                        className='qrcode-img'
                        src={"https://seccap.dgcs.gos.pk/allimages/studentcardqrcodes/qrcode1fce651c3676767f9be8a95de975ee7b.png"}
                        alt="qr-code"
                      /> */}
                    </div>
                    <div className="card-body-info">
                      <div className="card-body-info-item">
                        <h2 className="card-body-info-item-left">
                          Student ID:
                        </h2>
                        <h2 className="card-body-info-item-right">
                          {studentData?.applied_id}
                        </h2>
                      </div>
                      <div
                        style={{ marginTop: "3%" }}
                        className="card-body-info-item"
                      >
                        <p
                          className="card-body-info-item-left"
                          style={{ fontSize: "11px" }}
                        >
                          Valid through:
                        </p>
                        <p
                          className="card-body-info-item-right"
                          style={{ fontSize: "11px" }}
                        >
                          31 May 2025
                        </p>
                      </div>
                      <div
                        style={{ marginTop: "7%" }}
                        className="card-body-info-item"
                      >
                        <h2
                          className="card-body-info-item-left"
                          style={{ fontSize: "11px" }}
                        >
                          Principal's Signature:
                        </h2>
                        <h2
                          className="card-body-info-item-right"
                          style={{ fontSize: "11px" }}
                        >
                          _____________
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <p>This card is NOT TRANSFERABLE.</p>
                  <p>
                    This card is provisional, Permanent card will be available
                    after board Enrollment
                  </p>
                  <p>
                    The College / College Education Department is not
                    responsible for any loss or expenses resulting from the
                    loss, theft, or misuse of this card. if this card is lost,
                    damaged, or stolen. If found, please return the card to
                    nearest Government Colleges.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Ad3 />
          <WhiteAd />
        </PreviewContent>
      </PreviewContainer>
      <Ad />
    </div>
  );
};

export default StudentCard;
