import Comment from "../Comment/Comment";

const CommentList = ({ comments, setComments, commentType }) => {
  return comments.map((comment) => (
    <div key={comment.id} className="comment-stack">
      <Comment
        commentProp={comment}
        comments={comments}
        setComments={setComments}
        commentType={commentType}
      />
    </div>
  ));
};

export default CommentList;
