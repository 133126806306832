import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { FragmentContainer, FragmentContent, FragmentHeader, FragmentHeaderH1 } from './FragmentsElements';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import isEmpty from "../../../helpers/validation";
import CustomAlert from "../../common/CustomAlert";
import CustomDialog from '../../common/CustomDialog';
import Divider from '@material-ui/core/Divider';
import * as ApiCalls from '../../../services/ApiCalls';


import CreateIcon from '@material-ui/icons/Create';

const PermissionXiiStudentDetailsInCollege = (props) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [reason, setReason] = useState('');

  const [province, setProvince] = useState('');
  const [addressRegion, setAddressRegion] = useState('');
  const [addressDistrict, setAddressDistrict] = useState('');
  const [districtClass8, setDistrictClass8] = useState('');
  const [districtClass5, setDistrictClass5] = useState('');
  const [domicileDistrict, setDomicileDistrict] = useState('');
  const [fatherDomicileDistrict, setFatherDomicileDistrict] = useState('');
  const [board, setBoard] = useState('');
  const [studyGroup, setStudyGroup] = useState('');
  const [subject, setSubject] = useState('');

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    await getProvinceByProvinceId(props?.studentDetailsInCollege?.province_id);
    await getAddressRegionByRegionId(props?.studentDetailsInCollege?.address_region_id);
    props?.studentDetailsInCollege?.address_district_id && await getAddressDistrictByDistrictId(props?.studentDetailsInCollege?.address_district_id);
    await getDomicileDistrictByDistrictId(props?.studentDetailsInCollege?.domicile_district_id);
    await getFatherDomicileDistrictByDistrictId(props?.studentDetailsInCollege?.father_domicile_district_id);
    await getDistrictClass8ByDistrictId(props?.studentDetailsInCollege?.class8_passing_district_id);
    await getDistrictClass5ByDistrictId(props?.studentDetailsInCollege?.class5_passing_district_id);
    await getBoardByBoardId(props?.studentDetailsInCollege?.board_id);
    await getStudyGroupByGroupId(props?.studentDetailsInCollege?.group_id);
    props?.studentDetailsInCollege?.sub_group_id && await getSubGroupBySubGroupId(props?.studentDetailsInCollege?.sub_group_id);
  }, []);

  const getProvinceByProvinceId = async (province_id) => {
    const res = await ApiCalls.getProvinceByProvinceId(province_id);
    if (res?.data?.status === 'success') {
      setProvince(res?.data?.data.province);
    }
  }

  const getAddressRegionByRegionId = async (region_id) => {
    const res = await ApiCalls.getRegionByRegionId(region_id);
    if (res?.data?.status === 'success') {
      setAddressRegion(res?.data?.data.region);
    }
  }

  const getAddressDistrictByDistrictId = async (district_id) => {
    const res = await ApiCalls.getDistrictByDistrictId(district_id);
    if (res?.data?.status === 'success') {
      setAddressDistrict(res?.data?.data?.district);
    }
  }

  const getDistrictClass8ByDistrictId = async (district_id) => {
    const res = await ApiCalls.getDistrictByDistrictId(district_id);
    if (res?.data?.status === 'success') {
      setDistrictClass8(res?.data?.data?.district);
    }
  }

  const getDistrictClass5ByDistrictId = async (district_id) => {
    const res = await ApiCalls.getDistrictByDistrictId(district_id);
    if (res?.data?.status === 'success') {
      setDistrictClass5(res?.data?.data?.district);
    }
  }

  const getDomicileDistrictByDistrictId = async (district_id) => {
    const res = await ApiCalls.getDistrictByDistrictId(district_id);
    if (res?.data?.status === 'success') {
      setDomicileDistrict(res?.data?.data?.district);
    }
  }

  const getFatherDomicileDistrictByDistrictId = async (district_id) => {
    const res = await ApiCalls.getDistrictByDistrictId(district_id);
    if (res?.data?.status === 'success') {
      setFatherDomicileDistrict(res?.data?.data?.district);
    }
  }

  const getBoardByBoardId = async (board_id) => {
    const res = await ApiCalls.getBoardByBoardId(board_id);
    if (res?.data?.status === 'success') {
      setBoard(res?.data?.data?.board);
    }
  }

  const getStudyGroupByGroupId = async (group_id) => {
    const res = await ApiCalls.getStudyGroupByGroupId(group_id);
    if (res?.data?.status === 'success') {
      setStudyGroup(res?.data?.data.group_name);
    }
  }

  const getSubGroupBySubGroupId = async (sub_group_id) => {
    const res = await ApiCalls.getSubGroupBySubGroupId(sub_group_id);
    if (res?.data?.status === 'success') {
      setSubject(res?.data?.data.science);
    }
  }

  const handleApproved = async () => {
    const data = {
      applied_id: props?.studentDetailsInCollege?.id,
    };

    await axios.post('https://www.dgcs.gos.pk/seccap2/students/approveXiiPermissions', data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });

    if (props?.studentDetailsInCollege?.status_id == 0) {
      props.setFragment('pendingpermissionsxii');
    } else if (props?.studentDetailsInCollege?.status_id == 1) {
      props.setFragment('permissionsxii');
    }


  }

  const handleCancelled = async () => {

    if (isEmpty(reason)) {
      setAlert('Please fill out Reason!', 'error');
    } else if (reason.length < 10) {
      setAlert("Reason field contains minimum 10 characters!", 'error');
    } else if (reason.length > 100) {
      setAlert("Reason field cannot contain maximum than 100 characters!", 'error');
    } else {

      // const data = {
      //   applied_id: props?.studentDetailsInCollege?.id,
      //   reason,
      // };
      // await axios.post('https://www.dgcs.gos.pk/seccap2/students/cancelSeccapAdmission', data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });

      // if (props?.studentDetailsInCollege?.status_id == 0) {
      //   props.setFragment('pendingpermissions');
      // } else if (props?.studentDetailsInCollege?.status_id == 1) {
      //   props.setFragment('permissions');
      // }

    }

  }

  return (
    <div>
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item xs={6} md={6}>
                <FragmentHeaderH1>Student Details</FragmentHeaderH1>
              </Grid>
              <Grid item xs={6} md={6}>
                {/* <div style={{ cursor: 'pointer', background: '#fff', height: '35px', padding: '5px', width: '35px', textAlign: 'center', alignItems: 'center', boxShadow: '0 1px 6px rgb(57 73 76 / 35%)', borderRadius: '50%' }} onClick={() => props.setFragment('editstudentpersonalinfo')}>
                  <CreateIcon />
                </div> */}
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider />
        <Container fixed>
          <FragmentContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <h1 style={{ marginBottom: '1rem' }}>
                  {props?.studentDetailsInCollege?.name}
                </h1>
                <div style={{ display: "flex", marginBottom: '0.5rem' }}>
                  <h4 style={{ marginRight: '10px' }}>Father Name: </h4>
                  <p>{props?.studentDetailsInCollege?.father_name}</p>
                </div>
                <div style={{ display: "flex", marginBottom: '0.5rem' }}>
                  <h4 style={{ marginRight: '10px' }}>Gender: </h4>
                  <p>{props?.studentDetailsInCollege?.gender}</p>
                </div>
                <div style={{ display: "flex", marginBottom: '0.5rem' }}>
                  <h4 style={{ marginRight: '10px' }}>Email: </h4>
                  <p>{props?.studentDetailsInCollege?.email}</p>
                </div>
              </Grid>
              <Grid item md={6}>
                <div style={{ display: 'flex', right: 0, justifyContent: 'flex-end', flexDirection: 'row-reverse' }}>
                  <img style={{ width: '100px', height: '100px', border: "1px solid #aaa", borderRadius: '5px' }} src="/images/profile.png" alt="profile" />
                </div>
              </Grid>

              <Grid container item xs={9} md={9}>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Mobile No</h4>
                    <p>{props?.studentDetailsInCollege?.mobile}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Nationality</h4>
                    <p>{props?.studentDetailsInCollege?.nationality}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Religion</h4>
                    <p>{props?.studentDetailsInCollege?.religion}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Date of Birth</h4>
                    <p>{props?.studentDetailsInCollege?.date_of_birth}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Place of Birth</h4>
                    <p>{props?.studentDetailsInCollege?.place_of_birth}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Cnic / B-Form</h4>
                    <p>{props?.studentDetailsInCollege?.cnic}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Domicile District</h4>
                    <p>{domicileDistrict}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Address Region</h4>
                    <p>{addressRegion}</p>
                  </Grid>
                  {addressDistrict && (
                    <Grid item md={4}>
                      <h4 style={{ marginBottom: '0.5rem' }}>Address District</h4>
                      <p>{addressDistrict}</p>
                    </Grid>
                  )}

                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={12}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Home Address</h4>
                    <p>{props?.studentDetailsInCollege?.home_address}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Father's Occupation</h4>
                    <p>{props?.studentDetailsInCollege?.father_occupation}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Father's / Guardian's Mobile Number</h4>
                    <p>{props?.studentDetailsInCollege?.father_mobile}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Father's Domicile District</h4>
                    <p>{fatherDomicileDistrict}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Father's / Guardian's CNIC</h4>
                    <p>{props?.studentDetailsInCollege?.father_cnic}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Mother's / Guardian's CNIC</h4>
                    <p>{props?.studentDetailsInCollege?.mother_cnic}</p>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={5} style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                  <Grid item xs={8} md={8}>
                    <h1 style={{ marginBottom: '0.5rem' }}>Educational Details</h1>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    {/* <div style={{ cursor: 'pointer', background: '#fff', height: '35px', padding: '5px', width: '35px', textAlign: 'center', alignItems: 'center', boxShadow: '0 1px 6px rgb(57 73 76 / 35%)', borderRadius: '50%' }} onClick={() => props.setFragment('editstudenteducationinfo')}>
                      <CreateIcon /> */}
                    {/* </div> */}
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Ninth Roll No</h4>
                    <p>{props?.studentDetailsInCollege?.ninth_roll_no}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Ninth Passing Year</h4>
                    <p>{props?.studentDetailsInCollege?.ninth_passing_year}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Matric Roll No</h4>
                    <p>{props?.studentDetailsInCollege?.matric_roll_no}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Passing Year</h4>
                    <p>{props?.studentDetailsInCollege?.matric_passing_year}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Ninth Total Marks</h4>
                    <p>{props?.studentDetailsInCollege?.ninth_total_marks}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Ninth Obtained Marks</h4>
                    <p>{props?.studentDetailsInCollege?.ninth_obtained_marks}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Ninth Scaled Total Marks</h4>
                    <p>{props?.studentDetailsInCollege?.ninth_scaled_total_marks}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Ninth Scaled Obtained Marks</h4>
                    <p>{props?.studentDetailsInCollege?.ninth_scaled_obtained_marks}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Matric Total Marks</h4>
                    <p>{props?.studentDetailsInCollege?.total_marks}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Matric Obtained Marks</h4>
                    <p>{props?.studentDetailsInCollege?.obtained_marks}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Matric Scaled Total Marks</h4>
                    <p>{props?.studentDetailsInCollege?.scaled_total_marks}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Matric Scaled Obtained Marks</h4>
                    <p>{props?.studentDetailsInCollege?.scaled_obtained_marks}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Province</h4>
                    <p>{province}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Board</h4>
                    <p>{board}</p>
                  </Grid>
                  <Grid item md={3}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Study Group</h4>
                    <p>{studyGroup}</p>
                  </Grid>
                  {subject && (
                    <Grid item md={3}>
                      <h4 style={{ marginBottom: '0.5rem' }}>Subject</h4>
                      <p>{subject}</p>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>

                  <Grid item md={12}>
                    <h4 style={{ marginBottom: '0.5rem' }}>School Name</h4>
                    <p>{props?.studentDetailsInCollege?.school_name}</p>
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Class 8 Passing District</h4>
                    <p>{districtClass8}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Class 8 Passing Year</h4>
                    <p>{props?.studentDetailsInCollege?.class8_passing_year}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Class 8 School Name</h4>
                    <p>{props?.studentDetailsInCollege?.class8_school_name}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Class 5 Passing District</h4>
                    <p>{districtClass5}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Class 5 Passing Year</h4>
                    <p>{props?.studentDetailsInCollege?.class5_passing_year}</p>
                  </Grid>
                  <Grid item md={4}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Class 5 School Name</h4>
                    <p>{props?.studentDetailsInCollege?.class5_school_name}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={12}>
                    <h4 style={{ marginBottom: '0.5rem' }}>Choice of Faculty</h4>
                    <p>{props?.studentDetailsInCollege?.faculty}</p>
                  </Grid>
                </Grid>
                {(props?.studentDetailsInCollege?.status_id == 0 || props?.studentDetailsInCollege?.status_id == 1) && (
                  <div style={{ width: '100%' }}>
                    <Grid container spacing={2} style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                      {props?.studentDetailsInCollege?.status_id == 0 && (
                        <Grid item md={3}>
                          <CustomDialog
                            btnText="Confirm"
                            title="Confirm Admission"
                            text=""
                            submitBtnText="Confirm"
                            handleApproved={handleApproved}
                          />
                        </Grid>
                      )}

                      <Grid item md={3}>
                        {/* <Button variant="contained" style={{ background: '#dc004e', color: '#fff' }} onClick={handleCancelled}>
                          Cancel
                        </Button> */}
                      </Grid>
                    </Grid>
                    {/* <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                      <Grid item xs={12} md={12}>
                        <TextField
                          id="outlined-multiline-static"
                          label="If Cancel Enter Reason"
                          multiline
                          fullWidth
                          rows={4}
                          value={reason}
                          onChange={(e) => e.target.value.length < 101 && setReason(e.target.value)}
                          variant="outlined"
                        />

                      </Grid>
                    </Grid> */}
                  </div>
                )}

              </Grid>
            </Grid>
            <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
          </FragmentContent>

          
          
          

        </Container>
      </FragmentContainer>
    </div>
  )
}

export default PermissionXiiStudentDetailsInCollege;
