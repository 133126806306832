import React, {useEffect} from "react";
import ScrollToTop from "../components/ScrollToTop";
import SignIn from "../components/Signin";
import {useHistory} from 'react-router-dom';

// Check for token
// if (localStorage.jwtToken) {
//     // Redirect to login
//     window.location.href = "/dashboard";
// }

const SigninPage = () => {
  let history = useHistory();
  useEffect(() => {
    // Check for token
    if (localStorage.jwtToken) {
        // Redirect to login
        history.push("/dashboard");
    }
  }, [])
  return (
    <>
      <ScrollToTop />
      <SignIn />
    </>
  );
};

export default SigninPage;
