// src/MapComponent.js
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import RoutingMachine from 'leaflet-routing-machine';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import ControlGeocoder from 'leaflet-control-geocoder';

// Create a default icon for the markers
const defaultIcon = new L.Icon({
  iconUrl: 'https://unpkg.com/leaflet/dist/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
});

const MapComponent = ({ locations, userLocation }) => {
  const [map, setMap] = useState(null);
  const [routingControl, setRoutingControl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredLocations, setFilteredLocations] = useState(locations);

  useEffect(() => {
    if (userLocation) {
      const mapInstance = L.map('map').setView(userLocation, 13);
      setMap(mapInstance);

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapInstance);

      const routing = L.Routing.control({
        waypoints: [L.latLng(userLocation?.lat, userLocation?.lng)],
        routeWhileDragging: true,
        geocoder: ControlGeocoder.nominatim(),
      }).addTo(mapInstance);
      setRoutingControl(routing);

      mapInstance.on('geosearch:result', function(e) {
        const { lat, lng } = e.results[0]?.latlng;
        if (routingControl) {
          routingControl.setWaypoints([
            L.latLng(userLocation.lat, userLocation.lng),
            L.latLng(lat, lng)
          ]);
        }
      });
    }
  }, [userLocation]);

  useEffect(() => {
    if (map) {
      // Clear previous markers
      map.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          map.removeLayer(layer);
        }
      });

      // Add markers for filtered locations
      filteredLocations.forEach(location => {
        L.marker([location?.lat, location?.lng], { icon: defaultIcon })
          .addTo(map)
          .bindPopup(location.name);
      });

      // Update routing control if a specific college is selected
      if (routingControl && searchQuery) {
        const selectedLocation = filteredLocations.find(loc => loc.name.toLowerCase() === searchQuery.toLowerCase());
        if (selectedLocation) {
          routingControl.setWaypoints([
            L.latLng(userLocation?.lat, userLocation?.lng),
            L.latLng(selectedLocation?.lat, selectedLocation?.lng)
          ]);
        }
      }
    }
  }, [filteredLocations, searchQuery, map]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query) {
      setFilteredLocations(locations.filter(loc => loc.name.toLowerCase().includes(query.toLowerCase())));
    } else {
      setFilteredLocations(locations);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Search colleges..."
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ margin: '10px', padding: '5px', width: '300px' }}
      />
      <div id="map" style={{ height: '80vh', width: '100%' }}></div>
    </div>
  );
};

export default MapComponent;
