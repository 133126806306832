import styled from "styled-components";

export const PreviewContainer = styled.div`
  background: #137a4c;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 0;
  min-height: 100vh;
  height: 100%;
`;

export const PreviewContent = styled.div`
  background: #fff;
  border-top: 1px solid #e4ebe4;
  border-left: 1px solid #e4ebe4;
  border-right: 1px solid #e4ebe4;
  width: 70%;
  padding: 3% 0%;
  padding-left: 3%;
  padding-right: 7%;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  @media screen and (min-width: 1500px) {
    width: 70%;
  }
`;

export const ButtonContent = styled.div`
  background: #fff;
  border-bottom: 1px solid #e4ebe4;
  width: 70%;
  padding-left: 3%;
  padding-right: 7%;
  padding-bottom: 3%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  @media screen and (min-width: 1500px) {
    width: 70%;
  }
`;

export const PreviewItem = styled.div`
  padding: 5% 0;
`;

export const PreviewHeading1 = styled.h1`
  font-size: 1.5vw;
  @media screen and (max-width: 768px) {
    font-size: 2.5vw;
  }
`;

export const PreviewHeading4 = styled.h4`
  font-size: 1.1vw;
  @media screen and (max-width: 768px) {
    font-size: 1.5vw;
  }
`;

export const PreviewParagraph = styled.p`
  font-size: 0.9vw;
  @media screen and (max-width: 768px) {
    font-size: 1.3vw;
  };
`;