import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Select master blaster campaign settings', 'Create an ad group', 'Create an ad'];
}

function getStep(color, text) {
  return <p style={{ color: `${color}`, fontSize: '13px', fontWeight: 'bold', borderBottom: `1px solid ${color}`}}>{text}</p>
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Select campaign settings...';
    case 1:
      return 'What is an ad group anyways?';
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown stepIndex';
  }
}

export default function Stepper(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const options = [
    {
      step: 2,
      text: '1. Set Educational Details',
    },
    {
      step: 3,
      text: '2. Set Personal Details',
    },
    {
      step: 4,
      text: '3. Select Choice of Faculty',
    },
    {
      step: 5,
      text: '4. Choose Your Concerned Colleges',
    },
    {
      step: 6,
      text: '5. Preview',
    },
  ]

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between'}}>
      {options.map(item => {

          if (item.step === props.step) {
            return getStep('#137a4c', item.text)
          }else if (item.step < props.step && window.innerWidth > 768) {
            return getStep('#000', item.text)
          } else if (window.innerWidth > 768) {
            return getStep('#aaa', item.text)
          }
          
        }
      )}
    </div>
  );
}
