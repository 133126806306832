import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="/about-us" onClick={toggle}>
            Admission Policy
          </SidebarLink>
          <SidebarLink to="/colleges" onClick={toggle}>
            Colleges
          </SidebarLink>
          <SidebarLink to="/status" onClick={toggle}>
            Check Status
          </SidebarLink>
          <SidebarLink to="/cut-off" onClick={toggle}>
            Cut Off
          </SidebarLink>
          {/* <SidebarLink to="services" onClick={toggle}>
            Merit Lists
          </SidebarLink> */}
          {/* <SidebarLink to="services" onClick={toggle}>
            Downloads
          </SidebarLink> */}
          <SidebarLink to="/signin" onClick={toggle}>
            Sign In
          </SidebarLink>
        </SidebarMenu>
        {/* <SideBtnWrap>
          <SidebarRoute to="/apply-online">Apply Online</SidebarRoute>
        </SideBtnWrap> */}
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
