import React, { useState, useEffect } from "react";
import {RegistrationContainer, RegistrationContent, RegistrationItem, RegistrationHeading} from './RegistrationFormElements';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import isEmpty, { isNumber } from "../../../../helpers/validation";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import * as ApiCalls from '../../../../services/ApiCalls';
import background from "./../../../../images/background.jpg";
import Stepper from "../stepper/Stepper";
import Grid from '@material-ui/core/Grid';

import studygroupsData from "../../../../data/studygroups.json";
import sciencegroupsData from "../../../../data/sciencegroups.json";
import districtsData from "../../../../data/districts.json";
import getScaledObtainedMarks from '../../../../functions/getScaledObtainedMarks';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  formControl: {
    margin: theme.spacing(1),
    width: "98%",
    minWidth: 120,
    marginBottom: '1%'
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  multilineColor:{
    color:'red'
  },
}));



const RegistrationForm = (props) => {
  const classes = useStyles();
  const [provinces, setProvinces] = useState([]);
  const [boards, setBoards] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [studyGroups, setStudyGroups] = useState([]);
  const [subjects, setSubjects] = useState([]);

  const passingYearsClass8 = [
    props.passingYear - 4,
    props.passingYear - 3,
    props.passingYear - 2
  ];

  const passingYearsClass5 = [
    props.passingYear - 8,
    props.passingYear - 7,
    props.passingYear - 6,
    props.passingYear - 5
  ]

  useEffect(async () => {
    window.scrollTo(0, 0);
    props.setOpenBackDrop(false);
    if ((props.availInDataBase || props.applied) && (props.subject || props.studyGroup == 1)) getSubGroupsByGroupId(props.studyGroup);
    if (props.marksheetImage) await props.setShowMarksheetImage(`${ApiCalls.imageURL}/${props.marksheetImage}`);
  }, []);

  const getSubGroupsByGroupId = (group_id) => {
    const subjects = sciencegroupsData?.filter(item => item.group_id == group_id);
    setSubjects(subjects);
  }

  const handleImage = (e) => {
    const img = e.target.files[0];
    props.setShowMarksheetImage(URL.createObjectURL(img));
    props.setImageMarksheet(e.target.files[0]);
    props.setImageMarksheetName(e.target.files[0].name);

  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'studyGroup') {
      props.setSubject('');
      props.setStudyGroup(value);
      getSubGroupsByGroupId(value);
    }
    if (name === 'subject') {
      props.setSubject(value);
    }
    if (name === 'totalMarks') {
      if ((isNumber(value) || value === '') && value.length < 5) {
        if (parseInt(value) < parseInt(props.obtainedMarks) || value === '') {
          props.setObtainedMarks(value);
        }
        props.setTotalMarks(value);
      }
    }
    if (name === 'obtainedMarks') {
      if ((isNumber(value) || value === '') && value.length < 5) {
        if (parseInt(value) > parseInt(props.totalMarks)) {
          props.setAlert('Obtained Marks can not be greater than Total Marks', 'error');
        } else {
          props.setObtainedMarks(value);
        }
      }
    }
    if (name === 'schoolName') {
      if (value.length < 101) {
        props.setSchoolName(value);
      }
    }
    if (name === 'schoolType') {
      props.setSchoolType(value);
    }
    if (name === 'passingYearClass8') {
      props.setPassingYearClass8(value);
    }
    if (name === 'districtClass8') {
      props.setDistrictClass8(value);
    }
    if (name === 'schoolNameClass8') {
      if (value.length < 101) {
        props.setSchoolNameClass8(value);
      }
    }
    if (name === 'passingYearClass5') {
      props.setPassingYearClass5(value);
    }
    if (name === 'districtClass5') {
      props.setDistrictClass5(value);
    }
    if (name === 'schoolNameClass5') {
      if (value.length < 101) {
        props.setSchoolNameClass5(value);
      }
    }
  };

  const handleBack = () => {
    props.setStudyGroup('');
    props.setSubject('');
    props.setTotalMarks('');
    props.setObtainedMarks('');
    props.setSchoolName('');
    props.setImageMarksheet('');
    props.setImageMarksheetName('');
    props.setMarksheetImage('');
    props.setAppliedId('');
    props.setFullName('');
    props.setFatherName('');
    props.setEmail('');
    props.setMobileNumber('');
    props.setGender('');
    props.setFatherMobileNumber('');
    props.setFatherCnic('');
    props.setRegion('');
    props.setFaculty('');
    props.setSelectedColleges([]);
    props.setAvailInDataBase(false);
    props.setApplied(false);
    props.setPassingYearClass8('');
    props.setSchoolNameClass8('');
    props.setPassingYearClass5('');
    props.setSchoolNameClass5('');
    props.setStep(1);
  }

  const handleClick = async () => {
    if (isEmpty(props.studyGroup)) {
      props.setAlert('Please select Study Group!', 'error');
    } else if (props.studyGroup.length > 100) {
      props.setAlert("Study Group field cannot contain maximum than 100 characters!", 'error');
    } else if (props.studyGroup == 1 && isEmpty(props.subject)) {
      props.setAlert('Please select Subject!', 'error');
    } else if (props.studyGroup == 1 && props.subject.length > 100) {
      props.setAlert("Subject field cannot contain maximum than 100 characters!", 'error');
    } else if (isEmpty(props.totalMarks)) {
      props.setAlert('Please fill out Total Marks!', 'error');
    } else if (!isNumber(props.totalMarks)) {
      props.setAlert('Total Marks field contains only numbers!', 'error');
    } else if (props.totalMarks.length > 4) {
      props.setAlert("Total Marks field cannot contain maximum than 4 numbers!", 'error');
    } else if (isEmpty(props.obtainedMarks)) {
      props.setAlert('Please fill out Obtained Marks!', 'error');
    } else if (!isNumber(props.obtainedMarks)) {
      props.setAlert('Obtained Marks field contains only numbers!', 'error');
    } else if (props.obtainedMarks.length > 4) {
      props.setAlert("Obtained Marks field cannot contain maximum than 4 numbers!", 'error');
    } else if (isEmpty(props.schoolName)) {
      props.setAlert('Please fill out School Name!', 'error');
    } else if (props.schoolName.length > 100) {
      props.setAlert("School Name field cannot contain maximum than 100 characters!", 'error');
    } else if (isEmpty(props.schoolType)) {
      props.setAlert('Please select Type of School!', 'error');
    } else if (props.schoolType != 1 && props.schoolType != 2) {
      props.setAlert('Please select valid Type of School!', 'error');
    } else if (isEmpty(props.passingYearClass8)) {
      props.setAlert('Please select Class 8 Passing Year!', 'error');
    } else if (isEmpty(props.districtClass8)) {
      props.setAlert('Please select Class 8 District!', 'error');
    } else if (props.districtClass8.length > 100) {
      props.setAlert("Class 8 District field cannot contain maximum than 100 characters!", 'error');
    } else if (isEmpty(props.schoolNameClass8)) {
      props.setAlert('Please fill out CLass 8 School Name!', 'error');
    } else if (props.schoolNameClass8.length > 100) {
      props.setAlert("CLass 8 School Name field cannot contain maximum than 100 characters!", 'error');
    } else if (isEmpty(props.passingYearClass5)) {
      props.setAlert('Please select Class 5 Passing Year!', 'error');
    } else if (isEmpty(props.districtClass5)) {
      props.setAlert('Please select Class 5 District!', 'error');
    } else if (props.districtClass5.length > 100) {
      props.setAlert("Class 5 District field cannot contain maximum than 100 characters!", 'error');
    } else if (isEmpty(props.schoolNameClass5)) {
      props.setAlert('Please fill out CLass 5 School Name!', 'error');
    } else if (props.schoolNameClass5.length > 100) {
      props.setAlert("CLass 5 School Name field cannot contain maximum than 100 characters!", 'error');
    } else if (parseInt(props.obtainedMarks) > parseInt(props.totalMarks)) {
      props.setAlert("Obtained Marks cannot be greater than Total Marks!", 'error');
    } else if (!props.availInDataBase && isEmpty(props.imageMarksheetName) && isEmpty(props.marksheetImage) && props.board != 2) {
      props.setAlert("Please upload Matric Marksheet!", 'error');
    } else {

      props.setOpenBackDrop(true);

      const scaled_obtained_marks = getScaledObtainedMarks(props.totalMarks, props.obtainedMarks, props.passingYear);
      
      props.setScaledObtainedMarks(scaled_obtained_marks);

      let imageMarksheetName = null;
      let formData = null;
      let applied_id = null;

      if (props.applied === true) {
        applied_id = props.appliedId;
      }

      if (props.marksheetImage) {
        imageMarksheetName = props.marksheetImage;
      }

      if (!props.availInDataBase && props.board != 2 && isEmpty(props.marksheetImage)) {
        imageMarksheetName =  `marksheet-${new Date().getTime()}-${props.matricRollNo}`;
        
        formData = new FormData();
        formData.append("image_url", props.imageMarksheet, imageMarksheetName);

        await ApiCalls.saveImage(formData);
      }

      const data = {
        province_id: props.province,
        board_id: props.board,
        cnic: props.cnic,
        matric_roll_no: props.matricRollNo,
        passing_year: props.passingYear,
        group_id: props.studyGroup,
        sub_group_id: props.subject,
        total_marks: props.totalMarks,
        obtained_marks: props.obtainedMarks,
        scaled_total_marks: 850,
        scaled_obtained_marks: scaled_obtained_marks,
        school_name: props.schoolName,
        school_type_id: props.schoolType,
        class8_passing_year: props.passingYearClass8,
        class8_passing_district_id: props.districtClass8,
        class8_school_name: props.schoolNameClass8,
        class5_passing_year: props.passingYearClass5,
        class5_passing_district_id: props.districtClass5,
        class5_school_name: props.schoolNameClass5,
        $is_in_board: props.availInDataBase ? 1 : 0,
        marksheet_image: imageMarksheetName,
        applied_id,
        vaccinated: props.vaccinated,
        region_id: props.region,
      };

      const response = await ApiCalls.Apply1(data);

      if (response?.data?.data) {
        if (applied_id === null) {
          props.setAppliedId(response?.data?.data);
        }

        
        setTimeout(() => {
          props.setStep(3);
        }, 1000);
        
      } else {
        props.setOpenBackDrop(false);
        props.setAlert("Something went wrong, please try again!", 'error');
      }

      

    }
    
  };

  return (
    <div>
    <RegistrationContainer style={{ backgroundImage: `url(${background})` }}>
      <RegistrationContent>
        <Stepper step={props.step}/>
        <RegistrationItem>
          <div style={{marginBottom: '5%', marginLeft: '8px'}}>
            <RegistrationHeading>Educational Details</RegistrationHeading>
          </div>
          <Grid container spacing={2}>
          
          <Grid item xs={12} sm={12} md={12}>
            {studygroupsData?.length > 0 && (
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-studyGroup">Matric Study Group</InputLabel>
                <Select
                  native
                  value={props.studyGroup}
                  onChange={handleChange}
                  id="outlined-studyGroup"
                  label="Matric Study Group"
                  name="studyGroup"
                >
                  <option aria-label="None" value="" />
                  {studygroupsData?.length > 0 && studygroupsData?.map(item => (
                    <option value={item?.id}>{item?.group_name}</option>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          
          <Grid item xs={12} sm={12} md={12}>
            {subjects?.length > 0 && (
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-subject">Matric Subject</InputLabel>
                <Select
                  native
                  value={props.subject}
                  onChange={handleChange}
                  id="outlined-subject"
                  label="Matric Subject"
                  name="subject"
                  // disabled={props.availInDataBase && props.subject != '' && true}
                >
                  <option aria-label="None" value="" />
                  {subjects?.length > 0 && subjects?.map(item => (
                    <option value={item?.id}>{item?.science}</option>
                  ))}
                </Select>
              </FormControl>
            )}
          </Grid>
          
          <Grid item xs={12} sm={12} md={6}>
            <div style={{marginTop: '2%', marginLeft: '8px'}}>
              <TextField fullWidth id="outlined-total" label="Matric Total Marks" type="text" variant="outlined" name="totalMarks" value={props.totalMarks} onChange={handleChange} disabled={props.availInDataBase && true} />
            </div>
          </Grid>
          
          <Grid item xs={12} sm={12} md={6}>
            <div style={{marginTop: '2%', marginLeft: '8px'}}>
              <TextField fullWidth id="outlined-obtained" label="Matric Obtained Marks" type="text" variant="outlined" name="obtainedMarks" value={props.obtainedMarks} onChange={handleChange} />
              <p>Please Enter Valid Obtained Marks, Otherwise you won't be able get Admission</p>
            </div>
          </Grid>
          
          <Grid item xs={12} sm={12} md={6}>
            <div style={{marginTop: '3%', marginLeft: '8px'}}>
              <TextField fullWidth id="outlined-school" label="Matric School Name" type="text" variant="outlined" name="schoolName" value={props.schoolName} onChange={handleChange} />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-schoolType">Type of School in Matric</InputLabel>
              <Select
                native
                id="outlined-schoolType"
                value={props.schoolType}
                onChange={handleChange}
                label="Type of School in Matric"
                name="schoolType"
              >
                <option aria-label="None" value="" />
                <option value="1">GOVERNMENT</option>
                <option value="2">PRIVATE</option>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={12} md={6}>
            <FormControl style={{marginTop: '3%'}} fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-passing-year-class8">Class 8 Passing Year</InputLabel>
              <Select
                native
                value={props.passingYearClass8}
                onChange={handleChange}
                label="Class 8 Passing Year"
                name="passingYearClass8"
                id="outlined-passing-year-class8"
              >
                <option aria-label="None" value="" />
                {passingYearsClass8.map(item => (
                  <option value={item}>{item}</option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          {districtsData?.length > 0 && (
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-district-class8">Class 8 Passing District</InputLabel>
                <Select
                  native
                  value={props.districtClass8}
                  onChange={handleChange}
                  label="Class 8 Passing District"
                  name="districtClass8"
                  id="outlined-district-class8"
                >
                  <option aria-label="None" value="" />
                  {districtsData?.length > 0 && districtsData?.map(item => (
                    <option value={item?.id}>{item?.district}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          
          
          <Grid item xs={12} sm={12} md={12}>
            <div style={{marginTop: '2%', marginLeft: '8px'}}>
              <TextField fullWidth id="outlined-school-class8" label="Class 8 School Name" type="text" variant="outlined" name="schoolNameClass8" value={props.schoolNameClass8} onChange={handleChange} />
            </div>
          </Grid>
          
          <Grid item xs={12} sm={12} md={6}>
            <FormControl style={{marginTop: '3%'}} fullWidth variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-passing-year-class5">Class 5 Passing Year</InputLabel>
              <Select
                native
                value={props.passingYearClass5}
                onChange={handleChange}
                label="Class 5 Passing Year"
                name="passingYearClass5"
                id="outlined-passing-year-class5"
              >
                <option aria-label="None" value="" />
                {passingYearsClass5.map(item => (
                  <option value={item}>{item}</option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          {districtsData?.length > 0 && (
            <Grid item xs={12} sm={12} md={6}>
              <FormControl fullWidth variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-district-class5">Class 5 Passing District</InputLabel>
                <Select
                  native
                  value={props.districtClass5}
                  onChange={handleChange}
                  label="Class 5 Passing District"
                  name="districtClass5"
                  id="outlined-district-class5"
                >
                  <option aria-label="None" value="" />
                  {districtsData?.length > 0 && districtsData?.map(item => (
                    <option value={item?.id}>{item?.district}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          
          <Grid item xs={12} sm={12} md={12}>
            <div style={{marginTop: '2%', marginLeft: '8px'}}>
              <TextField fullWidth id="outlined-school-class5" label="Class 5 School Name" type="text" variant="outlined" name="schoolNameClass5" value={props.schoolNameClass5} onChange={handleChange} />
            </div>
          </Grid>
          </Grid>
          
          {!props.availInDataBase && props.board != 2 && (
            <div>
              <div style={{marginTop: '5%', marginLeft: '0px'}}>
                <div className={classes.root}>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="contained-button-file"
                    type="file"
                    onChange={handleImage}
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="contained" color="primary" component="span">
                      <PhotoCamera /> Upload Martic Marksheet
                    </Button>
                  </label>
                  <input accept="image/*" className={classes.input} onChange={handleImage} id="icon-button-file" type="file" />
                </div>
              </div>
              <div style={{marginTop: '3%', marginLeft: '8px'}}>
                <img style={{ width: '20vw', height: '100%'}} src={props.showMarksheetImage} alt="Marksheet" />
              </div>
            </div>
          )}

          
          
          <div style={{marginTop: '5%', marginLeft: '8px'}}>
            <Button variant="contained" color="default" style={{marginRight: '1rem'}} onClick={handleBack} >
              Back
            </Button>
            <Button variant="contained" color="primary" onClick={handleClick}>
                Save & Continue
            </Button>
          </div>
        </RegistrationItem>
      </RegistrationContent>
    </RegistrationContainer>
    
    </div>
  );
};

export default RegistrationForm;
