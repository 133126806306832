import React, { useState, useEffect, useRef } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import SimpleBackdrop from "../../common/Backdrop";
import WhiteAd from "../../Ad/WhiteAd";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import CustomAlert from "../../common/CustomAlert";
import NavBar from "../NavBar/NavBar";
import { IoIosArrowBack } from "react-icons/io";

const NewTopic = () => {
  const editor = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const params = useParams();
  const history = useHistory();
  let subject_id;

  let sub = params.category;

  if (sub === "english") {
    subject_id = 1;
  } else if (sub === "biology") {
    subject_id = 12;
  } else if (sub === "mathematics") {
    subject_id = 8;
  } else if (sub === "physics") {
    subject_id = 7;
  } else if (sub === "chemistry") {
    subject_id = 6;
  } else if (sub === "computer-science") {
    subject_id = 13;
    sub = sub.split("-").join(" ");
  }

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClick = async () => {
    const data = {
      title,
      content,
      subject_id,
    };

    if (title.trim() === "") {
      setAlert("Title is required!", "error");
    } else {
      await setOpenBackDrop(true);

      let res = await axios.post(
        "https://www.dgcs.gos.pk/seccap2/discussions",
        data,
        {
          headers: {
            Authorization: `${localStorage.getItem("discussionsJwtToken")}`,
          },
        }
      );

      if (res) {
        await setOpenBackDrop(false);
      }

      if (res?.data?.status === "success") {
        setTitle("");
        setContent("");
        setAlert("Post has been added successfully!", "success");
        setTimeout(() => {
          history.push(`/discussions/${params.category}`);
        }, 2000);
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, "error");
      } else {
        setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  const visitNewTopicPage = async (subject_id) => {
    const data = {
      subject_id,
    };

    let res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/discussions/visitNewTopicPage",
      data,
      {
        headers: {
          Authorization: `${localStorage.getItem("discussionsJwtToken")}`,
        },
      }
    );
  };

  useEffect(() => {
    visitNewTopicPage(subject_id);
    // Check for token
    if (!localStorage?.discussionsJwtToken) {
      // Redirect to login
      history.push("/login");
    }
  }, []);

  return (
    <div>
      <div className="navbar">
        <NavBar />
      </div>
      <div className="main-container">
        <SimpleBackdrop openBackDrop={openBackDrop} />
        <div>
          <Link
            to={`/discussions/${params.category}`}
            style={{
              display: "flex",
              color: "#6b7280",
              justifyContent: "center",
              alignItems: "center",
              textDecoration: "none",
              width: "100px",
              height: "32px",
              border: "1px solid #9ca3af",
              borderRadius: "8px",
            }}
          >
            <IoIosArrowBack style={{ marginRight: "4px" }} /> Back
          </Link>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h1 style={{ marginTop: "30px" }}>
              {sub.charAt(0).toUpperCase() + sub.slice(1)}
            </h1>
          </div>
        </div>
        <div
          style={{
            border: "1px solid #c3c3c3",
            padding: "25px",
            borderRadius: "10px",
            marginTop: "90px",
          }}
        >
          <div style={{ marginBottom: "10px" }}>
            <TextField
              fullWidth
              id="outlined-title"
              label="Title"
              type="text"
              variant="outlined"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div style={{ marginTop: "50px" }}>
            <RichTextEditor content={content} setContent={setContent} />
          </div>
          <div style={{ marginTop: "30px" }}>
            <div
              style={{
                display: "flex",
                background: "#000",
                color: "#fff",
                padding: "10px 15px",
                borderRadius: "25px",
                fontWeight: "bold",
                fontSize: "14px",
                cursor: "pointer",
                width: "121px",
              }}
              onClick={handleClick}
            >
              <div>
                <p>Publish Topic</p>
              </div>
            </div>
          </div>
          <CustomAlert
            open={open}
            message={message}
            severity={severity}
            handleClose={handleClose}
          />
        </div>
      </div>
      <WhiteAd />
    </div>
  );
};

export default NewTopic;
