import styled from "styled-components";

export const BannerContainer = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-color: #101522;
  background: #450a0a;

  // @media screen and (max-width: 768px) {
  //   height: 1100px;
  // }

  // @media screen and (max-width: 480px) {
  //   height: 1300px;
  // }
`;

export const BannerTitle = styled.h3`
  color: #fff;
  letter-spacing: 2px;

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;