import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import axios from "axios";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import isEmpty, { isNumber } from "../../../helpers/validation";
import CustomAlert from "../../common/CustomAlert";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import {
  FragmentContainer,
  FragmentHeader,
  FragmentHeaderH1,
  FragmentContent,
} from "./FragmentsElements";
import SimpleBackdrop from "./../../common/Backdrop";

const OlevelUnapprovedDetails = (props) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [colleges, setColleges] = useState([]);
  const [reason, setReason] = useState("");
  const [marks, setMarks] = useState("");
  const [openBackDrop, setOpenBackDrop] = React.useState(false);

  let history = useHistory();

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCollegesByAppliedId(props.unapprovedData.id);
  }, []);

  const handleApproved = async () => {
    if (false) {
      setAlert("Please Enter Marks!", "error");
    } else {
      await setOpenBackDrop(true);
      const data = {
        applied_id: props.unapprovedData.id,
        marks,
      };
      const res = await axios.post(
        "https://www.dgcs.gos.pk/seccap2/students/olevelApprove",
        data,
        { headers: { Authorization: localStorage.getItem("jwtToken") } }
      );
      await setOpenBackDrop(false);
      if (res?.data?.status === "success") {
        await props.setFragment("olevelunapproved");
      } else if (
        res.data.error.message === "user is not verified" ||
        res.data.error.message === "user is not authorized"
      ) {
        // Remove token from localStorage
        localStorage.removeItem("jwtToken");
        // Redirect to login
        history.push("/signin");
      }
    }
  };

  const handleCancelled = async () => {
    if (isEmpty(reason)) {
      setAlert("Please fill out Reason!", "error");
    } else if (reason.length < 10) {
      setAlert("Reason field contains minimum 10 characters!", "error");
    } else if (reason.length > 100) {
      setAlert(
        "Reason field cannot contain maximum than 100 characters!",
        "error"
      );
    } else {
      await setOpenBackDrop(true);

      const data = {
        applied_id: props.unapprovedData.id,
        reason,
      };
      const res = await axios.post(
        "https://www.dgcs.gos.pk/seccap2/students/olevelCancel",
        data,
        { headers: { Authorization: localStorage.getItem("jwtToken") } }
      );
      await setOpenBackDrop(false);
      if (res?.data?.status === "success") {
        await props.setFragment("olevelunapproved");
      } else if (
        res.data.error.message === "user is not verified" ||
        res.data.error.message === "user is not authorized"
      ) {
        // Remove token from localStorage
        localStorage.removeItem("jwtToken");
        // Redirect to login
        history.push("/signin");
      }
    }
  };

  const getCollegesByAppliedId = async (applied_id) => {
    const data = {
      applied_id,
    };

    const res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/colleges/olevelApplied",
      data
    );
    if (res?.data?.status === "success") {
      setColleges(res?.data?.data);
    }
  };

  return (
    <div>
      <FragmentContainer>
        <SimpleBackdrop openBackDrop={openBackDrop} />
        <FragmentHeader>
          <FragmentHeaderH1>Applicant's Detail</FragmentHeaderH1>
        </FragmentHeader>
        <Divider />
      </FragmentContainer>
      <FragmentContainer>
        <Container fixed>
          <FragmentContent>
            <Grid container spacing={2}>
              <Grid container item xs={12} md={6}>
                <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Name</h4>
                    <p>{props?.unapprovedData?.name}</p>
                  </Grid>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Gender</h4>
                    <p>{props?.unapprovedData?.gender}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Passing Year</h4>
                    <p>{props?.unapprovedData?.passing_year}</p>
                  </Grid>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: "0.5rem" }}>
                      Choice of Faculty
                    </h4>
                    <p>{props?.unapprovedData?.faculty}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Total Marks</h4>
                    <p>{props?.unapprovedData?.total_marks}</p>
                  </Grid>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Obtained Marks</h4>
                    <p>{props?.unapprovedData?.obtained_marks}</p>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: "0.5rem" }}>
                      Scaled Total Marks
                    </h4>
                    <p>{props?.unapprovedData?.scaled_total_marks}</p>
                  </Grid>
                  <Grid item md={6}>
                    <h4 style={{ marginBottom: "0.5rem" }}>
                      Scaled Obtained Marks
                    </h4>
                    <p>{props?.unapprovedData?.scaled_obtained_marks}</p>
                  </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                  <Grid item md={12}>
                    <h4 style={{ marginBottom: "0.5rem" }}>Chosen Colleges</h4>
                    {colleges?.map((item, index) => (
                      <p style={{ marginBottom: "0.5rem" }}>{`${index + 1}) ${
                        item?.college_name
                      }`}</p>
                    ))}
                  </Grid>
                </Grid>
                {props?.unapprovedData?.subject1 &&
                  props?.unapprovedData?.grade1 && (
                    <Grid
                      className="my-row"
                      container
                      spacing={1}
                      style={{ marginBottom: "3%" }}
                    >
                      <Grid className="my-item" item xs={1}>
                        <p>1.</p>
                      </Grid>
                      <Grid className="my-item" item xs={6}>
                        <h4 style={{ marginBottom: "2%" }}>Subject</h4>
                        <p>{props?.unapprovedData?.subject1}</p>
                      </Grid>
                      <Grid className="my-item" item xs={5}>
                        <h4 style={{ marginBottom: "2%" }}>Grade</h4>
                        <p>{props?.unapprovedData?.grade1}</p>
                      </Grid>
                    </Grid>
                  )}
                {props?.unapprovedData?.subject2 &&
                  props?.unapprovedData?.grade2 && (
                    <Grid
                      className="my-row"
                      container
                      spacing={1}
                      style={{ marginBottom: "3%" }}
                    >
                      <Grid className="my-item" item xs={1}>
                        <p>2.</p>
                      </Grid>
                      <Grid className="my-item" item xs={6}>
                        <h4 style={{ marginBottom: "2%" }}>Subject</h4>
                        <p>{props?.unapprovedData?.subject2}</p>
                      </Grid>
                      <Grid className="my-item" item xs={5}>
                        <h4 style={{ marginBottom: "2%" }}>Grade</h4>
                        <p>{props?.unapprovedData?.grade2}</p>
                      </Grid>
                    </Grid>
                  )}
                {props?.unapprovedData?.subject3 &&
                  props?.unapprovedData?.grade3 && (
                    <Grid
                      className="my-row"
                      container
                      spacing={1}
                      style={{ marginBottom: "3%" }}
                    >
                      <Grid className="my-item" item xs={1}>
                        <p>3.</p>
                      </Grid>
                      <Grid className="my-item" item xs={6}>
                        <h4 style={{ marginBottom: "2%" }}>Subject</h4>
                        <p>{props?.unapprovedData?.subject3}</p>
                      </Grid>
                      <Grid className="my-item" item xs={5}>
                        <h4 style={{ marginBottom: "2%" }}>Grade</h4>
                        <p>{props?.unapprovedData?.grade3}</p>
                      </Grid>
                    </Grid>
                  )}
                {props?.unapprovedData?.subject4 &&
                  props?.unapprovedData?.grade4 && (
                    <Grid
                      className="my-row"
                      container
                      spacing={1}
                      style={{ marginBottom: "3%" }}
                    >
                      <Grid className="my-item" item xs={1}>
                        <p>4.</p>
                      </Grid>
                      <Grid className="my-item" item xs={6}>
                        <h4 style={{ marginBottom: "2%" }}>Subject</h4>
                        <p>{props?.unapprovedData?.subject4}</p>
                      </Grid>
                      <Grid className="my-item" item xs={5}>
                        <h4 style={{ marginBottom: "2%" }}>Grade</h4>
                        <p>{props?.unapprovedData?.grade4}</p>
                      </Grid>
                    </Grid>
                  )}
                {props?.unapprovedData?.subject5 &&
                  props?.unapprovedData?.grade5 && (
                    <Grid
                      className="my-row"
                      container
                      spacing={1}
                      style={{ marginBottom: "3%" }}
                    >
                      <Grid className="my-item" item xs={1}>
                        <p>5.</p>
                      </Grid>
                      <Grid className="my-item" item xs={6}>
                        <h4 style={{ marginBottom: "2%" }}>Subject</h4>
                        <p>{props?.unapprovedData?.subject5}</p>
                      </Grid>
                      <Grid className="my-item" item xs={5}>
                        <h4 style={{ marginBottom: "2%" }}>Grade</h4>
                        <p>{props?.unapprovedData?.grade5}</p>
                      </Grid>
                    </Grid>
                  )}
                {props?.unapprovedData?.subject6 &&
                  props?.unapprovedData?.grade6 && (
                    <Grid
                      className="my-row"
                      container
                      spacing={1}
                      style={{ marginBottom: "3%" }}
                    >
                      <Grid className="my-item" item xs={1}>
                        <p>6.</p>
                      </Grid>
                      <Grid className="my-item" item xs={6}>
                        <h4 style={{ marginBottom: "2%" }}>Subject</h4>
                        <p>{props?.unapprovedData?.subject6}</p>
                      </Grid>
                      <Grid className="my-item" item xs={5}>
                        <h4 style={{ marginBottom: "2%" }}>Grade</h4>
                        <p>{props?.unapprovedData?.grade6}</p>
                      </Grid>
                    </Grid>
                  )}
                {props?.unapprovedData?.subject7 &&
                  props?.unapprovedData?.grade7 && (
                    <Grid
                      className="my-row"
                      container
                      spacing={1}
                      style={{ marginBottom: "3%" }}
                    >
                      <Grid className="my-item" item xs={1}>
                        <p>7.</p>
                      </Grid>
                      <Grid className="my-item" item xs={6}>
                        <h4 style={{ marginBottom: "2%" }}>Subject</h4>
                        <p>{props?.unapprovedData?.subject7}</p>
                      </Grid>
                      <Grid className="my-item" item xs={5}>
                        <h4 style={{ marginBottom: "2%" }}>Grade</h4>
                        <p>{props?.unapprovedData?.grade7}</p>
                      </Grid>
                    </Grid>
                  )}
                {props?.unapprovedData?.subject8 &&
                  props?.unapprovedData?.grade8 && (
                    <Grid
                      className="my-row"
                      container
                      spacing={1}
                      style={{ marginBottom: "3%" }}
                    >
                      <Grid className="my-item" item xs={1}>
                        <p>8.</p>
                      </Grid>
                      <Grid className="my-item" item xs={6}>
                        <h4 style={{ marginBottom: "2%" }}>Subject</h4>
                        <p>{props?.unapprovedData?.subject8}</p>
                      </Grid>
                      <Grid className="my-item" item xs={5}>
                        <h4 style={{ marginBottom: "2%" }}>Grade</h4>
                        <p>{props?.unapprovedData?.grade8}</p>
                      </Grid>
                    </Grid>
                  )}
                {props?.unapprovedData?.subject9 &&
                  props?.unapprovedData?.grade9 && (
                    <Grid
                      className="my-row"
                      container
                      spacing={1}
                      style={{ marginBottom: "3%" }}
                    >
                      <Grid className="my-item" item xs={1}>
                        <p>9.</p>
                      </Grid>
                      <Grid className="my-item" item xs={6}>
                        <h4 style={{ marginBottom: "2%" }}>Subject</h4>
                        <p>{props?.unapprovedData?.subject9}</p>
                      </Grid>
                      <Grid className="my-item" item xs={5}>
                        <h4 style={{ marginBottom: "2%" }}>Grade</h4>
                        <p>{props?.unapprovedData?.grade9}</p>
                      </Grid>
                    </Grid>
                  )}
                <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                  <Grid item md={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleApproved}
                    >
                      Approve
                    </Button>
                  </Grid>
                  <Grid item md={6}>
                    <Button
                      variant="contained"
                      style={{ background: "#dc004e", color: "#fff" }}
                      onClick={handleCancelled}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "1rem" }}>
                  {/* <Grid item md={12}>
                    <TextField fullWidth id="outlined-marks" label="If Approve Enter Marks" name="marks" value={marks} onChange={(e) => (isNumber(e.target.value) || e.target.value === '') && setMarks(e.target.value)} type="text" variant="outlined" />

                  </Grid> */}
                  <Grid item md={12}>
                    <TextField
                      id="outlined-multiline-static"
                      label="If Cancel Enter Reason"
                      multiline
                      fullWidth
                      rows={4}
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={6}>
                <img
                  width="500"
                  src={`https://www.dgcs.gos.pk/seccap2/public/olevelimages2022/${props.unapprovedData.marksheet_image}`}
                  alt="Gradesheet"
                />
              </Grid>
            </Grid>
            <CustomAlert
              open={open}
              message={message}
              severity={severity}
              handleClose={handleClose}
            />
          </FragmentContent>
        </Container>
      </FragmentContainer>
    </div>
  );
};

export default OlevelUnapprovedDetails;
