import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
  ],
};

const RichTextEditor = ({ content, setContent }) => {
  return (
    <div>
      <ReactQuill
        theme="snow"
        value={content}
        onChange={setContent}
        className="editor-input"
        modules={modules}
      />
    </div>
  );
};

export default RichTextEditor;
