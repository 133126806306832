import React, { useState, useEffect } from "react";
import { Button } from "../ButtonElements";
import background from "../../images/background.jpg";
import {
  ApplyOnlineContainer,
  ApplyOnlineContent,
  ApplyOnlineItem,
} from "./ApplyOnlineElements";
import FullScreenDialog from "../common/FullScreenDialog";
import Ad from "../Ad";

const ClaimCollege = () => {
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(!hover);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ApplyOnlineContainer style={{ backgroundImage: `url(${background})` }}>
        <ApplyOnlineContent>
          {/* <ApplyOnlineItem>
            <Button
              to="/available-seats"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
              big="true"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Check Seats Availability
            </Button>
          </ApplyOnlineItem> */}
          <ApplyOnlineItem>
            <Button
              to="/claim-college"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
              big="true"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Claim College
            </Button>
          </ApplyOnlineItem>

          <ApplyOnlineItem>
            <Button
              to="/olevel-claim-college"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              big="true"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              Claim College (For O Level Students)
            </Button>
          </ApplyOnlineItem>
        </ApplyOnlineContent>
      </ApplyOnlineContainer>
      <Ad />
    </>
  );
};

export default ClaimCollege;
