import React from "react";
import ScrollToTop from "../components/ScrollToTop";
import Olevel from "../components/Olevel";

const OlevelPage = () => {
  return (
    <>
      <ScrollToTop />
      <Olevel />
    </>
  );
};

export default OlevelPage;
