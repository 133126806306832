import React, { useState, useEffect } from "react";
import axios from "axios";
import ScrollToTop from "../../../components/ScrollToTop";
import { useHistory } from "react-router-dom";
import SimpleBackdrop from "../../common/Backdrop";
import {
  NewApplicationContainer,
  NewApplicationContent,
  NewApplicationItem,
  NewApplicationHeading,
} from "./../DiscussionElements/DiscussionElements";
import background from "./../../../images/bg-discussions.jpg";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CustomAlert from "../../common/CustomAlert";
import isEmpty, { isNumber, isNumberOrDash } from "../../../helpers/validation";
import { olevelPassingYears } from "../../../data/passingyears";
import {
  setDiscussionTokenInLocalStorage,
  getUser,
} from "../../../functions/functions";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  multilineColor: {
    color: "red",
  },
}));

const Login = () => {
  let history = useHistory();
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const [data, setData] = useState({
    login_as: "1",
    cnic: "",
    matric_roll_no: "",
    passing_year: "",
    email: "",
    password: "",
  });

  const handleClick = async () => {
    if (isEmpty(data?.login_as)) {
      setAlert("Please Select Login as!", "error");
    } else if (
      (parseInt(data?.login_as) === 1 || parseInt(data?.login_as) === 2) &&
      isEmpty(data?.cnic)
    ) {
      setAlert("Please Enter Cnic / B-form no!", "error");
    } else if (
      parseInt(data?.login_as) === 1 &&
      isEmpty(data?.matric_roll_no)
    ) {
      setAlert("Please Enter Matric roll no!", "error");
    } else if (parseInt(data?.login_as) === 2 && isEmpty(data?.passing_year)) {
      setAlert("Please Select Passing year!", "error");
    } else if (parseInt(data?.login_as) === 3 && isEmpty(data?.email)) {
      setAlert("Please Enter your Email!", "error");
    } else if (parseInt(data?.login_as) === 3 && isEmpty(data?.password)) {
      setAlert("Please Enter your Password!", "error");
    } else {
      setOpenBackDrop(true);

      const res = await axios.post(
        "https://www.dgcs.gos.pk/seccap2/users/dLogin",
        data
      );

      if (res) {
        setOpenBackDrop(false);
      }

      if (res?.data?.data) {
        const { token } = res.data.data;

        if (token) {
          // Save to localStorage
          setDiscussionTokenInLocalStorage(token);
          // Redirect to login
          history.push("/discussions");
          // Decode token to get user data
          const user = getUser(token);
        } else {
          setOpenBackDrop(false);
          setAlert("Invalid Credentials!", "error");
        }
      } else {
        setOpenBackDrop(false);
        setAlert("Invalid Credentials!", "error");
      }
    }
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (value?.length < 255) {
      if (name === "cnic") {
        if (isNumberOrDash(value) && value?.length < 16) {
          setData((preValue) => ({
            ...preValue,
            [name]: value,
          }));
        }
      } else if (name === "matric_roll_no") {
        if (isNumber(value) && value?.length < 10) {
          setData((preValue) => ({
            ...preValue,
            [name]: value,
          }));
        }
      } else if (name === "login_as") {
        setData(() => ({
          login_as: value,
          cnic: "",
          matric_roll_no: "",
          passing_year: "",
          email: "",
          password: "",
        }));
      } else {
        setData((preValue) => ({
          ...preValue,
          [name]: value,
        }));
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    // Check for token
    if (localStorage?.discussionsJwtToken) {
      // Redirect to login
      history.push("/discussions");
    }
  }, []);
  return (
    <>
      <ScrollToTop />
      <div>
        <SimpleBackdrop openBackDrop={openBackDrop} />
        <div>
          <NewApplicationContainer
            style={{ backgroundImage: `url(${background})` }}
          >
            <NewApplicationContent>
              <NewApplicationItem>
                <div style={{ marginBottom: "5%" }}>
                  <NewApplicationHeading>Login</NewApplicationHeading>
                </div>

                <FormControl
                  style={{ marginBottom: "10px" }}
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-login-as">Login as</InputLabel>
                  <Select
                    native
                    value={data?.login_as}
                    onChange={handleChange}
                    id="outlined-login-as"
                    label="Login as"
                    name="login_as"
                  >
                    <option value="1">Student</option>
                    <option value="2">Olevel Student</option>
                    <option value="3">Staff</option>
                  </Select>
                </FormControl>

                {(parseInt(data?.login_as) === 1 ||
                  parseInt(data?.login_as) === 2) && (
                  <div style={{ marginBottom: "10px" }}>
                    <TextField
                      fullWidth
                      id="outlined-cnic"
                      placeholder="xxxxx-xxxxxxx-x"
                      label="CNIC / B-FORM No"
                      type="text"
                      variant="outlined"
                      name="cnic"
                      value={data?.cnic}
                      onChange={handleChange}
                    />
                  </div>
                )}

                {parseInt(data?.login_as) === 1 && (
                  <div style={{ marginBottom: "10px" }}>
                    <TextField
                      fullWidth
                      id="outlined-matric-roll-no"
                      label="Enter Your Matric Roll No"
                      name="matric_roll_no"
                      value={data?.matric_roll_no}
                      onChange={handleChange}
                      type="text"
                      variant="outlined"
                    />
                  </div>
                )}

                {parseInt(data?.login_as) === 2 && (
                  <div style={{ marginBottom: "10px" }}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-passing-year">
                        Select Passing Year
                      </InputLabel>
                      <Select
                        native
                        value={data?.passing_year}
                        onChange={handleChange}
                        label="Select Passing Year"
                        name="passing_year"
                      >
                        <option aria-label="None" value="" />

                        {olevelPassingYears.map((item) => (
                          <option value={item}>{item}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}

                {parseInt(data?.login_as) === 3 && (
                  <>
                    <div style={{ marginBottom: "10px" }}>
                      <TextField
                        fullWidth
                        id="outlined-email"
                        label="Enter Your Email"
                        name="email"
                        value={data?.email}
                        onChange={handleChange}
                        type="email"
                        variant="outlined"
                      />
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <TextField
                        fullWidth
                        id="outlined-password"
                        label="Enter Your Password"
                        name="password"
                        value={data?.password}
                        onChange={handleChange}
                        type="password"
                        variant="outlined"
                      />
                    </div>
                  </>
                )}

                <div style={{ marginTop: "5%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    Login
                  </Button>
                </div>
              </NewApplicationItem>
            </NewApplicationContent>
          </NewApplicationContainer>
        </div>

        <CustomAlert
          open={open}
          message={message}
          severity={severity}
          handleClose={handleClose}
        />
      </div>
    </>
  );
};

export default Login;
