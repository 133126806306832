const getScaledObtainedMarks = (totalMarks, obtainedMarks, passingYear) => {
  const year = new Date().getFullYear();
  const previousyear = year - 1;
  const beforeTwoYear = year - 2;
  const beforeThreeYear = year - 3;

  const scaled_total_marks = 850;
  let scaled_obtained_marks = (obtainedMarks / totalMarks) * scaled_total_marks;
  scaled_obtained_marks = Math.ceil(scaled_obtained_marks);

  if (passingYear == previousyear) {
    scaled_obtained_marks = scaled_obtained_marks - 5;
  } else if (passingYear == beforeTwoYear) {
    scaled_obtained_marks = scaled_obtained_marks - 10;
  } else if (passingYear == beforeThreeYear) {
    scaled_obtained_marks = scaled_obtained_marks - 15;
  }

  return scaled_obtained_marks;
};

export default getScaledObtainedMarks;
