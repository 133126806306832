import React, { useState, useEffect } from "react";
import {
  NewApplicationContainer,
  NewApplicationContent,
  NewApplicationItem,
  NewApplicationHeading,
} from "../Complaint/NewApplicationElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Button from "@material-ui/core/Button";
import isEmpty, { isNumber } from "../../helpers/validation";
import CustomAlert from "../common/CustomAlert";
import SimpleBackdrop from "../common/Backdrop";
import * as ApiCalls from "../../services/ApiCalls";
import { useLocation } from "react-router";
import background from "./../../images/background.jpg";

import { isCNIC, isNumberOrDash } from "../../helpers/validation";
import { useHistory } from "react-router-dom";
import { olevelPassingYears } from "../../data/passingyears";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { ApplyOnlineItem } from "../ApplyOnline/ApplyOnlineElements";
import FullScreenDialog from "../common/FullScreenDialog";
import SelectGames from "./SelectGames";
import PersonalInfoForm from "./PersonalInfoForm";
import SindhGamesCard from "./SindhGamesCard";
import WhiteAd from "../Ad/WhiteAd";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  input: {
    display: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  multilineColor: {
    color: "red",
  },
}));

const SindhGames = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const [selectedGames, setSelectedGames] = useState([]);

  const [step, setStep] = useState(1);
  // Personal Profile
  const [appliedId, setAppliedId] = useState("");
  const [sindhGamesAppliedId, setSindhGamesAppliedId] = useState("");
  const [reviewedBy, setReviewBy] = useState("");

  const [fullName, setFullName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [collegeRegion, setCollegeRegion] = useState("");
  const [collegeDistrict, setCollegeDistrict] = useState("");
  const [collegeTown, setCollegeTown] = useState("");
  const [college, setCollege] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [profileImages, setProfileImages] = useState([]);
  const [selectedProfileImages, setSelectedProfileImages] = useState([]);

  const [contactNo, setContactNo] = useState("");
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const [matricRollNo, setMatricRollNo] = useState("");
  const [cnic, setCnic] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [olevelStudent, setOlevelStudent] = useState("No");
  const [xiOrXiiStudent, setXiOrXiiStudent] = useState("XI");
  const [collegeClass, setCollegeClass] = useState("");
  const [showImage, setShowImage] = useState("");
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [error, setError] = useState(false);
  const [image_path, setImagePath] = useState("");

  const [availInDataBase, setAvailInDataBase] = useState(false);
  const [applied, setApplied] = useState(false);

  useEffect(async () => {
    window.scrollTo(0, 0);
    await setOpenBackDrop(false);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleImage = (e) => {
    const img = e.target.files[0];
    setShowImage(URL.createObjectURL(img));
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name);
  };

  const handleChange = async (event) => {
    const { name, value } = event.target;

    if (name === "collegeClass") {
      await setCollegeClass(value);
    }

    if (name === "olevelStudent") {
      await setOlevelStudent(value);
      await setAppliedId("");
      await setCnic("");
      await setMatricRollNo("");
      await setPassingYear("");
      await setFullName("");
      await setContactNo("");
      await setComment("");
      await setImage("");
      await setImageName("");
      await setShowImage("");
    }

    if (name === "cnic") {
      if (isNumberOrDash(value) && value.length < 16) {
        setCnic(value);
      }
    }

    if (name === "matricRollNo") {
      if ((isNumber(value) || value === "") && value.length < 11) {
        setMatricRollNo(value);
      }
    }

    if (name === "passingYear") {
      if ((isNumber(value) || value === "") && value.length < 5) {
        setPassingYear(value);
      }
    }

    if (name === "reviewBy") {
      await setReviewBy(value);
      await setAppliedId("");
      await setCnic("");
      await setMatricRollNo("");
      await setPassingYear("");
      await setFullName("");
      await setContactNo("");
      await setComment("");
      await setImage("");
      await setImageName("");
      await setShowImage("");
      await setOlevelStudent("No");
    } else if (name === "name" && value.length < 101) {
      setFullName(value);
    } else if (
      name === "contact" &&
      ((isNumber(value) && value.length < 12) || value === "")
    ) {
      setContactNo(value);
    } else if (name === "comment") {
      if (value?.length < 1001) {
        setComment(value);
      }
    }
  };

  const handleClick = async () => {
    let applied_id = "";
    let err = false;

    if (isEmpty(collegeClass.trim())) {
      setAlert("Please select Class!", "error");
    } else if (isEmpty(cnic.trim())) {
      setAlert("Please fill out CNIC / B-FORM No!", "error");
    } else if (!isCNIC(cnic.trim())) {
      setAlert("Please fill out valid CNIC / B-FORM No!", "error");
    } else if (cnic.length !== 15) {
      setAlert("CNIC / B-FORM No field contains only 15 characters!", "error");
    } else if (
      (collegeClass === "XI" || collegeClass === "XII") &&
      olevelStudent === "No" &&
      isEmpty(matricRollNo)
    ) {
      setAlert("Please fill out Maric Roll No!", "error");
    } else if (
      (collegeClass === "XI" || collegeClass === "XII") &&
      olevelStudent === "No" &&
      matricRollNo.length > 10
    ) {
      setAlert(
        "Maric Roll No field cannot contain maximum than 10 numbers!",
        "error"
      );
    } else if (
      (collegeClass === "XI" || collegeClass === "XII") &&
      olevelStudent === "Yes" &&
      isEmpty(passingYear)
    ) {
      setAlert("Please fill out Passing Year!", "error");
    } else if (
      (collegeClass === "XI" || collegeClass === "XII") &&
      olevelStudent === "Yes" &&
      passingYear.length > 4
    ) {
      setAlert(
        "Passing Year field cannot contain maximum than 4 numbers!",
        "error"
      );
    } else {
      const dataSindhGamesApplied = {
        cnic,
      };
      const resSindhGamesApplied = await ApiCalls.sindhGamesApplied(
        dataSindhGamesApplied
      );
      if (resSindhGamesApplied?.data?.status === "success") {
        if (resSindhGamesApplied?.data?.data?.length != 0) {
          const resSindhGamesAppliedData = resSindhGamesApplied?.data?.data;
          await setSindhGamesAppliedId(resSindhGamesAppliedData?.id);
          await setAppliedId(resSindhGamesAppliedData?.applied_id);
          await setFullName(resSindhGamesAppliedData?.full_name);
          await setFatherName(resSindhGamesAppliedData?.father_name);
          await setEmail(resSindhGamesAppliedData?.email);
          await setMobileNumber(resSindhGamesAppliedData?.mobile);
          await setGender(resSindhGamesAppliedData?.gender_id);
          await setCnic(resSindhGamesAppliedData?.cnic);
          await setDateOfBirth(resSindhGamesAppliedData?.date_of_birth);
          await setHomeAddress(resSindhGamesAppliedData?.home_address);
          await setImagePath(resSindhGamesAppliedData?.image_path);
        }
      }

      if (collegeClass === "GAD") {
        setStep(2);
      } else {
        setOpenBackDrop(true);

        if (
          (collegeClass === "XI" || collegeClass === "XII") &&
          olevelStudent === "No"
        ) {
          const data = {
            matric_roll_no: matricRollNo,
            cnic,
          };

          let resApplied;

          if (collegeClass === "XI") {
            resApplied = await ApiCalls.isSeccapAppliedSindhGames(data);
          } else if (collegeClass === "XII") {
            resApplied = await ApiCalls.isSeccapAppliedSindhGames(data);
          }

          if (resApplied?.data?.status === "success") {
            if (resApplied?.data?.data?.length === 0) {
              await setOpenBackDrop(false);
              setAlert(
                "Please make sure your admission is confirmed by College or Enter valid data!",
                "error"
              );
              err = true;
              await setError(true);
            } else {
              const dataApplied = resApplied?.data?.data;
              applied_id = dataApplied?.id;
              await setAppliedId(dataApplied?.id);
              await setAppliedId(dataApplied?.id);
              await setFullName(dataApplied?.name);
              await setFatherName(dataApplied?.father_name);
              await setEmail(dataApplied?.email);
              await setMobileNumber(dataApplied?.mobile);
              await setGender(dataApplied?.gender_id);
              await setCnic(dataApplied?.cnic);
              await setDateOfBirth(dataApplied?.date_of_birth);
              await setCollegeRegion(dataApplied?.region_id);
              await setCollegeDistrict(dataApplied?.district_id);
              await setCollege(dataApplied?.college_id);
              await setHomeAddress(dataApplied?.home_address);
            }
          } else if (resApplied?.data?.error?.message) {
            await setOpenBackDrop(false);
            setAlert(resApplied?.data?.error?.message, "error");
            err = true;
            await setError(true);
          } else {
            await setOpenBackDrop(false);
            setAlert("Something went wrong, Please try again!", "error");
            err = true;
            await setError(true);
          }
        }

        if (
          (collegeClass === "XI" || collegeClass === "XII") &&
          olevelStudent === "Yes"
        ) {
          const data = {
            passing_year: passingYear,
            cnic,
          };

          let resApplied;

          if (collegeClass === "XI") {
            resApplied = await ApiCalls.isSeccapAppliedSindhGamesOlevel(data);
          } else if (collegeClass === "XII") {
            resApplied = await ApiCalls.isSeccapAppliedSindhGamesOlevel(data);
          }

          if (resApplied?.data?.status === "success") {
            if (resApplied?.data?.data?.length === 0) {
              await setOpenBackDrop(false);
              setAlert(
                "Please make sure your admission is confirmed by College or Enter valid data!",
                "error"
              );
              err = true;
              await setError(true);
            } else {
              const dataApplied = resApplied?.data?.data;
              applied_id = dataApplied?.id;
              await setAppliedId(dataApplied?.id);
              await setFullName(dataApplied?.name);
              await setFatherName(dataApplied?.father_name);
              await setEmail(dataApplied?.email);
              await setMobileNumber(dataApplied?.mobile);
              await setGender(dataApplied?.gender_id);
              await setCnic(dataApplied?.cnic);
              await setDateOfBirth(dataApplied?.date_of_birth);
              await setCollegeRegion(dataApplied?.region_id);
              await setCollegeDistrict(dataApplied?.district_id);
              await setCollege(dataApplied?.college_id);
              await setHomeAddress(dataApplied?.home_address);
            }
          } else if (resApplied?.data?.error?.message) {
            await setOpenBackDrop(false);
            setAlert(resApplied?.data?.error?.message, "error");
            err = true;
            await setError(true);
          } else {
            await setOpenBackDrop(false);
            setAlert("Something went wrong, Please try again!", "error");
            err = true;
            await setError(true);
          }
        }

        if (err === false) {
          await setOpenBackDrop(false);
          await setStep(2);
        }
      }

      // if (error === false && applied_id !== '') {
      //   await setOpenBackDrop(false);
      //   let url = `/#/student-card/${applied_id}/${xiOrXiiStudent}/${olevelStudent}`;
      //   let win = window.open(url, '_blank');
      //   win.focus();

      // }
    }
  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />

      {step === 1 && (
        <div>
          <NewApplicationContainer
            style={{ backgroundImage: `url(${background})` }}
          >
            <NewApplicationContent>
              {/* <ApplyOnlineItem>
                <FullScreenDialog text={"HOW TO GET DIGITAL ID CARD"} src={'https://www.youtube.com/embed/c0EQbAwjWmM'} />
              </ApplyOnlineItem> */}
              <NewApplicationItem>
                <div style={{ marginBottom: "5%" }}>
                  <NewApplicationHeading
                    style={{
                      fontSize: "22px",
                      color: "#ffb300",
                      textShadow: "1px 1px 2px #000000",
                    }}
                  >
                    Apply online for 6th Sindh College Games 2024
                  </NewApplicationHeading>
                </div>

                <div style={{ marginBottom: "2%" }}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor="outlined-collegeClass">
                      Select Class
                    </InputLabel>
                    <Select
                      native
                      value={collegeClass}
                      onChange={handleChange}
                      label="Select Class"
                      name="collegeClass"
                    >
                      <option aria-label="None" value="" />
                      <option value="XI">XI</option>
                      <option value="XII">XII</option>
                    </Select>
                  </FormControl>
                </div>

                {(collegeClass === "XI" || collegeClass === "XII") && (
                  <div style={{ marginBottom: "2%" }}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="outlined-olevelStudent">
                        Olevel Student
                      </InputLabel>
                      <Select
                        native
                        value={olevelStudent}
                        onChange={handleChange}
                        label="Olevel Student"
                        name="olevelStudent"
                      >
                        <option aria-label="None" value="" />
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Select>
                    </FormControl>
                  </div>
                )}

                <div style={{ marginBottom: "2%" }}>
                  <TextField
                    fullWidth
                    id="outlined-mycnic"
                    placeholder="12345-1234567-1"
                    label="CNIC / B-FORM No"
                    type="text"
                    variant="outlined"
                    name="cnic"
                    value={cnic}
                    onChange={handleChange}
                  />
                </div>

                {(collegeClass === "XI" || collegeClass === "XII") &&
                  olevelStudent === "No" && (
                    <div style={{ marginBottom: "2%" }}>
                      <TextField
                        fullWidth
                        id="outlined-matric-roll-no"
                        label="Enter Your Matric Roll No"
                        name="matricRollNo"
                        value={matricRollNo}
                        onChange={handleChange}
                        type="text"
                        variant="outlined"
                      />
                    </div>
                  )}

                {(collegeClass === "XI" || collegeClass === "XII") &&
                  olevelStudent === "Yes" && (
                    <div style={{ marginBottom: "2%" }}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="outlined-passingYear">
                          O-level Passing Year
                        </InputLabel>
                        <Select
                          native
                          value={passingYear}
                          onChange={handleChange}
                          label="O-level Passing Year"
                          name="passingYear"
                        >
                          <option aria-label="None" value="" />
                          {olevelPassingYears.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  )}

                <div style={{ marginTop: "3%" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    Apply
                  </Button>
                </div>
              </NewApplicationItem>
              <WhiteAd />
            </NewApplicationContent>
          </NewApplicationContainer>
        </div>
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      )}

      {step === 2 && (
        <PersonalInfoForm
          image_path={image_path}
          profileImages={profileImages}
          setProfileImages={setProfileImages}
          selectedProfileImages={selectedProfileImages}
          setSelectedProfileImages={setSelectedProfileImages}
          fullName={fullName}
          setFullName={setFullName}
          fatherName={fatherName}
          setFatherName={setFatherName}
          email={email}
          setEmail={setEmail}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          gender={gender}
          setGender={setGender}
          cnic={cnic}
          setCnic={setCnic}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          collegeRegion={collegeRegion}
          setCollegeRegion={setCollegeRegion}
          collegeDistrict={collegeDistrict}
          setCollegeDistrict={setCollegeDistrict}
          collegeTown={collegeTown}
          setCollegeTown={setCollegeTown}
          college={college}
          setCollege={setCollege}
          homeAddress={homeAddress}
          setHomeAddress={setHomeAddress}
          setAlert={setAlert}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
        />
      )}

      {step === 3 && (
        <SelectGames
          setAlert={setAlert}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
          setSelectedGames={setSelectedGames}
        />
      )}

      {step === 4 && (
        <SindhGamesCard
          image_path={image_path}
          profileImages={profileImages}
          selectedProfileImages={selectedProfileImages}
          fullName={fullName}
          setFullName={setFullName}
          fatherName={fatherName}
          setFatherName={setFatherName}
          email={email}
          setEmail={setEmail}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          gender={gender}
          setGender={setGender}
          cnic={cnic}
          setCnic={setCnic}
          dateOfBirth={dateOfBirth}
          setDateOfBirth={setDateOfBirth}
          collegeRegion={collegeRegion}
          setCollegeRegion={setCollegeRegion}
          collegeDistrict={collegeDistrict}
          setCollegeDistrict={setCollegeDistrict}
          collegeTown={collegeTown}
          setCollegeTown={setCollegeTown}
          college={college}
          setCollege={setCollege}
          homeAddress={homeAddress}
          setHomeAddress={setHomeAddress}
          collegeClass={collegeClass}
          olevelStudent={olevelStudent}
          selectedGames={selectedGames}
          appliedId={appliedId}
          sindhGamesAppliedId={sindhGamesAppliedId}
          setAlert={setAlert}
          setStep={setStep}
          setOpenBackDrop={setOpenBackDrop}
        />
      )}

      <CustomAlert
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

export default SindhGames;
