import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import * as ApiCalls from "../../../services/ApiCalls";
import CommentList from "../CommentList/CommentList";
import { FaReply } from "react-icons/fa";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
import SimpleBackdrop from "../../common/Backdrop";
import CustomAlert from "../../common/CustomAlert";
import { AiFillLike } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import ReactTimeAgo from "react-time-ago";

const Comment = ({ commentProp, comments, setComments, commentType }) => {
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [activity, setActivity] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [areChildrenHidden, setAreChildrenHidden] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [comment, setComment] = useState("");
  const [childComments, setChildComments] = useState([]);

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClick = async () => {
    const data = {
      post_id: params?.id,
      comment,
      parent_id: commentProp?.id,
    };

    if (comment.trim() === "") {
      setAlert("Comment is required!", "error");
    } else {
      await setOpenBackDrop(true);

      let res = await axios.post(
        "https://www.dgcs.gos.pk/seccap2/discussions/comment",
        data,
        {
          headers: {
            Authorization: `${localStorage.getItem("discussionsJwtToken")}`,
          },
        }
      );

      if (res) {
        await setOpenBackDrop(false);
      }

      if (res?.data?.status === "success") {
        setComment("");
        setIsReplying((prev) => !prev);
        setAlert("Comment has been added successfully!", "success");
        getChildCommentsData(commentProp?.id);
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, "error");
      } else {
        setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  const getChildCommentsData = async (parent_id) => {
    let searchFilters = "";
    if (searchFilters?.length === 0) {
      searchFilters = 0;
    }
    const res = await ApiCalls.getChildComments(parent_id, searchFilters);
    if (res?.data?.data) {
      setChildComments(res?.data?.data);
    }
  };

  const handleLike = async (comment_id) => {
    const res = await ApiCalls.likeComment(comment_id);
    if (commentType === "root") {
      let newData = [];
      let disData = comments;
      disData.map((item) => {
        if (item?.id === comment_id) {
          if (item.my_like === true) {
            item.likes = parseInt(item.likes) - 1;
            item.my_like = false;
          } else if (item.my_like === false) {
            item.likes = parseInt(item.likes) + 1;
            item.my_like = true;
          }
        }
        newData.push(item);
      });

      setComments(newData);
    } else if (commentType === "child") {
      let newData = [];
      let disData = comments;
      disData.map((item) => {
        if (item?.id === comment_id) {
          if (item.my_like === true) {
            item.likes = parseInt(item.likes) - 1;
            item.my_like = false;
          } else if (item.my_like === false) {
            item.likes = parseInt(item.likes) + 1;
            item.my_like = true;
          }
        }
        newData.push(item);
      });

      comments = newData;
      setActivity(!activity);
    }
  };

  useEffect(() => {
    getChildCommentsData(commentProp?.id);
  }, []);

  return (
    <>
      <SimpleBackdrop openBackDrop={openBackDrop} />
      <div className="comment">
        <div style={{ width: "100%", display: "flex" }}>
          <div>
            <img
              style={{
                width: "40px",
                marginRight: "10px",
                borderRadius: "50%",
              }}
              src={require("./../../../images/profile.png").default}
            />
          </div>

          <div className="comment-header">
            <div>
              <h4 style={{ fontSize: "14px" }}>
                {commentProp?.name} - {commentProp?.college_name}
              </h4>
              <p
                style={{
                  fontSize: "14px",
                  color: "#5f6368",
                  marginTop: "6px",
                }}
              >
                Posted <ReactTimeAgo date={commentProp?.created_at} />
              </p>
            </div>
          </div>
        </div>
        {isEditing ? (
          <div
            style={{
              border: "1px solid #c3c3c3",
              padding: "25px",
              borderRadius: "10px",
              marginTop: "90px",
            }}
          >
            <div style={{ marginTop: "50px" }}>
              <RichTextEditor content={comment} setContent={setComment} />
            </div>
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                flexDirection: "row-reverse",
              }}
            >
              <div
                style={{
                  display: "flex",
                  background: "#000",
                  color: "#fff",
                  padding: "10px 15px",
                  borderRadius: "25px",
                  fontWeight: "bold",
                  fontSize: "14px",
                  cursor: "pointer",
                  width: "129px",
                }}
                onClick={handleClick}
              >
                <div>
                  <p>Post Comment</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="message"
            style={{ marginTop: "10px", marginBottom: "10px" }}
            dangerouslySetInnerHTML={{ __html: commentProp?.comment }}
          />
        )}

        <div className="footer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "5px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                marginRight: "5px",
                cursor: "pointer",
              }}
              onClick={() => handleLike(commentProp?.id)}
            >
              {commentProp?.my_like ? <AiFillLike /> : <AiOutlineLike />}
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {commentProp?.likes}
            </div>
          </div>

          {!isReplying && (
            <div>
              <div
                style={{
                  display: "flex",
                  fontSize: "14px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  marginTop: "2px",
                }}
                onClick={() => setIsReplying((prev) => !prev)}
              >
                <div style={{ marginRight: "6px" }}>
                  <FaReply />
                </div>
                <div>Reply</div>
              </div>
            </div>
          )}

          {/* <IconBtn
            Icon={likedByMe ? FaHeart : FaRegHeart}
            aria-label={likedByMe ? "Unlike" : "Like"}
          >
            4
          </IconBtn> */}
          {/* <IconBtn
            onClick={() => setIsReplying((prev) => !prev)}
            isActive={isReplying}
            Icon={FaReply}
            aria-label={isReplying ? "Cancel Reply" : "Reply"}
          /> */}
          {/* {user?.id === currentUser?.id && (
            <>
              <IconBtn
                onClick={() => setIsEditing((prev) => !prev)}
                isActive={isEditing}
                Icon={FaEdit}
                aria-label={isEditing ? "Cancel Edit" : "Edit"}
              />
              <IconBtn Icon={FaTrash} aria-label="Delete" color="danger" />
            </>
          )} */}
        </div>
      </div>
      {isReplying && (
        <div className="mt-1 ml-3">
          <div
            style={{
              border: "1px solid #c3c3c3",
              padding: "25px",
              borderRadius: "10px",
              marginTop: "10px",
            }}
          >
            <div style={{ marginTop: "50px" }}>
              <RichTextEditor content={comment} setContent={setComment} />
            </div>
            <div
              style={{
                marginTop: "30px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  background: "#fff",
                  color: "#000",
                  padding: "10px 15px",
                  borderRadius: "25px",
                  fontWeight: "bold",
                  fontSize: "14px",
                  cursor: "pointer",
                  width: "130px",
                  border: "1px solid #000",
                }}
                onClick={() => setIsReplying((prev) => !prev)}
              >
                <div>
                  <p>Cancel Editing</p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  background: "#000",
                  color: "#fff",
                  padding: "10px 15px",
                  borderRadius: "25px",
                  fontWeight: "bold",
                  fontSize: "14px",
                  cursor: "pointer",
                  width: "131px",
                  border: "1px solid #000",
                }}
                onClick={handleClick}
              >
                <div>
                  <p>Post Comment</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {childComments?.length > 0 && (
        <>
          <div
            className={`nested-comments-stack ${
              areChildrenHidden ? "hide" : ""
            }`}
          >
            <button
              className="collapse-line"
              aria-label="Hide Replies"
              onClick={() => setAreChildrenHidden(true)}
            />
            <div className="nested-comments">
              <CommentList
                comments={childComments}
                setComments={setComments}
                commentType="child"
              />
            </div>
          </div>
          <button
            className={`btn mt-1 ${!areChildrenHidden ? "hide" : ""}`}
            onClick={() => setAreChildrenHidden(false)}
          >
            Show Replies
          </button>
        </>
      )}
      <CustomAlert
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
};

export default Comment;
