import styled from "styled-components";

export const NewApplicationContainer = styled.div`
  background: #137a4c;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5% 0;
  min-height: 100vh;
  height: 100%;
`;

export const NewApplicationContent = styled.div`
  background: #fff;
  border: 1px solid #e4ebe4;
  width: 90%;
  padding: 3% 5%;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (min-width: 1500px) {
    width: 70%;
  }
`;

export const NewApplicationItem = styled.div`
  padding: 5% 0;
`;

export const NewApplicationHeading = styled.h1`
  font-size: 28px;
  font-weight: 500;
  text-align: center;
`;

export const NewApplicationHeading2 = styled.h2`
  font-size: 20px;
  font-weight: 500;
`;

export const NewApplicationHeading3 = styled.h3`
  font-size: 16px;
  font-weight: 500;
`;

export const NewApplicationparagraph = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
`;