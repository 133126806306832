import React from "react";
import { Link } from "react-router-dom";
import WhiteAd from "../Ad/WhiteAd";

const CutOff = () => {
  return (
    <div
      style={{
        marginTop: "150px",
        marginLeft: "10%",
        marginRight: "10%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* <h1 style={{ color: "#6b7280", textAlign: "center" }}>Cut Off 2024-25</h1> */}
      <Link style={{ marginBottom: "1%" }} to="/cutoff-2024">
        <h2>Consolidated Cut Off list 2024-25</h2>
      </Link>

      {/* <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "30px",
          marginBottom: "40px",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              CONSOLIDATED CUT OFF LIST 2024-25
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-2024">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL MALE CUT OFF LIST 2024-25
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-2024">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL FEMALE CUT OFF LIST 2024-25
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-2024">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
        </tbody>
      </table> */}

      <Link style={{ marginBottom: "1%" }} to="/students-guide">
        <h2>Student's Guide</h2>
      </Link>

      {/* <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                background: "#f2f2f2",
              }}
            >
              Faculty
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                background: "#f2f2f2",
              }}
            >
              Gender
            </th>
            <th
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                background: "#f2f2f2",
              }}
            >
              Cut Off
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              MALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-medical-male">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              FEMALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-medical-female">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-ENGINEERING
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              MALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-engineering-male">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-ENGINEERING
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              FEMALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-engineering-female">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              MALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-medical-male">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              MALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-medical-male">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              MALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-medical-male">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              MALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-medical-male">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              MALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-medical-male">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              MALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-medical-male">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
          <tr>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              PRE-MEDICAL
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              MALE
            </td>
            <td
              style={{
                border: "1px solid #ddd",
                padding: "8px",
                textAlign: "left",
                fontSize: "14px",
                fontWeight: "bold",
                color: "gray",
              }}
            >
              <Link to="/cutoff-pre-medical-male">
                <h3>Download</h3>
              </Link>
            </td>
          </tr>
        </tbody>
      </table> */}

      {/* <Link style={{ marginBottom: "1%" }} to="/cutoff-pre-medical-male-2022">
        <h2>Cut Off Pre-Medical Male 2022-23</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-pre-medical-female-2022">
        <h2>Cut Off Pre-Medical Female 2022-23</h2>
      </Link>

      <Link
        style={{ marginBottom: "1%" }}
        to="/cutoff-pre-engineering-male-2022"
      >
        <h2>Cut Off Pre-Engineering Male 2022-23</h2>
      </Link>

      <Link
        style={{ marginBottom: "1%" }}
        to="/cutoff-pre-engineering-female-2022"
      >
        <h2>Cut Off Pre-Engineering Female 2022-23</h2>
      </Link>

      <Link
        style={{ marginBottom: "1%" }}
        to="/cutoff-computer-science-male-2022"
      >
        <h2>Cut Off Computer Science Male 2022-23</h2>
      </Link>

      <Link
        style={{ marginBottom: "1%" }}
        to="/cutoff-computer-science-female-2022"
      >
        <h2>Cut Off Computer Science Female 2022-23</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-commerce-male-2022">
        <h2>Cut Off Commerce Male 2022-23</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-commerce-female-2022">
        <h2>Cut Off Commerce Female 2022-23</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-humanities-male-2022">
        <h2>Cut Off Humanities Male 2022-23</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-humanities-female-2022">
        <h2>Cut Off Humanities Female 2022-23</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-pre-medical-male">
        <h2>Cut Off Pre-Medical Male</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-pre-medical-female">
        <h2>Cut Off Pre-Medical Female</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-pre-engineering-male">
        <h2>Cut Off Pre-Engineering Male</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-pre-engineering-female">
        <h2>Cut Off Pre-Engineering Female</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-computer-science-male">
        <h2>Cut Off Computer Science Male</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-computer-science-female">
        <h2>Cut Off Computer Science Female</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-commerce-male">
        <h2>Cut Off Commerce Male</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-commerce-female">
        <h2>Cut Off Commerce Female</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-humanities-male">
        <h2>Cut Off Humanities Male</h2>
      </Link>

      <Link style={{ marginBottom: "1%" }} to="/cutoff-humanities-female">
        <h2>Cut Off Humanities Female</h2>
      </Link> */}

      <WhiteAd />
      <WhiteAd />
    </div>
  );
};

export default CutOff;
