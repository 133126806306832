import styled from "styled-components";

export const CollegesContainer = styled.div`
  background: #137a4c;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% 0;
  min-height: 100vh;
  height: 100%;
`;

export const CollegesContent = styled.div`
  background: #fff;
  border: 1px solid #e4ebe4;
  width: 95%;
  padding: 3% 5%;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  border-radius: 5px;
`;

export const CollegesItem = styled.div`
  padding: 5% 0;
`;

export const ButtonElement = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3% 0;
`;