import React from 'react';
import { CollegesContainer, CollegesContent, CollegesHeader } from './CollegesElements';
import CollegesTable from '../Dashboard/reports/Colleges/CollegesTable';
import background from "../../images/background.jpg";

const Colleges = () => {
  return (
    <CollegesContainer style={{ backgroundImage: `url(${background})` }}>
      <CollegesHeader>
          <h1>OUR COLLEGES IN SINDH</h1>
        </CollegesHeader>
      <CollegesContent style={{ backgroundImage: `url(${background})` }}>
        <CollegesTable />
      </CollegesContent>
    </CollegesContainer>
  )

}


export default Colleges;