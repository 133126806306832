import styled from "styled-components";

export const CollegesContainer = styled.div`
  background: #fff;
  height: 65rem;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const CollegesHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: #fff;
`;

export const CollegesP = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1% 20%;
  font-size: 20px;
`;

export const CollegesContent = styled.div`
  background: #fff;
  // border: 1px solid #e4ebe4;
  width: 100%;
  padding: 5% 5%;
`;

export const CollegesItem = styled.div`
  padding: 5% 0;
`;