import React, { useState } from "react";
import Video from "../../videos/v2.mp4";
import IMAGE from "../../images/team1.jpg";
import { Button } from "../ButtonElements";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import { Link } from "react-router-dom";
import { MdForum } from "react-icons/md";

const HeroSection = () => {
  const [hover, setHover] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);
  const [hover6, setHover6] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  const onHover2 = () => {
    setHover2(!hover2);
  };

  const onHover3 = () => {
    setHover3(!hover3);
  };

  const onHover4 = () => {
    setHover4(!hover4);
  };

  const onHover5 = () => {
    setHover5(!hover5);
  };

  const onHover6 = () => {
    setHover6(!hover6);
  };
  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1>Welcome to SECCAP</HeroH1>
        <HeroP>
          Sindh Electronic Centralized College Admission Program 2024-25
        </HeroP>
        <HeroBtnWrapper>
          {/* <Button
            to="/apply-online"
            onMouseEnter={onHover4}
            onMouseLeave={onHover4}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            style={{ width: "90%" }}
          >
            How to Apply {hover4 ? <ArrowForward /> : <ArrowRight />}
          </Button> */}
          {/* <div style={{ marginBottom: '5%' }}>
            <button style={{ width: '100%', borderRadius: '50px', background: 'red', color: "#fff", padding: '14px 66px', outline: 'none', border: '1px solid red', cursor: 'pointer' }}>
              <Link style={{ textDecoration: 'none', color: "#fff", letterSpacing: '1px' }} to='/complaint'>
                DIGITAL COMPLAINT BOX
              </Link>
            </button>
          </div> */}
          {/* <div style={{ marginBottom: '5%' }}>
            <button style={{ width: '100%', borderRadius: '50px', backgroundImage: 'linear-gradient(#141e30, #243b55)', color: "#fff", padding: '14px 10px', outline: 'none', border: '1px solid #004e92', cursor: 'pointer' }}>
              <Link style={{ textDecoration: 'none', color: "#ffb300", textShadow: '2px 2px 4px #000000', letterSpacing: '1px' }} to='/sindh-college-talent-hunt'>
                APPLY FOR 1ST SINDH COLLEGE TALENT HUNT 2023
              </Link>
            </button>
          </div> */}
          {/* <div style={{ marginBottom: "5%" }}>
            <button
              style={{
                width: "100%",
                borderRadius: "50px",
                backgroundImage: "linear-gradient(#440144, #000)",
                color: "#fff",
                padding: "14px 10px",
                outline: "none",
                border: "1px solid #CA1AD5",
                cursor: "pointer",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "#ffb300",
                  textShadow: "2px 2px 4px #000000",
                  letterSpacing: "1px",
                }}
                to="/sindh-college-games"
              >
                APPLY FOR 6th SINDH COLLEGE GAMES 2024
              </Link>
            </button>
          </div> */}
          <div style={{ marginBottom: "10px" }}>
            <button
              style={{
                width: "100%",
                borderRadius: "10px",
                background: "#000",
                color: "#fff",
                padding: "10px 80px",
                outline: "none",
                border: "1px solid #fff",
                cursor: "pointer",
                whiteSpace: "nowrap",
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "#fff",
                  letterSpacing: "1px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
                to="/discussions"
              >
                <div
                  style={{
                    marginRight: "10px",
                    display: "flex",
                    fontSize: "20px",
                  }}
                >
                  <MdForum />
                </div>
                <div>SECCAP FORUM</div>
              </Link>
            </button>
          </div>

          <Button
            to="/student-attendance"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            style={{ width: "100%", marginTop: "2%" }}
          >
            CHECK ATTENDANCE
          </Button>

          <Button
            to="/student-card"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            style={{ width: "100%", marginTop: "2%" }}
          >
            GET DIGITAL COLLEGE ID CARD
          </Button>
          <Button
            to="/apply-online"
            onMouseEnter={onHover2}
            onMouseLeave={onHover2}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            style={{ width: "100%", marginTop: "2%" }}
          >
            Apply Online {hover2 ? <ArrowForward /> : <ArrowRight />}
          </Button>
          <Button
            to="/o-level"
            onMouseEnter={onHover3}
            onMouseLeave={onHover3}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            style={{ width: "100%", marginTop: "2%" }}
          >
            Apply Online (For O Level Students){" "}
            {hover3 ? <ArrowForward /> : <ArrowRight />}
          </Button>
          {/* <Button
            to="/claim-college"
            onMouseEnter={onHover3}
            onMouseLeave={onHover3}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            style={{ width: '90%', marginTop: '2%' }}
          >
            Claim College {hover3 ? <ArrowForward /> : <ArrowRight />}
          </Button> */}
          {/* <Button
            to="/o-level"
            onMouseEnter={onHover2}
            onMouseLeave={onHover2}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            style={{ width: '90%', marginTop: '3%' }}
          >
            Apply Online (For O Level Students) {hover2 ? <ArrowForward /> : <ArrowRight />}
          </Button> */}
          {/* <Button
            to="/cut-off"
            onMouseEnter={onHover5}
            onMouseLeave={onHover5}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            style={{ width: "90%", marginTop: "2%" }}
          >
            CUT OFF{hover5 ? <ArrowForward /> : <ArrowRight />}
          </Button> */}
          <Button
            to="/seccap-result"
            onMouseEnter={onHover4}
            onMouseLeave={onHover4}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            style={{ width: "100%", marginTop: "2%" }}
          >
            CHECK SECCAP RESULT {hover4 ? <ArrowForward /> : <ArrowRight />}
          </Button>

          <Button
            to="/claim"
            onMouseEnter={onHover5}
            onMouseLeave={onHover5}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            style={{ width: "100%", marginTop: "2%" }}
          >
            Claim College {hover5 ? <ArrowForward /> : <ArrowRight />}
          </Button>

          {/* <Button
            to="/"
            onMouseEnter={onHover6}
            onMouseLeave={onHover6}
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            style={{ width: "90%", marginTop: "5%", textAlign: "center" }}
          >
            Seccap Result will be announced on 28th July 2023
          </Button> */}
          {/* <button style={{ background: 'blue', color: "#fff", padding: '12px' }}>
            <a style={{ textDecoration: 'none', color: "#fff" }} target="_blank" href={"https://web.facebook.com/groups/1007924619869977/"}>
              JOIN SECCAP GROUP ON FACEBOOK
            </a>
          </button> */}
          <div
            style={{ color: "#fff", textAlign: "center", marginTop: "20px" }}
          >
            {/* <p style={{ fontSize: '18px', marginBottom: '10px' }}>For any concerns, please contact via</p>
            <p style={{ marginBottom: '10px' }}>WhatsApp # 03158778882</p>
            <p style={{ marginBottom: '10px' }}>OR</p> */}

            <button
              style={{
                borderRadius: "50px",
                background: "blue",
                color: "#fff",
                padding: "12px 60px",
                outline: "none",
                border: "1px solid #137a4c",
                cursor: "pointer",
              }}
            >
              <a
                style={{
                  textDecoration: "none",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
                target="_blank"
                href={"https://web.facebook.com/groups/1007924619869977/"}
              >
                JOIN OUR FACEBOOK GROUP
              </a>
            </button>
          </div>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
