import React from 'react';
import CollegesTable from '../Colleges/CollegesTable';

const Main = () => {
  return (
    <div>
      <CollegesTable />
    </div>
  )
}

export default Main;
