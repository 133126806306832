import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { FragmentContainer, FragmentContent, FragmentHeader, FragmentHeaderH1 } from './FragmentsElements';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty, { isName, isNumber, isNumberOrDash, isEmail, isCNIC } from "../../../helpers/validation";
import CustomAlert from "../../common/CustomAlert";
import Divider from '@material-ui/core/Divider';
import * as ApiCalls from '../../../services/ApiCalls';


import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ReligionsData from '../../../data/religions.json';
import RegionsData from '../../../data/regions.json';
import DistrictsData from '../../../data/districts.json';
import SubdivisionsData from '../../../data/subdivisions.json';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
  formControl: {
    width: "100%",
    minWidth: 120,
    marginTop: '0px'
  },
  selectEmpty: {
    marginTop: theme.spacing(1),
  },
  multilineColor: {
    color: 'red'
  },
}));

const EditStudentPersonalInfoXii = (props) => {
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [severity, setSeverity] = React.useState('');
  const [reason, setReason] = useState('');
  const [religions, setReligions] = useState([]);

  const classes = useStyles();

  const [province, setProvince] = useState('');
  const [addressRegion, setAddressRegion] = useState('');
  const [addressDistrict, setAddressDistrict] = useState('');
  const [addressTowns, setAddressTowns] = useState([]);
  const [districtClass8, setDistrictClass8] = useState('');
  const [districtClass5, setDistrictClass5] = useState('');
  const [domicileDistrict, setDomicileDistrict] = useState('');
  const [addressDistricts, setAddressDistricts] = useState([]);
  const [fatherDomicileDistrict, setFatherDomicileDistrict] = useState('');
  const [board, setBoard] = useState('');
  const [studyGroup, setStudyGroup] = useState('');
  const [subject, setSubject] = useState('');

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(async () => {
    console.log(props.studentDetailsInCollege);
    window.scrollTo(0, 0);
    await getReligions();
    await getProvinceByProvinceId(props?.studentDetailsInCollege?.province_id);
    await getAddressRegionByRegionId(props?.studentDetailsInCollege?.address_region_id);
    props?.studentDetailsInCollege?.address_district_id && await getAddressDistrictByDistrictId(props?.studentDetailsInCollege?.address_district_id);
    await getDomicileDistrictByDistrictId(props?.studentDetailsInCollege?.domicile_district_id);
    await getFatherDomicileDistrictByDistrictId(props?.studentDetailsInCollege?.father_domicile_district_id);
    await getDistrictClass8ByDistrictId(props?.studentDetailsInCollege?.class8_passing_district_id);
    await getDistrictClass5ByDistrictId(props?.studentDetailsInCollege?.class5_passing_district_id);
    await getBoardByBoardId(props?.studentDetailsInCollege?.board_id);
    await getStudyGroupByGroupId(props?.studentDetailsInCollege?.group_id);
    props?.studentDetailsInCollege?.sub_group_id && await getSubGroupBySubGroupId(props?.studentDetailsInCollege?.sub_group_id);
  }, []);

  const getReligions = async () => {
    setReligions(ReligionsData);
  }

  const getAddressDistrictsByRegionId = async (region_id) => {
    const districts = DistrictsData?.filter(item => item.region_id == region_id);
    setAddressDistricts(districts);
  }

  const getProvinceByProvinceId = async (province_id) => {
    const res = await ApiCalls.getProvinceByProvinceId(province_id);
    if (res?.data?.status === 'success') {
      setProvince(res?.data?.data.province);
    }
  }

  const getAddressRegionByRegionId = async (region_id) => {
    const res = await ApiCalls.getRegionByRegionId(region_id);
    if (res?.data?.status === 'success') {
      setAddressRegion(res?.data?.data.region);
    }
  }

  const getAddressDistrictByDistrictId = async (district_id) => {
    const res = await ApiCalls.getDistrictByDistrictId(district_id);
    if (res?.data?.status === 'success') {
      setAddressDistrict(res?.data?.data?.district);
    }
  }

  const getDistrictClass8ByDistrictId = async (district_id) => {
    const res = await ApiCalls.getDistrictByDistrictId(district_id);
    if (res?.data?.status === 'success') {
      setDistrictClass8(res?.data?.data?.district);
    }
  }

  const getDistrictClass5ByDistrictId = async (district_id) => {
    const res = await ApiCalls.getDistrictByDistrictId(district_id);
    if (res?.data?.status === 'success') {
      setDistrictClass5(res?.data?.data?.district);
    }
  }

  const getDomicileDistrictByDistrictId = async (district_id) => {
    const res = await ApiCalls.getDistrictByDistrictId(district_id);
    if (res?.data?.status === 'success') {
      setDomicileDistrict(res?.data?.data?.district);
    }
  }

  const getFatherDomicileDistrictByDistrictId = async (district_id) => {
    const res = await ApiCalls.getDistrictByDistrictId(district_id);
    if (res?.data?.status === 'success') {
      setFatherDomicileDistrict(res?.data?.data?.district);
    }
  }

  const getBoardByBoardId = async (board_id) => {
    const res = await ApiCalls.getBoardByBoardId(board_id);
    if (res?.data?.status === 'success') {
      setBoard(res?.data?.data?.board);
    }
  }

  const getStudyGroupByGroupId = async (group_id) => {
    const res = await ApiCalls.getStudyGroupByGroupId(group_id);
    if (res?.data?.status === 'success') {
      setStudyGroup(res?.data?.data.group_name);
    }
  }

  const getSubGroupBySubGroupId = async (sub_group_id) => {
    const res = await ApiCalls.getSubGroupBySubGroupId(sub_group_id);
    if (res?.data?.status === 'success') {
      setSubject(res?.data?.data.science);
    }
  }

  const handleBack = () => {
    props.setFragment("studentdetailsincollege");
  }

  const handleClick = async () => {
    if (isEmpty(props.studentDetailsInCollege.name)) {
      setAlert('Please fill out Full Name!', 'error');
    } else if (!isName(props.studentDetailsInCollege.name)) {
      setAlert("Full Name field contains only letters and white spaces!", 'error');
    } else if (props.studentDetailsInCollege.name.length > 50) {
      setAlert("Full Name field cannot contain maximum than 50 characters!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.father_name)) {
      setAlert('Please fill out Father Name!', 'error');
    } else if (!isName(props.studentDetailsInCollege.father_name)) {
      setAlert("Father Name field contains only letters and white spaces!", 'error');
    } else if (props.studentDetailsInCollege.father_name.length > 50) {
      setAlert("Father Name field cannot contain maximum than 50 characters!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.email)) {
      setAlert('Please fill out Email!', 'error');
    } else if (!isEmail(props.studentDetailsInCollege.email)) {
      setAlert('Please fill out valid Email!', 'error');
    } else if (props.studentDetailsInCollege.email.length > 100) {
      setAlert("Email field cannot contain maximum than 100 characters!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.mobile)) {
      setAlert("Please fill out Mobile Number!", 'error');
    } else if (!isNumber(props.studentDetailsInCollege.mobile)) {
      setAlert("Mobile Number field contains only numbers!", 'error');
    } else if (props.studentDetailsInCollege.mobile.length !== 11) {
      setAlert("Mobile Number field contains only 11 numbers!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.nationality)) {
      setAlert("Please fill out Nationality!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.date_of_birth)) {
      setAlert("Please fill out Date of Birth!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.place_of_birth)) {
      setAlert("Please fill out Place of Birth!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.cnic)) {
      setAlert("Please fill out CNIC / B-FORM No!", 'error');
    } else if (!isCNIC(props.studentDetailsInCollege.cnic)) {
      setAlert("Please fill out valid CNIC / B-FORM No!", 'error');
    } else if (props.studentDetailsInCollege.cnic.length !== 15) {
      setAlert("CNIC / B-FORM No field contains only 15 characters!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.religion_id)) {
      setAlert("Please select Religion!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.domicile_district_id)) {
      setAlert("Please select Domicile District!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.father_mobile)) {
      setAlert("Please fill out Father's / Guardian's Mobile Number!", 'error');
    } else if (!isNumber(props.studentDetailsInCollege.father_mobile)) {
      setAlert("Father's / Guardian's Mobile Number field contains only numbers!", 'error');
    } else if (props.studentDetailsInCollege.father_mobile.length !== 11) {
      setAlert("Father's / Guardian's Mobile Number field contains only 11 numbers!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.father_cnic)) {
      setAlert("Please fill out Father's / Guardian's CNIC!", 'error');
    } else if (!isCNIC(props.studentDetailsInCollege.father_cnic)) {
      setAlert("Please fill out Father's / Guardian's valid CNIC!", 'error');
    } else if (props.studentDetailsInCollege.father_cnic.length !== 15) {
      setAlert("Father's / Guardian's CNIC field contains only 15 characters!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.father_occupation)) {
      setAlert("Please fill out Father's Occupation", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.father_domicile_district_id)) {
      setAlert("Please fill out Father's Domicile District", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.mother_cnic)) {
      setAlert("Please fill out Mother's CNIC!", 'error');
    } else if (!isCNIC(props.studentDetailsInCollege.mother_cnic)) {
      setAlert("Please fill out Mother's valid CNIC!", 'error');
    } else if (props.studentDetailsInCollege.mother_cnic.length !== 15) {
      setAlert("Mother's CNIC field contains only 15 characters!", 'error');
    } else if (isEmpty(props.studentDetailsInCollege.home_address)) {
      setAlert("Please Enter Home Address!", 'error');
    } else if (props.studentDetailsInCollege.email.home_address > 254) {
      setAlert("Home Address field cannot contain maximum than 254 characters!", 'error');
    } else {

      const data = {
        name: props.studentDetailsInCollege.name,
        father_name: props.studentDetailsInCollege.father_name,
        email: props.studentDetailsInCollege.email,
        mobile: props.studentDetailsInCollege.mobile,
        cnic: props.studentDetailsInCollege.cnic,
        date_of_birth: props.studentDetailsInCollege.date_of_birth,
        place_of_birth: props.studentDetailsInCollege.place_of_birth,
        nationality: props.studentDetailsInCollege.nationality,
        religion_id: props.studentDetailsInCollege.religion_id,
        domicile_district_id: props.studentDetailsInCollege.domicile_district_id,
        father_mobile: props.studentDetailsInCollege.father_mobile,
        father_cnic: props.studentDetailsInCollege.father_cnic,
        father_occupation: props.studentDetailsInCollege.father_occupation,
        father_domicile_district_id: props.studentDetailsInCollege.father_domicile_district_id,
        mother_cnic: props.studentDetailsInCollege.mother_cnic,
        home_address: props.studentDetailsInCollege.home_address,
        applied_id: props.studentDetailsInCollege.id,
      };

      const response = await axios.post('https://www.dgcs.gos.pk/seccap2/students/updateSeccapStudentInfoXii', data, { headers: { 'Authorization': localStorage.getItem('jwtToken') } });
      if (response?.data?.error) {
        setAlert(response?.data?.error?.message, 'error');
      } else if (response?.data?.data) {
        setAlert("Data has been updated successfully!", 'success');
        setTimeout(() => {
          props.setFragment(props.prevFragment);
        }, 1000);

      } else {
        setAlert("Something went wrong, please try again!", 'error');
      }



    }

  }

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (name === 'fullName') {
      if (isName(value) && value.length < 51) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            name: value,
          }
        });
      }
    }
    if (name === 'fatherName') {
      if (isName(value) && value.length < 51) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            father_name: value,
          }
        });
      }
    }
    if (name === 'email') {
      if (value.length < 101) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            email: value,
          }
        });
      }
    }
    if (name === 'mobileNumber') {
      if ((isNumber(value) || value === '') && value.length < 12) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            mobile: value,
          }
        });
      }
    }

    if (name === 'nationality') {
      if (isName(value) && value.length < 51) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            nationality: value,
          }
        });
      }
    }
    if (name === 'dateofbirth') {
      props.setStudentDetailsInCollege((prevState) => {
        return {
          ...prevState,
          date_of_birth: value,
        }
      });
    }
    if (name === 'placeofbirth') {
      if (isName(value) && value.length < 51) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            place_of_birth: value,
          }
        });
      }
    }
    if (name === 'cnic') {
      if (isNumberOrDash(value) && value.length < 16) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            cnic: value,
          }
        });
      }
    }
    if (name === 'religion') {
      props.setStudentDetailsInCollege((prevState) => {
        return {
          ...prevState,
          religion_id: value,
        }
      });
    }
    if (name === 'domicile') {
      props.setStudentDetailsInCollege((prevState) => {
        return {
          ...prevState,
          domicile_district_id: value,
        }
      });
    }
    if (name === 'fatherMobileNumber') {
      if ((isNumber(value) || value === '') && value.length < 12) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            father_mobile: value,
          }
        });
      }
    }
    if (name === 'fatherCnic') {
      if (isNumberOrDash(value) && value.length < 16) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            father_cnic: value,
          }
        });
      }
    }
    if (name === 'fatheroccupation') {
      if (isName(value) && value.length < 51) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            father_occupation: value,
          }
        });
      }
    }
    if (name === 'fatherdomicile') {
      props.setStudentDetailsInCollege((prevState) => {
        return {
          ...prevState,
          father_domicile_district_id: value,
        }
      });
    }
    if (name === 'motherCnic') {
      if (isNumberOrDash(value) && value.length < 16) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            mother_cnic: value,
          }
        });
      }
    }
    if (name === 'region') {
      await props.studentDetailsInCollege.setAddressRegion(value);
      await props.studentDetailsInCollege.setAddressDistrict('');
      await props.studentDetailsInCollege.setAddressTown('');
      // await setAddressTowns([]);
      // await setAddressDistricts([]);
      // await getAddressDistrictsByRegionId(value);
    }
    if (name === 'district') {

      await props.studentDetailsInCollege.setAddressDistrict(value);
      await props.studentDetailsInCollege.setAddressTown('');
      // await setAddressTowns([]);
      // getAddressTownsByDistrictId(value);
    }
    if (name === 'town') {
      props.studentDetailsInCollege.setAddressTown(value);
    }
    if (name === 'homeAddress') {
      if (value.length < 255) {
        props.setStudentDetailsInCollege((prevState) => {
          return {
            ...prevState,
            home_address: value,
          }
        });
      }
    }

  };

  return (
    <div>
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item xs={6} md={6}>
                <FragmentHeaderH1>Edit Student's Personal Info</FragmentHeaderH1>
              </Grid>
              <Grid item xs={6} md={6}>
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider />
        <Container fixed>
          <FragmentContent>
            <Grid container spacing={2}>

              <Grid container item xs={9} md={9}>
                <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-name" label="Full Name" type="text" variant="outlined" name="fullName" value={props?.studentDetailsInCollege?.name} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-father" label="Father Name" type="text" variant="outlined" name="fatherName" value={props?.studentDetailsInCollege?.father_name} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-email" placeholder="email@email.com" label="Email Address" type="email" variant="outlined" name="email" value={props?.studentDetailsInCollege?.email} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-mobile" placeholder="03001234567" label="Mobile Number" type="text" variant="outlined" name="mobileNumber" value={props?.studentDetailsInCollege?.mobile} onChange={handleChange} />
                    </div>
                  </Grid>
                  {/* <Grid item md={4}>
                    <FormControl fullWidth variant="outlined" className={classes.formControl}>
                      <InputLabel htmlFor="outlined-gender">Select Gender</InputLabel>
                      <Select
                        native
                        value={props?.studentDetailsInCollege?.gender}
                        onChange={handleChange}
                        label="Select Gender"
                        name="gender"
                      >
                        <option aria-label="None" value="" />
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                      </Select>
                    </FormControl>
                  </Grid> */}
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-nationality" label="Nationality" type="text" placeholder="Eg: Pakistani" variant="outlined" name="nationality" value={props?.studentDetailsInCollege?.nationality} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <form className={classes.container} noValidate>
                        <TextField
                          style={{
                            width: "100%"
                          }}
                          name="dateofbirth"
                          id="outlined-date"
                          label="Date of Birth"
                          type="date"
                          variant="outlined"
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={props?.studentDetailsInCollege?.date_of_birth}
                          onChange={handleChange}
                        />
                      </form>
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-placeofbirth" label="Place of Birth" type="text" placeholder="Eg: Karachi" variant="outlined" name="placeofbirth" value={props?.studentDetailsInCollege?.place_of_birth} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-mycnic" placeholder="12345-1234567-1" label="CNIC / B-FORM No" type="text" variant="outlined" name="cnic" value={props?.studentDetailsInCollege?.cnic} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    {religions?.length > 0 && (
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-religion">Religion</InputLabel>
                        <Select
                          native
                          value={props?.studentDetailsInCollege?.religion_id}
                          onChange={handleChange}
                          label="Religion"
                          name="religion"
                          id="outlined-religion"
                        >
                          <option aria-label="None" value="" />
                          {religions?.length > 0 && religions?.map(item => (
                            <option value={item?.id}>{item?.religion}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    {DistrictsData?.length > 0 && (
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-domicile">Domicile District</InputLabel>
                        <Select
                          native
                          value={props?.studentDetailsInCollege?.domicile_district_id}
                          onChange={handleChange}
                          label="Domicile District"
                          name="domicile"
                          id="outlined-domicile"
                        >
                          <option aria-label="None" value="" />
                          {DistrictsData?.length > 0 && DistrictsData?.map(item => (
                            <option value={item?.id}>{item?.district}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-father-mobile" placeholder="03001234567" label="Father's / Guardian's Mobile Number" type="text" variant="outlined" name="fatherMobileNumber" value={props?.studentDetailsInCollege?.father_mobile} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-cnic" placeholder="12345-1234567-1" label="Father's / Guardian's CNIC" type="text" variant="outlined" name="fatherCnic" value={props?.studentDetailsInCollege?.father_cnic} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-fatheroccupation" label="Father's Occupation" type="text" placeholder="Eg: Government Employee" variant="outlined" name="fatheroccupation" value={props?.studentDetailsInCollege?.father_occupation} onChange={handleChange} />
                    </div>
                  </Grid>
                  <Grid item md={4}>
                    {DistrictsData?.length > 0 && (
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-father-domicile">Father's Domicile District</InputLabel>
                        <Select
                          native
                          value={props?.studentDetailsInCollege?.father_domicile_district_id}
                          onChange={handleChange}
                          label="Father's Domicile District"
                          name="fatherdomicile"
                          id="outlined-father-domicile"
                        >
                          <option aria-label="None" value="" />
                          {DistrictsData?.length > 0 && DistrictsData?.map(item => (
                            <option value={item?.id}>{item?.district}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-mother-cnic" placeholder="12345-1234567-1" label="Mother's CNIC" type="text" variant="outlined" name="motherCnic" value={props?.studentDetailsInCollege?.mother_cnic} onChange={handleChange} />
                    </div>
                  </Grid>
                  {/* <Grid item md={4}>
                    {RegionsData?.length > 0 && (
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-region">
                          Address Region
                        </InputLabel>
                        <Select
                          native
                          value={props?.studentDetailsInCollege?.address_region_id}
                          onChange={handleChange}
                          id="outlined-region"
                          label="Address Region"
                          name="region"
                        >
                          <option aria-label="None" value="" />
                          {RegionsData?.length > 0 && RegionsData?.map(item => (
                            <option value={item?.id}>{item?.region}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid> */}
                  {/* <Grid item md={4}>
                    {addressDistricts?.length > 0 && (
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-district">
                          Address District
                        </InputLabel>
                        <Select
                          native
                          value={props?.studentDetailsInCollege?.address_district_id}
                          onChange={handleChange}
                          id="outlined-district"
                          label="Address District"
                          name="district"
                        >
                          <option aria-label="None" value="" />
                          {addressDistricts?.length > 0 && addressDistricts?.map(item => (
                            <option value={item?.id}>{item?.district}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    {addressTowns?.length > 0 && (
                      <FormControl fullWidth variant="outlined" className={classes.formControl}>
                        <InputLabel htmlFor="outlined-town">
                          Address Sub Division / Town
                        </InputLabel>
                        <Select
                          native
                          value={props.addressTown}
                          onChange={handleChange}
                          id="outlined-town"
                          label="Address Sub Division / Town"
                          name="town"
                        >
                          <option aria-label="None" value="" />
                          {addressTowns?.length > 0 && addressTowns?.map(item => (
                            <option value={item?.id}>{item?.subdivision}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid> */}
                  <Grid item md={4}>
                    <div>
                      <TextField fullWidth id="outlined-home-address" label="Home Address" type="text" variant="outlined" name="homeAddress" value={props?.studentDetailsInCollege?.home_address} onChange={handleChange} />
                    </div>
                  </Grid>
                </Grid>
                <Divider />
                <div style={{ marginTop: '5%' }}>
                  <Button variant="contained" color="default" style={{ marginRight: '1rem' }} onClick={handleBack} >
                    Back
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleClick}>
                    Save
                  </Button>
                </div>

              </Grid>
            </Grid>
            <CustomAlert open={open} message={message} severity={severity} handleClose={handleClose} />
          </FragmentContent>

          
          
          

        </Container>
      </FragmentContainer>
    </div>
  )
}

export default EditStudentPersonalInfoXii;
