import React from "react";
import { AboutUsContainer, AboutUsHeader, AboutUsP } from "./AboutUsElements";

const CutOff4 = (props) => {
  return (
    <div>
      <AboutUsContainer>
        {/* <AboutUsHeader>
          <h1>INTRODUCTION</h1>
        </AboutUsHeader>
        <AboutUsP>
          <p style={{textAlign: 'justify', textIndent: '100px'}}>
            The system of Centralized Admission Policy (CAP) was introduced in the 
            year 2000 by the Education Department, Government of Sindh for granting 
            admissions in class XI in the colleges and higher secondary schools of Karachi.
          </p>
        </AboutUsP>
        <AboutUsP>
          <p style={{textAlign: 'justify', textIndent: '100px'}}>
            The policy is to streamline the process of admission and root out all sorts
            of irregularities as well as to provide equal opportunity to the students getting 
            admission in the colleges and higher secondary schools of their choice on the 
            basis of merit (marks) only. Thanks to almighty Allah, the policy has been very 
            successful in achieving its targets to the degree of utmost satisfaction since the 
            day it was implemented.
          </p>
        </AboutUsP>
        <AboutUsP>
          <p style={{textAlign: 'justify', textIndent: '100px'}}>
            he authorities, in 2015 renamed and reframed the policy as Sindh E-Centralized Colleges Admission Program (SECCAP), the system of online 
            applications for admission in class XI under SECCAP was introduced. A 
            committee has been formed headed by Director General (Colleges) Sindh Karachi 
            to vigil the process of admissions in class XI in the colleges and higher secondary 
            schools of Karachi region.
          </p>
        </AboutUsP> */}
        {props?.year === "2022" ? (
          <object
            data="/pdf/PRE_ENGINEERING_FEMALE_2024.pdf"
            type="application/pdf"
            width="100%"
            height="550vh"
          >
            <p>
              Your web browser doesn't have a PDF plugin. Instead you can{" "}
              <a href={`/pdf/PRE_ENGINEERING_FEMALE_2024.pdf`}>
                click here to download the PDF file.
              </a>
            </p>
          </object>
        ) : (
          <object
            data="/pdf/PRE_ENGINEERING_FEMALE_2024.pdf"
            type="application/pdf"
            width="100%"
            height="550vh"
          >
            <p>
              Your web browser doesn't have a PDF plugin. Instead you can{" "}
              <a href={`/pdf/PRE_ENGINEERING_FEMALE_2024.pdf`}>
                click here to download the PDF file.
              </a>
            </p>
          </object>
        )}
      </AboutUsContainer>
    </div>
  );
};

export default CutOff4;
