import React, { useState, useEffect } from "react";
import {
  PreviewContainer,
  PreviewContent,
  PreviewHeading1,
  PreviewHeading2,
  PreviewHeading4,
  PreviewParagraph,
  ButtonContent,
} from "./Preview2Elements";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import jsPDF from "jspdf";
import * as ApiCalls from "../../../../services/ApiCalls";
import background from "./../../../../images/background.jpg";

import ProvincesData from "../../../../data/provinces2.json";
import ReligionsData from "../../../../data/religions.json";
import RegionsData from "../../../../data/regions.json";
import DistrictsData from "../../../../data/districts.json";
import BoardsData from "../../../../data/boards.json";
import StudygroupsData from "../../../../data/studygroups.json";
import SciencegroupsData from "../../../../data/sciencegroups.json";
import FacultiesData from "../../../../data/faculties.json";

const Preview2 = (props) => {
  const [province, setProvince] = useState("");
  const [addressRegion, setAddressRegion] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [districtClass8, setDistrictClass8] = useState("");
  const [districtClass5, setDistrictClass5] = useState("");
  const [domicileDistrict, setDomicileDistrict] = useState("");
  const [fatherDomicileDistrict, setFatherDomicileDistrict] = useState("");
  const [board, setBoard] = useState("");
  const [studyGroup, setStudyGroup] = useState("");
  const [subject, setSubject] = useState("");
  const [region, setRegion] = useState("");
  const [faculty, setFaculty] = useState("");
  const [zone, setZone] = useState("");
  const [religion, setReligion] = useState("");
  const [qrcodepath, setQrcodepath] = useState(props.qrCodePath);
  const [appliedId, setAppliedId] = useState(props.appliedId);
  const [appliedDate, setAppliedDate] = useState("");

  useEffect(async () => {
    window.scrollTo(0, 0);
    getProvinceByProvinceId(props.province);
    await getAddressRegionByRegionId(props.addressRegion);
    props.addressDistrict &&
      (await getAddressDistrictByDistrictId(props.addressDistrict));
    await getDomicileDistrictByDistrictId(props.domicileDistrict);
    await getFatherDomicileDistrictByDistrictId(props.fatherDomicileDistrict);
    await getDistrictClass8ByDistrictId(props.districtClass8);
    await getDistrictClass5ByDistrictId(props.districtClass5);
    await getBoardByBoardId(props.board);
    await getStudyGroupByGroupId(props.studyGroup);
    props.subject && (await getSubGroupBySubGroupId(props.subject));
    await getRegionByRegionId(props.region);
    await getFacultyByFacultyId(props.faculty);
    await getReligionByReligionId(props.religion);
    props?.zone && (await getZoneByZoneId(props?.zone));
    await props.setOpenBackDrop(false);
  }, []);

  const getProvinceByProvinceId = (province_id) => {
    ProvincesData.forEach((item) => {
      if (item.id == province_id) {
        setProvince(item?.province);
      }
    });
  };

  const getReligionByReligionId = async (religion_id) => {
    ReligionsData.forEach((item) => {
      if (item.id == religion_id) {
        setReligion(item?.religion);
      }
    });
  };

  const getAddressRegionByRegionId = async (region_id) => {
    RegionsData.forEach((item) => {
      if (item.id == region_id) {
        setAddressRegion(item?.region);
      }
    });
  };

  const getAddressDistrictByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setAddressDistrict(item?.district);
      }
    });
  };

  const getDistrictClass8ByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setDistrictClass8(item?.district);
      }
    });
  };

  const getDistrictClass5ByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setDistrictClass5(item?.district);
      }
    });
  };

  const getDomicileDistrictByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setDomicileDistrict(item?.district);
      }
    });
  };

  const getFatherDomicileDistrictByDistrictId = async (district_id) => {
    DistrictsData.forEach((item) => {
      if (item.id == district_id) {
        setFatherDomicileDistrict(item?.district);
      }
    });
  };

  const getBoardByBoardId = async (board_id) => {
    BoardsData.forEach((item) => {
      if (item.id == board_id) {
        setBoard(item?.board);
      }
    });
  };

  const getStudyGroupByGroupId = async (group_id) => {
    StudygroupsData.forEach((item) => {
      if (item.id == group_id) {
        setStudyGroup(item?.group_name);
      }
    });
  };

  const getSubGroupBySubGroupId = async (sub_group_id) => {
    SciencegroupsData.forEach((item) => {
      if (item.id == sub_group_id) {
        setSubject(item?.science);
      }
    });
  };

  const getRegionByRegionId = async (region_id) => {
    RegionsData.forEach((item) => {
      if (item.id == region_id) {
        setRegion(item?.region);
      }
    });
  };

  const getFacultyByFacultyId = async (faculty_id) => {
    FacultiesData.forEach((item) => {
      if (item.id == faculty_id) {
        setFaculty(item?.faculty);
      }
    });
  };

  const getZoneByZoneId = async (zone_id) => {
    const res = await ApiCalls.getZone(zone_id);
    if (res?.data?.data?.zone) {
      setZone(res?.data?.data?.zone);
    }
  };

  const handleBack = () => {
    props.setOpenBackDrop(true);
    setTimeout(() => {
      props.setStep(5);
    }, 1000);
  };

  const generatePDF = async () => {
    let doc = new jsPDF("potrait", "pt", "a4", "false");
    let previewcontent = document.querySelector("#application");
    previewcontent.style.width = "600px";
    previewcontent.style.padding = "10px";
    previewcontent.style.paddingRight = "15px";

    let h1 = document.querySelectorAll("h1");
    let h2 = document.querySelectorAll("h2");
    let h4 = document.querySelectorAll("h4");
    let p = document.querySelectorAll("p");
    let logoImage = document.querySelector("#logo-image");
    let profileImage = document.querySelector("#profile-image");
    let qrcodeImage = document.querySelector("#qrcode-image");
    // let app = document.querySelector("#application");
    // let appHeader = document.querySelector("#app-header");
    // let myRow = document.querySelectorAll(".my-row");
    // let myItem = document.querySelectorAll(".my-item");
    // for (let i = 0; i < myRow.length; i++) {
    //   myRow[i].style.display = "flex";
    //   myRow[i].style.justifyContent = "space-between";
    // }

    // for (let i = 0; i < myItem.length; i++) {
    //   myItem[i].style.flex = 1;
    //   myItem[i].style.paddingBottom = "0px";
    // }

    // appHeader.style.marginBottom = "0px";
    // app.style.width = "600px";
    // app.style.paddingLeft = "30px";
    // app.style.paddingRight = "30px";
    // app.style.paddingTop = "10px";
    // app.style.paddingBottom = "0px";
    for (let i = 0; i < h1.length; i++) {
      h1[i].style.fontSize = "12px";
    }
    for (let i = 0; i < h2.length; i++) {
      h2[i].style.fontSize = "10px";
    }
    for (let i = 0; i < h4.length; i++) {
      h4[i].style.fontSize = "8px";
    }
    for (let i = 0; i < p.length; i++) {
      p[i].style.fontSize = "8px";
    }

    // logoImage.style.width = "50px";
    // logoImage.style.height = "50px";

    profileImage.style.width = "60px";
    profileImage.style.height = "60px";

    if (qrcodeImage) {
      qrcodeImage.style.width = "50px";
      qrcodeImage.style.height = "50px";
    }

    doc.html(document.querySelector("#application"), {
      margin: [3, 3, 3, 3],
      autoPaging: "text",
      callback: function (pdf) {
        pdf.save("seccap.pdf");
        previewcontent.style.width = "70%";
      },
    });

    // let doc = new jsPDF();
    // doc.setFontSize(40);
    // doc.addImage("examples/images/Octonyan.jpg", "JPEG", 15, 40, 40, 40);
    // doc.text("SECCAP", 60, 60);
  };

  const handleClick = async () => {
    props.setOpenBackDrop(true);
    let imageMarksheetName = null;
    let imageProfileName = null;
    let formData = null;
    let profileFormData = null;
    let applied_id = null;

    const collegesIds = props.selectedColleges.map((item) => item.id);
    if (props?.imageMarksheetName) {
      imageMarksheetName = `marksheet-${new Date().getTime()}-${
        props.fullName
      }.png`;

      formData = new FormData();
      formData.append("image_url", props.imageMarksheet, imageMarksheetName);
    }

    // if (props?.imageProfile) {
    //   imageProfileName =  `profile-${new Date().getTime()}-${props.fullName}`;
    //   profileFormData = new FormData();
    //   profileFormData.append("image_url", props.imageProfile, imageProfileName);
    // } else {
    //   imageProfileName = props.imageProfileName;
    // }

    if (props.applied === true) {
      applied_id = props.appliedId;
      if (imageMarksheetName === null) {
        imageMarksheetName = props.marksheetImage;
      }
    }

    if (props?.imageMarksheetName) {
      await ApiCalls.saveImageOlevel(formData);
    }

    // if (props?.imageProfile) {
    //   await ApiCalls.saveProfileImage(profileFormData);
    // }

    const data = {
      vaccinated: props.vaccinated,
      applied_id,
      name: props.fullName,
      father_name: props.fatherName,
      email: props.email,
      mobile: props.mobileNumber,
      gender_id: props.gender,
      cnic: props.cnic,
      date_of_birth: props.dateOfBirth,
      place_of_birth: props.placeOfBirth,
      nationality: props.nationality,
      religion_id: props.religion,
      domicile_district_id: props.domicileDistrict,
      father_mobile: props.fatherMobileNumber,
      father_cnic: props.fatherCnic,
      father_occupation: props.fatherOccupation,
      father_domicile_district_id: props.fatherDomicileDistrict,
      mother_cnic: props.motherCnic,
      address_region_id: props.addressRegion,
      address_district_id: props.addressDistrict,
      address_town_id: props.addressTown,
      passing_year: props.passingYear,
      class5_passing_year: props.passingYearClass5,
      class5_passing_district_id: props.districtClass5,
      class5_school_name: props.schoolNameClass5,
      school_name: props.schoolName,
      school_type_id: props.schoolType,
      home_address: props.homeAddress,
      faculty_id: props.faculty,
      colleges_ids: collegesIds,
      approved: 0,
      marksheet_image: imageMarksheetName,
      region_id: props.region,
      subject1: props.subject1,
      subject2: props.subject2,
      subject3: props.subject3,
      subject4: props.subject4,
      subject5: props.subject5,
      subject6: props.subject6,
      subject7: props.subject7,
      subject8: props.subject8,
      subject9: props.subject9,
      grade1: props.grade1,
      grade2: props.grade2,
      grade3: props.grade3,
      grade4: props.grade4,
      grade5: props.grade5,
      grade6: props.grade6,
      grade7: props.grade7,
      grade8: props.grade8,
      grade9: props.grade9,
      uc: props.unionCouncil,
      ward: props.ward,
      who_filled_form: props.whoFilledForm,
      student_goal: props.studentGoal,
    };

    let res;

    if (props.applied === true) {
      res = await ApiCalls.InsertApplicationOlevel(data);
    } else {
      res = await ApiCalls.InsertApplicationOlevel(data);
    }

    if (res?.data?.status === "success") {
      if (res?.data?.data?.applied_id) {
        await setQrcodepath(res?.data?.data?.qrCodePath);
        await setAppliedId(res?.data?.data?.applied_id);
        await setAppliedDate(res?.data?.data?.date);
        await setAppliedDate(res?.data?.data?.date);
        await generatePDF();
        await props.setStep(7);
      } else if (res?.data?.data?.date) {
        await setAppliedDate(res?.data?.data?.date);
        await setAppliedDate(res?.data?.data?.date);
        await generatePDF();
        await props.setStep(7);
      } else {
        props.setOpenBackDrop(false);
        props.setAlert("Something went wrong, Please try again!", "error");
      }
    } else if (res?.data?.error?.message) {
      props.setOpenBackDrop(false);
      props.setAlert(res?.data?.error?.message, "error");
    } else {
      props.setOpenBackDrop(false);
      props.setAlert("Something went wrong, Please try again!", "error");
    }
  };

  return (
    <div>
      <PreviewContainer style={{ backgroundImage: `url(${background})` }}>
        <PreviewContent id="application">
          <div style={{ border: "1px solid #4b5563" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 1%",
                borderBottom: "1px solid #4b5563",
                width: "100%",
              }}
            >
              <div
                style={{ width: "60%", display: "flex", alignItems: "center" }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img id="logo-image" src={"./images/logo.png"} alt="logo" />
                </div>
                <div>
                  <PreviewHeading1 style={{ color: "#137a4c" }}>
                    Sindh Electronic Centralized College Admission Program
                    2024-25
                  </PreviewHeading1>
                </div>
              </div>
              <div>
                {appliedId && (
                  <img
                    id="qrcode-image"
                    style={{
                      width: "6vw",
                    }}
                    src={qrcodepath}
                    alt="qrcode"
                  />
                )}

                <div>
                  {appliedId && (
                    <PreviewParagraph>Applied Id: {appliedId}</PreviewParagraph>
                  )}

                  {appliedDate && (
                    <PreviewParagraph>Date: {appliedDate}</PreviewParagraph>
                  )}
                </div>
              </div>

              {/* <div>
                <img
                  style={{ marginRight: "10px" }}
                  src={require("./../../../../images/logo.png").default}
                  alt="logo"
                />
              </div> */}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "1%",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  width: "60%",
                }}
              >
                <div>
                  <PreviewHeading1>Application Form</PreviewHeading1>
                </div>
              </div>
              <div>
                <img
                  id="profile-image"
                  style={{
                    width: "7vw",
                    border: "1px solid #aaa",
                    borderRadius: "5px",
                  }}
                  src={"./images/profile.png"}
                  alt="profile"
                />
              </div>
            </div>

            {/* <div>
              <div
                id="app-header"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "1%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ marginRight: "10px" }}
                    src={require("./../../../../images/logo.png").default}
                    alt="logo"
                  />{" "}
                  <PreviewHeading1 style={{ color: "#137a4c" }}>
                    SECCAP
                  </PreviewHeading1>
                </div>

                <PreviewHeading1>Provisional Admission</PreviewHeading1>

                <div>
                  <img
                    id="profile-image"
                    style={{
                      width: "100px",
                      height: "100px",
                      border: "1px solid #aaa",
                      borderRadius: "5px",
                    }}
                    src="/images/profile.png"
                    alt="profile"
                  />
                </div>
              </div>
            </div> */}

            <div
              style={{
                padding: "1%",
                borderBottom: "1px solid #4b5563",
                background: "#137a4c",
                color: "#fff",
              }}
            >
              <PreviewHeading2>PERSONAL INFORMATION</PreviewHeading2>
            </div>

            <div>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Name</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.fullName}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Father Name</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.fatherName}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Gender</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>
                    {parseInt(props.gender) === 1
                      ? "Male"
                      : parseInt(props.gender) === 2
                      ? "Female"
                      : ""}
                  </PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Email Address</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.email}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>CNIC / B-FORM No</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.cnic}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Mobile No</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.mobileNumber}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Date of Birth</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.dateOfBirth}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Place of Birth</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.placeOfBirth}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Nationality</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.nationality}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Religion</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{religion}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Domicile District</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{domicileDistrict}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Father's Domicile District</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{fatherDomicileDistrict}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Father' s Occupation</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.fatherOccupation}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Father's Mobile No</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>
                    {props.fatherMobileNumber}
                  </PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Father's CNIC</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.fatherCnic}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Mother's CNIC</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={4}
                >
                  <PreviewParagraph>{props.motherCnic}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Home Address</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={10}
                >
                  <PreviewParagraph>{props.homeAddress}</PreviewParagraph>
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                padding: "1%",
                borderBottom: "1px solid #4b5563",
                background: "#137a4c",
                color: "#fff",
              }}
            >
              <PreviewHeading2>EDUCATIONAL INFORMATION</PreviewHeading2>
            </div>

            <div>
              {props?.subject1 && props.grade1 && (
                <Grid className="my-row" container>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={4}
                  >
                    <PreviewParagraph>1.</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Subject</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props?.subject1}</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Grade</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props.grade1}</PreviewParagraph>
                  </Grid>
                </Grid>
              )}

              {props?.subject2 && props.grade2 && (
                <Grid className="my-row" container>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={4}
                  >
                    <PreviewParagraph>2.</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Subject</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props?.subject2}</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Grade</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props.grade2}</PreviewParagraph>
                  </Grid>
                </Grid>
              )}

              {props?.subject3 && props.grade3 && (
                <Grid className="my-row" container>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={4}
                  >
                    <PreviewParagraph>3.</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Subject</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props?.subject3}</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Grade</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props.grade3}</PreviewParagraph>
                  </Grid>
                </Grid>
              )}

              {props?.subject4 && props.grade4 && (
                <Grid className="my-row" container>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={4}
                  >
                    <PreviewParagraph>4.</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Subject</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props?.subject4}</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Grade</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props.grade4}</PreviewParagraph>
                  </Grid>
                </Grid>
              )}

              {props?.subject5 && props.grade5 && (
                <Grid className="my-row" container>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={4}
                  >
                    <PreviewParagraph>5.</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Subject</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props?.subject5}</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Grade</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props.grade5}</PreviewParagraph>
                  </Grid>
                </Grid>
              )}

              {props?.subject6 && props.grade6 && (
                <Grid className="my-row" container>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={4}
                  >
                    <PreviewParagraph>6.</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Subject</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props?.subject6}</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Grade</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props.grade6}</PreviewParagraph>
                  </Grid>
                </Grid>
              )}

              {props?.subject7 && props.grade7 && (
                <Grid className="my-row" container>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={4}
                  >
                    <PreviewParagraph>7.</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Subject</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props?.subject7}</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Grade</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props.grade7}</PreviewParagraph>
                  </Grid>
                </Grid>
              )}

              {props?.subject8 && props.grade8 && (
                <Grid className="my-row" container>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={4}
                  >
                    <PreviewParagraph>8.</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Subject</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props?.subject8}</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Grade</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props.grade8}</PreviewParagraph>
                  </Grid>
                </Grid>
              )}

              {props?.subject9 && props.grade9 && (
                <Grid className="my-row" container>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={4}
                  >
                    <PreviewParagraph>9.</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Subject</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props?.subject9}</PreviewParagraph>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      borderRight: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewHeading4>Grade</PreviewHeading4>
                  </Grid>
                  <Grid
                    style={{
                      borderBottom: "1px solid #4b5563",
                      padding: "1%",
                    }}
                    className="my-item"
                    item
                    xs={2}
                  >
                    <PreviewParagraph>{props.grade9}</PreviewParagraph>
                  </Grid>
                </Grid>
              )}
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Passing Year</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewParagraph>{props.passingYear}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>School Name</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={6}
                >
                  <PreviewParagraph>{props.schoolName}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Class 5 Passing District</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{districtClass5}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Class 5 Passing Year</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewParagraph>{props.passingYearClass5}</PreviewParagraph>
                </Grid>
              </Grid>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={3}
                >
                  <PreviewHeading4>Class 5 School Name</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={9}
                >
                  <PreviewParagraph>{props.schoolNameClass5}</PreviewParagraph>
                </Grid>
              </Grid>
            </div>

            <div
              style={{
                padding: "1%",
                borderBottom: "1px solid #4b5563",
                background: "#137a4c",
                color: "#fff",
              }}
            >
              <PreviewHeading2>SELECTED COLLEGES</PreviewHeading2>
            </div>

            <div>
              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>College Board</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewParagraph>{region}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Zone</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewParagraph>{zone}</PreviewParagraph>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Choice of Faculty</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    borderBottom: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewParagraph>{faculty}</PreviewParagraph>
                </Grid>
              </Grid>

              <Grid className="my-row" container>
                <Grid
                  style={{
                    borderRight: "1px solid #4b5563",
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={2}
                >
                  <PreviewHeading4>Choosen Colleges</PreviewHeading4>
                </Grid>
                <Grid
                  style={{
                    padding: "1%",
                  }}
                  className="my-item"
                  item
                  xs={10}
                >
                  {props.selectedColleges?.map((item, index) => (
                    <PreviewParagraph>
                      {`${index + 1}) ${item?.ddo_code} ${item?.college_name}`}{" "}
                    </PreviewParagraph>
                  ))}
                </Grid>
              </Grid>
            </div>
          </div>
        </PreviewContent>

        <ButtonContent>
          <Button
            variant="contained"
            color="default"
            style={{ marginRight: "1rem" }}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleClick}>
            Submit
          </Button>
        </ButtonContent>
      </PreviewContainer>
    </div>
  );
};

export default Preview2;
