import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

export const setTokenInLocalStorage = (token) => {
  // Set token to ls
  localStorage.setItem("jwtToken", `Bearer ${token}`);
  // Set token to Auth header
  setAuthToken(`Bearer ${token}`);
};

export const setDiscussionTokenInLocalStorage = (token) => {
  // Set token to ls
  localStorage.setItem("discussionsJwtToken", `Bearer ${token}`);
  // Set token to Auth header
  setAuthToken(`Bearer ${token}`);
};

export const getUser = (token) => {
  if (token) {
    const decoded = jwt_decode(token);
    return decoded;
  }
};

export const checkModule = (user, module) => {
  return user?.modules?.some(
    (item) => item === module || user?.userRole === "super admin"
  );
};
