import React, { useEffect, useState } from "react";
import { BannerContainer, BannerTitle } from "./BannerElements";
import * as ApiCalls from "../../services/ApiCalls";
import "./index.css";

const Banner = () => {
  const [lastDate, setLastDate] = useState("");

  useEffect(async () => {
    await getLastDateToApply();
  }, []);

  const getLastDateToApply = async () => {
    let res = await ApiCalls.getLastDateToApply();
    setLastDate(res?.data?.data?.date);
  };

  return (
    <BannerContainer>
      <div className="heading-container">
        <BannerTitle className="animated-heading" direction="scroll">
          Last Date to Apply {"-"}{" "}
          {new Date(lastDate).toLocaleString("en-us", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </BannerTitle>
      </div>
      {/* <BannerTitle style={{ marginTop: '10px' }} direction="scroll">
        Seccap Result will be announced 30th July 2022 {"(For Karachi Region)"}
      </BannerTitle> */}
    </BannerContainer>
  );
};

export default Banner;
