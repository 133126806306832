import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
// import { Link as LinkS } from "react-scroll";

export const Button = styled(LinkR)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#137a4c" : "#fff")};
  white-space: nowrap;
  
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#fff" : "#137a4c")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "14px")};
  outline: none;
  border: ${({ primary }) =>
    primary ? "1px solid #137a4c" : "1px solid #137a4c"};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#999900" : "#999900")};
    color: ${({ dark }) => (dark ? "#fff" : "#fff")};
    border: 1px solid #999900;
  }

  @media screen and (min-width: 1500px) {
  }
`;

// export const Button = styled(LinkR)`
//   border-radius: 50px;
//   background: ${({ primary }) => (primary ? "#137a4c" : "#fff")};
//   white-space: nowrap;
//   padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
//   color: ${({ dark }) => (dark ? "#fff" : "#137a4c")};
//   font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
//   outline: none;
//   border: ${({ primary }) =>
//     primary ? "1px solid #137a4c" : "1px solid #137a4c"};
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   transition: all 0.2s ease-in-out;
//   text-decoration: none;

//   &:hover {
//     transition: all 0.2s ease-in-out;
//     background: ${({ primary }) => (primary ? "#fff" : "#137a4c")};
//     color: ${({ dark }) => (dark ? "#137a4c" : "#fff")};
//   }
// `;
