import React, { Component } from "react";
import { AdContainer } from "./AdElements";
import "./adStyle.css";

class Ad extends Component {
  // componentDidMount() {
  //   (window.adsbygoogle = window.adsbygoogle || []).push({});
  // }
  render() {
    return (
      <AdContainer>
        <div style={{ opacity: "0" }}>
          {/* <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-2605536906605283"
            data-ad-slot="8267434140"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins> */}
        </div>
      </AdContainer>
    );
  }
}

export default Ad;
