import React, {useState, useEffect} from 'react';
import { AdmissionContainer, AdmissionContent } from './AdmissionElements';
import * as ApiCalls from '../../../../services/ApiCalls';
import background from "./../../../../images/background.jpg";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const Admission = (props) => {
  const [college, setCollege] = useState();
  const [faculty, setFaculty] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    getCollegeByCollegeId(props.collegeId);
    getFacultyByFacultyId(props.facultyId);
    props.setOpenBackDrop(false);
    
  }, []);

  const getCollegeByCollegeId = async (college_id) => {
    const res = await ApiCalls.getCollegeByCollegeId(college_id);
    if (res?.data?.status === 'success') {
      setCollege(res?.data?.data.college_name);
    }
  }

  const getFacultyByFacultyId = async (faculty_id) => {
    const res = await ApiCalls.getFacultyByFacultyId(faculty_id);
    if (res?.data?.status === 'success') {
      setFaculty(res?.data?.data?.faculty);
    }
  }
  
  return (
    <AdmissionContainer style={{ backgroundImage: `url(${background})` }}>
      <AdmissionContent>
        <h3 style={{ color: 'grey', textAlign: 'center' }}>
          {props.admissionStatus == 0 ? "You have been selected in college" : props.admissionStatus == 1 ? "Your admission is confirmed in college" : ""}
        </h3>
        <h1 style={{ color: '#137a4c', textAlign: 'center'}}>{college}</h1>
        <h2 style={{ color: 'grey', textAlign: 'center', marginBottom: '1rem' }}>in {faculty}</h2>
        {props.admissionStatus == 0 && (
          <div>
            <p style={{ color: 'grey', textAlign: 'center' }}>Please report at college along with your matric documents to confirm your Admission</p>
            <p style={{ color: 'grey', textAlign: 'center', marginTop: '10px' }}>Required Documents:</p>
            <p style={{ color: 'grey', textAlign: 'center', marginTop: '10px' }}>1) Matric Marksheet</p>
            <p style={{ color: 'grey', textAlign: 'center', marginTop: '5px' }}>2) Matric Certificate</p>
          </div>
        )}
        
        
        {/* <FormControl fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-province">
            Province
          </InputLabel>
          <Select
            native
            value=""
            id="outlined-college"
            label="College"
            name="college"
          >
            <option aria-label="None" value="" />
            <option value=""></option>
          </Select>
        </FormControl> */}
      </AdmissionContent>
    </AdmissionContainer>
  )
}

export default Admission;