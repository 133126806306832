import React, { useState, useEffect } from "react";
import { StatusContainer, StatusContent, StatusItem } from "./StatusElements";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Preview2 from "./components/preview/Preview2";
import Admission from "./components/admission/Admission";
import isEmpty, { isNumber } from "../../helpers/validation";
import CustomAlert from "../common/CustomAlert";
import SimpleBackdrop from "../common/Backdrop";
import * as ApiCalls from "../../services/ApiCalls";
import background from "./../../images/background.jpg";
import { isCNIC, isNumberOrDash } from "../../helpers/validation";
import { useHistory } from "react-router-dom";
import { olevelPassingYears } from "../../data/passingyears";
import WhiteAd from "../Ad/WhiteAd";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(0),
  },
  multilineColor: {
    color: "red",
  },
}));

const OlevelStatus = () => {
  const classes = useStyles();
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const [step, setStep] = useState(1);
  // Matric Profile
  const [ninthRollNo, setNinthRollNo] = useState("");
  const [matricRollNo, setMatricRollNo] = useState("");
  const [passingYear, setPassingYear] = useState("");
  const [vaccinated, setVaccinated] = useState("");
  const [passingYearClass5, setPassingYearClass5] = useState("");
  const [passingYearClass8, setPassingYearClass8] = useState("");
  const [province, setProvince] = useState("");
  const [board, setBoard] = useState("");
  const [studyGroup, setStudyGroup] = useState("");
  const [subject, setSubject] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [obtainedMarks, setObtainedMarks] = useState("");
  const [scaledObtainedMarks, setScaledObtainedMarks] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [schoolNameClass5, setSchoolNameClass5] = useState("");
  const [schoolNameClass8, setSchoolNameClass8] = useState("");
  const [districtClass5, setDistrictClass5] = useState("");
  const [districtClass8, setDistrictClass8] = useState("");
  const [imageMarksheet, setImageMarksheet] = useState();
  const [imageMarksheetName, setImageMarksheetName] = useState("");
  const [marksheetImage, setMarksheetImage] = useState("");
  const [showMarksheetImage, setShowMarksheetImage] = useState(
    require("../../images/doc.png").default
  );
  const [showProfileImage, setShowProfileImage] = useState(
    require("../../images/profile.png").default
  );
  // Personal Profile
  const [appliedId, setAppliedId] = useState("");
  const [appliedDate, setAppliedDate] = useState("");
  const [fullName, setFullName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gender, setGender] = useState("");
  const [nationality, setNationality] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [placeOfBirth, setPlaceOfBirth] = useState("");
  const [cnic, setCnic] = useState("");
  const [religion, setReligion] = useState("");
  const [fatherMobileNumber, setFatherMobileNumber] = useState("");
  const [fatherCnic, setFatherCnic] = useState("");
  const [fatherOccupation, setFatherOccupation] = useState("");
  const [motherCnic, setMotherCnic] = useState("");
  const [domicileDistrict, setDomicileDistrict] = useState("");
  const [fatherDomicileDistrict, setFatherDomicileDistrict] = useState("");
  const [imageProfile, setImageProfile] = useState();
  const [imageProfileName, setImageProfileName] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [addressRegion, setAddressRegion] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [addressTown, setAddressTown] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [approved, setApproved] = useState("");
  const [claimCount, setClaimCount] = useState();

  // Apply for college
  const [region, setRegion] = useState("");
  const [faculty, setFaculty] = useState("");
  const [selectedColleges, setSelectedColleges] = useState([]);

  const [collegeId, setCollegeId] = useState("");
  const [facultyId, setFacultyId] = useState("");
  const [admissionStatus, setAdmissionStatus] = useState("");
  const [qrCodePath, setQrCodePath] = useState("");
  const [zone, setZone] = useState("");

  const [availInDataBase, setAvailInDataBase] = useState(false);
  const [applied, setApplied] = useState(false);

  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    setOpenBackDrop(false);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "cnic") {
      if (isNumberOrDash(value) && value.length < 16) {
        setCnic(value);
      }
    }

    if (name === "passingYear") {
      if ((isNumber(value) || value === "") && value.length < 5) {
        setPassingYear(value);
      }
    }
  };

  const handleClick = async () => {
    if (isEmpty(cnic)) {
      setAlert("Please fill out CNIC / B-FORM No!", "error");
    } else if (!isCNIC(cnic)) {
      setAlert("Please fill out valid CNIC / B-FORM No!", "error");
    } else if (cnic.length !== 15) {
      setAlert("CNIC / B-FORM No field contains only 15 characters!", "error");
    } else if (isEmpty(passingYear)) {
      setAlert("Please select Passing Year!", "error");
    } else if (passingYear.length > 4) {
      setAlert(
        "Passing Year field cannot contain maximum than 4 numbers!",
        "error"
      );
    } else {
      setOpenBackDrop(true);

      const data1 = {
        passing_year: passingYear,
        cnic,
      };

      const resApplied = await ApiCalls.isOlevelApplied(data1);
      if (resApplied?.data?.status === "success") {
        if (resApplied?.data?.data?.length === 0) {
          setOpenBackDrop(false);
          setAlert("Enter Valid CNIC or Passing Year!", "error");
        } else if (resApplied?.data?.data) {
          setApplied(true);
          const data = resApplied?.data?.data;

          if (isEmpty(data.region_id)) {
            setAlert("Complete your Application!", "error");
            history.push("/olevel-edit-application");
          } else {
            await setAppliedId(data?.id);
            await setApproved(data?.approved);
            await setFullName(data?.name);
            await setFatherName(data?.father_name);
            await setEmail(data?.email);
            await setMobileNumber(data?.mobile);
            await setGender(data?.gender_id);
            await setNationality(data.nationality);
            await setDateOfBirth(data.date_of_birth);
            await setPlaceOfBirth(data.place_of_birth);
            await setReligion(data.religion_id);
            await setCnic(data?.cnic);
            await setFatherMobileNumber(data?.father_mobile);
            await setFatherCnic(data?.father_cnic);
            await setMotherCnic(data?.mother_cnic);
            await setStudyGroup(data?.group_id);
            data?.sub_group_id && (await setSubject(data?.sub_group_id));
            await setObtainedMarks(data?.scaled_obtained_marks);
            await setSchoolName(data?.school_name);
            await setPassingYearClass8(data.class8_passing_year);
            await setDistrictClass8(data.class8_passing_district_id);
            await setSchoolNameClass8(data.class8_school_name);
            await setPassingYearClass5(data.class5_passing_year);
            await setDistrictClass5(data.class5_passing_district_id);
            await setSchoolNameClass5(data.class5_school_name);
            await setDomicileDistrict(data.domicile_district_id);
            await setFatherDomicileDistrict(data.father_domicile_district_id);
            await setFatherOccupation(data.father_occupation);
            await setAddressRegion(data.address_region_id);
            data?.address_district_id &&
              (await setAddressDistrict(data?.address_district_id));
            await setHomeAddress(data?.home_address);
            data?.marksheet_image &&
              (await setMarksheetImage(data.marksheet_image));
            await setRegion(data.region_id);
            setQrCodePath(data?.qr_code_path);
            setZone(data?.zone_id);
            setAppliedDate(data?.created_at);

            const resAdmissions = await ApiCalls.isInOlevelAdmissions(data1);

            if (resAdmissions?.data?.data?.length === 0) {
              await setStep(2);
            } else if (resAdmissions?.data?.data) {
              await setCollegeId(resAdmissions?.data?.data?.college_id);
              await setFacultyId(resAdmissions?.data?.data?.faculty_id);
              await setAdmissionStatus(resAdmissions?.data?.data?.status_id);
              setClaimCount(resAdmissions?.data?.data?.claim_count);
              if (parseInt(resAdmissions?.data?.data?.class_id) === 1) {
                setStep(2);
              } else {
                setAlert("Something went wrong, Please try again!", "error");
              }
            } else {
              await setStep(2);
            }
          }
        } else {
          await setOpenBackDrop(false);
          await setAlert("Something went wrong, Please try again!", "error");
        }
      } else if (resApplied?.data?.error?.message) {
        await setOpenBackDrop(false);
        await setAlert(resApplied?.data?.error?.message, "error");
      } else {
        await setOpenBackDrop(false);
        await setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  return (
    <div>
      <SimpleBackdrop openBackDrop={openBackDrop} />
      {step === 1 && (
        <div>
          <StatusContainer style={{ backgroundImage: `url(${background})` }}>
            <StatusContent>
              <StatusItem>
                <div style={{ marginBottom: "30px" }}>
                  <h1>Check Status</h1>
                </div>
                <div style={{ marginBottom: "2%" }}>
                  <TextField
                    fullWidth
                    id="outlined-mycnic"
                    placeholder="12345-1234567-1"
                    label="CNIC / B-FORM No"
                    type="text"
                    variant="outlined"
                    name="cnic"
                    value={cnic}
                    onChange={handleChange}
                  />
                </div>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-passing-year">
                    Select Passing Year
                  </InputLabel>
                  <Select
                    native
                    value={passingYear}
                    onChange={handleChange}
                    label="Select Passing Year"
                    name="passingYear"
                  >
                    <option aria-label="None" value="" />
                    {olevelPassingYears.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ marginTop: "30px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClick}
                  >
                    Check
                  </Button>
                </div>
              </StatusItem>
              <WhiteAd />
            </StatusContent>
          </StatusContainer>
        </div>
        // eslint-disable-next-line react/jsx-no-comment-textnodes
      )}

      {step === 2 && (
        <Preview2
          province={province}
          board={board}
          studyGroup={studyGroup}
          subject={subject}
          totalMarks={totalMarks}
          obtainedMarks={obtainedMarks}
          schoolName={schoolName}
          imageMarksheet={imageMarksheet}
          imageMarksheetName={imageMarksheetName}
          appliedId={appliedId}
          approved={approved}
          fullName={fullName}
          fatherName={fatherName}
          email={email}
          mobileNumber={mobileNumber}
          gender={gender}
          nationality={nationality}
          dateOfBirth={dateOfBirth}
          placeOfBirth={placeOfBirth}
          religion={religion}
          fatherOccupation={fatherOccupation}
          cnic={cnic}
          fatherMobileNumber={fatherMobileNumber}
          fatherCnic={fatherCnic}
          motherCnic={motherCnic}
          domicileDistrict={domicileDistrict}
          fatherDomicileDistrict={fatherDomicileDistrict}
          imageProfile={imageProfile}
          imageProfileName={imageProfileName}
          profileImage={profileImage}
          showProfileImage={showProfileImage}
          addressRegion={addressRegion}
          addressDistrict={addressDistrict}
          homeAddress={homeAddress}
          ninthRollNo={ninthRollNo}
          matricRollNo={matricRollNo}
          passingYear={passingYear}
          passingYearClass5={passingYearClass5}
          passingYearClass8={passingYearClass8}
          schoolNameClass5={schoolNameClass5}
          schoolNameClass8={schoolNameClass8}
          districtClass5={districtClass5}
          districtClass8={districtClass8}
          region={region}
          faculty={faculty}
          selectedColleges={selectedColleges}
          setOpenBackDrop={setOpenBackDrop}
          availInDataBase={availInDataBase}
          setStep={setStep}
          collegeId={collegeId}
          facultyId={facultyId}
          admissionStatus={admissionStatus}
          qrCodePath={qrCodePath}
        />
      )}

      {step === 3 && (
        <Admission
          collegeId={collegeId}
          facultyId={facultyId}
          admissionStatus={admissionStatus}
          setOpenBackDrop={setOpenBackDrop}
        />
      )}

      <CustomAlert
        open={open}
        message={message}
        severity={severity}
        handleClose={handleClose}
      />
    </div>
  );
};

export default OlevelStatus;
