import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CustomAlert from "../../common/CustomAlert";
import isEmpty from "../../../helpers/validation";
import * as ApiCalls from "../../../services/ApiCalls";
import axios from "axios";
import { getUser } from "../../../functions/functions";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  FragmentContainer,
  FragmentHeader,
  FragmentHeaderH1,
  FragmentContent,
} from "./FragmentsElements";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function AddZone(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [zone, setZone] = useState("");
  const [zone2, setZone2] = useState("");
  const [zones, setZones] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [region, setRegion] = useState();
  const [region2, setRegion2] = useState();
  const [regions, setRegions] = useState([]);
  const [district, setDistrict] = useState();
  const [subdivisions, setSubdivisions] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [colleges2, setColleges2] = useState([]);
  const [collegeId, setCollegeId] = useState();
  const [subdivision, setSubdivision] = useState();
  const [genders, setGenders] = useState([]);
  const [gender, setGender] = useState();
  const [faculties, setFaculties] = useState([]);
  const [faculty, setFaculty] = useState([]);
  const [facultySeat, setFacultySeat] = useState([]);
  const [facultyGender, setFacultyGender] = useState([]);
  const [ddo, setDdo] = useState("");
  const [college, setCollege] = useState([]);
  const [college2, setCollege2] = useState([]);
  const [seats, setSeats] = useState();
  const user = getUser(localStorage?.jwtToken);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const setAlert = (message, severity) => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  useEffect(async () => {
    window.scrollTo(0, 0);
    let resRegions;

    if (user?.userRole === "rd") {
      resRegions = await axios.get(
        `https://www.dgcs.gos.pk/seccap2/regions/${user?.userId}`
      );
    } else {
      resRegions = await axios.get("https://www.dgcs.gos.pk/seccap2/regions");
    }

    const genderRes = await axios.get(
      "https://www.dgcs.gos.pk/seccap2/colleges/genders"
    );

    const facultiesRes = await axios.get(
      "https://www.dgcs.gos.pk/seccap2/faculties"
    );

    setRegions(resRegions.data.data);
    // setDistricts(res.data.data);
    setGenders(genderRes.data.data);
    setFaculties(facultiesRes.data.data);
  }, []);

  const getZones = async (region_id) => {
    const res = await ApiCalls.getZones(region_id);
    if (res?.data?.status === "success") {
      setZones(res?.data?.data);
    }
  };

  const handleChange = (event) => {
    if (event.target.name === "region") {
      setDistrict("");
      setSubdivision("");
      setRegion(event.target.value);
      getColleges(event.target.value, 1);
    } else if (event.target.name === "gender") {
      setFaculty([]);
      setFacultySeat([]);
      setFacultyGender([]);
      setGender(event.target.value);
    } else if (event.target.name === "ddo") {
      setDdo(event.target.value);
    } else if (event.target.name === "college") {
      setCollege(event.target.value);
    } else if (event.target.name === "seats") {
      setSeats(event.target.value);
    } else if (event.target.name === "region2") {
      setRegion2(event.target.value);
      getColleges(event.target.value, 2);
      getZones(event.target.value);
    } else if (event.target.name === "zone2") {
      setZone2(event.target.value);
    }
  };

  const getDistricts = async (region_id) => {
    const res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/districts/get",
      { region_id },
      {
        headers: {
          Authorization: `${localStorage.getItem("jwtToken")}`,
        },
      }
    );

    setDistricts(res.data.data);
  };

  const getSubDivisions = async (district_id) => {
    const res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/subdivisions/district",
      { district_id },
      {
        headers: {
          Authorization: `${localStorage.getItem("jwtToken")}`,
        },
      }
    );

    setSubdivisions(res.data.data);
  };

  const getColleges = async (region_id, college) => {
    const res = await axios.post(
      "https://www.dgcs.gos.pk/seccap2/colleges/remainingZoneColleges",
      { region_id },
      {
        headers: {
          Authorization: `${localStorage.getItem("jwtToken")}`,
        },
      }
    );

    if (college === 1) {
      setColleges(res?.data?.data);
    } else if (college === 2) {
      setColleges2(res?.data?.data);
    }
  };

  const handleChangeMultiple = (event, college) => {
    if (college === 1) {
      setCollege(event?.target?.value);
    } else if (college === 2) {
      setCollege2(event?.target?.value);
    }
  };

  const handleClick = async () => {
    if (isEmpty(region)) {
      setAlert("Please fill out region field!", "error");
    } else if (isEmpty(zone)) {
      setAlert("Please fill out zone field!", "error");
    } else if (isEmpty(college)) {
      setAlert("Please select college!", "error");
    } else {
      const data = {
        region_id: region,
        zone,
        college,
      };

      const res = await axios.post(
        "https://www.dgcs.gos.pk/seccap2/zones/add",
        data,
        { headers: { Authorization: localStorage.getItem("jwtToken") } }
      );

      if (res?.data?.status === "success") {
        setAlert("Zone has been added successfully!", "success");
        setTimeout(() => {
          props.setFragment("zones");
        }, 2000);
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, "error");
      } else {
        setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  const handleClick2 = async () => {
    if (isEmpty(region2)) {
      setAlert("Please fill out region field!", "error");
    } else if (isEmpty(zone2)) {
      setAlert("Please fill out zone field!", "error");
    } else if (isEmpty(college2)) {
      setAlert("Please select college!", "error");
    } else {
      const data = {
        region_id: region,
        zone_id: zone2,
        college2,
      };

      const res = await axios.post(
        "https://www.dgcs.gos.pk/seccap2/zones/addCollege",
        data,
        { headers: { Authorization: localStorage.getItem("jwtToken") } }
      );

      if (res?.data?.status === "success") {
        setAlert("Zone has been added successfully!", "success");
        setTimeout(() => {
          props.setFragment("zones");
        }, 2000);
      } else if (res?.data?.error?.message) {
        setAlert(res?.data?.error?.message, "error");
      } else {
        setAlert("Something went wrong, Please try again!", "error");
      }
    }
  };

  return (
    <div>
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item md={12}>
                <FragmentHeaderH1>Add Zone</FragmentHeaderH1>
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider />
        <Container>
          <FragmentContent>
            <div style={{ width: "100%" }}>
              <div>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Region
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={region}
                    onChange={handleChange}
                    label="Region"
                    name="region"
                  >
                    {regions?.map((item) => (
                      <MenuItem value={item?.id}>{item?.region}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                <TextField
                  fullWidth
                  id="outlined-seat"
                  label="Zone"
                  onChange={(e) => setZone(e.target.value)}
                  name={zone}
                  type="text"
                  variant="outlined"
                />
              </div>

              <div style={{ marginBottom: "20px" }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Colleges
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    multiple
                    value={college}
                    onChange={(e) => handleChangeMultiple(e, 1)}
                    input={<Input />}
                    MenuProps={MenuProps}
                  >
                    {colleges?.length > 0 &&
                      colleges?.map((item) => (
                        <MenuItem
                          key={item?.id}
                          name={item?.id}
                          value={item?.id}
                          style={getStyles(item?.id, item?.college_name, theme)}
                        >
                          {item?.ddo_code} {item?.college_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick}
                >
                  Add
                </Button>
              </div>

              <CustomAlert
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
              />
            </div>
          </FragmentContent>
        </Container>
      </FragmentContainer>
      <FragmentContainer>
        <Container fixed>
          <FragmentHeader>
            <Grid container spacing={5}>
              <Grid item md={12}>
                <FragmentHeaderH1>Add College in Zone</FragmentHeaderH1>
              </Grid>
            </Grid>
          </FragmentHeader>
        </Container>
        <Divider />
        <Container>
          <FragmentContent>
            <div style={{ width: "100%" }}>
              <div style={{ marginBottom: "20px" }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Region
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={region2}
                    onChange={handleChange}
                    label="Region"
                    name="region2"
                  >
                    {regions?.map((item) => (
                      <MenuItem value={item?.id}>{item?.region}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div style={{ marginBottom: "20px" }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Zone
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={zone2}
                    onChange={handleChange}
                    label="Zone"
                    name="zone2"
                  >
                    {zones?.map((item) => (
                      <MenuItem value={item?.id}>{item?.zone}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div style={{ marginBottom: "20px" }}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Colleges
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    multiple
                    value={college2}
                    onChange={(e) => handleChangeMultiple(e, 2)}
                    input={<Input />}
                    MenuProps={MenuProps}
                  >
                    {colleges2?.length > 0 &&
                      colleges2?.map((item) => (
                        <MenuItem
                          key={item?.id}
                          name={item?.id}
                          value={item?.id}
                          style={getStyles(item?.id, item?.college_name, theme)}
                        >
                          {item?.ddo_code} {item?.college_name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClick2}
                >
                  Add
                </Button>
              </div>

              <CustomAlert
                open={open}
                message={message}
                severity={severity}
                handleClose={handleClose}
              />
            </div>
          </FragmentContent>
        </Container>
      </FragmentContainer>
    </div>
  );
}
